import { defineMessages } from 'react-intl'

const messages = defineMessages({
  addAttachment: {
    id: 'permits.addAttachment',
    description: 'Label for permit attachment button',
    defaultMessage: '?? Add new attachment',
  },
  attachmentEdit: {
    id: 'permits.attachmentEdit',
    description: 'Label for attachment edit button',
    defaultMessage: '?? Edit',
  },
  attachmentRemove: {
    id: 'permits.attachmentRemove',
    description: 'Label for attachment remove button',
    defaultMessage: '?? Remove file',
  },
  attachmentSave: {
    id: 'permits.attachmentSave',
    description: 'Label for attachment save button',
    defaultMessage: '?? Save',
  },
  attachmentCancelEditing: {
    id: 'permits.attachmentCancelEditing',
    description: 'Label for attachment cancel edit button',
    defaultMessage: '?? Cancel',
  },
  attachmentCancelUpload: {
    id: 'permits.attachmentCancelUpload',
    description: 'Label for attachment cancelling button',
    defaultMessage: '?? Cancel uploading',
  },
  attachmentStatusUploading: {
    id: 'permits.attachmentStatusUploading',
    description: 'Label for attachment status',
    defaultMessage: '?? Uploading...',
  },
  attachmentStatusInProgress: {
    id: 'permits.attachmentStatusInProgress',
    description: 'Label for attachment status',
    defaultMessage: '?? In progress...',
  },
  attachmentStatusUnknown: {
    id: 'permits.attachmentStatusUnknown',
    description: 'Label for attachment status',
    defaultMessage: '?? Unknown',
  },
  attachmentUploadedCount: {
    id: 'permits.attachmentUploadedCount',
    description: 'Text presentation of uploaded attachment count, with variable {attachmentCount}',
    defaultMessage: '?? Uploaded {attachmentCount, plural, one {# file} other {# files}}',
  },
  attachmentUploaded: {
    id: 'permits.attachmentUploaded',
    description: 'Text presentation of uploaded document',
    defaultMessage: 'Attachment uploaded',
  },
  attachmentUploadFailed: {
    id: 'permits.attachmentUploadFailed',
    description: 'Text presentation of failed attachment upload',
    defaultMessage: 'Attachment upload failed',
  },
  deleteAttachmentModalCancel: {
    id: 'permits.deleteAttachmentModalCancel',
    description: 'Cancel button text in modal',
    defaultMessage: '?? Cancel',
  },
  deleteAttachmentModalDelete: {
    id: 'permits.deleteAttachmentModalDelete',
    description: 'Continue button text in modal',
    defaultMessage: '?? Delete',
  },
  deleteAttachmentModalTitle: {
    id: 'permits.deleteAttachmentModalTitle',
    description: 'Title of modal',
    defaultMessage: '?? Delete attachment',
  },
  deleteAttachmentModalMessage: {
    id: 'permits.deleteAttachmentModalMessage',
    description: 'Message of modal',
    defaultMessage: '?? Do you really want to delete attachment "{filename}"?',
  },
  deleteAttachmentErrors: {
    id: 'permits.deleteAttachmentErrors',
    description: 'Text in notification',
    defaultMessage: '?? An error occurred while deleting attachment. Please try again later.',
  },
  attachmentDeleted: {
    id: 'permits.attachmentDeleted',
    description: 'Text in notification',
    defaultMessage: '?? Attachment deleted',
  },
  attachmentAdditionalHelp: {
    id: 'permits.attachmentAdditionalHelp',
    description: 'Text in dropzone',
    defaultMessage: '?? or add file dragging it here.',
  },
  attachmentUploadFailureReasonCancelled: {
    id: 'permits.attachmentUploadFailureReasonCancelled',
    description: 'Text in notification',
    defaultMessage: '?? Cancelled',
  },
  attachmentUploadFailureReasonTooLarge: {
    id: 'permits.attachmentUploadFailureReasonTooLarge',
    description: 'Text in notification',
    defaultMessage: '?? File is too large',
  },
  attachmentUploadFailureReasonUnknown: {
    id: 'permits.attachmentUploadFailureReasonUnknown',
    description: 'Text in notification',
    defaultMessage: '?? Unknown',
  },
  attachmentInformationModalCancel: {
    id: 'permits.attachmentInformationModalCancel',
    description: 'Cancel button text in modal',
    defaultMessage: '?? Cancel',
  },
  attachmentInformationModalContinue: {
    id: 'permits.attachmentInformationModalContinue',
    description: 'Continue button text in modal',
    defaultMessage: '?? Add',
  },
  attachmentInformationModalTitle: {
    id: 'permits.attachmentInformationModalTitle',
    description: 'Title of modal',
    defaultMessage: '?? Choose attachment type',
  },
  failedAttachmentsTitle: {
    id: 'permits.failedAttachmentsTitle',
    description: 'Title of section',
    defaultMessage: '?? The following attachments failed to upload',
  },
  declinedAttachmentsTitle: {
    id: 'permits.declinedAttachmentsTitle',
    description: 'Title of error message',
    defaultMessage: '?? The following attachments were declined',
  },
  attachmentGenericFetchError: {
    id: 'permits.attachmentGenericFetchError',
    description: 'Error message',
    defaultMessage: '?? An error occurred while loading attachments. Please try again later.',
  },
  attachmentGenericUploadErrorTitle: {
    id: 'permits.attachmentGenericUploadErrorTitle',
    description: 'Error message',
    defaultMessage: '?? An error occurred while uploading attachments.',
  },
  attachmentGenericUploadErrorMessage: {
    id: 'permits.attachmentGenericUploadErrorMessage',
    description: 'Error message',
    defaultMessage: '?? An error occurred while uploading attachments. Please try again later.',
  },
  attachmentGenericUploadError: {
    id: 'permits.attachmentGenericUploadError',
    description: 'Error message',
    defaultMessage: '?? Please verify that the file is in PDF format and the size does not exceed {maxFileSize}MB.',
  },
  attachmentNotFound: {
    id: 'permits.attachmentNotFound',
    description: 'Error message',
    defaultMessage: '?? Attachment not found',
  },
})

export default {
  ...messages,
}

