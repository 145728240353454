import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { has, filter, get, chain, includes, isEqual } from 'lodash'
import ReactMarkdown from 'react-markdown'
import { uniqueId, markdownLinkToText } from 'src/utils'
import { messages as validationMessages } from 'src/utils/validation'
import InfoElementHeaderArea from 'src/components/form_v2/InfoElementHeaderArea'
import repetitiveComponentMessages from 'src/routes/permits/permit/components/RepetitiveComponent/messages'
import ErrorList from '../ErrorList'
import ItemForm from './components/ItemForm'
import ItemSingleForm from './components/ItemSingleForm'
import ItemsTable from './components/ItemsTable'
import ItemsTableReadOnly from './components/ItemsTableReadOnly'
import {
  PERMIT_GOODSLOCATION_DISABLED_BY_TYPE_CODE,
  PERMIT_GOODSLOCATION_INPUT_TYPE_ADDRESS,
  PERMIT_GOODSLOCATION_DEFAULT_VALUES,
  PERMIT_GOODSLOCATION_FIELDS_MAP,
} from './constants'
import {
  validateFormValues,
} from './utils'

class GoodsLocation extends React.Component {
  constructor(props) {
    super(props)
    this.infoElementName = props.infoElement.code
    this.isSingleInfoElement = props.infoElement.repeat === 1
    this.mappedFields = PERMIT_GOODSLOCATION_FIELDS_MAP[props.infoElement.code]
    this.fieldValueSourceName = `${props.infoElement.code}.${this.mappedFields.location}FieldValueSource`
    this.state = {
      selectedItem: null,
      isDisabled: false,
      disabledInfoText: null,
      isReadOnly: false,
      justCancelled: false,
      showValidation: false,
    }
    this.addNew = this.addNew.bind(this)
    this.cancelEditing = this.cancelEditing.bind(this)
    this.selectItem = this.selectItem.bind(this)
    this.updateItem = this.updateItem.bind(this)
    this.deleteItem = this.deleteItem.bind(this)
  }

  componentDidMount() {
    this.checkInfoElementStatus()
  }

  componentDidUpdate(prevProps) {
    const submits = get(this.props, 'formApi.submits', 0)
    const prevSubmits = get(prevProps, 'formApi.submits', 0)
    if (submits > prevSubmits) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ showValidation: true })
      this.validateValues(true)
    }

    const values = get(this.props, `formApi.values.${this.props.infoElement.code}`)
    const prevValues = get(prevProps, `formApi.values.${this.props.infoElement.code}`)
    if (!isEqual(values, prevValues)) {
      this.validateValues()
    }
  }

  setDefaultValue(disabledInfoText) {
    const { formApi, infoElement } = this.props
    formApi.setValue(infoElement.code, [{
      [this.mappedFields.location]: markdownLinkToText(disabledInfoText).slice(0, 249),
      /* eslint-disable max-len */
      [`${this.mappedFields.location}FieldValueSource`]: PERMIT_GOODSLOCATION_INPUT_TYPE_ADDRESS,
      [`${this.mappedFields.countryCode}`]: PERMIT_GOODSLOCATION_DEFAULT_VALUES.countryCode,
      [`${this.mappedFields.typeCode}`]: PERMIT_GOODSLOCATION_DEFAULT_VALUES.typeCode,
      [`${this.mappedFields.identificationQualifier}`]: PERMIT_GOODSLOCATION_DEFAULT_VALUES.identificationQualifier,
      /* eslint-enable max-len */
    }])
  }

  validateValues() {
    const { formApi, infoElement } = this.props
    if (this.isSingleInfoElement) {
      const formState = formApi.getFormState()
      const hasFieldValueSourceSelected = get(formState, `values.${this.fieldValueSourceName}`)
      const hasFieldValueSourceError = get(formState, `errors.${this.fieldValueSourceName}`)
      const isValid = validateFormValues(infoElement, get(formState, 'values', {}))

      if (!isValid && !hasFieldValueSourceSelected && !hasFieldValueSourceError) {
        formApi.setError(`${this.fieldValueSourceName}`, validationMessages.required)
      }
      if (hasFieldValueSourceSelected && hasFieldValueSourceError) {
        formApi.setError(`${infoElement.code}.${this.mappedFields.location}FieldValueSource`, null)
      }
    }
  }

  checkInfoElementStatus() {
    const { structure, intl: { formatMessage } } = this.props
    const typeCode = get(structure, 'code')
    const isDisabled = includes(PERMIT_GOODSLOCATION_DISABLED_BY_TYPE_CODE, typeCode)
    const disabledInfoText = isDisabled ? formatMessage({ id: `/permits/infoelements/${this.infoElementName}_${typeCode}` }) : null
    const isReadOnly = has(this.props, 'type') && this.props.type === 'readonly'
    if (isDisabled) {
      this.setDefaultValue(disabledInfoText)
    }
    this.setState({
      isDisabled,
      disabledInfoText,
      isReadOnly,
    })
  }

  addNew(data) {
    if (!this.isDisabled) {
      const { formApi, infoElement } = this.props
      if (!has(formApi.values, infoElement.code)) {
        formApi.values[infoElement.code] = []
      }
      const newItems = formApi.values[infoElement.code]
      newItems.push({
        ...data,
        id: uniqueId(),
      })
      formApi.setValue(infoElement.code, newItems)
    }
  }

  cancelEditing() {
    this.setState({ selectedItem: null, justCancelled: true })
    setTimeout(() => {
      this.setState({ justCancelled: false })
    }, 500)
  }

  selectItem(item, index) {
    if (!get(this.state, 'justCancelled')) {
      this.setState({
        selectedItem: item,
        selectedItemIndex: index,
      })
    }
  }

  updateItem(row) {
    if (!this.isDisabled) {
      const { formApi, infoElement } = this.props
      const newItems = filter(formApi.values[infoElement.code], item => item.id !== row.id)
      newItems.push(row)
      formApi.setValue(infoElement.code, newItems)
    }
  }

  deleteItem(rowId) {
    if (!this.isDisabled) {
      const { formApi, infoElement } = this.props
      const newItems = filter(formApi.values[infoElement.code].slice(0), row => row.id !== rowId)
      formApi.setValue(infoElement.code, newItems)
    }
  }

  render() {
    const {
      infoElement,
      locale,
      formApi,
      renderField,
      codesets,
      structure,
      intl,
    } = this.props
    const {
      isDisabled,
      disabledInfoText,
      isReadOnly,
      showValidation,
    } = this.state

    const values = formApi.values[infoElement.code] || []
    const hasError = showValidation && infoElement.mandatory && values.length === 0

    const fieldsToShow = {
      [get(PERMIT_GOODSLOCATION_FIELDS_MAP[infoElement.code], 'countryCode')]: 1,
      [get(PERMIT_GOODSLOCATION_FIELDS_MAP[infoElement.code], 'typeCode')]: 2,
      [get(PERMIT_GOODSLOCATION_FIELDS_MAP[infoElement.code], 'locationType')]: 2,
      [get(PERMIT_GOODSLOCATION_FIELDS_MAP[infoElement.code], 'identificationQualifier')]: 3,
      [get(PERMIT_GOODSLOCATION_FIELDS_MAP[infoElement.code], 'location')]: 4,
    }

    let fields = infoElement.fields
    if (this.isSingleInfoElement) {
      fields = infoElement.fields.map(field => ({
        ...field,
        code: field.code.split('.')[1],
      }))
    }

    fields = chain(fields)
      .filter(field => has(fieldsToShow, field.code))
      .sortBy(field => fieldsToShow[field.code])
      .value()

    return (
      <Col xs={12}>
        <Row>
          <Col xs={12} className="noSidePadding">
            <hr />
            <InfoElementHeaderArea
              infoElement={infoElement}
              locale={locale}
              showHelp
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="noSidePadding">
            {!isReadOnly && !isDisabled && this.isSingleInfoElement &&
              <ItemSingleForm
                key="itemSingleForm"
                formApi={formApi}
                locale={locale}
                intl={this.props.intl}
                permitTypeCode={structure.code}
                infoElement={infoElement}
                renderField={renderField}
              />
            }
            {!isReadOnly && !isDisabled && !this.isSingleInfoElement && [
              <ItemForm
                key="itemForm"
                selectedItem={this.state.selectedItem}
                cancelEditing={this.cancelEditing}
                updateItem={this.updateItem}
                deleteItem={this.deleteItem}
                locale={locale}
                intl={this.props.intl}
                permitTypeCode={structure.code}
                infoElement={infoElement}
                renderField={renderField}
                addNew={this.addNew}
              />,
              <ItemsTable
                key="itemsTable"
                items={values}
                fields={fields}
                locale={locale}
                codesets={codesets}
                selectItem={(item, index) => this.selectItem(item, index)}
                deleteItem={this.deleteItem}
              />,
            ]}
            {isReadOnly && !isDisabled && <ItemsTableReadOnly
              infoElement={infoElement}
              fields={fields}
              locale={locale}
              codesets={codesets}
              selectItem={(item, index) => this.selectItem(item, index)}
              deleteItem={this.deleteItem}
              values={values}
              formApi={formApi}
              renderErrors={() => (
                <ErrorList errors={formApi.errors} infoElement={infoElement} />
              )}
            />}
            {isDisabled &&
              <ReactMarkdown
                source={disabledInfoText}
                linkTarget="_blank"
              />
            }
            {!this.isSingleInfoElement && infoElement.mandatory && !isReadOnly && !isDisabled &&
              <Row>
                <Col md={8} sm={12}>
                  <div className={hasError ? 'has-error' : ''}>
                    <p id={`new-row-description-${infoElement.code}`} className="help-block">
                      {intl.formatMessage(repetitiveComponentMessages.mandatoryRowDescription)}
                    </p>
                  </div>
                </Col>
              </Row>
            }
          </Col>
        </Row>
      </Col>
    )
  }
}

const mapStateToProps = state => ({
  codesets: get(state, 'codeset.cachedCodesets', []),
})

export default connect(mapStateToProps, undefined)(
  injectIntl(GoodsLocation)
)
