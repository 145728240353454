import React from 'react'
import { Row, Col } from 'react-bootstrap'
import InfoElementHeaderArea from 'src/components/form_v2/InfoElementHeaderArea'

export default function SmallHeaderInfoElement(props) {
  const { infoElement, locale, renderField } = props

  return (
    <Col sm={6} xs={12}>
      <Row>
        <Col xs={12}>
          <InfoElementHeaderArea
            infoElement={infoElement}
            locale={locale}
            small
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          {infoElement.fields.map(field => renderField({
            field,
          }))}
        </Col>
      </Row>
    </Col>
  )
}
