import { handleActions } from 'redux-actions'
import { CMS_CONTENT, CMS_SWITCH_LOCALE } from '../constants'

const filterMessages = (locale, content) => {
  const messages = {}
  if (content) {
    Object.keys(content).forEach((key) => {
      messages[key] = content[key][locale]
    })
  }
  return messages
}

export default handleActions({
  [CMS_SWITCH_LOCALE]: {
    next: (state, action) => ({
      ...state,
      cmsLocale: action.payload.locale,
      cmsMessages: filterMessages(action.payload.locale, state.cmsContent),
      error: null,
    }),
    throw: (state, action) => ({
      ...state,
      error: action.payload.message,
    }),
  },
  [CMS_CONTENT]: {
    next: (state, action) => ({
      ...state,
      cmsContentLoaded: true,
      cmsContent: action.payload.data,
      cmsLocale: action.payload.locale,
      cmsMessages: filterMessages(action.payload.locale, action.payload.data),
      error: null,
    }),
    throw: (state, action) => ({
      ...state,
      cmsContentLoaded: false,
      error: action.payload.message,
    }),
  },
}, {
  cmsContent: null,
  cmsLocale: null,
  cmsContentLoaded: false,
  requestingContent: false,
  error: null,
})
