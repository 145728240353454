import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { find, endsWith, xor, get } from 'lodash'
import InfoElementHeaderArea from 'src/components/form_v2/InfoElementHeaderArea'
import { GUARANTOR_CODE_UNDERTAKING } from '../constants'

export default class GuaranteeForm extends React.Component {
  constructor(props) {
    super(props)
    const guaranteeForm = get(this.props.formApi.values.guaranteeForm, 'guaranteeForm')
    this.state = {
      showGuarantorFields: guaranteeForm === GUARANTOR_CODE_UNDERTAKING,
      guaranteeForm: guaranteeForm === GUARANTOR_CODE_UNDERTAKING,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const selectedGuaranteeForm = get(nextProps.formApi.values.guaranteeForm, 'guaranteeForm')
    if (selectedGuaranteeForm !== this.state.guaranteeForm) {
      this.setState({
        guaranteeForm: selectedGuaranteeForm,
        showGuarantorFields: selectedGuaranteeForm === GUARANTOR_CODE_UNDERTAKING,
      })
    }
  }

  render() {
    const { infoElement, locale, renderField } = this.props
    const codeField = find(infoElement.fields, field => endsWith(field.code, 'guaranteeForm'))
    const nameField = find(infoElement.fields, field => endsWith(field.code, 'guarantorName'))
    const addressField = find(infoElement.fields, field => endsWith(field.code, 'guarantorAddress'))
    const countryCodeField = find(infoElement.fields, field => endsWith(field.code, 'guarantorCountryCode'))
    const postcodeField = find(infoElement.fields, field => endsWith(field.code, 'guarantorPostcode'))
    const cityField = find(infoElement.fields, field => endsWith(field.code, 'guarantorCity'))
    const additionalInfoField = find(infoElement.fields, field =>
      endsWith(field.code, 'guarantorAdditionalInformation')
    )

    const otherFields = xor(infoElement.fields, [
      codeField,
      nameField,
      addressField,
      countryCodeField,
      postcodeField,
      cityField,
      additionalInfoField,
    ])

    return (
      <Col sm={6} xs={12}>
        <Row>
          <Col xs={12}>
            <InfoElementHeaderArea
              infoElement={infoElement}
              locale={locale}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            {codeField && renderField({
              field: codeField,
            })}
          </Col>
          {this.state.showGuarantorFields && [
            <Col xs={12} key="guarantorName">
              {nameField && renderField({
                field: nameField,
              })}
            </Col>,
            <Col xs={12} key="guarantorAddress">
              {addressField && renderField({
                field: addressField,
              })}
            </Col>,
            <Col xs={12} key="guarantorPostcode">
              {postcodeField && renderField({
                field: postcodeField,
              })}
            </Col>,
            <Col xs={12} key="guarantorCity">
              {cityField && renderField({
                field: cityField,
              })}
            </Col>,
            <Col xs={12} key="guarantorCountryCode">
              {countryCodeField && renderField({
                field: countryCodeField,
              })}
            </Col>,
          ]}
          <Col xs={12} key="guarantorAdditionalInformation">
            {additionalInfoField && renderField({
              field: additionalInfoField,
            })}
          </Col>
          <Col xs={12}>
            {otherFields && otherFields.map(field => renderField({
              field,
            }))}
          </Col>
        </Row>
      </Col>
    )
  }
}
