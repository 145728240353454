import React from 'react'
import Icon from 'src/components/Icon'

function IconRenderer({ src, alt: type }) {
  if (type === 'ICON') {
    const iconName = src
    return <Icon name={iconName} md style={{ color: '#00205b' }} />
  }
  return null
}

export default IconRenderer
