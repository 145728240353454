import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Button, ButtonGroup } from 'react-bootstrap'
import md5 from 'md5'
import { injectIntl, FormattedMessage, defineMessages } from 'react-intl'
import { connect } from 'react-redux'
import LinkRenderer from './LinkRenderer'
import styles from './CookieNotice.scss'
import { setShowCookieNotice } from '../actions'

const messages = defineMessages({
  cookieBanner: {
    id: 'termsOfService.cookieNotice.cookieBanner',
    description: 'Aria label',
    defaultMessage: 'Cookie banner',
  },
  buttonMandatoryText: {
    id: 'termsOfService.cookieNotice.buttonMandatoryText',
    description: 'Label of button',
    defaultMessage: 'I accept only necessary cookies',
  },
  buttonAllText: {
    id: 'termsOfService.cookieNotice.buttonAllText',
    description: 'Label of button',
    defaultMessage: 'I accept all cookies',
  },
  cookieNotice: {
    id: '/authentication/cookieNotice',
    defaultMessage: 'Cookies...',
  },
  termsOfServiceVersion: {
    id: '/authentication/termsOfServiceVersion',
    defaultMessage: 'Terms of service...',
  },
})

class CookieNotice extends React.Component {
  constructor(props) {
    super(props)
    this.onClickAcceptAll = this.onClickAcceptAll.bind(this)
    this.onClickAcceptMandatory = this.onClickAcceptMandatory.bind(this)
    this.props.setShowCookieNotice(this.isCookiesNoticeShown(props))
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.cookies.showNotice !== this.props.cookies.showNotice) {
      this.props.setShowCookieNotice(this.isCookiesNoticeShown(nextProps))
    }
  }

  onClickAcceptMandatory() {
    this.acceptCookies('mandatory')
  }

  onClickAcceptAll() {
    this.acceptCookies('all')
  }

  acceptCookies = (mode) => {
    const { intl: { formatMessage } } = this.props
    const termsOfServiceVersion = formatMessage(messages.termsOfServiceVersion)

    if (mode === 'all' && document.referrer && sessionStorage.getItem('__snbref') === null) {
      sessionStorage.setItem('__snbref', document.referrer)
    }

    localStorage.setItem('acceptedCookieNotice', md5(termsOfServiceVersion))
    localStorage.setItem('cookieMode', mode)
    this.props.setShowCookieNotice(false)
    this.props.onAccept()
  }

  isCookiesNoticeShown = (props) => {
    const { intl: { formatMessage } } = props
    const termsOfServiceVersion = formatMessage(messages.termsOfServiceVersion)
    const acceptedTermsFromLocalStorage = localStorage.getItem('acceptedCookieNotice')
    let cookieNotice = formatMessage(messages.cookieNotice)
    if (cookieNotice === messages.cookieNotice.id) {
      cookieNotice = ''
    }

    return cookieNotice && acceptedTermsFromLocalStorage !== md5(termsOfServiceVersion) && props.cookies.showNotice
  }

  render() {
    const {
      intl: { formatMessage },
      cookies,
    } = this.props

    if (cookies.showNotice) {
      return (
        <div id="cookieNotice-accesibility" tabIndex={-1} className={styles.cookieNotice} role="region" aria-label={formatMessage(messages.cookieBanner)} >
          <div className={styles.disclaimerContent}>
            <div className={styles.disclaimerNote}>
              <ReactMarkdown
                source={formatMessage(messages.cookieNotice)}
                renderers={{ Link: LinkRenderer }}
                target={'_blank'}
              />
            </div>
            <ButtonGroup className={styles.buttons}>
              <Button
                className={styles.acceptButton}
                onClick={this.onClickAcceptAll}
                id-qa-test={'btn-accept-all'}
              >
                <FormattedMessage {...messages.buttonAllText} />
              </Button>
              <Button
                className={styles.acceptButton}
                onClick={this.onClickAcceptMandatory}
                id-qa-test={'btn-accept'}
              >
                <FormattedMessage {...messages.buttonMandatoryText} />
              </Button>
            </ButtonGroup>
          </div>
        </div>
      )
    }
    return null
  }
}


const mapStateToProps = ({
  cookies,
}) => ({
  cookies,
})

const mapActionCreators = {
  setShowCookieNotice,
}

export default injectIntl(connect(mapStateToProps, mapActionCreators)(CookieNotice))
