import {
  has,
  get,
  isArray,
  filter,
  set,
  find,
  includes,
  pick,
  isNil,
  mapKeys,
} from 'lodash'
import moment from 'moment'
import Papa from 'papaparse'
import {
  messages as validationMessages,
} from 'src/utils/validation'
import {
  CODESET_STRING_DELIMITER,
} from 'src/components/codeset/constants'
import api from '../../../api'
import {
  getFieldErrors,
} from '../../permitManager'
import messages from './messages'
import {
  DEFAULT_TARIC_CODE_MAX_LENGTH,
  DEFAULT_TARIC_CODE_MIN_LENGTH,
  RADIO_BUTTONS,
  unitAmountPairs,
} from './constants'
import {
  checkFormValues as checkRepetitiveFormValues,
} from '../RepetitiveComponent/utils'

export const fetchTaricDescription = (taricCode, locale) => {
  if (isNil(taricCode) || taricCode.length < DEFAULT_TARIC_CODE_MIN_LENGTH || taricCode.length > DEFAULT_TARIC_CODE_MAX_LENGTH) {
    return new Promise((resolve) => {
      resolve('')
    })
  }
  return api.fetchTaricCodeInformation(taricCode, locale)
    .then((response) => {
      if (has(response, 'GoodsValidationResult.T3GOODS.ERROR')) {
        return ''
      }
      return response
    })
    .then((response) => {
      const goodsNode = get(response, 'GoodsValidationResult.T3GOODS.GOODSNODE')
      let taricDescription = ''
      if (isArray(goodsNode)) {
        const descriptions = []
        goodsNode.forEach(item => descriptions.push(get(item, 'DESCR._text')))
        taricDescription = descriptions.join('; ')
      } else {
        taricDescription = get(goodsNode, 'DESCR._text', '')
      }
      return taricDescription
    })
}

// Needs refactoring
export const validatePairs = (formData, fieldCodes) => {
  const unitAmountFormFields = pick(formData, fieldCodes)

  const unitAmountFormPairsToValidate = {}

  for (const [key, value] of Object.entries(unitAmountFormFields)) {
    if (value !== '') {
      unitAmountFormPairsToValidate[key] = value
    }
  }

  const invalidFields = []
  unitAmountPairs.forEach((item) => {
    if (
      unitAmountFormPairsToValidate[item.unit]
        ? !unitAmountFormPairsToValidate[item.amount]
        : unitAmountFormPairsToValidate[item.amount]
    ) {
      if (unitAmountFormPairsToValidate[item.unit]) {
        invalidFields.push(item.amount)
      } else {
        invalidFields.push(item.unit)
      }
    }
  })
  return invalidFields
}

export const readAndParseCSVFile = (file, options) => {
  // Only allow files with .csv and .txt extensions, or no extension at all
  if (file.name && file.name.includes('.') && !/^.+(\.csv|\.txt)$/i.test(file.name)) {
    return Promise.reject({
      message: messages.invalidFileType,
    })
  }
  return readFileMaybeUTF8(file)
    .then(readCSV)
    .catch(() => Promise.reject({
      message: messages.fileReaderError,
    }))
    .then((data) => {
      if (data.length > options.rowLimit) {
        return Promise.reject({
          message: messages.invalidNumberOfRows,
          values: { limit: options.rowLimit },
        })
      }
      const parsedRows = []
      const numberOfColumnsMin = options.featureCountInFile.min
      const numberOfColumnsMax = options.featureCountInFile.max

      for (let i = 0; i < data.length; i += 1) {
        const row = data[i].map(str => str.replace('\xa0', ' ').trim())

        const positionOfAnEmptyValue = data[i].indexOf('')

        // Provide an error and abort importing if any of the rows have an empty column.
        if (positionOfAnEmptyValue > -1) {
          return Promise.reject({
            message: messages.invalidEmptyValueInCSV,
            values: {
              lineNumber: i + 1,
              inputValue: '',
              columnNumber: positionOfAnEmptyValue + 1 },
          })
        }

        if (row.length < numberOfColumnsMin || row.length > numberOfColumnsMax) {
          return Promise.reject({
            message: messages.invalidNumberOfDataColumnsInCSV,
            values: { lineNumber: i + 1 },
          })
        }
        if (row[3]) { // Quantity field
          row[3] = row[3].toUpperCase()
        }
        if (row[5]) { // Currency field
          row[5] = row[5].toUpperCase()
        }
        parsedRows.push(row)
      }

      return parsedRows
    })
}

function readFileMaybeUTF8(file) {
  return readFile(file, 'utf-8')
    .then((str) => {
      if (isInvalidUTF8(str)) {
        // Try default windows file encoding instead
        return readFile(file, 'ISO-8859-1')
      }
      return str
    })
}

function isInvalidUTF8(str) {
  const invalidUTF8Char = '\ufffd'
  return str.indexOf(invalidUTF8Char) >= 0
}

function readFile(file, encoding) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.onerror = reject
    fileReader.onload = event => resolve(event.target.result)
    fileReader.readAsText(file, encoding)
  })
}

function readCSV(str) {
  return new Promise((resolve, reject) => {
    Papa.parse(str, {
      skipEmptyLines: true,
      complete: ({ data }) => {
        resolve(data)
      },
      error: reject,
    })
  })
}

export const mapRowData = (rowData, features, fieldMap) => {
  const mappedRowData = {}

  features.forEach((field) => {
    const fieldIndexInFile = fieldMap[field]
    mappedRowData[field] = rowData[fieldIndexInFile] ? rowData[fieldIndexInFile].trim() : undefined
    if (RADIO_BUTTONS.includes(field)) {
      switch (rowData[fieldIndexInFile]) {
      case '1':
        mappedRowData[field] = 'true'
        break
      case '2':
        mappedRowData[field] = 'false'
        break
      default:
        // mappedRowData[field] = 'false'
        break
      }
    }

    return mappedRowData
  })

  return mappedRowData
}

export const validateRowData = async (item, additionalParams) => new Promise((resolve) => {
  const locale = get(additionalParams, 'locale', 'fi')
  const codeFields = get(additionalParams, 'codeFieldNames', '')
  const fetchTaricCodePromises = codeFields.map(codeFieldName => fetchTaricDescription(item[codeFieldName], locale))

  Promise.all(fetchTaricCodePromises).then((descriptions) => {
    const descriptionsMappedKeys = mapKeys(descriptions, (value, key) => codeFields[key])

    resolve(validateItemData(item, additionalParams, descriptionsMappedKeys))
  })
})

export const validateItemData = (item, additionalParams, taricResponses) => {
  const {
    fields = [],
    codesets = {},
    codeFieldNames: codeFields = '',
  } = additionalParams
  let errors = {}

  const validationHelper = (obj, key, message) => {
    if (message) {
      set(obj, key, message)
    }
    return obj
  }

  codeFields.forEach((codeFieldName) => {
    if (!isNil(item[codeFieldName]) && get(taricResponses, codeFieldName, '') === '') {
      set(errors, codeFieldName, messages.goodNotFound)
    }
  })

  const checkCodeset = (field) => {
    const value = item[field.code]
    const subCodesetString = has(field, 'subCodesetName')
      ? `${CODESET_STRING_DELIMITER}${field.subCodesetName}`
      : ''
    const date = moment().format('YYYY-MM-DD')
    const codesetValues = get(codesets,
      `${field.codesetName}${subCodesetString}`)[date]
    if (!find(codesetValues, { code: value })) {
      errors = validationHelper(errors, field.code, validationMessages.invalidValue)
    }
  }

  const fieldsToValidate = filter(fields, field =>
    !includes(codeFields, field.code) && field.visible)
  fieldsToValidate.forEach((field) => {
    const value = item[field.code]

    const validationErrors = getFieldErrors(field, item)
    if (validationErrors.length) {
      errors = validationHelper(errors, field.code, validationErrors[0])
    }

    if ((field.type === 'CODESET' || field.type === 'CURRENCY') && value !== undefined) {
      checkCodeset(field)
    }
  })

  return errors
}

export const setTaricCodeError = (formApi, taricCodeFieldName, message) => {
  if (formApi) {
    formApi.setError(taricCodeFieldName, message)
  } else {
    setTimeout(() => {
      setTaricCodeError(formApi, taricCodeFieldName, message)
    }, 100)
  }
}

export const setTaricCodeDescription = (formApi, taricCodeFieldName, taricDescription) => {
  if (formApi) {
    const formState = formApi.getFormState()
    const newValues = {
      ...formState.values,
    }

    if (taricDescription) {
      newValues[`${taricCodeFieldName}Description`] = taricDescription
    } else {
      delete newValues[`${taricCodeFieldName}Description`]
    }

    formApi.setFormState({
      ...formState,
      values: newValues,
    })
  } else {
    setTimeout(() => {
      setTaricCodeDescription(formApi, taricCodeFieldName, taricDescription)
    }, 100)
  }
}

export const isTaricCodeValid = taricCode => (/^(?!(\d{2}00))(\d{4})$|^\d{6}$|^\d{8}$|^\d{10}$/).test(taricCode)

export const isTaricCodeFieldMandatory = (infoElement, taricCodeFieldName) => {
  const taricCodeField = find(infoElement.fields, { code: taricCodeFieldName })
  return infoElement.mandatory && taricCodeField.mandatory
}

export const checkFormValues = (formApi, infoElement, taricDescription, taricCodeFieldName) => {
  const formData = formApi && formApi.getFormState().values
  let isValid = checkRepetitiveFormValues(formApi, infoElement)

  // if taric code already given check taric code
  if (get(formData, taricCodeFieldName, '') !== '' && isNil(taricDescription)) {
    setTaricCodeError(formApi, taricCodeFieldName, messages.goodNotFound)
    isValid = false
  }

  return isValid
}
