import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { get } from 'lodash'
import Modal from 'src/components/Modal'
import Icon from 'src/components/Icon'
import messages from 'src/components/messages'
import { minutesToMs, getMinutesLeft, timeoutMinutesLeft } from 'src/utils/session'
import api from 'src/utils/api'
import { FormattedMessage, injectIntl } from 'react-intl'
import { sendLogoutRequestAndExit } from 'src/actions'
import css from './sessionTimer.scss'

const withSessionTimer = (WrappedComponent) => {
  class SessionTimerWrapper extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        showIdleWarning: false,
        showSessionTimeoutWarning: false,
      }
      this.timeouts = {
        idleLogout: {
          timeout: undefined, // Timeout object created later with setTimeout function
          minutes: 45, // Default timeout in minutes, this is tried to be updated from config/redux
          action: this.logout, // Function which is launched after timeout
        },
        idleWarning: {
          timeout: undefined,
          minutes: 40,
          action: this.showIdleWarning,
        },
        sessionLogout: {
          timeout: undefined,
          minutes: 60 * 12,
          action: this.logout,
        },
        sessionWarning: {
          timeout: undefined,
          minutes: (60 * 12) - 15,
          action: this.showSessionTimeoutWarning,
        },
        refreshSession: {
          timeout: undefined,
          minutes: 50,
          action: this.refreshSession,
        },
      }

      this.events = [
        'mousemove',
        'mousedown',
        'click',
        'scroll',
        'keydown',
        'touchstart',
      ]
    }

    componentDidUpdate(prevProps) {
      if (this.props.isAuthenticated && !prevProps.isAuthenticated) {
        this.addEventListeners()
        this.setIdleTimeouts()
        this.setSessionTimeouts()
        this.setRefreshTimeout()
      } else if (!this.props.isAuthenticated && (prevProps.isAuthenticated || prevProps.isAuthenticating)) {
        this.removeEventListeners()
        this.clearAllTimeouts()
      }
    }

    setIdleTimeouts = () => {
      this.timeouts.idleLogout = this.setTimeoutHelper(this.timeouts.idleLogout, this.props.idleLogoutTimeout)
      this.timeouts.idleWarning = this.setTimeoutHelper(this.timeouts.idleWarning, this.props.idleWarningTimeout)
    }

    setSessionTimeouts = () => {
      const { sessionWarningTimeout, sessionExpirationTime } = this.props

      // If there is a logout value defined for testing purposes use it
      const timeoutMinutes = getMinutesLeft(sessionExpirationTime)
        || this.timeouts.sessionLogout.minutes

      this.timeouts.sessionLogout = this.setTimeoutHelper(this.timeouts.sessionLogout, timeoutMinutes)

      const warningTimeoutMinutes = sessionWarningTimeout
        // If session expires before given warning time out show warning immediately
        ? Math.max(0, (timeoutMinutes - sessionWarningTimeout))
        : this.timeouts.sessionWarning.minutes

      this.timeouts.sessionWarning = this.setTimeoutHelper(this.timeouts.sessionWarning, warningTimeoutMinutes)
    }

    setRefreshTimeout = () => {
      this.timeouts.refreshSession =
        this.setTimeoutHelper(this.timeouts.refreshSession, this.timeouts.refreshSession.minutes)
    }

    setTimeoutHelper = (obj, timeoutMinutes) => {
      const timeoutObj = { ...obj }
      timeoutObj.timeout = setTimeout(timeoutObj.action, minutesToMs(timeoutMinutes))
      timeoutObj.minutes = timeoutMinutes >= 0 ? timeoutMinutes : timeoutObj.minutes
      timeoutObj.timerStart = (new Date()).getTime()
      return timeoutObj
    }

    addEventListeners = () => {
      for (const event of this.events) {
        window.addEventListener(event, this.resetIdleTimeout, false)
      }
    }

    removeEventListeners = () => {
      for (const event of this.events) {
        window.removeEventListener(event, this.resetIdleTimeout, false)
      }
    }

    clearIdleTimeouts = () => {
      clearTimeout(this.timeouts.idleWarning.timeout)
      clearTimeout(this.timeouts.idleLogout.timeout)
    }

    clearAllTimeouts = () => {
      Object.entries(this.timeouts).forEach((obj) => { clearTimeout(obj.timeout) })
    }

    clearAndSetNewIdleTimeOuts = () => {
      this.clearIdleTimeouts()
      this.setIdleTimeouts()
    }

    resetIdleTimeout = () => {
      // logout before refreshing if timeouts are done
      if (timeoutMinutesLeft(this.timeouts.idleLogout) <= 0 || timeoutMinutesLeft(this.timeouts.sessionLogout) <= 0) {
        this.logout()
      }
      if (!this.state.showIdleWarning) {
        this.clearAndSetNewIdleTimeOuts()
      }
    }

    extendSession = () => {
      this.hideIdleWarning()
      this.clearAndSetNewIdleTimeOuts()
    }

    refreshSession = async () => {
      await api.refreshSession()
      this.setRefreshTimeout()
    }

    logout = () => {
      this.hideIdleWarning()
      this.hideSessionTimeoutWarning()
      this.clearAllTimeouts()
      this.props.sendLogoutRequestAndExit()
    }

    hideIdleWarning = () => {
      this.setState({ showIdleWarning: false })
    }

    showIdleWarning = () => {
      this.setState({ showIdleWarning: true })
    }

    hideSessionTimeoutWarning = () => {
      this.setState({ showSessionTimeoutWarning: false })
    }

    showSessionTimeoutWarning = () => {
      this.setState({ showSessionTimeoutWarning: true })
    }

    render() {
      const {
        intl: { formatMessage },
      } = this.props

      const sessionWarningIn = this.timeouts.sessionLogout.minutes - this.timeouts.sessionWarning.minutes
      const sessionLogoutMinutesMsg = `${sessionWarningIn} ${formatMessage(messages.minutes)}`

      const idleWarningIn = this.timeouts.idleLogout.minutes - this.timeouts.idleWarning.minutes
      const idleLogoutMinutesMsg = `${idleWarningIn} ${formatMessage(messages.minutes)}`

      return (
        <div>
          <Modal
            show={this.state.showIdleWarning}
            titleMessage={messages.idleTimeOutWarningHeader}
            cancelMessage={messages.logout}
            onClickCancel={() => this.logout()}
            continueMessage={messages.extendSession}
            onClickContinue={() => this.extendSession()}
            focusButton
          >
            <p id="dialog-message">
              <FormattedMessage
                {...messages.idleTimeOutWarningInfo}
                values={{ idleLogoutMinutes: <b>{idleLogoutMinutesMsg}</b> }}
              />
            </p>
          </Modal>

          <Modal
            show={this.state.showSessionTimeoutWarning && !this.state.showIdleWarning}
            titleMessage={{ ...messages.sessionTimeOutWarningHeader, ...{ value: sessionLogoutMinutesMsg } }}
            cancelMessage={messages.logout}
            onClickCancel={() => this.logout()}
            continueMessage={messages.dismissSessionInfo}
            onClickContinue={() => this.hideSessionTimeoutWarning()}
            bodyStyle={{ padding: 0 }}
            focusButton
          >
            <div id="dialog-message">
              <div
                className={'panel-body alert alert-danger'}
                style={{ borderBottom: '5px solid', backgroundColor: '#fce8eb' }}
              >
                <div className="row">
                  <div className="col-xs-1" style={{ paddingTop: '20px', paddingBottom: '15px' }} >
                    <Icon name="attention" lg className={css.iconWarning} />
                  </div>
                  <div className="col-xs-11">
                    <span>
                      <p style={{ paddingLeft: '10px', paddingTop: '20px', paddingBottom: '15px', color: '#c71530' }}>
                        <FormattedMessage
                          {...messages.sessionTimeOutWarning}
                          values={{ sessionLogoutMinutes: <b>{sessionLogoutMinutesMsg}</b> }}
                        />
                      </p>
                    </span>
                  </div>
                </div>

              </div>
              <p style={{ paddingLeft: '30px' }}>
                {formatMessage(messages.sessionTimeOutWarningInfo)}
              </p>
            </div>
          </Modal>

          <WrappedComponent {...this.props} />
        </div>
      )
    }
  }
  return SessionTimerWrapper
}

const mapStateToProps = state => ({
  idleWarningTimeout: get(state.config.bootstrapConfig, 'idle_warning_timeout', undefined),
  idleLogoutTimeout: get(state.config.bootstrapConfig, 'idle_logout_timeout', undefined),
  sessionWarningTimeout: get(state.config.bootstrapConfig, 'session_warning_timeout', undefined),
  sessionExpirationTime: get(state.auth, 'sessionExpirationTime', undefined),
  isAuthenticated: get(state.auth, 'isAuthenticated', undefined),
  isAuthenticating: get(state.auth, 'isAuthenticating', undefined),
})

export default compose(
  connect(mapStateToProps, { sendLogoutRequestAndExit }),
  injectIntl,
  withSessionTimer
)
