import React, { useState, useEffect } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { get } from 'lodash'
import { injectIntl, FormattedMessage, defineMessages } from 'react-intl'
import { connect } from 'react-redux'
import AccessibleMessage from 'src/components/AccessibleMessage'
import { apiCall } from 'src/utils/http'
import { focusElementById } from 'src/utils'
import styles from './BusinessIdInput.scss'
import { PERMIT_TYPES_HIDE_BUSINESS_ID_SEARCH_BUTTON } from './../constants'

export const getCompanyNameFieldCode = field => `${field.code}-name`

export const COMPANY_NAME_NOT_FOUND = '-'

const messages = defineMessages({
  fetchButton: {
    id: 'permits.fetchButton',
    description: '',
    defaultMessage: 'Hae',
  },
  companyNotFound: {
    id: 'permits.companyNotFound',
    description: '',
    defaultMessage: 'Yritystä ei löytynyt',
  },
  messageDeclarantProviderIdentificationDesc: {
    id: 'permits.messageDeclarantProviderIdentificationDesc',
    description: 'Hint text for the provider\'s EORI number',
    defaultMessage: 'Provide the EORI number',
  },
})

function getReadableCompanyName(props) {
  const { field, formApi } = props
  const companyNameFieldName = getCompanyNameFieldCode(field)
  const companyName = get(formApi.getFormState().values, companyNameFieldName)
  return companyName === COMPANY_NAME_NOT_FOUND ? '' : companyName
}

function onCustomerNotFound({ field, formApi, companyNameFieldName }) {
  formApi.setValue(companyNameFieldName, COMPANY_NAME_NOT_FOUND)
  formApi.setError(field.code, messages.companyNotFound)
  focusIdInput()
}

function focusIdInput() {
  focusElementById('messageDeclarantProviderIdentification', 300)
}

export async function fetchCustomerName(customerId, tmCustomerUrl) {
  const encodedCustomerId = encodeURIComponent(customerId)

  try {
    const customerName = await apiCall(
      `${tmCustomerUrl}/customerName/?eori=${encodedCustomerId}`,
      { method: 'GET', cache: 'default' },
      null,
      null,
      false
    )
    return customerName
  } catch (error) {
    throw error
  }
}

function BusinessIdInput(props) {
  const { infoElement, field, formApi, renderField, buttonDescriptionId, bootstrapConfig, intl: { formatMessage } } = props
  const companyNameFieldName = getCompanyNameFieldCode(field)
  const isReadonly = props.staticValue
  const { values } = formApi.getFormState()
  const eori = get(values, field.code)
  const companyName = get(values, companyNameFieldName)
  const [foundEori, setFoundEori] = useState(companyName ? eori : null)

  useEffect(() => {
    if (foundEori && eori !== foundEori) {
      formApi.setValue(companyNameFieldName, null)
      formApi.setError(companyNameFieldName, null)
    }
  }, [foundEori, eori])

  const fieldDesc = formatMessage(messages.messageDeclarantProviderIdentificationDesc)

  const fieldData = {
    field: { ...field, type: 'text', mandatory: true },
    infoElement,
    descriptionText: !isReadonly && fieldDesc,
    autoFocus: true,
    errors: formApi.errors,
    staticValue: isReadonly,
  }

  const showSearchButton = !PERMIT_TYPES_HIDE_BUSINESS_ID_SEARCH_BUTTON.includes(infoElement.codeName)
  const readableCompanyName = getReadableCompanyName(props)

  return (
    <Row className={styles.row}>
      <div>
        <Col xs={12} md={isReadonly || !showSearchButton ? 12 : 10} className={styles.inputColumn}>
          {
            renderField(fieldData)
          }
        </Col>

        {(!isReadonly && showSearchButton) && <Col xs={12} md={2} className={styles.buttonColumn}>
          <Button
            id={`find-company-${infoElement.code}`}
            aria-describedby={buttonDescriptionId}
            bsStyle="primary"
            block
            className={styles.button}
            onClick={() => {
              const url = bootstrapConfig.tm_customer_ext_url
              fetchCustomerName(eori, url)
                .then((customerName) => {
                  if (customerName) {
                    formApi.setValue(companyNameFieldName, customerName)
                    setFoundEori(eori)
                  } else {
                    onCustomerNotFound({ ...props, companyNameFieldName })
                  }
                })
                .catch(() => {
                  onCustomerNotFound({ ...props, companyNameFieldName })
                })
            }}
            id-qa-test={`button-find-company-${infoElement.code}`}
          >
            <span><FormattedMessage {...messages.fetchButton} /></span>
          </Button>
        </Col>}

      </div>

      {readableCompanyName && <div>
        <Col className={styles.messageContainer} xs={12} md={12}>
          <p><AccessibleMessage message={readableCompanyName} /></p>
        </Col>
      </div>
      }

    </Row>
  )
}

const mapStateToProps = state => ({
  bootstrapConfig: get(state, 'config.bootstrapConfig'),
})

export default injectIntl(connect(mapStateToProps)(BusinessIdInput))
