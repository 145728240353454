import React from 'react'
import { Row, Col } from 'react-bootstrap'
import moment from 'moment'
import { find, endsWith, xor, get, concat, chain, has, isArray, keys, forEach, includes } from 'lodash'
import { CODESET_STRING_DELIMITER } from 'src/components/codeset/constants'
import InfoElementHeaderArea from 'src/components/form_v2/InfoElementHeaderArea'
import {
  PERMIT_GUARANTEE_PROCEDURE_TYPE_ALL_VISIBLE,
} from '../constants'

export default class CustomsProcedureOperationCount extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      customsProcedureTypeValues: [],
      customsProcedureTypeCodeset: null,
    }
    this.customsProcedureTypeField = chain(props.structure.infoElements)
      .map('fields')
      .flatten()
      .find({ code: 'customsProcedureType.customsProcedureType' })
      .value()
  }

  componentWillUnmount() {
    const { formApi } = this.props
    const operationCountObj = {
      operationCount: get(formApi.values, 'operationCount.operationCount'),
    }
    formApi.setValue('operationCount', operationCountObj)
  }

  parseCodeset() {
    const { codesets } = this.props
    const { customsProcedureTypeCodeset } = this.state

    if (this.customsProcedureTypeField && !isArray(customsProcedureTypeCodeset)) {
      const subCodesetString = has(this.customsProcedureTypeField, 'subCodesetName')
        ? `${CODESET_STRING_DELIMITER}${this.customsProcedureTypeField.subCodesetName}`
        : ''
      const date = moment().format('YYYY-MM-DD')
      const customsProcedureTypeCodesetObj = get(codesets,
        `${this.customsProcedureTypeField.codesetName}${subCodesetString}`)[date]
      const customsProcedureTypeCodesetArr = Object.values(customsProcedureTypeCodesetObj)
      this.setState({
        customsProcedureTypeCodeset: customsProcedureTypeCodesetArr,
      })
    }
  }

  saveToFormState(customsProcedureTypeValues) {
    const { formApi, locale } = this.props
    const { customsProcedureTypeCodeset } = this.state
    const newValues = []
    const operationCountValues = get(formApi.values, 'operationCount') || []
    if (isArray(customsProcedureTypeCodeset)) {
      customsProcedureTypeValues.map((customsProcedureType) => {
        const selectedCustomsProcedureType = find(this.state.customsProcedureTypeCodeset, {
          code: `${customsProcedureType}`,
        }).name[locale]
        const operationCountByCustomsProcedureType = get(operationCountValues, `operationCount-${customsProcedureType}`)
        // eslint-disable-next-line
        newValues.push(`${selectedCustomsProcedureType} (${customsProcedureType}): ${operationCountByCustomsProcedureType || 0}`)
        formApi.setValue('operationCount.operationCount', newValues.join('\n'))
        return null
      })
    }
  }

  render() {
    const { infoElement, locale, formApi, renderField } = this.props
    const { customsProcedureTypeCodeset } = this.state

    const applicationTypeCode = get(formApi, 'values.applicationTypeCode')
    const operationCountField = find(infoElement.fields, field => endsWith(field.code, 'operationCount'))
    const otherFields = xor(infoElement.fields, [
      operationCountField,
    ])

    const fieldProps = {
      showLabel: false,
    }

    this.parseCodeset()

    let customsProcedureTypeValues = []
    if (this.customsProcedureTypeField) {
      if (includes(PERMIT_GUARANTEE_PROCEDURE_TYPE_ALL_VISIBLE, applicationTypeCode)) {
        customsProcedureTypeValues = get(formApi, 'values.customsProcedureType.customsProcedureType', [])
      } else {
        customsProcedureTypeValues = concat(
          get(formApi, 'values.customsProcedureType.debtGuaranteeProcedureType') || [],
          get(formApi, 'values.customsProcedureType.liabilityGuaranteeProcedureType') || [],
        )
      }

      if (has(formApi, 'values.operationCount')) {
        const operationCount = get(formApi, 'values.operationCount')
        const operationCountKeys = keys(operationCount)
        if (operationCountKeys.length === 1 && operationCountKeys[0] === 'operationCount') {
          const operationCountValues = get(formApi, 'values.operationCount.operationCount').split('\n')
          forEach(operationCountValues, (value) => {
            const code = value.substring(value.lastIndexOf('(') + 1, value.lastIndexOf(')'))
            const count = value.substring(value.lastIndexOf(':') + 1, value.length).trim()
            formApi.setValue(`operationCount.operationCount-${code}`, count)
          })
        } else {
          const operationCountValue = JSON.stringify(get(formApi.values, 'operationCount'))
          if (operationCountValue !== this.state.previousOperationCountValue) {
            this.saveToFormState(customsProcedureTypeValues)
            this.setState({
              previousOperationCountValue: operationCountValue,
            })
          }
        }
      }
    }

    return (
      <Col>
        <Row>
          <Col sm={12}>
            <InfoElementHeaderArea
              infoElement={infoElement}
              locale={locale}
              small
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            {customsProcedureTypeValues && customsProcedureTypeCodeset && customsProcedureTypeValues.map(item => <Row>
              <Col sm={6}>
                {find(customsProcedureTypeCodeset, { code: `${item}` }).name[locale]}
              </Col>
              <Col sm={6}>
                {operationCountField && renderField({
                  field: {
                    ...operationCountField,
                    code: `${operationCountField.code}-${item}`,
                    maxLength: '6',
                  },
                  ...fieldProps,
                  input: {
                    type: 'number',
                  },
                })}
              </Col>
            </Row>)}
            {!this.customsProcedureTypeField && <Row>
              <Col sm={12}>
                {operationCountField && renderField({
                  field: {
                    ...operationCountField,
                    maxLength: '6',
                  },
                  ...fieldProps,
                  input: {
                    type: 'number',
                  },
                })}
              </Col>
            </Row>}
          </Col>
          <Col sm={12}>
            {otherFields && otherFields.map(field => renderField({
              field,
              ...fieldProps,
            }))}
          </Col>
        </Row>
      </Col>
    )
  }
}
