import React from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Col } from 'react-bootstrap'
import classNames from 'classnames'
import Checkbox from 'src/components/form/Checkbox.js'
import ReactMarkdown from 'react-markdown'
import LinkRenderer from 'src/components/LinkRenderer'
import { messages } from 'src/utils/validation'
import HeaderArea from '../../../../components/form_v2/HeaderArea'
import styles from './AcceptTermsCheckbox.scss'

export const SENDING_ACCEPTED = 'terms_of_service_accepted'
export const SENDING_TRIED = 'sending_tried'

function AcceptTermsCheckboxSsa({ formApi, intl }) {
  const isAccepted = !!formApi.getValue(SENDING_ACCEPTED)
  const isTried = !!formApi.getValue(SENDING_TRIED)
  const hasError = !isAccepted && isTried
  const termsOfUse = intl.messages['/permits/fields/termsOfUse']

  return (
    <div className={hasError ? 'has-error' : ''}>
      <Checkbox
        required
        checked={isAccepted}
        id={SENDING_ACCEPTED}
        id-qa-test={SENDING_ACCEPTED}
        onClick={() => {
          formApi.setValue(SENDING_ACCEPTED, !isAccepted)
          formApi.setValue(SENDING_TRIED, undefined)
        }}
        ariaRequired
      >
        <ReactMarkdown
          source={termsOfUse}
          renderers={{ Link: LinkRenderer }}
          target={'_blank'}
        />
        {hasError &&
          <p
            id={`${SENDING_ACCEPTED}-error`}
            className="help-block"
          >
            <FormattedMessage {...messages.required} />
          </p>
        }
      </Checkbox>
    </div>
  )
}


function AcceptTermsCheckboxTat({ formApi, intl }) {
  const isAccepted = !!formApi.getValue(SENDING_ACCEPTED)
  const isTried = !!formApi.getValue(SENDING_TRIED)
  const hasError = !isAccepted && isTried
  const title = intl.messages['/permits/fields/termsOfUseLabel_TAT']
  const checkBoxLabel = intl.messages['/permits/fields/termsOfUseCheckbox_TAT']
  const termsOfUse = intl.messages['/permits/fields/termsOfUse_TAT']

  const descriptionClasses = hasError ? classNames(styles['accept-description'], 'help-block', 'has-error') : classNames(styles['accept-description'], 'help-block')
  const errorClass = classNames(styles['error-padding'], 'help-block')

  return (
    <Col xs={12}>
      <div className={hasError ? 'has-error' : ''}>
        <HeaderArea title={title} small />
        <Checkbox
          required
          checked={isAccepted}
          id={SENDING_ACCEPTED}
          id-qa-test={SENDING_ACCEPTED}
          onClick={() => {
            formApi.setValue(SENDING_ACCEPTED, !isAccepted)
            formApi.setValue(SENDING_TRIED, undefined)
          }}
          ariaRequired
          ariaDescribedby={hasError ? `terms_of_service_description ${SENDING_ACCEPTED}-error` : 'terms_of_service_description'}
        >
          {checkBoxLabel}
        </Checkbox>
        <ReactMarkdown
          className={descriptionClasses}
          source={termsOfUse}
          renderers={{ Link: LinkRenderer }}
          target={'_blank'}
          containerProps={{ id: 'terms_of_service_description' }}
        />
        {hasError &&
        <p
          id={`${SENDING_ACCEPTED}-error`}
          className={errorClass}
        >
          <FormattedMessage {...messages.required} />
        </p>
        }
      </div>
    </Col>
  )
}

function AcceptTermsCheckbox({ structure, isPerson, ...rest }) {
  if (isPerson) {
    return null
  }

  switch (structure?.code) {
  case 'SSA': {
    return <AcceptTermsCheckboxSsa {...rest} />
  }
  case 'TAT': {
    return <AcceptTermsCheckboxTat {...rest} />
  }

  default: {
    return null
  }
  }
}

export default injectIntl(AcceptTermsCheckbox)
