import { getStore } from 'src/store'
import { endWithBackSlash } from 'src/utils/session'
import { memoize } from 'lodash'
import { get } from './apiBase'

const getTmExtAuthUrl = memoize(() => getStore().getState().config.bootstrapConfig.tm_ext_auth_user_api_url)

const api = {
  refreshSession: () =>
    get(`${endWithBackSlash(getTmExtAuthUrl())}refreshSession`),
}

export default api
