import React from 'react'
import { injectIntl } from 'react-intl'
import { isObject } from 'lodash'
import AccessibleMessage from '../AccessibleMessage'
import validationMessages from '../validation/messages'

function ErrorMessage({ fieldName, error, intl: { formatMessage } }) {
  if (!error) {
    return null
  }

  const errorMessage = isObject(error) ? formatMessage(error, error.values) : error
  const accessibleMessage = formatMessage(
    validationMessages.fieldError,
    {
      fieldName,
      errorMessage,
    },
  )

  return <React.Fragment>
    {errorMessage}
    <AccessibleMessage message={accessibleMessage} hideMessage />
  </React.Fragment>
}

export default injectIntl(ErrorMessage)
