import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Row, Col, Button } from 'react-bootstrap'
import { isArray } from 'lodash'
import Icon from 'src/components/Icon'
import Modal from 'src/components/Modal'
import ButtonToolbar from 'src/components/ButtonToolbar'
import InfoList from 'src/components/InfoList'
import noRowsRenderer from 'src/components/lists/noRowsRenderer'
import { focusElementById } from 'src/utils/index'
import { formatCellValue } from '../../../formatValue'
import messages from '../messages'
import { FOCUS_TARGET_ID_PREFIX } from '../../../constants'
import GuaranteePerCustomsProcedureRows from '../../GuaranteePerCustomsProcedure/GuaranteePerCustomsProcedureRows'

export default class ItemsList extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      count: props.count || 0,
      list: props.items,
      showDeleteConfirmationModal: false,
      selectedItem: null,
      selectedItemIndex: null,
    }
  }

  formatCellValue(row, field) {
    const { codesets, locale, emptyValue } = this.props
    return formatCellValue(row, field, { codesets, locale, includeCountryCode: false, emptyValue })
  }

  render() {
    const {
      selectItem,
      deleteItem,
      items,
      fields,
      infoElement,
      guaranteeCustomsProcedure,
      locale,
    } = this.props

    const {
      showDeleteConfirmationModal,
      selectedItemIndex,
    } = this.state

    if (!items || items.length === 0) {
      return noRowsRenderer(messages.noRows, true)
    }

    return (
      <div style={{ paddingBottom: '10px' }}>
        {showDeleteConfirmationModal &&
          <Modal
            show={showDeleteConfirmationModal}
            showContinue
            showCancel
            titleMessage={guaranteeCustomsProcedure ? messages.deleteGuaranteeCustomsProcedure : messages.deleteItemText}
            continueMessage={messages.continueDeleteConfirmation}
            cancelMessage={messages.ignoreDeleteConfirmation}
            onClickCancel={() => {
              this.setState({ showDeleteConfirmationModal: null, selectedItemIndex: null })
              focusElementById(`${infoElement?.code || ''}-button-delete-${showDeleteConfirmationModal}`)
            }}
            onClickContinue={() => {
              deleteItem(selectedItemIndex)
              this.setState({ showDeleteConfirmationModal: null, selectedItemIndex: null })
            }}
            focusDisableButton
          >
            <Row>
              <Col xs={2}>
                <Icon
                  name={'delete'}
                  style={{ display: 'block', fontSize: '26px' }}
                />
              </Col>
              <Col xs={10}>
                <p className="lead">
                  <FormattedMessage {...messages.deleteItemConfirmation} />
                </p>
              </Col>
            </Row>
          </Modal>
        }
        <InfoList>
          {isArray(items) && items.map((row, i) => {
            // check if we are dealing with Message Declaration Provider
            const hasDeclarantProviderId = fields.find(field => field.code === 'messageDeclarantProviderIdentification')

            return (
              <li key={i} id-qa-test={`label-table-row-${i}`}>
                <Row>
                  {/* make declarant provider ID focusable... */}
                  <Col
                    xs={12}
                    sm={6}
                    tabIndex={hasDeclarantProviderId && '-1'}
                    id={hasDeclarantProviderId && `${FOCUS_TARGET_ID_PREFIX}${row.messageDeclarantProviderIdentification}`}
                  >
                    {!guaranteeCustomsProcedure && fields.map(field =>
                      <React.Fragment key={field.code}>
                        { /* Break before postal code fields in case there's an address to show */
                          field.code.endsWith('PostalCode') && <br />
                        }
                        <span id-qa-test={`label-${row[field.code]}`}>
                          {this.formatCellValue(row, field)}
                        </span>

                      </React.Fragment>
                    )}
                    {guaranteeCustomsProcedure &&
                      <GuaranteePerCustomsProcedureRows
                        codesets={this.props.codesets}
                        errors={[]}
                        fields={fields}
                        item={row}
                        itemIndex={i}
                        locale={locale}
                      />}
                  </Col>
                  <Col xs={12} sm={6} componentClass={ButtonToolbar} className="text-right">
                    <Button
                      bsStyle="link"
                      onClick={() => selectItem(row)}
                      id-qa-test={`button-edit-${i}`}
                    >
                      <FormattedMessage {...messages.edit} /><Icon name="edit" />
                    </Button>
                    <Button
                      bsStyle="link"
                      onClick={() => this.setState({
                        showDeleteConfirmationModal: i + 1,
                        selectedItem: row,
                        selectedItemIndex: i,
                      })}
                      id={`${infoElement?.code || ''}-button-delete-${i + 1}`}
                      id-qa-test={`button-delete-${i}`}
                    >
                      <FormattedMessage {...messages.delete} /><Icon name="delete" />
                    </Button>
                  </Col>
                </Row>
              </li>
            )
          })}
        </InfoList>
      </div>
    )
  }
}
