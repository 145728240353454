import { memoize } from 'lodash'
import { getStore } from 'src/store'
import { get, deleteMethod, post, put } from 'src/utils/apiBase'


const getTmPermitUrl = memoize(() => getStore().getState().config.bootstrapConfig.tm_permit_ext_url)

const api = {
  fetchPermitApplications: authorizationOrDecisionHolderIdentification =>
    get(`${getTmPermitUrl()}/permitApplications?authorizationOrDecisionHolderIdentification=${authorizationOrDecisionHolderIdentification}`),
  fetchPermitApplication: (referenceNumber, version) =>
    get(`${getTmPermitUrl()}/permitApplications/${referenceNumber}?version=${version}`),
  fetchPermits: authorizationOrDecisionHolderIdentification => get(`${getTmPermitUrl()}/permits?authorizationOrDecisionHolderIdentification=${authorizationOrDecisionHolderIdentification}`),
  fetchRepliesAndProposals: authorizationOrDecisionHolderIdentification =>
    get(`${getTmPermitUrl()}/repliesAndProposals?authorizationOrDecisionHolderIdentification=${authorizationOrDecisionHolderIdentification}`),
  fetchPermit: (referenceNumber, authorizationOrDecisionHolderIdentification, version) =>
    get(`${getTmPermitUrl()}/permits/${referenceNumber}/?authorizationOrDecisionHolderIdentification=${authorizationOrDecisionHolderIdentification}&version=${version}`),
  fetchPermitLatestVersion: (referenceNumber, authorizationOrDecisionHolderIdentification) =>
    get(`${getTmPermitUrl()}/permits/${referenceNumber}/latest?authorizationOrDecisionHolderIdentification=${authorizationOrDecisionHolderIdentification}`),
  fetchDecisionFile: (authorizationOrDecisionHolderIdentification, referenceNumber, typeCode, version) =>
    // eslint-disable-next-line
    get(`${getTmPermitUrl()}/permits/${referenceNumber}/decision?version=${version}&authorizationOrDecisionHolderIdentification=${authorizationOrDecisionHolderIdentification}`),
  amendDecision: (referenceNumber, version) =>
    post(
      `${getTmPermitUrl()}/permits/${referenceNumber}/amend?version=${version}`
    ),
  renewDecision: (referenceNumber, version) =>
    post(
      `${getTmPermitUrl()}/permits/${referenceNumber}/renew?version=${version}`
    ),
  revokeDecision: (referenceNumber, version) =>
    post(
      `${getTmPermitUrl()}/permits/${referenceNumber}/revoke?version=${version}`
    ),
  replyDecision: (referenceNumber, version) =>
    post(
      `${getTmPermitUrl()}/permits/${referenceNumber}/reply?version=${version}`
    ),
  fetchPermitTypes: () => get(`${getTmPermitUrl()}/permitApplications/types`),
  fetchActiveGuarantee: authorizationOrDecisionHolderIdentification =>
    get(`${getTmPermitUrl()}/permits/guarantee?authorizationOrDecisionHolderIdentification=${authorizationOrDecisionHolderIdentification}`),
  fetchActiveGuaranteeApplication: authorizationOrDecisionHolderIdentification =>
    get(`${getTmPermitUrl()}/permitApplications/guarantee?authorizationOrDecisionHolderIdentification=${authorizationOrDecisionHolderIdentification}`),
  fetchLatestApplication: authorizationOrDecisionHolderIdentification =>
    get(`${getTmPermitUrl()}/permitApplications/latest?authorizationOrDecisionHolderIdentification=${authorizationOrDecisionHolderIdentification}`),
  createDraft: permitApplication =>
    post(`${getTmPermitUrl()}/permitApplications?submit=false`, permitApplication),
  saveDraft: (permitApplication, referenceNumber, version) =>
    put(
      `${getTmPermitUrl()}/permitApplications/${referenceNumber}?submit=false&version=${version}&validate=false`,
      permitApplication
    ),
  validateDraft: (permitApplication, referenceNumber, version) =>
    put(
      `${getTmPermitUrl()}/permitApplications/${referenceNumber}?submit=false&version=${version}&validate=true`,
      permitApplication
    ),
  sendApplication: (permitApplication, referenceNumber, version) =>
    put(
      `${getTmPermitUrl()}/permitApplications/${referenceNumber}?submit=true&version=${version}&validate=false`,
      permitApplication
    ),
  deleteApplication: (referenceNumber, version) =>
    deleteMethod(
      `${getTmPermitUrl()}/permitApplications/${referenceNumber}?version=${version}`
    ),
  cancelApplication: (referenceNumber, version) =>
    post(
      `${getTmPermitUrl()}/permitApplications/${referenceNumber}/cancel?version=${version}`
    ),
  copyApplication: (referenceNumber, version, typeCode) =>
    post(
      `${getTmPermitUrl()}/permitApplications/${referenceNumber}/copy?version=${version}&typeCode=${typeCode}`
    ),
  fetchPermitStructure: (applicationType, applicationDate) =>
    // eslint-disable-next-line
    get(`${getTmPermitUrl()}/permitApplications/structure/${applicationType}?date=${encodeURIComponent(applicationDate)}`),
  fetchAttachments: referenceNumber =>
    get(`${getTmPermitUrl()}/permitApplications/${referenceNumber}/attachments?version=0`),
  fetchAttachment: (referenceNumber, attachmentId, fileFormat) =>
    // eslint-disable-next-line
    get(`${getTmPermitUrl()}/permitApplications/${referenceNumber}/attachments/${attachmentId}?fileFormat=${fileFormat}&version=0`),
  createAttachment: (referenceNumber, typeCode, attachmentData) =>
    // eslint-disable-next-line
    post(`${getTmPermitUrl()}/permitApplications/${referenceNumber}/attachments?version=0&typeCode=${typeCode}`, attachmentData),
  deleteAttachment: (referenceNumber, attachmentId) =>
    deleteMethod(`${getTmPermitUrl()}/permitApplications/${referenceNumber}/attachments/${attachmentId}?version=0`),
  fetchTaricCodeInformation: (taricCode, locale) => get(`${getTmPermitUrl()}/taric/code/${taricCode}?lang=${locale}`),
}

export default api
