import { handleActions } from 'redux-actions'
import {
  CUSTOMER_INFORMATION_DESTROY_STATE,
  CUSTOMER_INFORMATION_REQUEST,
  CUSTOMER_INFORMATION_FETCHED,

  REPRESENTATIVE_INFORMATION_REQUEST,
  REPRESENTATIVE_INFORMATION_FETCHED,

  GUARANTEE_STATUSES_FETCHED,
  GUARANTEE_STATUSES_REQUEST,
  SHOW_NO_CUSTOMER_INFORMATION_MODAL,
  HIDE_NO_CUSTOMER_INFORMATION_MODAL,
  PERMIT_REPRESENTATIVE_INFORMATION_REQUEST,
  PERMIT_REPRESENTATIVE_INFORMATION_FETCHED,
  CURRENT_REPRESENTATIVE_TO_PERMIT_REPRESENTATIVE,
} from './constants'

const initialState = {
  customerInformation: {},
  representativeInformation: {},
  permitRepresentativeInformation: {},
  fetchingCustomer: false,
  fetchingRepresentative: false,
  fetchingPermitRepresentative: false,
  fetchingGuaranteeStatuses: false,
  error: null,
  representativeError: null,
  permitRepresentativeError: null,
}

export default handleActions({
  [CUSTOMER_INFORMATION_DESTROY_STATE]: () => initialState,
  [CUSTOMER_INFORMATION_REQUEST]: state => ({
    ...state,
    fetchingCustomer: true,
  }),
  [CUSTOMER_INFORMATION_FETCHED]: {
    next: (state, action) => ({
      ...state,
      customerInformation: {
        ...state.customerInformation,
        [action.payload.customerId]: {
          ...state.customerInformation[action.payload.customerId],
          ...action.payload.data,
        },
      },
      fetchingCustomer: false,
      error: null,
    }),
    throw: (state, action) => ({
      ...state,
      fetchingCustomer: false,
      error: action.payload.value,
    }),
  },
  [REPRESENTATIVE_INFORMATION_REQUEST]: state => ({
    ...state,
    fetchingRepresentative: true,
  }),
  [PERMIT_REPRESENTATIVE_INFORMATION_REQUEST]: state => ({
    ...state,
    fetchingPermitRepresentative: true,
  }),
  [REPRESENTATIVE_INFORMATION_FETCHED]: {
    next: (state, action) => ({
      ...state,
      representativeInformation: {
        ...state.representativeInformation,
        [action.payload.representativeId]: {
          ...state.representativeInformation[action.payload.representativeId],
          ...action.payload.data,
        },
      },
      fetchingRepresentative: false,
      representativeError: null,
    }),
    throw: (state, action) => ({
      ...state,
      fetchingRepresentative: false,
      representativeError: action.payload.value,
    }),
  },
  [PERMIT_REPRESENTATIVE_INFORMATION_FETCHED]: {
    next: (state, action) => ({
      ...state,
      permitRepresentativeInformation: {
        ...state.permitRepresentativeInformation,
        [action.payload.representativeId]: {
          ...state.permitRepresentativeInformation[action.payload.representativeId],
          ...action.payload.data,
        },
      },
      fetchingPermitRepresentative: false,
      permitRepresentativeError: null,
    }),
    throw: (state, action) => ({
      ...state,
      fetchingPermitRepresentative: false,
      permitRepresentativeError: action.payload.value,
    }),
  },
  [CURRENT_REPRESENTATIVE_TO_PERMIT_REPRESENTATIVE]: (state, action) => ({
    ...state,
    permitRepresentativeInformation: {
      ...state.permitRepresentativeInformation,
      [action.payload.representativeIdentification]: {
        ...state.representativeInformation[action.payload.representativeId],
      },
    },
  }),
  [GUARANTEE_STATUSES_REQUEST]: state => ({
    ...state,
    fetchingGuaranteeStatuses: true,
  }),
  [GUARANTEE_STATUSES_FETCHED]: {
    next: (state, action) => ({
      ...state,
      customerInformation: {
        ...state.customerInformation,
        [action.payload.customerId]: {
          ...state.customerInformation[action.payload.customerId],
          ...action.payload.data,
        },
      },
      fetchingGuaranteeStatuses: false,
      error: null,
    }),
    throw: (state, action) => ({
      ...state,
      fetchingGuaranteeStatuses: false,
      error: action.payload.value,
    }),
  },
  [SHOW_NO_CUSTOMER_INFORMATION_MODAL]: state => ({
    ...state,
    showNoCustomerInfoModal: true,
  }),
  [HIDE_NO_CUSTOMER_INFORMATION_MODAL]: state => ({
    ...state,
    showNoCustomerInfoModal: false,
  }),
}, initialState)

