import React from 'react'
import { isObject, has, isString } from 'lodash'
import { injectIntl } from 'react-intl'
import { updateStatusMessage } from 'src/utils'

/**
 * This component writes messages to a global aria-live="assertive" element. This will
 * prompt the screen reader to read the message.
 *
 * The live region element is mounted at the Root component with id GLOBAL_STATUS_ID.
 *
 * https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Live_Regions
 */

class AccessibleMessage extends React.Component {
  componentDidMount() {
    updateStatusMessage(this.getMessage())
  }

  componentDidUpdate(prevProps) {
    if (prevProps.message !== this.props.message) {
      updateStatusMessage(this.getMessage())
    }
  }

  getMessage() {
    const { intl: { formatMessage } } = this.props
    if (isObject(this.props.message) && has(this.props.message, 'id')) {
      return formatMessage(this.props.message, this.props.message.values)
    }
    return isString(this.props.message) ? this.props.message : ''
  }

  render() {
    const { id, className } = this.props
    return this.props.hideMessage ? null : <span id={id} className={className}>{this.getMessage()}</span>
  }
}

export default injectIntl(AccessibleMessage)
