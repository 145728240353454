import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { find, xor } from 'lodash'
import InfoElementHeaderArea from 'src/components/form_v2/InfoElementHeaderArea'

export default function (props) {
  const {
    infoElement,
    locale,
    formApi,
    renderField,
    currency,
    amount,
    basic,
    size,
    input,
  } = props

  const currencyField = currency || find(infoElement.fields, field => field.type === 'CURRENCY')
  const amountField = amount || find(infoElement.fields, field => field.type === 'NUMBER')

  const fieldProps = {
    formApi,
    input,
  }

  const otherFields = xor(infoElement.fields, [currencyField, amountField])

  const defaultSize = {
    xs: 12,
    sm: 6,
    md: 6,
  }

  const renderFields = () => ([
    <Col {...{ ...defaultSize, ...size }} key="amountField">
      {amountField && renderField({
        field: amountField,
        ...fieldProps,
      })}
    </Col>,
    <Col {...{ ...defaultSize, ...size }} key="currencyField">
      {currencyField && renderField({
        field: {
          ...currencyField,
          type: 'CODESET_AUTOCOMPLETE',
        },
        ...fieldProps,
      })}
    </Col>,
  ])

  if (basic) {
    return renderFields()
  }

  return (
    <Col sm={6} xs={12}>
      {<Row>
        <Col xs={12}>
          <InfoElementHeaderArea
            infoElement={infoElement}
            locale={locale}
          />
        </Col>
      </Row>}
      <Row>
        {renderFields()}
      </Row>
      {<Row>
        <Col xs={12}>
          {otherFields && otherFields.map(field => renderField({
            field,
            ...fieldProps,
          }))}
        </Col>
      </Row>}
    </Col>
  )
}
