import React from 'react'
import { isObject } from 'lodash'
import { FormattedMessage, injectIntl } from 'react-intl'
import classNames from 'classnames'
import Icon from 'src/components/Icon'
import styles from './InfoArea.scss'

function InfoArea({
  children,
  title,
  icon,
  type,
  style,
  extraClasses,
}) {
  const areaType = type || 'info'
  return (
    <div className={classNames(styles.containerInfo, styles[areaType], extraClasses && [...extraClasses])} style={style}>
      <div className={styles.containerInfoInner}>
        <Icon name={icon || 'info'} className={styles.iconInfo} />
        <div className={styles.containerInfoText}>
          {title && <span className={styles.containerInfoTitle}>
            {isObject(title) ? <FormattedMessage {...title} /> : title}
          </span>}
          {children}
        </div>
      </div>
    </div>
  )
}

export default injectIntl(InfoArea)
