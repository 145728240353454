import { includes } from 'lodash'
import { ALLOWED_FILE_FORMATS } from './constants'

export function readFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

export function bytesToSize(bytes) {
  if (bytes === 0) { return 'n/a' }
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
  if (i === 0) { return `${bytes}` }
  return `${(bytes / (1024 ** i)).toFixed(0)}`
}

export function isAllowedFileFormat(filename) {
  const filenameSplitted = filename.toLowerCase().split('.')
  const fileFormat = filenameSplitted[filenameSplitted.length - 1]
  if (includes(ALLOWED_FILE_FORMATS, fileFormat)) {
    return true
  }
  return false
}
