export const SUPPORTED_LOCALES = ['fi', 'sv', 'en']
export const DEFAULT_LOCALE = 'fi'
export const DEFAULT_AUTHORIZATION_TYPES = ['ipa', 'hpa', 'ypa']
// eslint-disable-next-line max-len
export const DEFAULT_AUTHORIZATION_TYPES_BY_APPLICATION = '[ { "applicationName": "intrastat", "types": [ "ypa" ] }, { "applicationName": "permits", "types": [ "ipa","hpa", "ypa" ] }, { "applicationName": "iat", "types": [ "ipa","hpa","ypa" ] } ]'
export const IPA = 'ipa'
export const LOGGED_OUT_HASH = '#logged_out'

export const BOOTSTRAP_CONFIG = '@@nakki/BOOTSTRAP_CONFIG'
export const LOADING_ACTION = '@@nakki/LOADING_ACTION'
export const LOGOUT_REQUEST = '@@nakki/LOGOUT_REQUEST'
export const LOGOUT = '@@nakki/LOGOUT'
export const LOGIN_DATA_REQUEST = '@@nakki/LOGIN_DATA_REQUEST'
export const LOGIN_DATA = '@@nakki/LOGIN_DATA'
export const SWITCH_LOCALE = '@@nakki/SWITCH_LOCALE'
export const SELECT_AUTHORIZATION = '@@nakki/SELECT_AUTHORIZATION'
export const ACCEPT_TERMS = '@@nakki/ACCEPT_TERMS'
export const CMS_CONTENT = '@@nakki/CMS_CONTENT'
export const CMS_SWITCH_LOCALE = '@@nakki/CMS_SWITCH_LOCALE'
export const SUOMI_FI_AUTHORIZATION_TYPE_REQUEST = '@@nakki/SUOMI_FI_AUTHORIZATION_TYPE_REQUEST'
export const CHANGE_SELECTED_NAME = '@@nakki/CHANGE_SELECTED_NAME'
export const RETURN_URI_AFTER_ROLE_SWITCH = '@@nakki/RETURN_URI_AFTER_ROLE_SWITCH'
export const SHOW_SWITCH_ROLE_CONFIRMATION_MODAL = '@@nakki/SHOW_CHANGE_ROLE_CONFIRMATION_MODAL'
export const SHOW_LOGOUT_CONFIRMATION_MODAL = '@@nakki/SHOW_LOGOUT_CONFIRMATION_MODAL'
export const SET_SHOW_COOKIE_NOTICE = '@@nakki/SET_SHOW_COOKIE_NOTICE'

export const SHOW_SWITCH_ROLE_CONFIRMATION_MODAL_FROM_LEVEL = 3

export const AUTH_TYPES = {
  private: 'private',
  business: 'business',
  principal: 'principal',
}

export const AUTH_PLURALITY_TYPES = {
  NO_AUTH: 'NO_AUTH',
  PRIVATE: 'PRIVATE',
  ONLY_ONE_DELEGATE: 'ONLY_ONE_DELEGATE',
  ONLY_ONE_DIRECT: 'ONLY_ONE_DIRECT',
  MULTIPLE: 'MULTIPLE',
}


export const AUTH_TYPE_MAP = {
  hpa: 'principal',
  ipa: 'private',
  ypa: 'business',
}

export const TUTTU_PATH = 'import'

export const FEATURES = {
  PERMIT_AMENDMENT: true,
  PERMITS_ONBOARDING: true,
  MESSAGES: false,
}

export const LANGUAGE_NATION = {
  fi: 'FI',
  sv: 'FI',
  en: 'GB',
}

export const LOCALE = {
  fi: 'fi',
  sv: 'sv',
  en: 'en',
}

export const SERVICE_PATHS = [
  '/iat',
  '/permits',
  '/intrastat',
  '/forms/eori',
  '/forms/customs-information',
  '/forms/location-information',
  '/mrn',
  '/ext-auth/warehousing',
  '/customer-information',
  '/guarantee',
]
export const PATH_TO_TITLE_MAP = [
  {
    path: '/',
    title: {
      fi: 'Tullin asiointipalvelu | Tulli',
      en: 'Customs online services | Customs',
      sv: 'Tull elektroniska tjänster | Tull',
    },
  },
  {
    path: '/permits',
    title: {
      fi: 'Luvat ja lupien hakeminen | Tulli',
      en: 'Authorisations and related applications | Customs',
      sv: 'Tillstånd och ansökan om tillstånd | Tull',
    },
  },
  {
    path: '/permits/application',
    title: {
      fi: 'Lupahakemus | Tulli',
      en: 'Apply for authorisation | Customs',
      sv: 'Tillståndsansökan | Tull',
    },
  },
  {
    path: '/permits/permit',
    title: {
      fi: 'Myönnetty lupa | Tulli',
      en: '',
      sv: 'Beviljat tillstånd | Tull',
    },
  },
  {
    path: '/intrastat',
    title: {
      fi: 'Intrastat-ilmoituspalvelu | Tulli',
      en: 'Intrastat declarations | Customs',
      sv: 'Intrastat-deklarationstjänsten | Tull',
    },
  },
  {
    path: '/intrastat/declaration',
    title: {
      fi: 'Intrastat-ilmoitus | Tulli',
      en: 'Submit an Intrastat declaration | Customs',
      sv: 'Lämna in Intrastat-deklaration | Tull',
    },
  },
  {
    path: '/forms/eori',
    title: {
      fi: 'EORI-numeron hakeminen | Tulli',
      en: 'Applying for an EORI number | Customs',
      sv: 'Ansök om EORI-nummer | Tull',
    },
  },
  {
    path: '/forms/customs-information',
    title: {
      fi: 'Kysy tullineuvonnasta | Tulli',
      en: 'Contact Customs Information | Customs',
      sv: 'Fråga tullrådgivningen | Tull',
    },
  },
  {
    path: '/forms/location-information',
    title: {
      fi: 'Toimipistetietojen muutospyyntö | Tulli',
      en: 'Request for amendment of company office details | Customs',
      sv: 'Begäran om ändring av uppgifter om verksamhetsställe | Tull',
    },
  },
  {
    path: '/forms/location-information/summary',
    title: {
      fi: 'Toimipistetietojen muutospyynnön yhteenveto | Tulli',
      en: 'Summary of the request for amendment of company office details | Customs',
      sv: 'Sammanfattning av begäran om ändring av uppgifter om verksamhetsställe | Tull',
    },
  },
  {
    path: '/forms/location-information/print-summary/print',
    title: {
      fi: 'Toimipistetietojen muutospyynnön yhteenveto - tulostusnäkymä | Tulli',
      en: 'Summary of the request for amendment of company office details - print view | Customs',
      sv: 'Sammanfattning av begäran om ändring av uppgifter om verksamhetsställe - utskriftsvy | Tull',
    },
  },
  {
    path: '/mrn',
    title: {
      fi: 'MRN-tunnus - Seuraa tulli-ilmoitusketjua | Tulli',
      en: 'MRN search provider | Customs',
      sv: 'MRN-kod - Följ tulldeklarationskedjan | Tull',
    },
  },
  {
    path: '/customer-information',
    title: {
      fi: 'Omat tiedot | Tulli',
      en: 'My data | Customs',
      sv: 'Egna uppgifter | Tull',
    },
  },
  {
    path: '/guarantee',
    title: {
      fi: 'Yksittäisvakuuden maksaminen | Tulli',
      en: 'Payment of individual guarantee‎ | Customs',
      sv: 'Betalning av individuell garanti | Tull',
    },
  },
  {
    path: '/guarantee/stop',
    title: {
      fi: 'Yksittäisvakuuden maksaminen on keskeytetty | Tulli',
      en: 'Payment of individual guarantee‎ has been stopped | Customs',
      sv: 'Betalningen av den individuella säkerheten har stängts av | Tull',
    },
  },
  {
    path: '/feedback',
    title: {
      fi: 'Uloskirjautuminen ja palaute | Tulli',
      sv: 'Utloggning och respons | Tull',
      en: 'Log out and feedback | Finnish Customs',
    },
  },
  {
    path: 'suomiFiAuthorization',
    title: {
      fi: 'Valitse asiointitapa | Tulli',
      en: 'Choose authorization type | Customs',
      sv: 'Välj behörighetstyp | Tull',
    },
  },
  {
    path: 'returnUriAfterRoleSwitch',
    title: {
      fi: 'Valitse asiointirooli | Tulli',
      en: 'Select a customer role | Customs',
      sv: 'Välj kundroll | Tull',
    },
  },
]

export const GLOBAL_STATUS_ID = 'global-status'
export const GENERAL_SERVICE_CONFIG = 'general'

export const UID_TYPE = {
  SUOMI_FI: 'pin',
  KATSO: 'kid',
  EIDAS: 'eidas',
  FOREIGNER: 'fpi',
}

export const RETURN_URI_QUERY_PARAM = 'returnUriAfterRoleSwitch'
export const SUOMI_FI_AUTHORIZATION_QUERY_PARAM = 'suomiFiAuthorization'

export const SESSION_TOKEN_INVALID = 'invalidToken'
export const SESSION_TOKEN_EXPIRED = 'expiredToken'
export const SESSION_TOKEN_MISSING = 'missingToken'

export const HTTP_STATUS_401 = 401
