import { createDefaultAction } from 'src/utils/redux'
import commonApiMessages from 'src/utils/apiMessages'
import permitsApiMessages from './apiMessages'
import {
  DESTROY_PERMIT_STATE,
  PERMIT_DATA_FETCHED,
  PERMIT_DATA_SAVED,
  APPLICATION_ATTACHMENT_UPLOADING,
  APPLICATION_ATTACHMENT_UPLOADED,
  APPLICATION_ATTACHMENT_IN_PROGRESS,
  SAVE_INITIAL_VALUES,
} from './constants'

const apiMessages = {
  ...commonApiMessages,
  ...permitsApiMessages,
}

export const destroyPermitState = createDefaultAction(DESTROY_PERMIT_STATE)

/**
 * Handle network and server errors in failed (non persisted) save operation.
 *
 * @throws {Error|SubmissionError}
 */
export const handleServerErrors = (dispatch, error) => {
  /* eslint-disable no-underscore-dangle */
  if (error.name === 'ServerValidationError' && error.value) {
    const i18nErrors = error.getFieldErrors(apiMessages)
    if (!i18nErrors._error && error.value.code && apiMessages[error.value.code]) {
      i18nErrors._error = apiMessages[error.value.code]
    } else if (!i18nErrors._error && i18nErrors.totalNumberLines) {
      // totalNumberLines is not shown in UI, add it to global errors
      i18nErrors._error = i18nErrors.totalNumberLines
    }
  }
  throw error
}

export const permitDataFetched = createDefaultAction(PERMIT_DATA_FETCHED)

export const permitSaved = createDefaultAction(PERMIT_DATA_SAVED)

export const destroyState = () =>
  dispatch => dispatch(destroyPermitState())

export const applicationAttachmentUploading = createDefaultAction(APPLICATION_ATTACHMENT_UPLOADING)
export const applicationAttachmentUploaded = createDefaultAction(APPLICATION_ATTACHMENT_UPLOADED)
export const applicationAttachmentsInProgress = createDefaultAction(APPLICATION_ATTACHMENT_IN_PROGRESS)
export const applicationSaveInitialValues = createDefaultAction(SAVE_INITIAL_VALUES)

export const startAttachmentUploading = () =>
  dispatch => dispatch(applicationAttachmentUploading())

export const stopAttachmentUploading = () =>
  dispatch => dispatch(applicationAttachmentUploaded())

export const attachmentsInProgress = value =>
  dispatch => dispatch(applicationAttachmentsInProgress(value))

export const saveInitialValues = initialValues => (dispatch) => {
  dispatch(applicationSaveInitialValues(initialValues))
}
