import { get } from 'lodash'
import permitMessages from '../../../messages'
import GoodsToBePlacedUnderProcedureItemsList from '../../GoodsToBePlacedUnderProcedure/components/ItemsList'

export default class ItemsList extends GoodsToBePlacedUnderProcedureItemsList {
  getFieldName(field) {
    const { locale, intl: { formatMessage } } = this.props

    const fieldCustomNameMap = {
      goodsToBePlacedUnderProcedureCombinedNomenclatureAndTaricCode: formatMessage(permitMessages.headingGoodCode),
      equivalentGoodCombinedNomenclatureCode: formatMessage(permitMessages.headingEquivalentGoodCode),
      goodsToBePlacedUnderProcedureDescription: formatMessage(permitMessages.headingGoodDescription),
      equivalentGoodDescription: formatMessage(permitMessages.headingGoodDescription),
    }

    return get(fieldCustomNameMap, field.code, null) || get(field, `name.${locale}`)
  }

  getVisibleFields() {
    return this.getFields()
      .filter(f => f.visibleInCombinedLists)
  }

  getFields() {
    return Object.values(this.props.infoElements)
      .map(ie => ie.fields)
      .flat()
      .filter(f => f.visible)
  }

  getFieldHeadings(activeFields) {
    const { locale } = this.props
    return activeFields.map(field => ({
      value: this.getFieldName(field) || field.name[locale],
    }))
  }
}
