import React from 'react'
import { Row } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { get } from 'lodash'

import { formatCellValue } from '../../formatValue'
import GuaranteePerCustomsProcedureRow from './GuaranteePerCustomsProcedureRow'

function formatValue({ item, field, locale, codesets, emptyValue }) {
  return formatCellValue(item, field, {
    formatMessage: msg => <FormattedMessage {...msg} />,
    emptyValue: emptyValue || null,
    locale,
    codesets,
  })
}

export default function GuaranteePerCustomsProcedureRows({ item, itemIndex, fields, errors, locale, codesets }) {
  const renderGuaranteePerCustomsProcedureField = ({ code, valueLabel, suffixCode, emptyValue }) => {
    const field = fields.find(f => f.code === code)

    if (!field) {
      return null
    }

    const fieldError = get(errors, `${field.code}-${itemIndex}`, '')
    const value = formatValue({ item, field, locale, codesets, emptyValue })

    if (!value && !emptyValue) {
      return null
    }

    const getFormattedValue = (val, suffix) => {
      if (!suffix) {
        return val
      }

      const suffixField = fields.find(f => f.code === suffix)
      const suffixValue = formatValue({ item, field: suffixField, locale, codesets })

      return `${val} ${suffixValue}`
    }

    return (
      <GuaranteePerCustomsProcedureRow
        label={valueLabel ? value : field.name[locale]}
        itemIndex={itemIndex}
        field={field}
        fieldError={fieldError}
        locale={locale}
      >
        {valueLabel ? null : getFormattedValue(value, suffixCode)}
      </GuaranteePerCustomsProcedureRow>
    )
  }

  return <Row className="formElementGutter" key={itemIndex}>
    <div className="clearfix form-group">
      {renderGuaranteePerCustomsProcedureField({ code: 'guaranteeCustomsProcedure', valueLabel: true })}

      {renderGuaranteePerCustomsProcedureField({ code: 'guaranteeExistingDebt', suffixCode: 'guaranteeExistingDebtCurrency' })}
      {renderGuaranteePerCustomsProcedureField({ code: 'guaranteeExistingDebtDescription' })}

      {renderGuaranteePerCustomsProcedureField({ code: 'guaranteePotentialDebt', suffixCode: 'guaranteePotentialDebtCurrency' })}
      {renderGuaranteePerCustomsProcedureField({ code: 'guaranteePotentialDebtDescription' })}

    </div>
  </Row>
}
