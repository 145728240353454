import React from 'react'
import { injectIntl, defineMessages } from 'react-intl'
import { Field } from 'react-form'
import { FormControl, HelpBlock } from 'react-bootstrap'
import { find, get, isObject, omit } from 'lodash'
import Icon from 'src/components/Icon'
import styles from 'src/styles/_forms.scss'
import CommonFormGroup from './CommonFormGroup'
import ErrorMessage from './ErrorMessage'
import { getValidationErrors, required } from '../../utils/validation'

const messages = defineMessages({
  errorNoOptions: {
    id: 'form.error.noOptionsPlaceholder',
    description: 'Error placeholder for input without necessary data',
    defaultMessage: 'Error in loading options',
  },
})

const Select2 = injectIntl((props) => {
  const {
    input,
    multiple,
    disabled,
    mandatory,
    fetchError,
    ariaLabel,
    labelId,
    intl: { formatMessage },
  } = props

  const validate = (value) => {
    const { validation } = input
    if (get(validation, 'mandatory')) {
      const requiredCheck = required(value)
      if (validation.mandatory && requiredCheck) {
        return requiredCheck
      }
    }
    return null
  }

  const handleValidation = value => getValidationErrors(value, input, validate)

  const options = input.options || props.options

  let { placeholderMessage } = props

  if (fetchError && !(options && options.length)) {
    placeholderMessage = messages.errorNoOptions
  }

  let combinedOptions = []

  if (placeholderMessage) {
    combinedOptions.push(
      <option
        key="placeholder"
        disabled
        hidden={mandatory}
        value=""
        id-qa-test={'option-placeholder'}
      >
        {isObject(placeholderMessage) ? formatMessage(placeholderMessage) : placeholderMessage}
      </option>
    )
  }

  const currentValueNotInOptions = Boolean(input && input.value && !find(options, it => it.value === input.value))

  if (currentValueNotInOptions) {
    combinedOptions.push(
      <option
        key={input.value}
        value={input.value}
        id-qa-test={`option-${input.value}`}
      >
        {input.value}
      </option>
    )
  }

  const groups = {}
  const nonGrouped = []

  if (options) {
    options.forEach((option) => {
      if (option.group) {
        if (!groups[option.group.labelMessage.id]) {
          groups[option.group.labelMessage.id] = []
        }

        groups[option.group.labelMessage.id].push(option)
      } else {
        nonGrouped.push(option)
      }
    })
  }

  Object
    .keys(groups)
    .sort((a, b) => groups[a][0].group.sort - groups[b][0].group.sort)
    .forEach((key) => {
      const groupOptions = groups[key].map(option =>
        <option
          key={option.id}
          value={option.value}
          id-qa-test={`option-${option.value}`}
        >
          {option.message ? formatMessage(option.message) : option.title}
        </option>
      )

      combinedOptions.push(
        <optgroup key={groups[key][0].group.labelMessage.id} label={formatMessage(groups[key][0].group.labelMessage)}>
          {groupOptions}
        </optgroup>
      )
    })

  combinedOptions = combinedOptions.concat(nonGrouped.map(option =>
    <option key={option.id} value={option.value} id-qa-test={`option-${option.value}`}>
      {option.message ? formatMessage(option.message) : option.title}
    </option>
  ))

  const customAriaLabelledBy = props?.input?.customAriaLabelledBy

  return (
    <Field
      field={input.name}
      options={options} // re-render when new options
      validate={input.validate && handleValidation}
    >
      {(fieldApi) => {
        const { onChange, onBlur } = props
        const { value, error, setValue, setTouched } = fieldApi

        return (
          <div>
            <FormControl
              {...omit(input, ['static', 'validate', 'visible', 'customAriaLabelledBy', 'options', 'validation', 'label'])}
              id-qa-test={`select-${input.name}`}
              componentClass="select"
              aria-label={ariaLabel}
              aria-labelledby={labelId}
              aria-describedby={customAriaLabelledBy}
              aria-required={mandatory}
              disabled={disabled}
              multiple={multiple}
              value={input.value || value || ''}
              onChange={(event) => {
                setValue(event.target.value)
                if (onChange) {
                  onChange(event.target.value, null)
                }
              }}
              onBlur={(event) => {
                setTouched()
                if (onBlur) {
                  onBlur(event)
                }
              }}
            >
              {combinedOptions}
            </FormControl>
            <span
              className={styles.inputActionIcon}
              style={{ pointerEvents: 'none', cursor: 'pointer' }}
            >
              <Icon name="chevron-tight-down" inline />
            </span>
            {error &&
              <HelpBlock role="alert">
                <ErrorMessage error={error} fieldName={ariaLabel} />
              </HelpBlock>
            }
          </div>
        )
      }}
    </Field>
  )
})

export default Select2
/**
 * Wraps Select2 with CommonFormGroup
 */
export function Select2FieldGroup(props) {
  return (
    <CommonFormGroup {...props}>
      <Select2 {...props} />
    </CommonFormGroup>
  )
}
