import React from 'react'
import { isObject, isEmpty } from 'lodash'
import { FormattedMessage } from 'react-intl'
import { getErrorsForInfoElement } from '../utils'

export default function ErrorList(props) {
  const { errors, infoElement } = props
  const errorsForAllFields = getErrorsForInfoElement(errors, infoElement)

  return (
    !isEmpty(errorsForAllFields) &&
    <div className="has-error">
      {errorsForAllFields.map((error, index) => (
        <div className="help-block" key={index}>
          {isObject(error) ? <FormattedMessage {...error} /> : error}
        </div>
      ))}
    </div>

  )
}
