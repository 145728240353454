import React from 'react'
import ReactMarkdown from 'react-markdown'
import { injectIntl } from 'react-intl'
import { Row, Col } from 'react-bootstrap'
import { formatCmsMessage, formatOptionalCmsMessage } from 'src/utils'
import Icon from 'src/components/Icon'
import LinkRenderer from 'src/components/LinkRenderer'
import styles from './Onboarding.scss'

function IconRenderer({ src, alt: type }) {
  if (type === 'ICON') {
    const iconName = src
    return <Icon className={styles.icon} name={iconName} />
  }
  return null
}

class Onboarding extends React.Component {
  renderSection(section) {
    const {
      structure: { code: permitTypeCode },
      intl,
      isPerson,
    } = this.props

    const icon = isPerson ? formatOptionalCmsMessage(intl, `/permits/onboarding/${section}_icon_${permitTypeCode}_person`)
      : formatOptionalCmsMessage(intl, `/permits/onboarding/${section}_icon_${permitTypeCode}`)
    const content = isPerson ? formatOptionalCmsMessage(intl, `/permits/onboarding/${section}_content_${permitTypeCode}_person`)
      : formatOptionalCmsMessage(intl, `/permits/onboarding/${section}_content_${permitTypeCode}`)

    if (content) {
      return (
        <Row className={styles.sectionContainer}>
          <Col md={2} className={styles.iconContainer}>
            { icon &&
              <ReactMarkdown
                source={icon}
                renderers={{
                  Image: IconRenderer,
                }}
              />
            }
          </Col>
          <Col md={10} className={styles.textContainer}>
            <ReactMarkdown
              source={content}
              renderers={{
                Link: LinkRenderer,
              }}
              target={'_blank'}
            />
          </Col>
        </Row>
      )
    }
    return null
  }

  render() {
    const {
      structure: { code: permitTypeCode },
      intl: { formatMessage },
      isPerson,
    } = this.props

    const title = isPerson ? formatCmsMessage(formatMessage, `/permits/onboarding/title_${permitTypeCode}_person`) : formatCmsMessage(formatMessage, `/permits/onboarding/title_${permitTypeCode}`)

    return (
      <div className={styles.onboarding}>
        { title &&
          <Row>
            <Col xs={12}>
              <ReactMarkdown
                source={title}
              />
            </Col>
          </Row>
        }
        {this.renderSection('section1')}
        {this.renderSection('section2')}
        {this.renderSection('section3')}
        {this.renderSection('section4')}
      </div>
    )
  }
}

export default (injectIntl(Onboarding))
