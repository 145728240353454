/**
 * @file React Intl translation messages for permits headers page
 * @see {@link https://github.com/yahoo/react-intl}
 */

import { defineMessages } from 'react-intl'

const messages = defineMessages({
  /**
   * Note: Messages property name matches error.code sent from api.
   *       Message id matches intl key in ui translation files.
   */
  requiredField: {
    id: 'api.permits.requiredField',
    description: 'Validation error for missing required field value',
    defaultMessage: '?? Field is mandatory',
  },
  invalidFieldValue: {
    id: 'api.permits.invalidFieldValue',
    description: 'Validation error for invalid field value',
    defaultMessage: '?? Value is invalid',
  },
  fieldValueTooLong: {
    id: 'api.permits.fieldValueTooLong',
    description: 'Validation error for too long field value',
    defaultMessage: '?? Value is too long',
  },
  fieldValueTooShort: {
    id: 'api.permits.fieldValueTooShort',
    description: 'Validation error for too short field value',
    defaultMessage: '?? Value is too short',
  },
  fieldValueTooLow: {
    id: 'api.permits.fieldValueTooLow',
    description: 'Validation error for too low field value',
    defaultMessage: '?? Value is too low',
  },
  fieldValueTooHigh: {
    id: 'api.permits.fieldValueTooHigh',
    description: 'Validation error for too high field value',
    defaultMessage: '?? Value is too high',
  },
  valueMustBeGreaterThanZero: {
    id: 'api.permits.valueMustBeGreaterThanZero',
    description: 'Validation error for zero field value, when greater than zero is required',
    defaultMessage: '?? Value must be greater than 0',
  },
  invalidFieldFormat: {
    id: 'api.permits.invalidFieldFormat',
    description: 'Validation error for invalid field value format',
    defaultMessage: '?? Value format is invalid',
  },
})

export default messages
