import React from 'react'
import { Button } from 'react-bootstrap'
import { defineMessages, FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import { xor, isString } from 'lodash'
import { SUPPORTED_LOCALES, LANGUAGE_NATION } from 'src/constants'

import styles from './switchLocale.scss'

const messages = defineMessages({
  fi: {
    id: 'locale.finnish',
    description: 'Label for selecting locale finnish',
    defaultMessage: 'Suomi',
  },
  en: {
    id: 'locale.english',
    description: 'Label for selecting locale english',
    defaultMessage: 'English',
  },
  sv: {
    id: 'locale.swedish',
    description: 'Label for selecting locale swedish',
    defaultMessage: 'Svenska',
  },
})

export default function SwitchLocale({ location, locale, onSwitchLocale, inNavigation, className, autoFocus }) {
  let allowedLocales = SUPPORTED_LOCALES
  if (isString(location) && location.startsWith('/permits')) {
    allowedLocales = xor(SUPPORTED_LOCALES, ['en'])
  }
  return (
    <ul className={classNames(className, 'list-inline', 'shoulder-button__right', styles.locales)}>
      {allowedLocales.filter(supportedLocale => supportedLocale !== locale).map((supportedLocale, idx) => {
        const translatedLocale = messages[supportedLocale]
        return (
          <li key={`locale-${supportedLocale}`}>
            <Button
              bsStyle="link"
              active={locale === supportedLocale}
              onClick={() => onSwitchLocale(supportedLocale)}
              className={inNavigation && 'navbar-btn'}
              lang={`${supportedLocale}-${LANGUAGE_NATION[supportedLocale]}`}
              id-qa-test={`btn-switchLocale-${supportedLocale}`}
              autoFocus={autoFocus && idx === 0}
            >
              {translatedLocale &&
                <FormattedMessage {...messages[supportedLocale]} />}
              {!translatedLocale && supportedLocale}
            </Button>
          </li>
        )
      })
      }
    </ul>
  )
}
