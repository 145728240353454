import React from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Row, Col } from 'react-bootstrap'
import { without, find, get, some } from 'lodash'
import InfoElementHeaderArea from 'src/components/form_v2/InfoElementHeaderArea'
import componentMessages from 'src/components/messages'
import PicForm from './components/PicForm'
import PicsList from './components/PicsList'
import messages from '../RepetitiveComponent/messages'

class PersonInCharge extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedPic: null,
      showValidation: false,
    }
    this.addNewPic = this.addNewPic.bind(this)
    this.cancelPicEditing = this.cancelPicEditing.bind(this)
    this.updatePic = this.updatePic.bind(this)
    this.deletePic = this.deletePic.bind(this)
  }

  componentDidUpdate(prevProps) {
    const submits = get(this.props, 'formApi.submits', 0)
    const prevSubmits = get(prevProps, 'formApi.submits', 0)
    if (submits > prevSubmits) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ showValidation: true })
    }
  }

  addNewPic(data) {
    const { formApi } = this.props
    const newPics = formApi.values.personInCharge
    newPics.push(data)
    formApi.setValue('personInCharge', newPics)
  }

  cancelPicEditing() {
    this.setState({ selectedPic: null })
  }

  updatePic(oldData, newData) {
    const { formApi } = this.props
    const newPics = [...formApi.values.personInCharge]
    const index = newPics.indexOf(oldData)
    newPics[index] = newData

    formApi.setValue('personInCharge', newPics)
  }

  deletePic(pic) {
    const { formApi } = this.props
    const picFiltered = without(formApi.values.personInCharge, pic)
    formApi.setValue('personInCharge', picFiltered)
  }

  render() {
    const {
      infoElement,
      locale,
      formApi,
      renderField,
      codesets,
      intl,
    } = this.props

    const { showValidation } = this.state

    const picNewRowDescriptionId = 'new-row-description-personInCharge'
    const picCountryCodeField = find(infoElement.fields, { code: 'personInChargeCountryCode' })
    const mandatory = get(infoElement, 'mandatory') && some(infoElement.fields, 'mandatory')
    const pics = formApi.values.personInCharge || []

    return (
      <Col xs={12}>
        <Row>
          <Col xs={12}>
            <hr />
            <InfoElementHeaderArea
              infoElement={infoElement}
              locale={locale}
            />
            {!mandatory && (
              <p className="customLabel">
                <FormattedMessage {...componentMessages.formFieldOptional}>
                  {msg => `(${msg})`}
                </FormattedMessage>
              </p>
            )}
          </Col>
        </Row>
        <Row className="form-group">
          <Col xs={12}>
            <PicForm
              buttonDescriptionId={picNewRowDescriptionId}
              selectedPicData={this.state.selectedPic}
              cancelPicEditing={this.cancelPicEditing}
              updatePic={this.updatePic}
              locale={locale}
              infoElement={infoElement}
              renderField={renderField}
              addNewPic={this.addNewPic}
            />
            <PicsList
              pics={pics}
              locale={locale}
              selectPic={pic => this.setState({ selectedPic: pic })}
              deletePic={this.deletePic}
              codesets={codesets}
              countryCodeField={picCountryCodeField}
            />
            {mandatory && pics.length === 0 &&
              <div className={showValidation ? 'has-error' : ''}>
                <p
                  id={picNewRowDescriptionId}
                  className="help-block"
                  aria-label={intl.formatMessage(messages.mandatoryRowDescription)}
                >
                  {intl.formatMessage(messages.mandatoryRowDescription)}
                </p>
              </div>
            }
          </Col>
        </Row>
      </Col>
    )
  }
}

export default injectIntl(PersonInCharge)
