import {
  PERMIT_GOODS_FIELD_PREFIX,
  PERMIT_EQUIVALENT_GOOD_FIELD_PREFIX,
  PERMIT_PROCESSED_PRODUCT_FIELD_PREFIX,
  PERMIT_REPLACEMENT_PRODUCT_FIELD_PREFIX,
  PERMIT_GOODS_CODE_FIELD_NAME,
  PERMIT_EQUIVALENT_GOOD_CODE_FIELD_NAME,
  PERMIT_PROCESSED_PRODUCT_CODE_FIELD_NAME,
  PERMIT_REPLACEMENT_PRODUCT_CODE_FIELD_NAME,
} from '../PermitPage/constants'

// Settings for combined infoElements
const PERMIT_YIELD_RATE_FIELD_PREFIX = 'yieldRate'
const PERMIT_GOODS_IDENTIFICATION_FIELD_PREFIX = 'goodsIdentification'
const PERMIT_ECONOMIC_CONDITIONS_FIELD_PREFIX = 'economicConditions'
export const PERMIT_COMBINED_COLUMN_MAP = {
  [`${PERMIT_GOODS_FIELD_PREFIX}${PERMIT_GOODS_CODE_FIELD_NAME}`]: 0,
  [`${PERMIT_GOODS_FIELD_PREFIX}Description`]: 1,
  [`${PERMIT_GOODS_FIELD_PREFIX}Quantity`]: 2,
  [`${PERMIT_GOODS_FIELD_PREFIX}MeasurementUnit`]: 3,
  [`${PERMIT_GOODS_FIELD_PREFIX}Amount`]: 4,
  [`${PERMIT_GOODS_FIELD_PREFIX}Currency`]: 5,
  [`${PERMIT_EQUIVALENT_GOOD_FIELD_PREFIX}sIdentification`]: 6,
  [`${PERMIT_EQUIVALENT_GOOD_FIELD_PREFIX}sMeasures`]: 7,
  [`${PERMIT_EQUIVALENT_GOOD_FIELD_PREFIX}sAntidumping`]: 8,
  [`${PERMIT_EQUIVALENT_GOOD_FIELD_PREFIX}${PERMIT_EQUIVALENT_GOOD_CODE_FIELD_NAME}`]: 9,
  [`${PERMIT_EQUIVALENT_GOOD_FIELD_PREFIX}Description`]: 10,
  [`${PERMIT_EQUIVALENT_GOOD_FIELD_PREFIX}UseFields`]: 11,
  [`${PERMIT_YIELD_RATE_FIELD_PREFIX}`]: 12,
  [`${PERMIT_GOODS_IDENTIFICATION_FIELD_PREFIX}`]: 13,
  [`${PERMIT_GOODS_IDENTIFICATION_FIELD_PREFIX}AdditionalInformation`]: 14,
  [`${PERMIT_ECONOMIC_CONDITIONS_FIELD_PREFIX}`]: 15,
  [`${PERMIT_ECONOMIC_CONDITIONS_FIELD_PREFIX}AdditionalInformation`]: 16,
}

// Number of fields in file import
export const PERMIT_GOODS_UNDER_PROCEDURE_BASIC_IMPORT_FIELD_NUMBER = 2
export const PERMIT_GOODS_UNDER_PROCEDURE_ALL_IMPORT_FIELD_NUMBER = 6
export const PERMIT_EQUIVALENT_GOOD_BASIC_IMPORT_FIELD_NUMBER = 2
export const PERMIT_EQUIVALENT_GOOD_ALL_IMPORT_FIELD_NUMBER = 9
export const PERMIT_PROCESSED_PRODUCT_BASIC_IMPORT_FIELD_NUMBER = 2
export const PERMIT_PROCESSED_PRODUCT_ALL_IMPORT_FIELD_NUMBER = 2
export const PERMIT_REPLACEMENT_PRODUCT_BASIC_IMPORT_FIELD_NUMBER = 7
export const PERMIT_REPLACEMENT_PRODUCT_ALL_IMPORT_FIELD_NUMBER = 7

export const PERMIT_GOODS_UNDER_PROCEDURE_COLUMN_MAP = {
  [`${PERMIT_GOODS_FIELD_PREFIX}${PERMIT_GOODS_CODE_FIELD_NAME}`]: 0,
  [`${PERMIT_GOODS_FIELD_PREFIX}Description`]: 1,
  [`${PERMIT_GOODS_FIELD_PREFIX}Quantity`]: 2,
  [`${PERMIT_GOODS_FIELD_PREFIX}MeasurementUnit`]: 3,
  [`${PERMIT_GOODS_FIELD_PREFIX}Amount`]: 4,
  [`${PERMIT_GOODS_FIELD_PREFIX}Currency`]: 5,
}
export const PERMIT_EQUIVALENT_GOOD_COLUMN_MAP = {
  [`${PERMIT_EQUIVALENT_GOOD_FIELD_PREFIX}${PERMIT_EQUIVALENT_GOOD_CODE_FIELD_NAME}`]: 0,
  [`${PERMIT_EQUIVALENT_GOOD_FIELD_PREFIX}Description`]: 1,
  [`${PERMIT_EQUIVALENT_GOOD_FIELD_PREFIX}sIdentification`]: 6,
  [`${PERMIT_EQUIVALENT_GOOD_FIELD_PREFIX}sMeasures`]: 7,
  [`${PERMIT_EQUIVALENT_GOOD_FIELD_PREFIX}sAntidumping`]: 8,
}
export const PERMIT_PROCESSED_PRODUCT_COLUMN_MAP = {
  [`${PERMIT_PROCESSED_PRODUCT_FIELD_PREFIX}${PERMIT_PROCESSED_PRODUCT_CODE_FIELD_NAME}`]: 0,
  [`${PERMIT_PROCESSED_PRODUCT_FIELD_PREFIX}Description`]: 1,
}
export const PERMIT_REPLACEMENT_PRODUCT_COLUMN_MAP = {
  [`${PERMIT_REPLACEMENT_PRODUCT_FIELD_PREFIX}${PERMIT_REPLACEMENT_PRODUCT_CODE_FIELD_NAME}`]: 0,
  [`${PERMIT_REPLACEMENT_PRODUCT_FIELD_PREFIX}Description`]: 1,
  [`${PERMIT_REPLACEMENT_PRODUCT_FIELD_PREFIX}`]: 6,
}

export const unitAmountPairs = [
  {
    unit: `${PERMIT_GOODS_FIELD_PREFIX}MeasurementUnit`,
    amount: `${PERMIT_GOODS_FIELD_PREFIX}Quantity`,
  },
  {
    unit: `${PERMIT_GOODS_FIELD_PREFIX}Currency`,
    amount: `${PERMIT_GOODS_FIELD_PREFIX}Amount`,
  },
]

export const USE_EQUIVALENT_GOOD_FIELD_NAME = 'equivalentGoodUseFields'
export const DEFAULT_TARIC_CODE_MIN_LENGTH = 4
export const DEFAULT_TARIC_CODE_MAX_LENGTH = 10

export const RADIO_BUTTONS = [
  'equivalentGoodsAntidumping',
  'equivalentGoodUseFields',
]
