import React from 'react'
import { sortServices } from 'src/utils'
import ServiceList from './ServiceList'

export default function AnonymousFrontpage(props) {
  const { locale, services, serviceGroups, router } = props
  const anonymousServiceGroups = sortServices(serviceGroups).filter(serviceGroup =>
    serviceGroup.extensionValues.find(extValue => extValue.extensionName === 'usage').value === 'unauthenticated'
  )

  return (
    <div className="frontpage-panels">
      {anonymousServiceGroups && anonymousServiceGroups.map((serviceGroup) => {
        const currentServices = services.filter((service) => {
          const groupIdUnauthenticated = service.extensionValues.find(
            extValue => extValue.extensionName === 'groupIdUnauthenticated'
          ).value
          return groupIdUnauthenticated === serviceGroup.code
        })

        const header = serviceGroup.basicAttributes.find(basicAttr => basicAttr.languageCode === locale).name

        return (
          <div key={serviceGroup.code} className="service-list-wrapper">
            <h2>{header}</h2>
            <ServiceList
              services={currentServices}
              router={router}
              locale={locale}
            />
          </div>
        )
      })}
    </div>
  )
}
