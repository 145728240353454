import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { isArray, some, get } from 'lodash'
import { FormattedMessage } from 'react-intl'
import ErrorMessage from 'src/components/form_v2/ErrorMessage'
import { formatCellValue } from '../../formatValue'
import messages from './messages'

function formatValue(item, field, locale, codesets) {
  return formatCellValue(item, field, {
    formatMessage: msg => <FormattedMessage {...msg} />,
    emptyValue: '',
    locale,
    codesets,
  })
}

const renderRow = (item, itemIndex, fields, errors, locale, codesets) =>
  <div key={itemIndex} style={{ marginBottom: '10px' }}>
    {fields.map((field) => {
      const fieldError = get(errors, `${field.code}-${itemIndex}`, '')
      return (
        <span key={field.code}>
          <b>{field.name[locale]}:</b>
          <span id-qa-test={`label-${field.code}-${itemIndex}`}>
            {formatValue(item, field, locale, codesets)}
          </span><br />
          {fieldError &&
            <div className={'has-error'}>
              <p className="help-block">
                <ErrorMessage error={fieldError} fieldName={field.name[locale]} />
              </p>
            </div>
          }
        </span>
      )
    })}
  </div>

export default function RepetitiveComponentReadOnly(props) {
  const {
    infoElement,
    values,
    locale,
    codesets,
  } = props

  const isMandatory = infoElement.mandatory && some(infoElement.fields, { mandatory: true })
  const errors = get(props.formApi.getFormState(), `errors.${infoElement.code}`)

  const renderErrorMessage = () => {
    if (infoElement.code === 'amountOfDuty') {
      return (
        <FormattedMessage {...messages.missingRequiredRowsAmountOfDuty} />
      )
    }
    return (
      <FormattedMessage {...messages.missingRequiredRows} />
    )
  }

  return (
    <div>
      <Row>
        <Col xs={12}>
          <h4>{infoElement.name[locale]}</h4>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          {!values[infoElement.code] && !isMandatory &&
            <div>--</div>
          }
          {!values[infoElement.code] && isMandatory &&
            <div className="has-error">
              <div className="help-block">
                {renderErrorMessage()}
              </div>
            </div>
          }
          {values[infoElement.code]
            && isArray(values[infoElement.code])
            && values[infoElement.code].map((item, index) =>
              renderRow(item, index, infoElement.fields, errors, locale, codesets)
            )}
        </Col>
      </Row>
    </div>
  )
}
