/**
 * Core layout structure
 * @module layout/CoreLayout
 */

import React from 'react'
import { get } from 'lodash'
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl'
import ErrorBoundary from 'src/components/ErrorBoundary'
import HeaderBarContainer from './HeaderBar'
import GlobalNotifications from '../containers/GlobalNotifications'
import Footer from './Footer'

// Import global CSS styles
import '../styles/core.scss'

const messages = defineMessages({
  jumpToContent: {
    id: 'header.jumpToContent',
    description: 'Jump to content link label for screen readers',
    defaultMessage: 'Jump to content',
  },
})

function CoreLayout({ children, router }) {
  const usePrintMode = get(router, 'params.viewMode') === 'print'
  return ([
    <div key="coreLayout" id="coreLayout" className={usePrintMode ? 'print' : undefined}>
      <div className="accessibility-links-container" id="accessibility" tabIndex="-1">
        <ul>
          <li>
            <a href="#main" target="_self" className="sr-only">
              <FormattedMessage {...messages.jumpToContent} />
            </a>
          </li>
        </ul>
      </div>
      <GlobalNotifications />
      <div className="page-container">
        <div className="view-container">
          <ErrorBoundary>
            {!usePrintMode && <HeaderBarContainer />}
            {children}
          </ErrorBoundary>
        </div>
      </div>
    </div>,
    !usePrintMode && <Footer key="footer" />,
  ])
}

export default (injectIntl(CoreLayout))
