import { createAction } from 'redux-actions'
import { identity } from 'lodash'

const defaultMetaCreator = (action, meta) => meta

/**
 * @name actionCreator
 * @description See {@link https://redux-actions.js.org/} for more details.
 * @function
 * @param payload - Will be set to action.payload. If instance of an Error object, action.error is set to true.
 * @param meta - Will be set to action.meta
 * @returns {object} Flux Standard Action object having properties type, payload and (optional) meta.
 */

/**
 * Function for creating action creators for given action type.
 *
 * @param {string} type - Redux action type
 * @param {function} [payloadCreator] - Creates action payload, identity function is used by default
 * @param {function} [metaCreator] - Creates action metadata, second action creator function argument is used by default
 * @returns {actionCreator} action creator function taking two arguments: payload and meta
 */
// eslint-disable-next-line import/prefer-default-export
export function createDefaultAction(type, payloadCreator = identity, metaCreator = defaultMetaCreator) {
  return createAction(type, payloadCreator, metaCreator)
}
