const logLevels = ['debug', 'info', 'warn', 'error']
let minLogLevel = DEV ? 'debug' : 'error'

window.setLogLevel = (newLevel) => {
  if (logLevels.indexOf(newLevel) !== -1) {
    minLogLevel = newLevel
  }
}

// Safer logger that only logs if console object exist and log level allows logging.
function logger(level, ...logArgs) {
  /* eslint-disable no-console */
  if (!console || typeof console[level] !== 'function') {
    return
  }
  if (logLevels.indexOf(level) < logLevels.indexOf(minLogLevel)) {
    return
  }
  console[level](...logArgs)
  /* eslint-enable no-console */
}

export default logLevels.reduce((loggers, level) => Object.assign(loggers, { [level]: logger.bind(null, level) }), {})
