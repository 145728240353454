import React from 'react'
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl'
import { includes } from 'lodash'
import Checkbox from 'src/components/form_v2/Checkbox'
import { RadioListWithField } from 'src/components/form_v2/RadioList'
import Input from 'src/components/form_v2/Input'
import Datepicker from 'src/components/form_v2/Datepicker'
import Select2FieldGroup from 'src/components/form_v2/Select2'
import { StaticWithField } from 'src/components/form_v2/Static'
import Loader from 'src/components/Loader'
import InteractiveElement from 'src/components/InteractiveElement'
import { CommonCodeset } from 'src/components/codeset'
import Icon from 'src/components/Icon'
import styles from 'src/styles/_forms.scss'

export const messages = defineMessages({
  clearInputValue: {
    id: 'form.input.clear',
    description: 'Label for clearing selected form input value',
    defaultMessage: 'Clear selection',
  },
})

/**
 * Input area for a form control. Renders input control, validation result and help panel based on given props
 */
function InputArea(props) {
  const {
    id,
    formName,
    formApi,
    input,
    inputRef,
    onClear,
    children,
    type,
    placeholder,
    disabled,
    options,
    optionGroups,
    groupOptionsBy,
    mandatory,
    errors,
    warning,
    fetchError,
    loading,
    labelId,
    label,
    inputStyle,
    codesetObj,
    customValue,
    intl: { locale },
    autoFocus,
    helpText,
    applicationDate,
  } = props

  const inputType = input.type
  const ariaDescribedBy = [
    props.ariaDescribedBy,
    props.descriptionText ? `${input.name}-desc` : false,
    `${input.name}-error`,
  ].filter(Boolean).join(' ')
  const ariaProps = {
    'aria-required': mandatory,
    'aria-describedby': ariaDescribedBy,
  }
  if (labelId) {
    ariaProps['aria-labelledby'] = labelId
  }

  let inputComponent
  if (inputType === 'checkbox' || inputType === 'labeledCheckbox') {
    inputComponent = (
      <Checkbox
        id={id}
        formApi={formApi}
        ariaProps={ariaProps}
        {...input}
        locale={locale}
        errors={errors}
        warning={warning}
        style={inputStyle}
        inputRef={inputRef || input.inputRef}
        disabled={disabled || false}
        placeholder={placeholder}
        label={label}
      >
        {inputType === 'checkbox' ? placeholder : label}
      </Checkbox>
    )
  } else if (inputType === 'radioListWithField') {
    inputComponent = (
      <RadioListWithField
        id={id}
        formApi={formApi}
        {...ariaProps}
        input={input}
        locale={locale}
        errors={errors}
        warning={warning}
        style={inputStyle}
        options={options || input.options}
        inputRef={inputRef || input.inputRef}
        disabled={disabled || false}
        placeholder={placeholder}
        label={label}
      >
        {placeholder}
      </RadioListWithField>
    )
  } else if (inputType === 'date' || inputType === 'dateOfBirth') {
    inputComponent = (
      <Datepicker
        id={id}
        formApi={formApi}
        {...ariaProps}
        {...input}
        type={inputType}
        locale={locale}
        errors={errors}
        warning={warning}
        style={inputStyle}
        inputRef={inputRef || input.inputRef}
        disabled={disabled || false}
        placeholder={placeholder}
        label={label}
      />
    )
  } else if (inputType === 'select') {
    inputComponent = (
      <Select2FieldGroup
        id={id}
        formApi={formApi}
        {...ariaProps}
        input={input}
        type={type}
        options={options}
        optionGroups={optionGroups}
        locale={locale}
        errors={errors}
        warning={warning}
        style={inputStyle}
        inputRef={inputRef || input.inputRef}
        disabled={disabled || false}
        placeholder={placeholder}
        placeholderMessage={input.placeholder}
        label={label}
      />
    )
  } else if (inputType === 'static') {
    inputComponent = (
      <StaticWithField id={id} {...props} />
    )
  } else if (
    includes(['codeset', 'codesetAutocomplete', 'codesetStatic', 'codesetRadio', 'codesetCheckboxes'], inputType)
  ) {
    inputComponent = (
      <CommonCodeset
        id={id}
        formApi={formApi}
        {...ariaProps}
        input={input}
        codesetObj={codesetObj}
        type={inputType}
        locale={locale}
        errors={errors}
        warning={warning}
        style={inputStyle}
        inputRef={inputRef || input.inputRef}
        disabled={disabled || false}
        placeholder={placeholder}
        optionGroups={optionGroups}
        groupOptionsBy={groupOptionsBy}
        label={label}
        applicationDate={applicationDate}
      />
    )
  } else if (inputType) {
    inputComponent = (
      <Input
        id={id}
        formApi={formApi}
        formName={formName}
        ariaProps={ariaProps}
        {...input}
        type={inputType}
        locale={locale}
        errors={errors}
        warning={warning}
        inputStyle={inputStyle}
        inputRef={inputRef || input.inputRef}
        disabled={disabled || false}
        customValue={customValue}
        autoFocus={autoFocus}
        label={label}
        helpText={helpText}
      />
    )
  }

  return (
    <div style={{ position: 'relative' }}>
      {loading && <Loader input />}
      {!loading && onClear &&
        <InteractiveElement
          className={styles.inputActionIcon}
          onClick={onClear}
        >
          <Icon name="close" inline />
          <span className="sr-only"><FormattedMessage {...messages.clearInputValue} /></span>
        </InteractiveElement>
      }
      {inputComponent}
      {children}
      {fetchError && fetchError.id &&
        <p className="help-block">
          <span className="text-danger">
            <FormattedMessage {...fetchError} />
          </span>
        </p>
      }
    </div>
  )
}

export default injectIntl(InputArea)
