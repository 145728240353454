import React from 'react'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Jumbotron, Button } from 'react-bootstrap'
import { get, has } from 'lodash'
import Icon from 'src/components/Icon'
import Heading from 'src/layout/Heading'
import DisruptionNotice from 'src/routes/frontpage/components/DisruptionNotice'

class JumbotronMessage extends React.Component {
  componentDidMount() {
    const { focusOnButton } = this.props
    const button = document.getElementById('jumbotronButton')
    if (focusOnButton && button) {
      setTimeout(() => button.focus(), 10)
    } else if (this.infoMessageRef) {
      this.infoMessageRef.focus()
    }
  }

  render() {
    const {
      titleMessage,
      contentMessage,
      buttonMessage,
      onClickButton,
      iconName = 'info',
      children,
      fullpage,
      intl: { formatMessage },
    } = this.props

    let InfoMessage = null
    if (has(contentMessage, 'id')) {
      InfoMessage = <FormattedMessage {...contentMessage} />
    } else {
      InfoMessage = contentMessage
    }
    const content = (
      <Jumbotron style={fullpage ? undefined : { marginBottom: '0' }}>
        <div className="text-center lead">
          <Icon name={iconName} huge /><br /><br />
          {InfoMessage &&
            <p
              tabIndex="-1"
              style={{ outline: 'none' }}
              ref={(ref) => { this.infoMessageRef = ref }}
            >
              {InfoMessage}
            </p>
          }
          {buttonMessage &&
            <p>
              <Button
                id="jumbotronButton"
                bsStyle="primary"
                onClick={onClickButton}
                ref={(ref) => { this.buttonRef = ref }}
                aria-label={formatMessage(buttonMessage)}
              >
                {formatMessage(buttonMessage)}
              </Button>
            </p>
          }
        </div>
        {children}
      </Jumbotron>
    )
    if (fullpage) {
      return (
        <div>
          {titleMessage && <Heading message={titleMessage} compact />}
          <main className="container" role="main" id="main">
            {this.props.showDisruptionNotices &&
              this.props.disruptionNoticeUrls &&
              <DisruptionNotice
                locale={this.props.locale}
                className="container"
                disruptionNoticeUrls={this.props.disruptionNoticeUrls}
              />
            }
            {content}
          </main>
        </div>
      )
    }
    return content
  }
}

const mapStateToProps = state => ({
  locale: state.locale,
  disruptionNoticeUrls: get(state, 'config.bootstrapConfig.nakki_disruption_notices'),
})

export default connect(mapStateToProps)(injectIntl(JumbotronMessage))
