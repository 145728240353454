import { get, isEmpty, find } from 'lodash'
import logger from 'src/utils/logger'
import messages from './apiMessages'
import { showGlobalNotification, logout, sendLogoutRequestAndExit } from '../actions.js'
import { getStore } from '../store'
import { getFieldErrors } from './validation'
import { HTTP_STATUS_401, SESSION_TOKEN_EXPIRED, SESSION_TOKEN_INVALID } from '../constants'


/**
 * Currently tm_permit has SOAP timeout for 55 seconds
 */
const PROXY_TIMEOUT_SECONDS = 55

/**
 * Custom error for REST API responses. Fetch API Response object is included in property `response`.
 */
export function RestResponseError(message, response) {
  this.message = message
  this.response = response
}
RestResponseError.prototype = Object.create(Error.prototype)
RestResponseError.prototype.name = 'RestResponseError'
RestResponseError.prototype.message = ''
RestResponseError.prototype.constructor = RestResponseError

/**
 * Custom error for REST API responses. Fetch API Response object is included in property `response`.
 */
export function ServerValidationError(httpErrorObject) {
  this.value = httpErrorObject?.responseJson
  this.httpStatus = httpErrorObject?.httpStatus
}

ServerValidationError.prototype = Object.create(Error.prototype)
ServerValidationError.prototype.name = 'ServerValidationError'
ServerValidationError.prototype.message = 'Validation failed on server side'
ServerValidationError.prototype.constructor = ServerValidationError
ServerValidationError.prototype.getFieldErrors = function getIntlErrors(apiMessages) {
  return getFieldErrors(this.value, apiMessages)
}

/**
 * Custom error for timeouts
 */
export function ServerTimeoutError(httpErrorObject) {
  this.value = httpErrorObject?.responseJson
  this.httpStatus = httpErrorObject?.httpStatus
}

ServerTimeoutError.prototype = Object.create(Error.prototype)
ServerTimeoutError.prototype.name = 'ServerTimeoutError'
ServerTimeoutError.prototype.message = 'The server operation took too long'

function isProxyTimeout(errorObject, startTime) {
  const endTime = +new Date()
  const elapsedTimeSeconds = Math.ceil((endTime - startTime) / 1000)

  const errorStatus50x = errorObject.httpStatus.status === 502 || errorObject.httpStatus.status === 500

  return errorStatus50x && elapsedTimeSeconds > PROXY_TIMEOUT_SECONDS
}

/**
 * Check http status from fetch result
 * @throws {RestResponseError} with response property on status not ok
 */
export function checkHttpStatus(response) {
  if (response.ok) {
    return response
  }
  const error = new RestResponseError(response.statusText, response)
  throw error
}

/**
 * Create commonly used http-headers according to options
 * @returns {Object} headers object for Fetch API request options
 */
export function createHttpRequestHeaders(options) {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  if (options && options.locale) {
    headers['Accept-Language'] = options.locale
  }
  return headers
}

/**
 * Helper for handling Promise from Fetch Api Response
 * @returns {Promise} resolves with JSON object
 */
export function parseJSON(response) {
  if (!response || !response.status) {
    return Promise.reject()
  }
  return response.status === 204 ? Promise.resolve() : response.json()
}

/**
 * Helper for parsing error json messages.
 * @param {ResponseError} error - error object thrown from http utils, including Fetch API Response
 * @returns {Promise<ApiErrorObject>} original api response extended with intlMessage and httpStatus
 */
export function httpErrorParser(parser, error) {
  return parser(error.response)
    .catch(() => {
      // Could not parse error response body as json, continue with error handling without payload
      logger.debug('Could not parse api error as json', error)
    })
    .then((errorJson) => {
      const responseJson = errorJson || {}
      const arrayContainsErrors = Boolean(find(responseJson, it => !isEmpty(it.errors)))

      // If error was not specified server-side, use default messages by http code
      if (!arrayContainsErrors && !responseJson.errors && !responseJson.code) {
        const status = get(error, 'response.status')
        if (status === HTTP_STATUS_401) {
          responseJson.code = 'unauthorized'
        } else if (status === 403) {
          responseJson.code = 'forbidden'
        } else if (status === 404) {
          responseJson.code = 'notFound'
        } else if (status === 429) {
          responseJson.code = 'tooManyRequests'
        } else if (status === 502) {
          responseJson.code = 'proxyError'
        } else if (status === 500) {
          responseJson.code = 'unexpectedError'
        } else if (status === 503 || status === 504) {
          responseJson.code = 'serviceUnavailable'
        } else if (error instanceof TypeError) {
          responseJson.code = 'networkError'
        } else {
          responseJson.code = 'unexpectedError'
        }
      }

      const globalIntlMessage = (responseJson.code && messages[responseJson.code]) || null
      return {
        responseJson,
        globalIntlMessage,
        httpStatus: {
          ok: get(error, 'response.ok'),
          status: get(error, 'response.status'),
          text: get(error, 'response.statusText'),
        },
      }
    })
}

export function httpErrorHandler(dispatch, dispatchNotifications, startTime, errorObject) {
  if (dispatch) {
    const httpStatus = get(errorObject, 'httpStatus.status')
    const responseCode = get(errorObject, 'responseJson.code')

    switch (httpStatus) {
    case (HTTP_STATUS_401):
      if (responseCode === SESSION_TOKEN_INVALID) {
        dispatch(logout())
        if (dispatchNotifications) {
          dispatch(showGlobalNotification({
            level: 'error',
            modal: true,
            message: errorObject.globalIntlMessage,
          }))
        }
      } else if (responseCode === SESSION_TOKEN_EXPIRED) {
        dispatch(sendLogoutRequestAndExit())
      }
      break
    default:
    }

    if (dispatchNotifications && errorObject && errorObject.globalIntlMessage) {
      dispatch(showGlobalNotification({
        level: 'error',
        message: errorObject.globalIntlMessage,
      }))
    }
  }
  if (isProxyTimeout(errorObject, startTime)) {
    throw new ServerTimeoutError(errorObject)
  }

  throw new ServerValidationError(errorObject)
}

/**
 * Helper for calling fetch api with common request and response tasks:
 * * adds http headers and language
 * * handles http statuses
 * * parses errors and dispatches notifications on known error codes
 * * parses json content from success response
 *
 * By default caching is disabled! Use cache-property in init to change caching.
 *
 * @param input - Fetch API input (e.g. url)
 * @param init - Fetch API init (fetch init options, e.g. cache)
 * @param headerOptions - header options for api call, passed to createHttpRequestHeaders
 * @param dispatch - DEPRECATED: Redux dispatch function
 * @param dispatchNotifications - dispatch global notifications on api errors
 * @returns Promise resolved to parsed json response content
 */
export function apiCall(input, init, headerOptions, _dispatch_, dispatchNotifications, encodeInput = true) {
  const state = getStore().getState()
  const dispatch = getStore().dispatch
  const defaultHeaderOptions = {
    locale: state.locale,
  }
  const defaultOptions = {
    credentials: 'same-origin',
  }
  const options = Object.assign(defaultOptions, init)

  const url = encodeInput ? encodeURI(input) : input
  const startTime = +new Date()

  return fetch(url, {
    headers: createHttpRequestHeaders({ ...defaultHeaderOptions, ...headerOptions }),
    cache: 'no-store',
    ...options,
  })
    .then(checkHttpStatus)
    .then(parseJSON)
    .catch((rawError) => {
      logger.debug('apiCall http error', rawError)
      return httpErrorParser(parseJSON, rawError)
        .then(httpErrorHandler.bind(this, dispatch, dispatchNotifications, startTime))
    })
}

/**
 * Synchronously loops through resources calling asyncAction for each resource after previous action has finished.
 *
 * @param resources - Array of resources to handle asynchronously
 * @param asyncAction - Action to call with each resource
 * @returns Promise for the last resource action, once resolved, all actions have resolved in order
 */
export const synchronousPromiseChain = (resources, asyncAction) =>
  resources.reduce(
    (previousPromise, resource, index, array) =>
      previousPromise.then(() => asyncAction(resource, index + 1, array.length)),
    Promise.resolve()
  )
