import React from 'react'
import ReactMarkdown from 'react-markdown'
import LinkRenderer from 'src/components/LinkRenderer'
import IconRenderer from 'src/components/IconRenderer'
import { injectIntl } from 'react-intl'
import { FormGroup } from 'react-bootstrap'
import { isObject, has, omit, get } from 'lodash'
import LabelArea from './LabelArea'
import InputArea from './InputArea'

function CommonFormGroup(props) {
  const {
    label,
    input,
    children,
    errors,
    warning,
    showLabel,
    autoFocus,
    intl: { formatMessage },
    descriptionText,
  } = props

  const labelContent = isObject(label) ? formatMessage(label) : label
  let validationState = null
  if (has(errors, input.name)) {
    validationState = 'error'
  } else if (warning) {
    validationState = 'warning'
  }
  if (!get(input, 'visible', true)) {
    return null
  }

  const hasLabel = label
    && (input.type !== 'labeledCheckbox' || input.static)
  const inputGroupTypes = ['radioListWithField', 'codesetRadio', 'codesetCheckboxes']
  const isInputGroup = inputGroupTypes.includes(input.type)

  return (
    <FormGroup
      controlId={input.name}
      className={`clearfix ${props.formGroupClassname}`}
      style={props.formGroupStyle}
      validationState={validationState}
      role={isInputGroup ? 'group' : undefined}
      aria-labelledby={isInputGroup ? `${input.name}Label` : undefined}
      aria-describedby={isInputGroup && descriptionText ? `${input.name}-desc` : undefined}
    >
      {hasLabel && // checkbox has its own label
        <LabelArea
          id={`${input.name}Label`}
          input={input}
          help={input.help}
          mandatory={input.validation.mandatory}
          disabled={props.disabled}
          showLabel={showLabel}
          id-qa-test={`label-${input.id}`}
          label={labelContent}
          staticValue={input.static}
        >
          {labelContent}
        </LabelArea>}
      {descriptionText &&
        <ReactMarkdown
          containerProps={{ id: `${input.name}-desc` }}
          source={descriptionText}
          renderers={{ Link: LinkRenderer, Image: IconRenderer }}
          linkTarget="_blank"
        />
      }
      <InputArea
        {...{
          ...props,
          input: omit(props.input, ['multilineLabel']),
          autoFocus,
          label: labelContent,
        }}
      >
        {children}
      </InputArea>
    </FormGroup>
  )
}

export default injectIntl(CommonFormGroup)
