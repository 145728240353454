import React, { Component } from 'react'
import { Field } from 'react-form'
import { FormControl, HelpBlock } from 'react-bootstrap'
import { FormattedMessage, injectIntl } from 'react-intl'
import { SingleDatePicker } from 'react-dates'
import moment from 'moment'
import classNames from 'classnames'
import Icon from 'src/components/Icon'
import SelectFieldGroup from 'src/components/form_v2/Select'
import { range, updateStatusMessageForInput } from 'src/utils'
import { messages as validationMessages } from 'src/utils/validation'
import 'src/styles/vendor/reactDates.scss'
import messages from '../messages'
import ErrorMessage from './ErrorMessage'

import styles from './Datepicker.scss'

class Datepicker extends Component {
  constructor(props) {
    super(props)

    const {
      name,
      locale,
    } = props

    this.state = {
      dateString: null,
      date: null,
      calendarOpen: false,
      focused: false,
    }
    this.yearOptions = []
    this.monthOptions = []
    this.setValue = null
    this.setTouched = null
    this.inputId = name
    this.labelFieldId = `${this.inputId}Label`
    this.errorFieldId = `${this.inputId}-error`
    moment.locale(locale)
    moment.months()
      .map((month, index) =>
        this.monthOptions.push({ id: index, value: index, title: month })
      )
    this.toggleCalendar = this.toggleCalendar.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.validate = this.validate.bind(this)
  }

  componentDidMount() {
    const { type } = this.props
    if (type === 'dateOfBirth') {
      Array.from(range(moment().year() - 100, moment().year()))
        .reverse()
        .map(year =>
          this.yearOptions.push({ id: year, value: year, title: year })
        )
    } else {
      Array.from(range(moment().year() - 2, moment().year() + 2))
        .reverse()
        .map(year =>
          this.yearOptions.push({ id: year, value: year, title: year })
        )
    }

    this.fixAccessibilityIssues()
  }

  componentDidUpdate() {
    this.fixAccessibilityIssues()
  }

  /**
   * NAKKI-381 Fixes react-dates accessibility issues by modifying DOM elements
   */
  fixAccessibilityIssues() {
    const inputElement = document.getElementById(this.inputId)
    if (inputElement) {
      inputElement.onblur = () => updateStatusMessageForInput(this.inputId)
      inputElement.setAttribute('aria-describedby', this.errorFieldId)
      inputElement.setAttribute('aria-labelledby', this.labelFieldId)
      inputElement.removeAttribute('aria-label')
    }
  }

  toggleCalendar(e) {
    e.preventDefault()
    if (!this.state.disableOpen) {
      this.setState({ calendarOpen: !this.state.calendarOpen, touched: true })
    }
  }

  handleDateChange(date) {
    const dateString = document.getElementById(this.inputId).value
    this.setState({ dateString, date, calendarOpen: false, touched: true })
    this.setTouched()
    this.setValue((date && date.format('YYYYMMDD')) || dateString)
  }

  validate(value) {
    const mandatory = this.props.mandatory
    let error = null
    if (value) {
      error = moment(value, 'YYYYMMDD', true).isValid() ?
        null : { error: validationMessages.invalidDate }
    }
    if (mandatory && !value) {
      error = { error: validationMessages.required }
    }
    return error
  }

  render() {
    const {
      placeholder,
      label,
      intl: { formatMessage },
    } = this.props

    const {
      touched,
      hasError,
      date,
      calendarOpen,
    } = this.state

    const datepickerButtonStyles = calendarOpen ?
      classNames(styles.datepickerButton, styles.datepickerButtonFocus) : styles.datepickerButton

    return (
      <div className={hasError ? 'has-error' : ''}>
        {!this.props.static &&
          <div className={styles.datepickerContainer}>
            <SingleDatePicker
              id={this.inputId}
              date={date}
              onDateChange={this.handleDateChange}
              focused={calendarOpen}
              onFocusChange={({ focused }) => {
                if (!focused) {
                  this.setState({ calendarOpen: false, disableOpen: true })
                  setTimeout(() => this.setState({ disableOpen: false }), 50)
                  if (!focused) {
                    this.setTouched()
                  }
                }
              }}
              showDefaultInputIcon={false}
              keepOpenOnDateSelect={false}
              numberOfMonths={1}
              customCloseIcon={<Icon name="close" inline />}
              small
              noBorder
              hideKeyboardShortcutsPanel
              placeholder={placeholder || formatMessage(messages.dateDefaultPlaceholder)}
              isOutsideRange={() => false}
              daySize={51}
              displayFormat="D.M.YYYY"
              screenReaderInputMessage="-"
              renderMonthElement={({ month, onMonthSelect, onYearSelect }) => (
                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                  <div style={{ margin: '2px', position: 'relative' }}>
                    <label
                      id="selectMonthLabel"
                      htmlFor="selectMonth"
                      className="hiddenElement"
                    >
                      <FormattedMessage
                        {...messages.monthLabel}
                      />
                    </label>
                    <SelectFieldGroup
                      input={{
                        name: 'selectMonth',
                        value: month.month(),
                        onChange: newValue => onMonthSelect(month, newValue),
                      }}
                      options={this.monthOptions}
                      multiple={false}
                    />
                  </div>
                  <div style={{ margin: '2px', position: 'relative' }}>
                    <label
                      id="selectYearLabel"
                      htmlFor="selectYear"
                      className="hiddenElement"
                    >
                      <FormattedMessage
                        {...messages.yearLabel}
                      />
                    </label>
                    <SelectFieldGroup
                      input={{
                        name: 'selectYear',
                        value: month.year(),
                        onChange: newValue => onYearSelect(month, newValue),
                      }}
                      options={this.yearOptions}
                      multiple={false}
                    />
                  </div>
                </div>
              )}
            />
            <div
              aria-hidden
              onClick={this.toggleCalendar}
              className={datepickerButtonStyles}
            >
              <Icon name="calendar" md inline />
            </div>
          </div>
        }
        <Field
          field={this.props.name}
          validate={this.validate}
        >
          {(fieldApi) => {
            const { value, error, setValue, setTouched } = fieldApi

            if (!touched && value) {
              const dateValue = moment(value, 'YYYYMMDD')
              if (dateValue.isValid()) {
                this.setState({ date: dateValue })
              }
            }
            if (!this.setTouched) {
              this.setTouched = setTouched
            }
            if (!this.setValue) {
              this.setValue = setValue
            }

            this.setState({ hasError: error })

            return (
              <div>
                {this.props.static &&
                  <FormControl.Static id-qa-test={`label-date-value-${this.props.name}`}>
                    {(value && moment(value, 'YYYYMMDD').format('DD.MM.YYYY')) || '--'}
                  </FormControl.Static>
                }
                <HelpBlock id={this.errorFieldId}>
                  {error &&
                    <div>
                      <ErrorMessage error={error} fieldName={label} />
                    </div>
                  }
                  {!this.props.static &&
                    <div aria-hidden="true">
                      { formatMessage(messages.dateHelpText) }
                    </div>
                  }
                  <div className="sr-only">
                    { formatMessage(messages.dateHelpTextA11y) }
                  </div>
                </HelpBlock>
              </div>
            )
          }}
        </Field>
      </div>
    )
  }
}

export default injectIntl(Datepicker)
