import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { isArray, some, get } from 'lodash'
import { FormattedMessage } from 'react-intl'
import messages from '../RepetitiveComponent/messages'
import GuaranteePerCustomsProcedureRows from './GuaranteePerCustomsProcedureRows'


export default function GuaranteePerCustomsReadOnly(props) {
  const {
    infoElement,
    values,
    locale,
    codesets,
  } = props

  const isMandatory = infoElement.mandatory && some(infoElement.fields, { mandatory: true })
  const errors = get(props.formApi.getFormState(), `errors.${infoElement.code}`)

  const renderErrorMessage = () => (
    <FormattedMessage {...messages.missingRequiredRows} />
  )

  return (
    <div>
      <Row>
        <Col xs={12}>
          <h4>{infoElement.name[locale]}</h4>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          {!values[infoElement.code] && !isMandatory &&
            <div>--</div>
          }
          {!values[infoElement.code] && isMandatory &&
            <div className="has-error">
              <div className="help-block">
                {renderErrorMessage()}
              </div>
            </div>
          }
        </Col>
      </Row>
      {values[infoElement.code]
            && isArray(values[infoElement.code])
            && values[infoElement.code].map((item, index) =>
              <GuaranteePerCustomsProcedureRows
                codesets={codesets}
                errors={errors}
                fields={infoElement.fields}
                item={item}
                itemIndex={index}
                locale={locale}
              />
            )}
    </div>
  )
}
