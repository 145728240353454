import React from 'react'
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl'
import cn from 'classnames'
import ModalInfo from 'src/components/ModalInfo'
import styles from './Footer.scss'

const messages = defineMessages({
  feedbackLabel: {
    id: 'feedback.label',
    description: 'Label text for application feedback',
    defaultMessage: 'Feedback',
  },
  headerText: {
    id: '/feedbackHeader',
    defaultMessage: 'Feedback header',
  },
  footerHeaderText: {
    id: '/footerFeedbackHeader',
    defaultMessage: 'Would you like to give feedback?',
  },
  feedback: {
    id: '/feedback',
    defaultMessage: 'Feedback...',
  },
  closingText: {
    id: '/feedbackClosingText',
    defaultMessage: 'Feedback closing text...',
  },
  feedbackLink: {
    id: '/feedbackLink',
    defaultMessage: 'Feedback link...',
  },
})

function FooterFeedbackButton({ intl: { formatMessage } }) {
  const feedbackText = formatMessage(messages.feedback)
  if (!feedbackText) {
    return null
  }

  return (
    <React.Fragment>
      <h2 className={styles.feedbackTitle}>
        <FormattedMessage {...messages.footerHeaderText} />
      </h2>
      <ModalInfo
        header={formatMessage(messages.headerText)}
        content={feedbackText}
        closingText={formatMessage(messages.closingText)}
        feedbackLink={formatMessage(messages.feedbackLink)}
        buttonText={formatMessage(messages.feedbackLabel)}
      >
        <div
          className={cn('btn btn-default', styles.defaultButton)}
        >
          <FormattedMessage {...messages.feedbackLabel} />
        </div>
      </ModalInfo>
    </React.Fragment>
  )
}

export default injectIntl(FooterFeedbackButton)
