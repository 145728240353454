import { handleAction } from 'redux-actions'
import { LOADING_ACTION } from '../constants'

export default handleAction(
  LOADING_ACTION,
  (state, { payload }) => {
    // Remove key from state on falsy values
    const value = payload.value || undefined
    return {
      ...state,
      [payload.key]: value,
    }
  },
  {}
)
