import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { injectIntl } from 'react-intl'
import { has, get, isArray, set, includes } from 'lodash'
import repetitiveComponentMessages from 'src/routes/permits/permit/components/RepetitiveComponent/messages'
import InfoElementHeaderArea from 'src/components/form_v2/InfoElementHeaderArea'
import ItemForm from './components/ItemForm'
import ItemsList from './components/ItemsList'
import Import from './components/Import'
import {
  DEFAULT_TARIC_CODE_MIN_LENGTH,
  DEFAULT_TARIC_CODE_MAX_LENGTH,
} from './constants'
import {
  PERMIT_REPLACEMENT_PRODUCT_FIELD_PREFIX,
} from '../PermitPage/constants'
import { PERSON_ALLOWED_PERMIT_TYPES } from '../../constants'

class GoodsToBePlacedUnderProcedure extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedItem: null,
      selectedItemIndex: null,
      justCancelled: false,
      errors: [],
      showValidation: false,
    }
    this.addNew = this.addNew.bind(this)
    this.cancelEditing = this.cancelEditing.bind(this)
    this.selectItem = this.selectItem.bind(this)
    this.updateItem = this.updateItem.bind(this)
    this.deleteItem = this.deleteItem.bind(this)
  }

  componentDidUpdate(prevProps) {
    const submits = get(this.props, 'formApi.submits', 0)
    const prevSubmits = get(prevProps, 'formApi.submits', 0)
    if (submits > prevSubmits) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ showValidation: true })
    }
  }

  getTaricCodeMinMaxLength() {
    const { formApi, infoElement } = this.props
    const formValues = get(formApi.getFormState(), 'values')
    if (infoElement.code === PERMIT_REPLACEMENT_PRODUCT_FIELD_PREFIX &&
      includes(['true', '1'], get(formValues, 'standardExchangeSystem.standardExchangeSystem'))) {
      return {
        min: 8,
        max: 10,
      }
    }
    return {
      min: DEFAULT_TARIC_CODE_MIN_LENGTH,
      max: DEFAULT_TARIC_CODE_MAX_LENGTH,
    }
  }

  addNew(data, errors = null) {
    const { formApi, infoElement } = this.props
    if (!has(formApi.values, infoElement.code)) {
      formApi.values[infoElement.code] = []
    }
    const newItems = formApi.values[infoElement.code]
    newItems.push({
      ...data,
      errors,
    })
    formApi.setValue(infoElement.code, newItems)
  }

  cancelEditing() {
    this.setState({ selectedItem: null, justCancelled: true })
    setTimeout(() => {
      this.setState({ justCancelled: false })
    }, 500)
  }

  selectItem(item, index) {
    if (!get(this.state, 'justCancelled')) {
      this.setState({
        selectedItem: item,
        selectedItemIndex: index,
      })
    }
  }

  updateItem(oldData, newData) {
    const { formApi, infoElement } = this.props
    const { selectedItemIndex } = this.state
    let newItems = formApi.values[infoElement.code]
    newItems = set(newItems, `[${selectedItemIndex}]`, newData)
    formApi.setValue(infoElement.code, newItems)
  }

  deleteItem() {
    const { formApi, infoElement } = this.props
    const newItems = formApi.values[infoElement.code].slice(0)
    newItems.splice(this.state.selectedItemIndex, 1)
    formApi.setValue(infoElement.code, newItems)
    this.setState({ selectedItem: null, selectedItemIndex: null })
  }

  render() {
    const {
      infoElement,
      locale,
      formApi,
      renderField,
      intl,
      structure,
      codesets,
    } = this.props

    const { showValidation } = this.state

    const hasGoods = isArray(get(formApi.values, infoElement.code)) &&
      formApi.values[infoElement.code].length > 0

    const hasError = showValidation && infoElement.mandatory && !hasGoods
    const goodProps = {
      codeFieldName: infoElement.codeFieldName,
      taricCodeLength: this.getTaricCodeMinMaxLength(),
    }

    const isReliefPermit = PERSON_ALLOWED_PERMIT_TYPES.includes(structure.code)

    return (
      <Col xs={12}>
        <Row>
          <Col xs={12}>
            <InfoElementHeaderArea
              infoElement={infoElement}
              locale={locale}
              help={infoElement?.help?.[locale]}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <ItemForm
              selectedItem={this.state.selectedItem}
              cancelEditing={this.cancelEditing}
              updateItem={this.updateItem}
              deleteItem={this.deleteItem}
              locale={locale}
              infoElement={infoElement}
              renderField={renderField}
              addNew={this.addNew}
              intl={intl}
              permitTypeCode={get(structure, 'code')}
              {...goodProps}
            />
            {!isReliefPermit && !hasGoods && <Import
              locale={locale}
              addNew={this.addNew}
              formatMessage={intl.formatMessage}
              infoElement={infoElement}
              permitTypeCode={get(structure, 'code')}
              codesets={codesets}
              {...goodProps}
            />}
            {hasGoods && <ItemsList
              items={[...formApi.values[infoElement.code]]}
              fields={infoElement.fields}
              locale={locale}
              selectItem={(item, index) => this.selectItem(item, index)}
              infoElement={infoElement}
              intl={intl}
              codesets={codesets}
              permitTypeCode={get(structure, 'code')}
              {...goodProps}
            />}
          </Col>
        </Row>
        {infoElement.mandatory &&
          <Row>
            <Col md={8} sm={12}>
              <div className={hasError ? 'has-error' : ''}>
                <p id={`new-row-description-${infoElement.code}`} className="help-block">
                  {intl.formatMessage(repetitiveComponentMessages.mandatoryRowDescription)}
                </p>
              </div>
            </Col>
          </Row>
        }
      </Col>
    )
  }
}

export default injectIntl(GoodsToBePlacedUnderProcedure)
