import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import { Grid, Row, Col } from 'react-bootstrap'
import { get, filter } from 'lodash'
import AcceptTermsCheckbox from './AcceptTermsCheckbox'
import ErrorList from './ErrorList'
import KiloDescriptionInfoArea from './KiloDescriptionInfoArea'
import {
  getReadOnlyInfoElementComponent,
  getInfoElementValueErrors,
  isInfoElementVisibleConditionally,
} from '../permitManager'
import {
  PERMIT_APPLICATION_STATUS_DRAFT,
} from '../../constants'
import {
  PERMIT_GROUP_KILO_DESCRIPTION,
  PERMIT_TYPES_REQUIRING_TERMS_ACCEPTANGE,
} from '../constants'
import { filterRepresentativeIfNoValue } from '../permitHelper'

class PermitSummary extends Component {
  componentDidMount() {
    const { structure, formApi } = this.props
    filter(structure.infoElements, 'visible')
      .forEach((infoElement) => {
        const errors = getInfoElementValueErrors(infoElement, formApi.values)
        Object.keys(errors).forEach((fieldKey) => {
          formApi.setError(fieldKey, errors[fieldKey][0])
        })
      })
  }

  render() {
    const {
      structure,
      locale,
      formApi,
      renderField,
      helpTexts,
      applicationState,
      saveDraft,
      customerHasAEO,
      codesets,
      intl,
      isPerson,
      applicant,
    } = this.props

    return (
      <Grid fluid style={{ marginTop: '10px', marginBottom: '40px' }}>
        {structure.groups && filter(structure.groups, group => group.code !== 'summary')
          .map(group =>
            <Row key={group.code}>
              <Col xs={12}>
                <Row>
                  <Col xs={12}>
                    <h3>{group.name[locale]}</h3>
                  </Col>
                </Row>
                {structure.infoElements
                  .filter(infoElement => infoElement.group === group.code)
                  /*
                  *  If permit data has been created with delegate user, the data has it and we want to show it
                  *  otherwise, we want to hide it
                  */
                  .filter(infoElement => filterRepresentativeIfNoValue(infoElement, formApi))
                  .map(infoElement => <InfoElementSummary
                    key={infoElement.code}
                    infoElement={infoElement}
                    formApi={formApi}
                    structure={structure}
                    locale={locale}
                    renderField={renderField}
                    helpTexts={helpTexts}
                    saveDraft={saveDraft}
                    intl={intl}
                    codesets={codesets}
                    isPerson={isPerson}
                    applicant={applicant}
                  />)
                }
                <hr />
              </Col>
            </Row>
          )}
        {applicationState === PERMIT_APPLICATION_STATUS_DRAFT &&
          !customerHasAEO &&
          PERMIT_GROUP_KILO_DESCRIPTION.includes(structure.code) &&
          <Row>
            <KiloDescriptionInfoArea
              formApi={formApi}
              helpTexts={helpTexts}
            />
          </Row>}
        {applicationState === PERMIT_APPLICATION_STATUS_DRAFT &&
          PERMIT_TYPES_REQUIRING_TERMS_ACCEPTANGE.includes(structure.code) &&
          <Row>
            <AcceptTermsCheckbox formApi={formApi} structure={structure} isPerson={isPerson} />
          </Row>
        }
      </Grid>
    )
  }
}

function InfoElementSummary({
  infoElement,
  formApi,
  structure,
  locale,
  renderField,
  helpTexts,
  saveDraft,
  intl,
  applicant,
  codesets,
  isPerson,
}) {
  const values = get(formApi.getFormState(), 'values')
  const visible = infoElement.visible && isInfoElementVisibleConditionally(infoElement, values)

  if (!visible) {
    return null
  }

  const InfoElementComponent = getReadOnlyInfoElementComponent(infoElement, structure)
  if (InfoElementComponent) {
    return (
      <InfoElementComponent
        infoElement={infoElement}
        values={formApi.values}
        locale={locale}
        applicant={applicant}
        renderField={renderField}
        formApi={formApi}
        structure={structure}
        helpTexts={helpTexts}
        saveDraft={saveDraft}
        codesets={codesets}
        formatMessage={intl.formatMessage}
        type={'readonly'}
        isPerson={isPerson}
        renderErrors={() => (
          <ErrorList errors={formApi.errors} infoElement={infoElement} />
        )}
      />
    )
  }

  return (
    <Row>
      <Col xs={12}>
        <h4>{infoElement.name[locale]}</h4>
      </Col>
      {infoElement.fields.map((field) => {
        if (field.infoElement.code === 'applicantCustomsResponsible') {
          if (field.visibleWhen && !field.visibleWhen(values, values)) {
            return null
          }
        }

        return <Col sm={6} xs={12} key={`field-${field.code}`}>
          {renderField({
            field,
            staticValue: true,
            showHelp: false,
            validate: false,
          })}
        </Col>
      })}
    </Row>
  )
}

export default injectIntl(PermitSummary)
