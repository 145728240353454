export const PERMIT_GOODSMOVEMENT_INFOELEMENT_NAME = 'goodsMovement'
export const PERMIT_GOODSLOCATION_INPUT_TYPE_WAREHOUSE = 'EXTERNAL_WAREHOUSE_IDENTIFIER'
export const PERMIT_GOODSLOCATION_INPUT_TYPE_EORI = 'EXTERNAL_EORI'
export const PERMIT_GOODSLOCATION_INPUT_TYPE_ADDRESS = 'MANUAL'
export const PERMIT_GOODSLOCATION_WAREHOUSE_IDENTIFIER_SEARCH = {
  fi: 'https://tulli.fi/sahkoiset-palvelut/palvelut/haku-ja-kyselypalvelut/varastotunnisteet',
  sv: 'https://tulli.fi/sv/elektroniska-tjanster/sok-och-forfragningstjanster/lagerkoder',
}
// Permit types which has specific location input types, defaults to all possible input types
export const PERMIT_GOODSLOCATION_INPUT_TYPES_BY_TYPE_CODE = {
  EIR: [
    PERMIT_GOODSLOCATION_INPUT_TYPE_WAREHOUSE,
    PERMIT_GOODSLOCATION_INPUT_TYPE_ADDRESS,
  ],
  ACE: [
    PERMIT_GOODSLOCATION_INPUT_TYPE_WAREHOUSE,
    PERMIT_GOODSLOCATION_INPUT_TYPE_ADDRESS,
  ],
  ACT: [
    PERMIT_GOODSLOCATION_INPUT_TYPE_WAREHOUSE,
    PERMIT_GOODSLOCATION_INPUT_TYPE_ADDRESS,
  ],
  TST: [
    PERMIT_GOODSLOCATION_INPUT_TYPE_WAREHOUSE,
    PERMIT_GOODSLOCATION_INPUT_TYPE_ADDRESS,
  ],
  IPO: [
    PERMIT_GOODSLOCATION_INPUT_TYPE_WAREHOUSE,
    PERMIT_GOODSLOCATION_INPUT_TYPE_ADDRESS,
  ],
  OPO: [
    PERMIT_GOODSLOCATION_INPUT_TYPE_WAREHOUSE,
    PERMIT_GOODSLOCATION_INPUT_TYPE_ADDRESS,
  ],
  EUS: [
    PERMIT_GOODSLOCATION_INPUT_TYPE_WAREHOUSE,
    PERMIT_GOODSLOCATION_INPUT_TYPE_ADDRESS,
  ],
  TEA: [
    PERMIT_GOODSLOCATION_INPUT_TYPE_WAREHOUSE,
    PERMIT_GOODSLOCATION_INPUT_TYPE_ADDRESS,
  ],
  EIA: [
    PERMIT_GOODSLOCATION_INPUT_TYPE_WAREHOUSE,
    PERMIT_GOODSLOCATION_INPUT_TYPE_ADDRESS,
  ],
  AWB: [
    PERMIT_GOODSLOCATION_INPUT_TYPE_WAREHOUSE,
    PERMIT_GOODSLOCATION_INPUT_TYPE_ADDRESS,
  ],
}

export const PERMIT_GOODSLOCATION_DISABLED_BY_TYPE_CODE = ['ACR']

export const PERMIT_GOODSLOCATION_FIELDS_MAP = {
  goodsLocation: {
    countryCode: 'goodsLocationCountryCode',
    typeCode: 'goodsLocationTypeCode',
    identificationQualifier: 'goodsLocationIdentificationQualifier',
    location: 'goodsLocationIdentification',
    additionalIdentifier: 'goodsLocationAdditionalIdentifier',
  },
  goodsMovement: {
    legalBaseCode: 'goodsMovementLegalBaseCode',
    eori: 'goodsMovementEori',
    countryCode: 'goodsMovementCountryCode',
    typeCode: 'goodsMovementLocationType',
    identificationQualifier: 'goodsMovementIdentificationQualifier',
    location: 'goodsMovementLocationIdentification',
    additionalIdentifier: 'goodsMovementAdditionalIdentifier',
  },
  processingOrUsePlace: {
    countryCode: 'processingOrUsePlaceCountryCode',
    typeCode: 'processingOrUsePlaceCodeType',
    identificationQualifier: 'processingOrUsePlaceIdentificationQualifier',
    location: 'processingOrUsePlaceIdentification',
    additionalIdentifier: 'processingOrUsePlaceAdditionalIdentifier',
  },
  firstProcessingOrUsePlace: {
    countryCode: 'firstProcessingOrUsePlaceCountryCode',
    typeCode: 'firstProcessingOrUsePlaceCodeType',
    identificationQualifier: 'firstProcessingOrUsePlaceIdentificationQualifier',
    location: 'firstProcessingOrUsePlaceIdentification',
    additionalIdentifier: 'firstProcessingOrUsePlaceAdditionalIdentifier',
  },
}

export const PERMIT_GOODSLOCATION_DEFAULT_VALUES = {
  countryCode: 'FI',
  typeCode: 'D',
  identificationQualifier: 'Z',
  legalBaseCode: 'A',
}
export const PERMIT_GOODSLOCATION_DEFAULT_INPUT_TYPE_WAREHOUSE_VALUES = {
  countryCode: {
    default: PERMIT_GOODSLOCATION_DEFAULT_VALUES.countryCode,
  },
  typeCode: {
    EIR: 'B',
    ACE: 'B',
    ACT: 'B',
    TST: 'B',
    IPO: 'B',
    OPO: 'B',
    EUS: 'B',
    TEA: 'B',
    AWB: 'B',
    EIA: 'B',
    default: PERMIT_GOODSLOCATION_DEFAULT_VALUES.typeCode,
  },
  identificationQualifier: {
    EIR: 'Y',
    ACE: 'Y',
    ACT: 'Y',
    TST: 'X',
    IPO: 'Y',
    OPO: 'Y',
    EUS: 'Y',
    TEA: 'Y',
    AWB: 'Y',
    EIA: 'Y',
    default: PERMIT_GOODSLOCATION_DEFAULT_VALUES.identificationQualifier,
  },
}
export const PERMIT_GOODSLOCATION_DEFAULT_INPUT_TYPE_ADDRESS_VALUES = {
  countryCode: {
    default: PERMIT_GOODSLOCATION_DEFAULT_VALUES.countryCode,
  },
  typeCode: {
    ACE: 'B',
    ACT: 'B',
    TST: 'B',
    AWB: 'B',
    EIA: 'B',
    default: PERMIT_GOODSLOCATION_DEFAULT_VALUES.typeCode,
  },
  identificationQualifier: {
    ACE: 'Y',
    ACT: 'Y',
    TST: 'Y',
    AWB: 'Z',
    EIA: 'Z',
    default: PERMIT_GOODSLOCATION_DEFAULT_VALUES.identificationQualifier,
  },
}
