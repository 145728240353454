import { defineMessages } from 'react-intl'

const messages = defineMessages({
  newItemHeading: {
    id: 'permits.repetitiveComponents.newItemHeading',
    description: 'Label for modal adding new item',
    defaultMessage: 'Add new item',
  },
  itemEditHeading: {
    id: 'permits.repetitiveComponents.itemEditHeading',
    description: 'Label for modal editing item',
    defaultMessage: 'Edit item',
  },
  noRows: {
    id: 'permits.repetitiveComponents.noRows',
    description: 'Information message for empty repetitiveComponents list',
    defaultMessage: 'No items',
  },
  deleteItemConfirmation: {
    id: 'permits.repetitiveComponents.deleteItemConfirmation',
    description: 'Label for deleting item',
    defaultMessage: 'Do you really want to delete selected item? This cannot be undone.',
  },
  continueDeleteConfirmation: {
    id: 'permits.repetitiveComponents.continueDeleteConfirmation',
    description: 'Button label for deleting item',
    defaultMessage: 'Yes',
  },
  ignoreDeleteConfirmation: {
    id: 'permits.repetitiveComponents.ignoreDeleteConfirmation',
    description: 'Button label for ignoring deleting item',
    defaultMessage: 'No',
  },
  deleteItemText: {
    id: 'permits.repetitiveComponents.deleteItemText',
    description: 'Label for modal title',
    defaultMessage: 'Delete item',
  },
  edit: {
    id: 'permits.repetitiveComponents.edit',
    description: 'label for item edit button',
    defaultMessage: 'Edit',
  },
  add: {
    id: 'permits.repetitiveComponents.add',
    description: 'Label for adding item',
    defaultMessage: 'Add item',
  },
  save: {
    id: 'permits.repetitiveComponents.save',
    description: 'Label for saving item',
    defaultMessage: 'Save item',
  },
  cancel: {
    id: 'permits.repetitiveComponents.cancel',
    description: 'Label for cancelling item editing',
    defaultMessage: 'Cancel',
  },
  delete: {
    id: 'permits.repetitiveComponents.delete',
    description: 'Label for deleting item',
    defaultMessage: 'Delete',
  },
  doneSavingItem: {
    id: 'permits.repetitiveComponents.doneSavingItem',
    description: 'Notification message shown after saving item',
    defaultMessage: 'Item was saved successfully',
  },
  missingMandatoryFields: {
    id: 'permits.repetitiveComponents.missingMandatoryFields',
    description: 'Notification message for missing fields',
    defaultMessage: 'Fill all required fields',
  },
  missingRequiredRowsAmountOfDuty: {
    id: 'permits.repetitiveComponents.missingRequiredRows_amountOfDuty',
    description: 'Notification message for missing rows',
    defaultMessage: 'Missing mandatory rows',
  },
  missingRequiredRows: {
    id: 'permits.repetitiveComponents.missingRequiredRows',
    description: 'Notification message for missing rows',
    defaultMessage: 'Missing mandatory rows',
  },
  optionWarehouseIdentifier: {
    id: 'permits.goodsLocation.optionWarehouseIdentifier',
    description: 'Label for radio list',
    defaultMessage: '?? Warehouse identifier',
  },
  optionEori: {
    id: 'permits.goodsLocation.optionEori',
    description: 'Label for radio list',
    defaultMessage: '?? EORI',
  },
  optionAddress: {
    id: 'permits.goodsLocation.optionAddress',
    description: 'Label for radio list',
    defaultMessage: '?? Address',
  },
  openWarehouseIdentifierSearch: {
    id: 'permits.goodsLocation.openWarehouseIdentifierSearch',
    description: 'Label for link',
    defaultMessage: '?? Open warehouse identifier search',
  },
})

export default messages
