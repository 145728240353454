import { has, filter, get } from 'lodash'
import moment from 'moment'
import { CODESET_STRING_DELIMITER } from './constants'

export function itemToSelectOption(item, locale, codesetExtension, fieldId, onlyName) {
  let title = getCodesetTitle(item, locale, codesetExtension, onlyName)
  if (fieldId === 'reasonRevocation.reasonRevocation' || fieldId === 'rejoinder.acceptanceOfProposal') {
    title = item.name[locale]
  }
  return {
    id: item.code,
    value: item.code,
    title,
    item,
  }
}

export function getCodesetTitle(item, locale, codesetExtension, onlyName = false) {
  if (codesetExtension) {
    const title = has(item, `extensionValues.${codesetExtension}.${locale}`)
      ? item.extensionValues[codesetExtension][locale] : ''
    return title !== '' ? title : item.code
  }

  if (onlyName) {
    return item.name[locale] !== '' ? item.name[locale] : item.code
  }

  return `${item.name[locale]} (${item.code})`
}

export function parseCodesetData(codesetData) {
  const parsedData = []
  codesetData
    .filter(item => item.code !== '--')
    .map((item) => {
      const attributes = {}
      const extensionValues = {}
      item.basicAttributes.map((attribute) => {
        attributes[attribute.languageCode] = attribute.name
        return null
      })
      if (item.extensionValues) {
        item.extensionValues.map((extensionValue) => {
          if (!extensionValues[extensionValue.extensionName]) {
            extensionValues[extensionValue.extensionName] = {}
          }
          extensionValues[extensionValue.extensionName][extensionValue.languageCode] = extensionValue.value
          return null
        })
      }
      parsedData.push({
        code: item.code,
        name: attributes,
        extensionValues,
      })
      return null
    })

  return parsedData
}

export function getFilterredCodeset(codesetData, codesetObj, strDate) {
  const date = (strDate ? moment(strDate) : moment()).format('YYYY-MM-DD')
  const codesetName = codesetObj.codesetName + (codesetObj.subCodesetName ?
    `${CODESET_STRING_DELIMITER}${codesetObj.subCodesetName}`
    : '')

  const codeset = codesetData[codesetName][date]

  if (!codeset) {
    return {}
  }

  return filterInvalidValues(
    filterValidValues(codeset, codesetObj),
    codesetObj.codesetInvalidValues
  )
}

function filterInvalidValues(codesetData, invalidValues) {
  if (invalidValues) {
    return filter(codesetData, item => !invalidValues.includes(item.code))
  }

  return codesetData
}

function filterValidValues(codesetData, codesetObj) {
  if (typeof codesetObj?.customFilterFn === 'function') {
    return codesetObj.customFilterFn(codesetData)
  }

  const validValues = get(codesetObj, 'codesetValidValues')

  if (validValues) {
    return filter(codesetData, item => validValues.includes(item.code))
  }

  return codesetData
}
