import { isEmpty } from 'lodash'

export const isSomethingPreventingModalClose = () => {
  // Check if there is popover blocking the escape
  const preventsClosing = getBlacklistedElements('.popover')

  if (!isEmpty(preventsClosing)) {
    return true
  }

  const activeElement = document.activeElement

  // check if there are focus on input
  if (activeElement && activeElement.nodeName === 'INPUT') {
    activeElement.blur()
    return true
  }

  return false
}

export const getBlacklistedElements = blacklist => document.querySelectorAll(blacklist)
