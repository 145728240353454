import { defineMessages } from 'react-intl'

export default defineMessages({
  title: {
    id: 'frontpage.title',
    description: 'Frontpage title',
    defaultMessage: 'Online services',
  },
  internalDeniedFrontpageServices: {
    id: 'frontpage.services.internal.denied.title',
    description: 'Title for internal services listing in frontpage for user without proper roles',
    defaultMessage: 'Services you don\'t have permissions for',
  },
})
