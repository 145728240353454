import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { RepresentativeInformation } from 'src/components/representative'

export default function RepresentativeDetailsReadOnly(props) {
  const {
    infoElement,
    renderErrors,
    isPerson,
  } = props


  return (
    <Row>
      <Col xs={12} sm={6}>
        <RepresentativeInformation isPerson={isPerson} infoElement={infoElement} />
      </Col>
      {renderErrors()}
    </Row>
  )
}
