import React, { useState, useEffect } from 'react'
import ReactMarkdown from 'react-markdown'
import { Alert, Grid, Row, Col, HelpBlock } from 'react-bootstrap'
import { injectIntl, FormattedMessage, defineMessages } from 'react-intl'
import Icon from 'src/components/Icon'
import Checkbox from 'src/components/form/Checkbox'
import { focusFirstInputElementInModal, focusFirstInputElementWithError } from 'src/utils'
import generalMessages from 'src/components/messages'
import Modal from './Modal'
import LinkRenderer from './LinkRenderer'
import css from './TermsOfService.scss'

// Header-related intl message definitions
const messages = defineMessages({
  title: {
    id: 'termsOfService.title',
    description: 'Title for terms of service modal',
    defaultMessage: 'Terms of service',
  },
  infoHeading: {
    id: 'termsOfService.infoHeading',
    description: 'Heading information about accepting terms',
    defaultMessage: 'You must accept terms of service to proceed',
  },
  accept: {
    id: 'termsOfService.accept',
    description: 'Button value for terms of service operation "accept"',
    defaultMessage: 'Accept',
  },
  cancel: {
    id: 'termsOfService.cancel',
    description: 'Button value for terms of service cancel operation (resulting in logout)',
    defaultMessage: 'Logout',
  },
  submitFailure: {
    id: 'termsOfService.submitFailure',
    description: 'Unexpected error in accepting terms of service',
    defaultMessage: 'Could not accept terms. Please try again.',
  },
})

function TermsOfService(props) {
  const [submitting, setSubmitting] = useState(false)
  const [accepted, setAccepted] = useState(false)
  const [acceptedTouched, setAcceptedTouched] = useState(false)

  const {
    show,
    onRejected,
    submitFailure,
    locale,
    onSwitchLocale,
    children,
    intl: { formatMessage },
    onAccepted,
  } = props

  useEffect(() => {
    focusFirstInputElementInModal()
  }, [])

  const onClickContinue = () => {
    if (!submitting && accepted) {
      setSubmitting(true)
      onAccepted().then(() => setSubmitting(false))
    } else {
      setAcceptedTouched(true)
      focusFirstInputElementWithError()
    }
  }

  return (
    <div>
      <Modal
        show={show}
        size="lg"
        titleMessage={messages.title}
        cancelMessage={messages.cancel}
        showCancel
        cancelDisabled={submitting}
        onClickCancel={onRejected}
        continueMessage={messages.accept}
        onClickContinue={onClickContinue}
        showContinue
        locale={locale}
        onSwitchLocale={onSwitchLocale}
        className={css.termsModal}
      >
        <div className={css.termsInfo}>
          <Icon name="info" className={css.termsInfoIcon} />
          <ReactMarkdown
            source={formatMessage({ id: '/authentication/termsOfServiceTitle' })}
            renderers={{ Link: LinkRenderer }}
            target={'_blank'}
          >
            <Icon name="info" />
          </ReactMarkdown>
        </div>
        <Grid fluid>
          <Row>
            <Col >
              <div className={acceptedTouched && !accepted ? 'has-error' : ''}>
                <Checkbox
                  required
                  checked={accepted}
                  id-qa-test={'radio-termsOfService-acceptance'}
                  onClick={() => setAccepted(!accepted)}
                  onBlur={() => setAcceptedTouched(true)}
                  ariaRequired
                  ariaDescribedby="termsAccepted-error"
                >
                  <ReactMarkdown
                    source={formatMessage({ id: '/authentication/termsOfServiceAcceptLabel' })}
                    renderers={{ Link: LinkRenderer }}
                    target={'_blank'}
                  />
                </Checkbox>
                {acceptedTouched && !accepted &&
                  <HelpBlock id="termsAccepted-error">
                    {formatMessage(generalMessages.mandatory)}
                  </HelpBlock>
                }
              </div>
              <ReactMarkdown
                source={formatMessage({ id: '/authentication/termsOfServiceAlternativeTransactionLabel' })}
                renderers={{ Link: LinkRenderer }}
                target={'_blank'}
              />
            </Col>
          </Row>
        </Grid>

        {submitFailure &&
          <Alert bsStyle="danger" className="text-center">
            <FormattedMessage {...messages.submitFailure} />
          </Alert>
        }
      </Modal>
      {children}
    </div>
  )
}

export default injectIntl(TermsOfService)
