import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Row, Col, Table } from 'react-bootstrap'
import { isArray, isEmpty, get } from 'lodash'
import ErrorMessage from 'src/components/form_v2/ErrorMessage'
import { getCellValueFormatter } from '../../../formatValue'
import messages from '../../GoodsToBePlacedUnderProcedure/messages'

const renderRow = (item, itemIndex, fields, hiddenFields, errors, locale, formatCellValue) => {
  const hiddenErrors = hiddenFields
    .map(field => ({
      field,
      error: get(errors, `${field.infoElement.code}.${field.code}-${itemIndex}`, ''),
    }))
    .filter(o => o.error !== '')

  return (<tr key={itemIndex}>
    {fields.map((field) => {
      const fieldError = get(errors, `${field.infoElement.code}.${field.code}-${itemIndex}`, '')
      return (
        <td key={field.code}>
          {formatCellValue(item, field)}
          {fieldError &&
            <div className={'has-error'}>
              <p className="help-block">
                <ErrorMessage error={fieldError} fieldName={field.name[locale]} />
              </p>
            </div>
          }
        </td>
      )
    })}
    {hiddenErrors.length > 0 && <td>
      <div className={'has-error'}>
        {hiddenErrors.map(({ field, error }) =>
          <p className="help-block">
            {field.name[locale]}: <ErrorMessage error={error} />
          </p>
        )}
      </div>
    </td>}
  </tr>)
}

export default function ItemsListReadOnly(props) {
  const {
    locale,
    infoElements,
    codesets,
    items,
    errors,
    intl: { formatMessage },
  } = props

  const allFields = Object.values(infoElements)
    .map(ie => ie.fields)
    .flat()
  const activeFields = allFields
    .filter(f => f.visibleInCombinedLists)
  const hiddenFields = allFields
    .filter(f => !f.visibleInCombinedLists)
  const isMandatoryInfoElement = allFields.some(field => field.mandatory)
  const formatCellValue = getCellValueFormatter({ codesets, locale })
  const hasValues = isArray(items) && !isEmpty(items)
  const hasHiddenErrors = hiddenFields.some(field => get(errors, `${field.infoElement.code}.${field.code}`))

  return (
    <div>
      <Row>
        <Col xs={12}>
          {!hasValues && !isMandatoryInfoElement &&
            <div>--</div>
          }
          {!hasValues && isMandatoryInfoElement &&
            <div className="has-error">
              <div className="help-block">
                <FormattedMessage {...messages.missingRequiredRows} />
              </div>
            </div>
          }
          {hasValues &&
            <div className="table-responsive" tabIndex="0" role="region" aria-label={formatMessage(messages.table)}>
              <Table striped bordered>
                <thead>
                  <tr>
                    {activeFields.map(field => <td key={field.code}><b>{field.name[locale]}</b></td>)}
                    {hasHiddenErrors &&
                    <td><b>
                      <FormattedMessage {...messages.error} />
                    </b></td>
                    }
                  </tr>
                </thead>
                <tbody>
                  {hasValues
                  && items.map((row, rowIndex) =>
                    renderRow(row, rowIndex, activeFields, hiddenFields, errors, locale, formatCellValue)
                  )}
                </tbody>
              </Table>
            </div>}
        </Col>
      </Row>
    </div>
  )
}
