import React from 'react'
import { Col, Button, Row, ButtonToolbar } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { Form } from 'react-form'
import { get, includes } from 'lodash'
import Icon from 'src/components/Icon'
import Modal from 'src/components/Modal'
import showNotification from 'src/utils/notifications'
import { focusFirstInputElementWithError } from 'src/utils'
import { checkFormValues } from '../RepetitiveComponent/utils'
import deleteMessages from '../RepetitiveComponent/messages'
import permitMessages from '../../../messages'
import instrastatMessages from '../../../../intrastat/declaration/routes/rows/messages'

const messages = {
  ...permitMessages,
  ...deleteMessages,
  ...instrastatMessages,
}

export default class GuaranteePerCustomsProcedureForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isNew: null,
    }
    this.onClickSave = this.onClickSave.bind(this)
    this.save = this.save.bind(this)
    this.cancel = this.cancel.bind(this)
    this.connectFormApi = this.connectFormApi.bind(this)
  }


  onClickSave(event) {
    this.formApi.submitForm(event)
      .then(focusFirstInputElementWithError)
  }

  onClickSaveAndCreateAnother() {
    const formData = this.getFormValues()

    this.save(formData, { createAnother: true })
  }

  getFormValues() {
    const formState = this.formApi && this.formApi.getFormState()
    return get(formState, 'values')
  }

  cancel(e) {
    if (e) {
      e.preventDefault()
    }

    if (this.state.isNew) {
      this.setState({ isNew: false })
    } else {
      this.props.cancelEditing()
    }
  }

  save(formData, opts = {}) {
    const { addNew, updateItem, selectedItem, infoElement } = this.props
    const { isNew } = this.state

    const isValid = checkFormValues(
      this.formApi,
      infoElement,
    )

    if (isValid) {
      if (isNew) {
        addNew(formData)
        this.afterDoneSaving(opts.createAnother)
      } else if (selectedItem) {
        updateItem(selectedItem, formData)
        this.afterDoneSaving()
      }
    } else {
      focusFirstInputElementWithError()
    }
  }

  afterDoneSaving(createAnother) {
    if (createAnother) {
      this.clearFormApi()
    } else {
      this.cancel()
    }

    showNotification({
      level: 'success',
      message: messages.doneSavingItem,
      autoDismiss: true,
    })
  }

  connectFormApi(formApi) {
    this.formApi = formApi
  }

  clearFormApi() {
    this.formApi.setFormState({})
  }

  renderFields(formApi) {
    const {
      infoElement,
      renderField,
    } = this.props

    const infoElementFields = infoElement.fields
    const visibleFields = infoElementFields.filter(field => field.visible)

    const guaranteeCustomsProcedureField = visibleFields.find(({ code }) => code === 'guaranteeCustomsProcedure')

    const guaranteeExistingDebtField = visibleFields.find(({ code }) => code === 'guaranteeExistingDebt')
    const guaranteeExistingDebtCurrencyField = visibleFields.find(({ code }) => code === 'guaranteeExistingDebtCurrency')
    const guaranteeExistingDebtDescriptionField = visibleFields.find(({ code }) => code === 'guaranteeExistingDebtDescription')

    const guaranteePotentialDebtField = visibleFields.find(({ code }) => code === 'guaranteePotentialDebt')
    const guaranteePotentialDebtCurrencyField = visibleFields.find(({ code }) => code === 'guaranteePotentialDebtCurrency')
    const guaranteePotentialDebtDescriptionField = visibleFields.find(({ code }) => code === 'guaranteePotentialDebtDescription')


    const staticFields = [
      guaranteeCustomsProcedureField,
      guaranteeExistingDebtField,
      guaranteeExistingDebtCurrencyField,
      guaranteeExistingDebtDescriptionField,
      guaranteePotentialDebtField,
      guaranteePotentialDebtCurrencyField,
      guaranteePotentialDebtDescriptionField,
    ].map(field => field && field.code)

    const otherFields = visibleFields
      .filter(field => !includes(staticFields, field.code))

    return (
      <React.Fragment>
        <Row key="itemRow-guaranteeCustomsProcedure">
          <Col xs={12}>
            {guaranteeCustomsProcedureField && renderField({
              field: guaranteeCustomsProcedureField,
              formApi,
              autoFocus: true,
              input: {
                ...guaranteeCustomsProcedureField.input,
              },
            })}
          </Col>
        </Row>
        <Row key="itemRow-guaranteeExistingDebt-inputs">
          <Col xs={12} sm={6}>
            {guaranteeExistingDebtField && renderField({
              field: guaranteeExistingDebtField,
              formApi,
            })}
          </Col>
          <Col xs={12} sm={6}>
            {guaranteeExistingDebtCurrencyField && renderField({
              field: { ...guaranteeExistingDebtCurrencyField, input: { defaultvalue: guaranteeExistingDebtCurrencyField.defaultvalue } },
              formApi,
            })}
          </Col>
        </Row>
        <Row key="itemRow-guaranteeExistingDebt-description">
          <Col xs={12}>
            {guaranteeExistingDebtDescriptionField && renderField({
              field: guaranteeExistingDebtDescriptionField,
              formApi,
            })}
          </Col>
        </Row>
        <Row key="itemRow-guaranteePotentialDebt-inputs">
          <Col xs={12} sm={6}>
            {guaranteePotentialDebtField && renderField({
              field: guaranteePotentialDebtField,
              formApi,
            })}
          </Col>
          <Col xs={12} sm={6}>
            {guaranteePotentialDebtCurrencyField && renderField({
              field: guaranteePotentialDebtCurrencyField,
              formApi,
            })}
          </Col>
        </Row>
        <Row key="itemRow-guaranteePotentialDebt-description">
          <Col xs={12}>
            {guaranteePotentialDebtDescriptionField && renderField({
              field: guaranteePotentialDebtDescriptionField,
              formApi,
            })}
          </Col>
        </Row>
        {otherFields.map((field, i) => (
          <Row key={`row-${i}`}>
            <Col xs={12} key={`col-${i}`}>
              {renderField({
                field,
                formApi,
              })}
            </Col>
          </Row>
        ))}
      </React.Fragment>
    )
  }

  render() {
    const {
      selectedItem,
      error,
      infoElement,
    } = this.props

    const { isNew } = this.state
    const titleMessage = isNew ? messages.newGuaranteePerCustomsProcedureHeading : messages.editGuaranteePerCustomsProcedureHeading

    const slidingContentStyle = {
      transition: 'transform 200ms ease-in-out',
    }

    const modalButtons = []

    modalButtons.push(
      <Button
        key="modalCancel"
        className="pull-left"
        onClick={this.cancel}
        id-qa-test="button-cancel"
      >
        <FormattedMessage {...messages.cancel} />
      </Button>)

    if (isNew) {
      modalButtons.push(<Button
        key="modalSaveAndAddNew"
        bsStyle="default"
        style={{ marginRight: '20px' }}
        disabled={this.state.isFetchingDescription}
        onClick={() => this.onClickSaveAndCreateAnother()}
        id-qa-test="button-save-add"
      >
        <Icon name="addnew" /><FormattedMessage {...messages.saveRowAndCreateAnother} />
      </Button>)
    }

    modalButtons.push(
      <Button
        key="modalSaveAndClose"
        bsStyle="primary"
        className="pull-right"
        onClick={this.onClickSave}
        id-qa-test="button-save"
      >
        <Icon name="addnew" />
        <FormattedMessage {...messages.saveProcedure} />
      </Button>)

    if (get(this.props, 'combined', false)) {
      const formApi = this.props.formApi
      this.connectFormApi(formApi)
      return this.renderFields(formApi)
    }

    return (
      <div>
        <ButtonToolbar>
          <Button
            id={`new-row-${infoElement.code}`}
            aria-describedby={this.props.buttonDescriptionId}
            bsStyle="primary"
            onClick={() => this.setState({ isNew: true })}
            id-qa-test={`button-add-${this.props.infoElement.code}`}
          >
            <Icon name="add" /><FormattedMessage {...messages.addGuaranteePerCustomsProcedure} />
          </Button>
        </ButtonToolbar>
        <Modal
          show={Boolean(isNew || selectedItem)}
          size="lg"
          showCancel={false}
          showContinue={false}
          onEscKey={this.cancel}
          titleMessage={titleMessage}
          animation={false}
          contentRef={(ref) => { this.modalContentRef = ref }}
          customFooterButtons={modalButtons}
        >
          <div
            ref={(ref) => { this.contentRef = ref }}
            style={slidingContentStyle}
            onTransitionEnd={this.onScrollToCN8TransitionEnd}
          >
            <Form
              getApi={this.connectFormApi}
              defaultValues={selectedItem && selectedItem}
              onSubmit={this.save}
              render={formApi =>
                <form>
                  {this.renderFields(formApi)}
                </form>
              }
            />
            <Row>
              <Col md={8} sm={12}>
                {error && error.id &&
                  <div className="text-danger text-right" role="alert" id-qa-test="label-error">
                    <FormattedMessage {...error} />
                  </div>
                }
              </Col>
            </Row>
          </div>
        </Modal>
      </div>
    )
  }
}
