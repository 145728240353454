import React from 'react'
import { Button } from 'react-bootstrap'
import ReactMarkdown from 'react-markdown'
import LinkRenderer from 'src/components/LinkRenderer'
import Modal from './Modal'
import messages from './messages'

export default class ModalInfo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }
    this.toggleOpen = this.toggleOpen.bind(this)
  }
  toggleOpen() {
    this.setState({
      open: !this.state.open,
    })
  }
  render() {
    const { header, content, closingText, buttonText, feedbackLink, className, style, loading = false } = this.props
    let { children } = this.props
    if (!children) {
      return null
    }
    if (!Array.isArray((children))) {
      children = [children]
    }
    if (!content) {
      return <div className={className} style={style}>{children}</div>
    }
    return (
      <div className={className} style={style}>
        {children.map((childItem, index) =>
          React.cloneElement(childItem, {
            onClick: this.toggleOpen,
            onKeyUp: (event) => {
              if (event.keyCode === 13) {
                this.toggleOpen(event)
              }
            },
            key: `dialog-info-child-${index}`,
            role: 'button',
            tabIndex: 0,
          }))
        }
        <Modal
          show={this.state.open}
          onEscKey={this.toggleOpen}
          showContinue
          showCancel={false}
          onClickContinue={this.toggleOpen}
          continueMessage={messages.closeInfoDialogButton}
          loading={loading}
          backdrop
        >
          {header &&
            <ReactMarkdown source={header} />
          }
          <ReactMarkdown
            source={content}
            renderers={{ Link: LinkRenderer }}
          />
          {(feedbackLink && buttonText) &&
            <div style={{ margin: '20px 0 20px 0' }}>
              <Button
                bsStyle="primary"
                id-qa-test="send-feedback-button"
                onClick={() => window.open(feedbackLink)}
              >
                {buttonText}
              </Button>
            </div>
          }
          {closingText &&
            <ReactMarkdown source={closingText} />
          }
        </Modal>
      </div>
    )
  }
}
