import { get } from 'lodash'
import { handleActions } from 'redux-actions'
import {
  LOGOUT_REQUEST,
  LOGOUT,
  LOGIN_DATA_REQUEST,
  LOGIN_DATA,
  SELECT_AUTHORIZATION,
  ACCEPT_TERMS,
  SUOMI_FI_AUTHORIZATION_TYPE_REQUEST,
  CHANGE_SELECTED_NAME,
  AUTH_TYPES,
  RETURN_URI_AFTER_ROLE_SWITCH,
  SHOW_SWITCH_ROLE_CONFIRMATION_MODAL,
  SHOW_LOGOUT_CONFIRMATION_MODAL,
} from '../constants'

export const initialAuthState = {
  authorizations: null,
  userId: null,
  name: null,
  isAuthenticated: false,
  isAuthenticating: false,
  termsAccepted: false,
  tryingToAcceptTerms: false,
  selectedAuthorizationType: null,
  selectedAuthorization: null,
  logoutRequested: false,
  logoutFailed: false,
  suomiFiAuthorizationRequest: false,
  sessionExpirationTime: null,
  uidType: null,
  foreignFriendlyPaths: null,
  returnUriAfterRoleSwitch: null,
  showSwitchRoleConfirmationModal: false,
  showLogoutConfirmationModal: false,
}

export default handleActions({
  [LOGOUT]: {
    next: state => ({
      ...state,
      isAuthenticated: false,
      userId: null,
      termsAccepted: false,
      name: null,
      selectedAuthorizationType: null,
      selectedAuthorization: null,
      logoutRequested: false,
      logoutFailed: false,
      uidType: null,
      foreignFriendlyPaths: null,
      address: null,
    }),
    throw: state => ({
      ...state,
      logoutRequested: false,
      logoutFailed: true,
    }),
  },
  [LOGOUT_REQUEST]: state => ({
    ...state,
    logoutRequested: true,
    logoutFailed: false,
  }),
  [SUOMI_FI_AUTHORIZATION_TYPE_REQUEST]: (state, action) => ({
    ...state,
    suomiFiAuthorizationRequest: action.payload,
  }),
  [RETURN_URI_AFTER_ROLE_SWITCH]: (state, action) => ({
    ...state,
    returnUriAfterRoleSwitch: action.payload,
  }),
  [SHOW_SWITCH_ROLE_CONFIRMATION_MODAL]: (state, action) => ({
    ...state,
    showSwitchRoleConfirmationModal: action.payload,
  }),
  [SHOW_LOGOUT_CONFIRMATION_MODAL]: (state, action) => ({
    ...state,
    showLogoutConfirmationModal: action.payload,
  }),
  [LOGIN_DATA]: {
    next: (state, action) => ({
      ...state,
      isAuthenticated: true,
      isAuthenticating: false,
      termsAccepted: action.payload.termsAccepted,
      authorizations: action.payload.authorizations ? [...action.payload.authorizations] : [],
      name: extractNameFromAuthData(action.payload),
      userId: action.payload.userId,
      uid: action.payload.uid,
      selectedAuthorizationType: action.payload.selectedAuthorizationType,
      selectedAuthorization: action.payload.selectedAuthorization ? action.payload.selectedAuthorization : null,
      sessionExpirationTime: action.payload.sessionExpirationTime,
      uidType: action.payload.uidType,
      foreignFriendlyPaths: action.payload.foreignFriendlyPaths,
      address: extractAddressFromAuthData(action.payload),
      hasPersonalDataAccessRestriction: action.payload.hasPersonalDataAccessRestriction,
      actOnBehalfOfMinor: action.payload.actOnBehalfOfMinor,
    }),
    throw: state => ({
      ...state,
      isAuthenticated: false,
      isAuthenticating: false,
      termsAccepted: false,
      authorizations: null,
      userId: null,
      name: null,
      selectedAuthorizationType: null,
      selectedAuthorization: null,
      address: null,
    }),
  },
  [LOGIN_DATA_REQUEST]: state => ({
    ...state,
    isAuthenticated: false,
    isAuthenticating: true,
    authorizations: null,
    name: null,
    selectedAuthorizationType: null,
    selectedAuthorization: null,
    address: null,
  }),
  [CHANGE_SELECTED_NAME]: (state, action) => ({
    ...state,
    name: action.payload,
  }),
  [SELECT_AUTHORIZATION]: (state, action) => ({
    ...state,
    selectedAuthorization: action.payload,
  }),
  [ACCEPT_TERMS]: {
    next: state => ({
      ...state,
      termsAccepted: true,
      acceptTermsFailure: false,
    }),
    throw: state => ({
      ...state,
      termsAccepted: false,
      acceptTermsFailure: true,
    }),
  },
}, initialAuthState)

function extractNameFromAuthData(authData) {
  const {
    authorizations,
    name,
    familyName,
    firstNames,
    selectedAuthorization,
  } = authData
  const selectedAuthorizationIdentifier = get(selectedAuthorization, 'identifier')
  const selectedAuthorizationObject =
  authorizations ? authorizations.find(item => item.identifier === selectedAuthorizationIdentifier) : null
  if (selectedAuthorizationObject && (selectedAuthorizationObject.type === AUTH_TYPES.private
    || selectedAuthorizationObject.type === AUTH_TYPES.principal)) {
    return selectedAuthorizationObject.name
  }
  if (name) {
    return name
  }

  if (familyName && firstNames) {
    return `${firstNames} ${familyName}`
  }

  return undefined
}

function extractAddressFromAuthData(authData) {
  if (authData.selectedAuthorizationType === AUTH_TYPES.private || authData.selectedAuthorizationType === AUTH_TYPES.principal) {
    return {
      address: authData.streetAddress_FI || authData.streetAddress_SE || authData.foreignAddressUserProvided,
      zipCode: authData.streetAddressPostalCode,
      // How about other countries?
      city: authData.streetAddressCity_FI || authData.streetAddressCity_SE || authData.foreignAddressFull_FI,
      // This needs refactoring, we want to know countrycode from DVV
      countryCode: (authData.streetAddress_FI && 'FI') || (authData.streetAddress_SE && 'SE') || authData.foreignAddressCountryCode,
    }
  }

  return null
}
