import { handleActions } from 'redux-actions'
import {
  CODESET_DESTROY_STATE,
  CODESET_REQUEST,
  CACHE_CODESET,
} from './constants'

const initialState = {
  fetchingCodesets: {},
  customerError: false,
  cachedCodesets: {},
  codesetErrors: {},
}

export default handleActions({
  [CODESET_DESTROY_STATE]: () => initialState,
  [CODESET_REQUEST]: (state, { payload: { codesetNameCombined } }) => ({
    ...state,
    fetchingCodesets: {
      ...state.fetchingCodesets,
      [codesetNameCombined]: true,
    },
    codesetErrors: {
      ...state.codesetErrors,
      [codesetNameCombined]: false,
    },
  }),
  [CACHE_CODESET]: {
    next: (state, { payload, meta: { codesetNameCombined, date } }) => ({
      ...state,
      cachedCodesets: {
        ...state.cachedCodesets,
        [codesetNameCombined]: {
          ...state[codesetNameCombined],
          [date]: {
            ...payload,
          },
        },
      },
      fetchingCodesets: {
        ...state.fetchingCodesets,
        [codesetNameCombined]: false,
      },
      codesetErrors: {
        ...state.codesetErrors,
        [codesetNameCombined]: false,
      },
    }),
    throw: (state, { payload, meta: { codesetNameCombined } }) => ({
      ...state,
      codesetErrors: {
        ...state.codesetErrors,
        [codesetNameCombined]: payload.globalIntlMessage || true,
      },
      fetchingCodesets: {
        ...state.fetchingCodesets,
        [codesetNameCombined]: false,
      },
    }),
  },
}, initialState)

