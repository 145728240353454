import React from 'react'
import { defineMessages, FormattedMessage } from 'react-intl'
import logger from 'src/utils/logger'
import JumbotronMessage from 'src/components/JumbotronMessage'

const messages = defineMessages({
  unexpectedUIErrorTitle: {
    id: 'error.unexpectedUIError.title',
    description: 'Error page title shown when unexpected React UI error occurs',
    defaultMessage: 'Unexpected error',
  },
  unexpectedUIErrorDescription: {
    id: 'error.unexpectedUIError.description',
    description: 'Error message shown when unexpected React UI error occurs',
    defaultMessage: 'Unexpected error occurred. You may restart this application by reloading this page.',
  },
  reload: {
    id: 'error.reloadButtonLabel',
    description: 'Label for page reload button shown on unexpected error situations',
    defaultMessage: 'Reload',
  },
})

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
    }
  }
  componentDidCatch(error, info) {
    const { onError } = this.props
    const componentStack = info ? info.componentStack : undefined
    logger.error('Unexpected error caught in ErrorBoundary.', error, componentStack)
    this.setState({
      hasError: true,
    })
    if (typeof onError === 'function') {
      try {
        onError.call(this, error, componentStack)
      } catch (callbackError) {
        logger.error('Error calling ErrorBoundary callback', callbackError)
      }
    }
  }
  render() {
    const { style, className, compact, forceError } = this.props
    if (this.state.hasError || forceError) {
      if (compact) {
        return (
          <div style={style} className={className}>
            <FormattedMessage {...messages.messages.unexpectedUIErrorDescription} />
          </div>
        )
      }
      return (
        <JumbotronMessage
          style={style}
          className={className}
          fullpage={this.props.fullpage}
          titleMessage={this.props.title ? this.props.title : messages.unexpectedUIErrorTitle}
          contentMessage={this.props.content ? this.props.content : messages.unexpectedUIErrorDescription}
          buttonMessage={messages.reload}
          onClickButton={() => location.reload()}
          iconName="attention"
        />
      )
    }
    return this.props.children
  }
}
