/**
 * Global notifications container, using Notifications component
 * @module containers/GlobalNotifications
 */

import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import NotificationSystem from 'react-notification-system-redux'
import { switchLocale, showLoginNotification } from 'src/actions'
import Notifications from '../components/Notifications'


const mapStateToProps = state => ({
  auth: state.auth,
  notifications: state.notifications,
  locale: state.locale,
})

const mapActionCreators = {
  hide: NotificationSystem.hide,
  onSwitchLocale: switchLocale,
  showLoginNotification,
}

export default injectIntl(connect(mapStateToProps, mapActionCreators)(Notifications))
