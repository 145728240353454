import { size, map } from 'lodash'

import GoodsToBePlacedUnderProcedureImport from '../../GoodsToBePlacedUnderProcedure/components/Import'

import {
  PERMIT_COMBINED_COLUMN_MAP,
} from '../../GoodsToBePlacedUnderProcedure/constants.js'
import {
  PERMIT_GOODS_FIELD_PREFIX,
  PERMIT_GOODS_CODE_FIELD_NAME,
  PERMIT_EQUIVALENT_GOOD_FIELD_PREFIX,
  PERMIT_EQUIVALENT_GOOD_CODE_FIELD_NAME,
} from '../../PermitPage/constants.js'

import {
  mapRowData,
  validateRowData,
} from '../../GoodsToBePlacedUnderProcedure/utils'

const getMinRowsCount = (infoElements) => {
  const repeats = map(infoElements, infoElement => parseInt(infoElement.repeat, 10))
  // Row count cannot be higher than infoElement with smallest amount of accepted row values (repeats)
  return Math.min(...repeats)
}

export default class Import extends GoodsToBePlacedUnderProcedureImport {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
    }
    this.features = Object.keys(PERMIT_COMBINED_COLUMN_MAP)
    this.featuresCount = size(PERMIT_COMBINED_COLUMN_MAP)
    this.featuresMap = PERMIT_COMBINED_COLUMN_MAP
    this.rowLimit = getMinRowsCount(props.infoElements)
  }

  async handleResponse(response) {
    const { addNew, locale, codesets, infoElements } = this.props
    const infoElementsFields = map(infoElements, ie => ie.fields).flat()

    for (let itemIndex = 0; itemIndex < response.length; itemIndex += 1) {
      const item = response[itemIndex]
      const mappedRow = mapRowData(item, this.features, this.featuresMap)
      const modifiedItem = { ...mappedRow }
      const codeFields = [
        `${PERMIT_GOODS_FIELD_PREFIX}${PERMIT_GOODS_CODE_FIELD_NAME}`,
        `${PERMIT_EQUIVALENT_GOOD_FIELD_PREFIX}${PERMIT_EQUIVALENT_GOOD_CODE_FIELD_NAME}`,
      ]
      let rowValidationErrors = {}

      // eslint-disable-next-line no-await-in-loop
      const validationErrors = await validateRowData(modifiedItem, {
        fields: infoElementsFields,
        locale,
        codesets,
        codeFieldNames: codeFields,
      })

      rowValidationErrors = {
        ...rowValidationErrors,
        ...validationErrors,
      }

      addNew(modifiedItem, rowValidationErrors)
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getOptions() {
    return {
      rowLimit: this.rowLimit,
      featureCountInFile: {
        min: this.featuresCount,
        max: this.featuresCount,
      },
    }
  }
}
