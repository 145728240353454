import { defineMessages } from 'react-intl'

const messages = defineMessages({
// Header-related intl message definitions
  logoAlt: {
    id: 'header.logo.alt',
    description: 'Alt text for Finnish Customs logo in header',
    defaultMessage: 'Finnish Customs',
  },
  switchRole: {
    id: 'header.user.switchRole',
    description: 'Heading for available roles to choose from in user information',
    defaultMessage: 'Switch role',
  },
  logout: {
    id: 'header.nav.logout',
    description: 'Link to logout with username',
    defaultMessage: 'Logout',
  },
  login: {
    id: 'header.nav.login',
    description: 'Link to login',
    defaultMessage: 'Login',
  },
})

export default messages
