import React, { useContext } from 'react'
import { Row, Col } from 'react-bootstrap'
import { find, endsWith, xor, get } from 'lodash'
import { FormattedMessage } from 'react-intl'
import {
  PERMIT_DEBT_GUARANTEE_LEVELS,
  PERMIT_LIABILITY_GUARANTEE_LEVELS,
} from '../constants'
import messages from '../messages'
import { getCodeset } from './PermitPage/customParams'
import PermitPageContext from './PermitPage/context'

export default function (props) {
  const { applicationDate } = useContext(PermitPageContext)
  const { infoElement, values, locale, renderField } = props
  const guaranteeLevelField = find(infoElement.fields, field => endsWith(field.code, 'guaranteeLevel'))

  const fieldProps = {
    input: {
      multiple: false,
    },
    showHelp: false,
  }

  const otherFields = xor(infoElement.fields, [
    guaranteeLevelField,
  ])

  const debtGuaranteeLevel = {
    field: {
      ...guaranteeLevelField,
      code: 'guaranteeLevel.debtGuaranteeLevel',
      codesetName: 'VakuudenTaso',
      codesetValidValues: PERMIT_DEBT_GUARANTEE_LEVELS,
      name: messages.debtGuaranteeLevel,
    },
    ...fieldProps,
    input: {
      ...fieldProps.input,
      type: 'codesetStatic',
    },
  }

  const liabilityGuaranteeLevel = {
    field: {
      ...guaranteeLevelField,
      code: 'guaranteeLevel.liabilityGuaranteeLevel',
      codesetName: 'VakuudenTaso',
      codesetValidValues: PERMIT_LIABILITY_GUARANTEE_LEVELS,
      name: messages.liabilityGuaranteeLevel,
    },
    ...fieldProps,
    input: {
      ...fieldProps.input,
      type: 'codesetStatic',
    },
  }

  const customsProcedureGuaranteeCodeset = getCodeset('CustomsProcedureGuarantee', { codesets: props.codesets, applicationDate })
  const legacyDebtProcedureTypes = get(values, 'customsProcedureType.debtGuaranteeProcedureType')
  const debtGuaranteeProcedureTypes = legacyDebtProcedureTypes || get(values, 'guaranteePerCustomsProcedure')?.filter((procedure) => {
    const existsInCodeset = Object.values(
      customsProcedureGuaranteeCodeset).find(customsProcedureGuarantee =>
      customsProcedureGuarantee.code === procedure.guaranteeCustomsProcedure
    )

    if (existsInCodeset && get(existsInCodeset, 'extensionValues.velka')) {
      return true
    }

    return false
  })

  const legacyLiabilityGuaranteeProcedures = get(values, 'customsProcedureType.liabilityGuaranteeProcedureType')
  const liabilityGuaranteeProcedureTypes = legacyLiabilityGuaranteeProcedures || get(values, 'guaranteePerCustomsProcedure')?.filter((procedure) => {
    const existsInCodeset = Object.values(
      customsProcedureGuaranteeCodeset).find(customsProcedureGuarantee =>
      customsProcedureGuarantee.code === procedure.guaranteeCustomsProcedure
    )

    if (existsInCodeset && get(existsInCodeset, 'extensionValues.vastuu')) {
      return true
    }

    return false
  })

  const showDebtGuaranteeLevelSelector = debtGuaranteeProcedureTypes && debtGuaranteeProcedureTypes.length > 0
  const showLiabilityGuaranteeLevelSelector = liabilityGuaranteeProcedureTypes
    && liabilityGuaranteeProcedureTypes.length > 0

  return (
    <div>
      <Row>
        <Col xs={12}>
          <h4>
            {infoElement.name[locale]}
          </h4>
        </Col>
      </Row>
      <Row>
        {!debtGuaranteeProcedureTypes && !liabilityGuaranteeProcedureTypes && <Col xs={12}>
          <FormattedMessage {...messages.missingGuaranteeText} /><br /><br />
        </Col>}
        <Col xs={12}>
          {showDebtGuaranteeLevelSelector && renderField({
            ...debtGuaranteeLevel,
          })}
        </Col>
        <Col xs={12}>
          {showLiabilityGuaranteeLevelSelector && renderField({
            ...liabilityGuaranteeLevel,
          })}
        </Col>
        <Col xs={12}>
          {otherFields && otherFields.map(field =>
            renderField({
              field: { ...field, code: `guaranteeLevel.${field.code}` },
              ...fieldProps,
              staticValue: true,
            })
          )}
        </Col>
      </Row>
    </div>
  )
}
