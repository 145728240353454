export const CUSTOMER_INFORMATION_ROUTE_PATH = 'customer-information'
export const STREET_ADDRESS_TYPE = 'STREET_ADDRESS'
export const STREET_ADDRESS_TYPE_NUMBER = 2
export const STREET_ADDRESS_ICON_NAME = 'building-multistorey'
export const POST_ADDRESS_TYPE = 'POST_ADDRESS'
export const POST_ADDRESS_ICON_NAME = 'message'
export const POST_ADDRESS_TYPE_NUMBER = 1
export const BILLING_ADDRESS_TYPE_NUMBER = 9
export const COMPANY_NAME_ID = 'company-name'
export const CUSTOMER_IDENTIFIER_ID = 'customer-identifier'
export const EORI_ID = 'eori'
export const GUARANTEE_CLOSED_STATUS = 'closed'
export const ASCENDING = 'ascending'

export const GUARANTEE_TYPES = {
  K: 'concentrated',
  T: 'concentratedGuarantor',
}

export const GUARANTEE_STATUSES = {
  available: 'guaranteeInUseStatus',
  closed: 'guaranteeClosedStatus',
}

export const GRAPH_COLORS = {
  red: '#e31837',
  grey: '#e6e6e6',
  blue: '#00205b',
}

export const TABLE_COLUMNS = {
  id: 'id',
  name: 'name',
  address: 'address',
}
