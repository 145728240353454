import React from 'react'
import { get } from 'lodash'
import HeaderArea from './HeaderArea'

export default function InfoElementHeaderArea({
  infoElement,
  locale,
  small,
  customTitle,
  descriptionText,
  showHelp = true,
  legend = false,
  help,
}) {
  const helpContent = showHelp && (help || get(infoElement, `help[${locale}]`))
  const title = customTitle || infoElement.name[locale]

  return (<HeaderArea
    id={infoElement.code}
    small={small}
    title={title}
    descriptionText={descriptionText}
    help={helpContent}
    showHelp={showHelp}
    legend={legend}
    mandatory={infoElement.mandatory}
  />)
}

