import React from 'react'

function onKeyUpHandler(onClick, event) {
  // return key act as a click event
  if (event.keyCode === 13) {
    onClick(event)
  }
}

export default function InteractiveElement(props) {
  const {
    type,
    tabIndex = '0',
    excludeTabIndex = false,
    role = 'button',
    excludeRole = false,
    children,
    style,
    id,
    usePointerCursor = true,
    ...passThroughProps
  } = props

  const TypeTag = type || 'div'
  const onKeyUp = props.onClick && onKeyUpHandler.bind(this, props.onClick)
  const interactiveStyle = {
    cursor: 'pointer',
    ...style,
  }
  /* eslint-disable jsx-a11y/no-static-element-interactions */
  return (
    <TypeTag
      {...passThroughProps}
      className={props.className}
      style={usePointerCursor ? interactiveStyle : style}
      tabIndex={excludeTabIndex === false ? tabIndex : undefined}
      role={excludeRole === false ? role : undefined}
      onClick={props.onClick}
      onKeyUp={onKeyUp}
      id={id}
    >
      {children}
    </TypeTag>
  )
  /* eslint-enable jsx-a11y/no-static-element-interactions */
}
