import { defineMessages } from 'react-intl'

const messages = defineMessages({
  newPicHeading: {
    id: 'permits.pics.newPicHeading',
    description: 'Label for modal adding new person',
    defaultMessage: '?? Add new person',
  },
  picEditHeading: {
    id: 'permits.pics.picEditHeading',
    description: 'Label for modal editing person',
    defaultMessage: '?? Edit person',
  },
  personName: {
    id: 'permits.pics.personName',
    description: 'Person name',
    defaultMessage: '?? Person name',
  },
  firstName: {
    id: 'permits.pics.firstName',
    description: 'Firstname',
    defaultMessage: '?? Firstname',
  },
  lastName: {
    id: 'permits.pics.lastName',
    description: 'Lastname',
    defaultMessage: '?? Lastname',
  },
  dateOfBirth: {
    id: 'permits.pics.dateOfBirth',
    description: 'Date of birth',
    defaultMessage: '?? Date of birth',
  },
  dateOfBirthDayPlaceholder: {
    id: 'permits.pics.dateOfBirthDayPlaceholder',
    description: 'Placeholder for day',
    defaultMessage: '?? Day',
  },
  dateOfBirthMonthPlaceholder: {
    id: 'permits.pics.dateOfBirthMonthPlaceholder',
    description: 'Placeholder for month',
    defaultMessage: '?? Month',
  },
  dateOfBirthYearPlaceholder: {
    id: 'permits.pics.dateOfBirthYearPlaceholder',
    description: 'Placeholder for year',
    defaultMessage: '?? Year',
  },
  dateNotValid: {
    id: 'permits.pics.dateNotValid',
    description: 'Text for invalid date',
    defaultMessage: '?? Date is not valid',
  },
  identityNumber: {
    id: 'permits.pics.identityNumber',
    description: 'Identity number i.e. social security number',
    defaultMessage: '?? Identification i.e. social security number',
  },
  identityNumberPlaceholder: {
    id: 'permits.pics.identityNumberPlaceholder',
    description: 'Give identification',
    defaultMessage: '?? Give identification',
  },
  roleInCompany: {
    id: 'permits.pics.roleInCompany',
    description: 'Role in company',
    defaultMessage: '?? Role in company',
  },
  roleInCompanyPlaceholder: {
    id: 'permits.pics.roleInCompanyPlaceholder',
    description: 'Label for role',
    defaultMessage: '?? Role...',
  },
  phone: {
    id: 'permits.pics.phone',
    description: 'Label for phone',
    defaultMessage: '?? Phone',
  },
  phonePlaceholder: {
    id: 'permits.pics.phonePlaceholder',
    description: 'Placeholder for phone',
    defaultMessage: '?? Give phone number in format +358...',
  },
  address: {
    id: 'permits.pics.address',
    description: 'Label for address',
    defaultMessage: '?? Address',
  },
  streetAddressPlaceholder: {
    id: 'permits.pics.streetAddressPlaceholder',
    description: 'Label for streetAddress placeholder',
    defaultMessage: '?? Street address',
  },
  postnoPlaceholder: {
    id: 'permits.pics.postnoPlaceholder',
    description: 'Label for postnoPlaceholder',
    defaultMessage: '?? Postnumber',
  },
  cityPlaceholder: {
    id: 'permits.pics.cityPlaceholder',
    description: 'Placeholder for city',
    defaultMessage: '?? City',
  },
  countryPlaceholder: {
    id: 'permits.pics.countryPlaceholder',
    description: 'Placeholder for country',
    defaultMessage: '?? Country',
  },
  noRows: {
    id: 'permits.pics.noRows',
    description: 'Information message for empty pics list',
    defaultMessage: '?? No pics',
  },
  editPic: {
    id: 'permits.pics.editPic',
    description: 'label for pic edit button',
    defaultMessage: '?? Edit',
  },
  deletePicConfirmation: {
    id: 'permits.pics.deletePicConfirmation',
    description: 'Label for deleting pic',
    defaultMessage: '?? Do you really want to delete selected person? This cannot be undone.',
  },
  continueDeleteConfirmation: {
    id: 'permits.pics.continueDeleteConfirmation',
    description: 'Button label for deleting pic',
    defaultMessage: '?? Yes',
  },
  ignoreDeleteConfirmation: {
    id: 'permits.pics.ignoreDeleteConfirmation',
    description: 'Button label for ignoring deleting pic',
    defaultMessage: '?? No',
  },
  deletePersonText: {
    id: 'permits.pics.deletePersonText',
    description: 'Label for modal title',
    defaultMessage: '?? Delete person',
  },
  addPic: {
    id: 'permits.pics.add',
    description: 'Label for adding pic',
    defaultMessage: '?? Add pic',
  },
  savePic: {
    id: 'permits.pics.save',
    description: 'Label for saving pic',
    defaultMessage: '?? Save pic',
  },
  cancelPic: {
    id: 'permits.pics.cancel',
    description: 'Label for cancelling pic editing',
    defaultMessage: '?? Cancel',
  },
  deletePic: {
    id: 'permits.pics.delete',
    description: 'Label for deleting pic',
    defaultMessage: '?? Delete',
  },
  errorsInPics: {
    id: 'permits.error.pics',
    description: 'Global error notification when there are errors in permit pics',
    defaultMessage: '?? There are errors on pics',
  },
  doneSavingPic: {
    id: 'permits.pics.doneSavingPic',
    description: 'Notification message shown after saving pic',
    defaultMessage: '?? Pic was saved successfully',
  },
  missingMandatoryFields: {
    id: 'permits.pics.missingMandatoryFields',
    description: 'Notification message for missing fields',
    defaultMessage: '?? Fill all required fields',
  },
  missingRequiredRows: {
    id: 'permits.pics.missingRequiredRows',
    description: 'Notification message for missing rows',
    defaultMessage: '?? Missing mandatory rows',
  },
})

export default messages
