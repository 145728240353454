import React from 'react'
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl'
import { ListGroup, ListGroupItem } from 'react-bootstrap'
import classNames from 'classnames'
import { get, isObject, xor, filter, indexOf } from 'lodash'
import Autosuggest from 'react-autosuggest'
import InteractiveElement from 'src/components/InteractiveElement'
import Icon from 'src/components/Icon'
import styles from 'src/styles/_forms.scss'
import { fixAutocompleteAccessibility } from 'src/utils'
import CommonFormGroup from './CommonFormGroup'
import { messages as inputMessages } from './InputArea'
import '../form/autocomplete.scss'

const messages = defineMessages({
  errorNoOptions: {
    id: 'form.error.noOptionsPlaceholder',
    description: 'Error placeholder for input without necessary data',
    defaultMessage: 'Error in loading options',
  },
})

class AutocompleteMultiple extends React.Component {
  static renderSuggestion(option, { query }) {
    const suggestion = option.title || option.value
    const filterMatchAt = suggestion.toLowerCase().indexOf(query.toLowerCase())
    if (!query || !query.length || filterMatchAt < 0) {
      return <span><Icon name="add" inline /> {suggestion}</span>
    }
    const beginning = suggestion.slice(0, filterMatchAt)
    const match = suggestion.slice(filterMatchAt, filterMatchAt + query.length)
    const end = suggestion.slice(filterMatchAt + query.length)
    return (
      <span><Icon name="add" inline /> {beginning}<strong>{match}</strong>{end}</span>
    )
  }

  constructor(props) {
    super(props)
    this.onChange = this.onChange.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this)
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this)
    this.onSuggestionSelected = this.onSuggestionSelected.bind(this)
    this.filterSuggestions = this.filterSuggestions.bind(this)
    this.shouldRenderSuggestions = this.shouldRenderSuggestions.bind(this)

    const selectedOptions = []
    if (props.options && props.input.value) {
      props.input.value.map((item) => {
        const selectedOption = props.options.find(it => it.value === item)
        if (selectedOption) {
          selectedOptions.push(selectedOption.value)
        }
        return null
      })
    }
    this.state = {
      selectedOptions,
      value: '',
      suggestions: props.options || [],
    }
  }

  componentDidMount() {
    const inputName = get(this.props, 'input.name')
    fixAutocompleteAccessibility(inputName)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.input.value && this.props.input.value) {
      this.setState({ value: '' })
      return
    }

    if (!nextProps.input.value && nextProps.initialized) {
      this.setState({ value: '' })
      return
    }
    if (!nextProps.input.value) {
      return
    }

    const selectedOptions = []
    nextProps.input.value.map((item) => {
      const selectedOption = nextProps.options && nextProps.options.find(it => it.value === item)
      if (selectedOption) {
        selectedOptions.push(selectedOption.value)
      }
      return null
    })
    this.setState(oldState => ({
      selectedOptions,
      value: this.filterSuggestions(oldState.value).length > 1 ? oldState.value : '',
    }))
  }

  componentDidUpdate() {
    const inputName = get(this.props, 'input.name')
    fixAutocompleteAccessibility(inputName)
  }

  onChange(event, { newValue }) {
    this.setState({
      value: newValue,
    })
  }

  onBlur() {
    const filteredSuggestions = this.state.value && this.filterSuggestions(this.state.value)
    if (filteredSuggestions.length === 1) {
      if (filteredSuggestions[0]) {
        this.onSuggestionSelected(undefined, { suggestionValue: filteredSuggestions[0].title })
      }
    }
    if (this.state.selectedOptions.length > 0) {
      const selectedOptions = this.state.selectedOptions
      this.props.input.onBlur(selectedOptions)
    } else {
      this.setState({ selectedOptions: [], value: '' })
      this.props.input.onBlur(null)
    }
  }

  onRemoveSelectedItem(event, value) {
    const selectedOptions = xor(this.state.selectedOptions, [value])
    this.setState({ selectedOptions })
    this.props.input.onChange(selectedOptions)
  }

  onSuggestionsFetchRequested({ value }) {
    this.setState({
      suggestions: this.filterSuggestions(value) || [],
    })
  }

  onSuggestionsClearRequested() {
    this.setState({
      suggestions: [],
    })
  }

  onSuggestionSelected(event, { suggestionValue }) {
    if (event) {
      event.preventDefault()
    }
    const matchingOption = this.props.options.find(it => it.title === suggestionValue)
    const selectedOptions = this.state.selectedOptions
    selectedOptions.push(matchingOption.value)
    this.props.input.onChange(selectedOptions)
  }

  shouldRenderSuggestions(value) {
    if (!value) {
      return true
    }
    const suggestions = this.filterSuggestions(value)
    if (suggestions.length === 0) {
      return false
    }
    if (suggestions.length === 1 && suggestions[0].value.title === value) {
      return false
    }
    return true
  }

  filterSuggestions(value) {
    const inputValue = value.trim().toLowerCase()

    if (!this.props.options || !this.props.options.length) {
      return []
    }

    const options = filter(this.props.options, option => indexOf(this.state.selectedOptions, option.value) < 0)
      .map((option) => {
        let relevance = 0

        const valueMatchOffset = option.value.toLowerCase().indexOf(inputValue)

        if (valueMatchOffset === 0) {
          relevance += 100
        } else if (valueMatchOffset > 0) {
          relevance += 30
        }

        const titleMatchOffset = option.title.toLowerCase().indexOf(inputValue)

        if (titleMatchOffset === 0) {
          relevance += 50
        } else if (titleMatchOffset > 0) {
          relevance += 1
        }

        return Object.assign({}, option, { relevance })
      })

    return options
      .filter(option => option.relevance > 0)
      .sort((optionA, optionB) => {
        if (optionA.relevance === optionB.relevance) {
          return optionA.title.localeCompare(optionB.title)
        }

        return optionB.relevance - optionA.relevance
      })
  }

  render() {
    const {
      value,
      title,
      suggestions,
    } = this.state

    const {
      input,
      disabled,
      options,
      mandatory,
      fetchError,
      label,
      className,
      intl: { formatMessage },
    } = this.props

    let { placeholderMessage } = this.props

    if (fetchError && !(options && options.length)) {
      placeholderMessage = messages.errorNoOptions
    }

    return (
      <div>
        <div style={{ position: 'relative' }}>
          <Autosuggest
            ref={(el) => { this.autosuggestRef = el }}
            suggestions={suggestions}
            getSuggestionValue={option => option.title}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            onSuggestionSelected={this.onSuggestionSelected}
            shouldRenderSuggestions={this.shouldRenderSuggestions}
            renderInputComponent={this.renderInputComponent}
            renderSuggestion={AutocompleteMultiple.renderSuggestion}
            // NAKKI-3672 and https://bugzilla.mozilla.org/show_bug.cgi?id=1069739 are why we have renderSuggestionsContainer
            renderSuggestionsContainer={
              ref => <div
                {...ref.containerProps}
                children={ref.children}
                tabIndex={-1}
                onFocus={() => document.getElementById(input.name).focus()}
              />
            }
            focusInputOnSuggestionClick={false}
            inputProps={{
              id: input.name,
              'id-qa-test': `input-${input.name}`,
              className: classNames('form-control autocomplete', className),
              onChange: this.onChange,
              onBlur: this.onBlur,
              value,
              title,
              disabled,
              'aria-label': label,
              'aria-required': mandatory,
              'aria-describedby': `${input.name}-error`,
              placeholder: isObject(placeholderMessage) ? formatMessage(placeholderMessage) : placeholderMessage,
              onKeyDown: (event) => {
                switch (event.key) {
                case 'Enter': {
                  event.preventDefault()
                  this.onBlur()
                  break
                }
                default:
                  break
                }
              },
            }}
          />
          {!this.state.value &&
            <span
              className={styles.inputActionIcon}
              style={{ pointerEvents: 'none', cursor: 'pointer' }}
            >
              <Icon name="chevron-tight-down" inline />
            </span>
          }
        </div>
        {this.state.selectedOptions.length > 0 &&
          <ListGroup>
            {this.state.selectedOptions.map((itemValue) => {
              const matchingOption = this.props.options.find(it => it.value === itemValue)
              return (
                <ListGroupItem
                  key={itemValue}
                  id-qa-test={`label-${this.props.input.name}-${itemValue}`}
                  className={styles.listGroupItem}
                >
                  {matchingOption && matchingOption.title}
                  <InteractiveElement
                    className={styles.inputActionIcon}
                    onClick={event => this.onRemoveSelectedItem(event, itemValue)}
                    id-qa-test={`button-close-${this.props.input.name}-${itemValue}`}
                  >
                    <Icon name="close" inline />
                    <span className="sr-only"><FormattedMessage {...inputMessages.clearInputValue} /></span>
                  </InteractiveElement>
                </ListGroupItem>
              )
            })}
          </ListGroup>
        }
      </div>
    )
  }
}

export default injectIntl(AutocompleteMultiple)

export function AutocompleteMultipleFieldGroup(props) {
  return (
    <CommonFormGroup {...props}>
      <AutocompleteMultiple {...props} />
    </CommonFormGroup>
  )
}
