import React, { useState } from 'react'
import { Col } from 'react-bootstrap'
import { injectIntl } from 'react-intl'

function InfoElementValidationMessages({ infoElement, formApi, intl: { formatMessage } }) {
  const [initialSubmits] = useState(formApi.submits)
  const showValidation = formApi.submits > initialSubmits

  if (!showValidation || !infoElement.validate) {
    return null
  }

  const customValidationError = infoElement.validate(formApi.getFormState().values, infoElement)
  if (!customValidationError) {
    return null
  }

  return (
    <Col xs={12}>
      <div className={'has-error'}>
        <p
          id={`${infoElement.code}-custom-validation`}
          className="help-block"
        >
          {formatMessage(customValidationError)}
        </p>
      </div>
    </Col>
  )
}

export default injectIntl(InfoElementValidationMessages)
