import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { find } from 'lodash'
import { FormattedMessage, injectIntl } from 'react-intl'
import Checkbox from 'src/components/form_v2/Checkbox'
import InfoElementHeaderArea from 'src/components/form_v2/InfoElementHeaderArea'
import messages from '../messages'

const ACCOUNTS_LOCATION_KEYS = [
  'accountsLocationAddress',
  'accountsLocationCity',
  'accountsLocationCountryCode',
  'accountsLocationPostalCode',
]

const ACCOUNTS_TYPE_KEYS = [
  'accountsType',
]

class AccountsTpe extends React.Component {
  constructor(props) {
    super(props)
    this.CHECKBOX_NAME = 'checkboxSameAsAccountsLocation'
    this.state = {
      previousValue: null,
      fields: {},
    }
    this.hasFilledFields = this.hasFilledFields.bind(this)
    this.onCheckboxChange = this.onCheckboxChange.bind(this)
  }

  onCheckboxChange(checked) {
    const { formApi } = this.props
    const setError = formApi.setError
    if (checked) {
      /*
        Clear error from invisible field to proceed
        since this value gets copied from accountsLocationCountryCode after submit
      */
      setError('recordsLocation.recordsLocationCountryCode', null)
    }
  }

  hasFilledFields() {
    const { formApi } = this.props
    const formState = formApi.getFormState()
    const { values } = formState
    if (values.accountsLocation && values.accountsType) {
      const hasLocationValues = ACCOUNTS_LOCATION_KEYS.every(key => values.accountsLocation[key])
      const hasTypeValues = ACCOUNTS_TYPE_KEYS.every(key => values.accountsType[key])
      return hasLocationValues && hasTypeValues
    }

    return false
  }

  render() {
    const {
      structure,
      infoElement,
      locale,
      formApi,
      renderField,
      intl: { formatMessage },
    } = this.props

    const recordsLocationInfoElement = find(structure.infoElements, { code: 'recordsLocation' })
    const recordsTypeInfoElement = find(structure.infoElements, { code: 'recordsType' })
    const accountsLocationInfoElement = find(structure.infoElements, { code: 'accountsLocation' })

    const fieldProps = {
      input: {
        visible: !formApi.values[this.CHECKBOX_NAME],
      },
      validate: !formApi.values[this.CHECKBOX_NAME],
      showLabel: !formApi.values[this.CHECKBOX_NAME],
    }

    return (
      <Col sm={6} xs={12}>
        <Row>
          <Col xs={12}>
            <InfoElementHeaderArea
              infoElement={infoElement}
              locale={locale}
              small
            />
            {recordsLocationInfoElement && accountsLocationInfoElement && this.hasFilledFields() && <Checkbox
              id={this.CHECKBOX_NAME}
              name={this.CHECKBOX_NAME}
              onChange={this.onCheckboxChange}
              locale={locale}
              placeholder={formatMessage(messages.sameAsAccountsLocationText)}
            >
              <FormattedMessage {...messages.sameAsAccountsLocationText} />
            </Checkbox>}
          </Col>
        </Row>
        {!formApi.values[this.CHECKBOX_NAME] && <Row>
          <Col xs={12}>
            {infoElement.fields && infoElement.fields.map(field => renderField({
              field,
              ...fieldProps,
            }))}
            {recordsTypeInfoElement && recordsTypeInfoElement.fields.map(field => renderField({
              field,
              ...fieldProps,
            }))}
          </Col>
        </Row>}
      </Col>
    )
  }
}

export default injectIntl(AccountsTpe)
