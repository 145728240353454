import React from 'react'
import { delay } from 'lodash'
import { FormattedMessage } from 'react-intl'
import { Jumbotron } from 'react-bootstrap'
import Icon from 'src/components/Icon'
import Loader from 'src/components/Loader'
import Heading from 'src/layout/Heading'
import messages from './messages'

const MINIMUM_LOGOUT_TIME_MS = 1500

export default class Logout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      logoutOngoing: false,
    }
  }
  componentDidMount() {
    this.doLogout()
  }

  componentDidUpdate() {
    this.doLogout()
  }

  doLogout = () => {
    const { isAuthenticated, sendLogoutRequest } = this.props
    const { logoutOngoing } = this.state

    if (isAuthenticated && !logoutOngoing) {
      this.setState({ logoutOngoing: true }, () => {
        const logoutRequestedTime = Date.now()
        sendLogoutRequest(this.props.token)
          .then((response) => {
            const logoutSucceededTime = Date.now()
            /**
             * Redirect user to given logout service and terminate this app.
             *
             * NOTE: After successfull logout in logout service, user will be redirected from authentication
             * microservice to `static/logout.html` script for lightweight storage clearing. From there user
             * will be redirected and may not return to this app.
             */
            if (response && response.redirect) {
              const logoutRedirect = () => {
                window.location.href = response.redirect
              }
              // If logout was too fast, delay redirect so user has some time to see logout page content
              if (logoutSucceededTime - logoutRequestedTime < MINIMUM_LOGOUT_TIME_MS) {
                delay(logoutRedirect, MINIMUM_LOGOUT_TIME_MS - (logoutSucceededTime - logoutRequestedTime))
              } else {
                logoutRedirect()
              }
            }
          })
      })
    }
  }

  render() {
    const { logoutFailed, logoutRequested, isAuthenticated } = this.props
    return (
      <div>
        <Heading message={messages.title} compact />
        <main className="container" role="main" id="main">
          <Jumbotron>
            <div className="text-center lead">
              <Icon name={logoutFailed ? 'alert-triangle' : 'lock-open'} huge /><br /><br />
              {!isAuthenticated &&
                <FormattedMessage {...messages.loggedOut} />
              }
              {logoutRequested &&
                <div>
                  <FormattedMessage {...messages.logoutRequested} />
                  <Loader small inline />
                </div>
              }
              {logoutFailed &&
                <p className="text-danger">
                  <FormattedMessage {...messages.logoutFailed} />
                </p>
              }
            </div>
          </Jumbotron>
        </main>
      </div>
    )
  }
}
