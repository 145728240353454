import React from 'react'
import { groupBy } from 'lodash'
import { CheckboxWithoutField } from './Checkbox'
import HeaderArea from './HeaderArea'

export default function CheckboxList({
  'aria-describedby': ariaDescribedBy,
  options,
  groupOptionsBy,
  input: { name, value, onChange },
}) {
  if (!groupOptionsBy) {
    return (
      <React.Fragment>
        <Checkboxes ariaDescribedBy={ariaDescribedBy} name={name} selectedValues={value} onChange={onChange} options={options} />
      </React.Fragment>
    )
  }
  const groups = groupBy(options, groupOptionsBy)

  return (
    <React.Fragment>
      {Object.entries(groups).map(([group, codes]) => {
        const id = `${name}-${group.replace(/[^a-zA-Z]/g, '_')}-label`
        return <div role="group" aria-labelledby={id} key={group}>
          <HeaderArea
            id={id}
            title={group}
          />
          <Checkboxes ariaDescribedBy={ariaDescribedBy} name={name} selectedValues={value} onChange={onChange} options={codes} />
        </div>
      })}
    </React.Fragment>
  )
}

function Checkboxes({ name, options, selectedValues, onChange, ariaDescribedBy }) {
  return options.map(({ value, title }) => {
    const checked = selectedValues.includes(value)
    return (
      <CheckboxWithoutField
        ariaProps={{ 'aria-describedby': ariaDescribedBy }}
        key={value}
        name={name}
        id={value}
        value={checked}
        onChange={(newValue) => {
          if (newValue && !checked) {
            onChange([...selectedValues, value])
          } else if (!newValue && checked) {
            onChange(selectedValues.filter(v => v !== value))
          }
        }}
        onBlur={() => {}}
      >
        {title}
      </CheckboxWithoutField>
    )
  }
  )
}
