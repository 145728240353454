import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Button } from 'react-bootstrap'
import Icon from 'src/components/Icon'
import messages from '../messages'


export default function PermitButtonToolbar({
  showBack,
  backAction,
  showNext,
  showExit,
  exitBsStyle,
  exitAlignment = 'left',
  disabled,
  children,
  exitAction,
  bordered,
}) {
  return (
    <div className={`panel-footer btn-toolbar ${bordered ? 'with-border' : ''}`}>
      {showBack &&
        <Button onClick={backAction} disabled={disabled} id-qa-test="button-back">
          <Icon name="chevron-left" />
          <FormattedMessage
            id-qa-test={`button-${messages.previousStep.defaultMessage.toLowerCase()}`}
            {...messages.previousStep}
          />
        </Button>
      }
      {showExit &&
        <Button
          disabled={disabled}
          bsStyle={exitBsStyle}
          onClick={exitAction}
          id-qa-test={`button-${exitAlignment}`}
        >
          <Icon name="close" /><FormattedMessage {...messages.exit} />
        </Button>
      }
      {showNext &&
        <Button type="submit" bsStyle="primary" disabled={disabled} id="nextButton" id-qa-test="button-next">
          <FormattedMessage
            {...messages.nextStep}
          />
          <Icon name="chevron-right" style={{ marginLeft: '8px', marginRight: 0 }} />
        </Button>
      }
      {children}
    </div>
  )
}
