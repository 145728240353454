import React, { useEffect } from 'react'
import { Col, Button, FormControl } from 'react-bootstrap'

import { get } from 'lodash'
import { Field } from 'react-form'
import { required } from 'src/utils/validation'
import { injectIntl, FormattedMessage, defineMessages } from 'react-intl'
import { connect } from 'react-redux'
import AccessibleMessage from 'src/components/AccessibleMessage'
import { apiCall } from 'src/utils/http'
import { focusElementById } from 'src/utils'
import styles from './ApplicantCustomsResponsibleDateEori.scss'
import { updateStatusMessageForInput } from '../../../../../../utils/index'

export const getCompanyNameFieldCode = field => `${field.code}-name`

export const COMPANY_NAME_NOT_FOUND = '-'

const messages = defineMessages({
  fetchButton: {
    id: 'permits.fetchButton',
    description: '',
    defaultMessage: 'Hae',
  },
  customsCompanyNotFound: {
    id: 'permits.customsCompanyNotFound',
    description: '',
    defaultMessage: 'Yritystä ei löytynyt',
  },
  messageDeclarantProviderIdentificationDesc: {
    id: 'permits.messageDeclarantProviderIdentificationDesc',
    description: 'Hint text for the provider\'s EORI number',
    defaultMessage: 'Provide the EORI number',
  },
})

function onCustomerNotFound({ field, formApi }) {
  formApi.setValue('customsResponsibleName', null)
  formApi.setValue(field.code, null)
  formApi.setError(field.code, messages.customsCompanyNotFound)
  focusIdInput()
}

function focusIdInput() {
  focusElementById('customsResponsibleName', 300)
}

export async function fetchCustomerName(customerId, tmCustomerUrl) {
  const encodedCustomerId = encodeURIComponent(customerId)

  try {
    const customerName = await apiCall(
      `${tmCustomerUrl}/customerName/?eori=${encodedCustomerId}`,
      { method: 'GET', cache: 'default' },
      null,
      null,
      false
    )
    return customerName
  } catch (error) {
    throw error
  }
}

function ApplicantCustomsResponsibleDateEori(props) {
  const { infoElement, field, formApi, buttonDescriptionId, bootstrapConfig, intl: { locale } } = props
  const isReadonly = props.staticValue
  const customsResponsibleName = formApi.getValue('customsResponsibleName')

  const error = formApi.getFormState().errors?.applicantCustomsResponsible?.applicantCustomsResponsibleDateEori

  useEffect(() => {
    const fieldValue = formApi.getValue('applicantCustomsResponsible.applicantCustomsResponsibleDateEori')

    if (fieldValue) {
      formApi.setValue('customsResponsibleEori', fieldValue)
      handleFetchCompanyName(fieldValue)
    }
  }, [])

  const handleFetchCompanyName = (customerEori) => {
    const url = bootstrapConfig.tm_customer_ext_url
    fetchCustomerName(customerEori, url)
      .then((customerName) => {
        if (customerName) {
          formApi.setValue('customsResponsibleName', customerName)
          formApi.setValue(field.code, customerEori)
          formApi.setError(field.code, null)
        } else {
          onCustomerNotFound(props)
        }
      })
      .catch(() => {
        onCustomerNotFound(props)
      })
  }

  const ariaProps = {
    'aria-describedby': `${field.code}-error`,
  }

  const validate = () => {
    const requiredCheck = required(customsResponsibleName)
    if (requiredCheck) {
      formApi.setError(field.code, requiredCheck)
    } else {
      formApi.setError(field.code, null)
    }

    return null
  }

  return (
    <div role="group" aria-labelledby={`${field.code}-group`} className={`clearfix formElementGutter form-group ${error ? 'has-error' : ''}`}>
      <span id={`${field.code}-group`} className="control-label customLabel">
        {field.name[locale]}
      </span>
      <Field
        field={'customsResponsibleEori'}
        locale={locale}
        validate={validate}
      >
        {(fieldApi) => {
          const { value, setValue, setTouched } = fieldApi
          return (
            <div>
              {isReadonly &&
                <FormControl.Static
                  id-qa-test={`label-${'customsResponsibleEori'.toLowerCase()}`}
                >
                  {value || '--'}
                </FormControl.Static>
              }
              {!isReadonly &&
                <div style={{ display: 'flex', flex: 1 }}>
                  <FormControl
                    componentClass={'input'}
                    value={value || ''}
                    id-qa-test={`input-${'customsResponsibleEori'.toLowerCase()}`}
                    onChange={(event) => {
                      const state = formApi.getFormState()

                      formApi.setFormState({
                        ...state,
                        errors: {
                          ...state.errors,
                          applicantCustomsResponsible: {
                            applicantCustomsResponsibleDateEori: undefined,
                          },
                        },
                      })

                      setValue(event.target.value)
                      formApi.setValue(field.code, null)
                      formApi.setValue('customsResponsibleName', undefined)
                    }}
                    onBlur={() => {
                      setTouched()
                      handleFetchCompanyName(value)
                      updateStatusMessageForInput('customsResponsibleName')
                    }}
                    onKeyPress={(event) => {
                      switch (event.key) {
                      case 'Enter': {
                        event.preventDefault()
                        break
                      }
                      default:
                        break
                      }
                    }}
                    {...ariaProps}
                  />
                  {!isReadonly && (
                    <div className={styles.buttonContainer}>
                      <Button
                        id={`find-company-${infoElement.code}`}
                        aria-describedby={buttonDescriptionId}
                        bsStyle="primary"
                        block
                        className={styles.button}
                        onClick={() => handleFetchCompanyName(value)}
                        id-qa-test={`button-find-company-${infoElement.code}`}
                      >
                        <span><FormattedMessage {...messages.fetchButton} /></span>
                      </Button>
                    </div>
                  )}
                </div>
              }
            </div>
          )
        }}
      </Field>

      {error && (
        <p className="help-block" id={`${field.code}-error-message`}>
          <span className="text-danger">
            <FormattedMessage {...error} />
          </span>
        </p>
      )}

      {!isReadonly && customsResponsibleName && <div>
        <Col className={styles.messageContainer} xs={12} md={12}>
          <p><AccessibleMessage message={customsResponsibleName} /></p>
        </Col>
      </div>
      }
    </div>
  )
}

const mapStateToProps = state => ({
  bootstrapConfig: get(state, 'config.bootstrapConfig'),
})

export default injectIntl(connect(mapStateToProps)(ApplicantCustomsResponsibleDateEori))
