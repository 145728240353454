import React from 'react'
import { get, head, includes, filter } from 'lodash'
import Table, { SORTING_TYPE_NUMBER } from 'src/components/Table/Table'
import ErrorBoundary from 'src/components/ErrorBoundary'
import ErrorMessage from 'src/components/form_v2/ErrorMessage'
import Icon from 'src/components/Icon'
import { formatCellValue } from '../../../formatValue'
import { getFieldErrors } from '../../../permitManager'
import messages from '../messages'

export default class ItemsList extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.formApi = null
    this.tableName = `goodsTable-${new Date().getTime()}`
    this.state = {
      selectedItem: null,
    }

    this.rowClick = this.rowClick.bind(this)
  }

  getFieldName(field) {
    const { locale } = this.props
    return get(field, `name.${locale}`)
  }

  getVisibleFields() {
    return this.getFields()
  }

  getFields() {
    return this.props.fields.filter(field => field.visible)
  }

  getRowErrors(row) {
    return this.getFields()
      .map((field) => {
        const errors = getFieldErrors(field, row)
        return {
          field,
          error: head(errors) || get(row.errors, field.code),
        }
      })
      .filter(({ error }) => error)
  }

  getFieldHeadings(activeFields) {
    const { locale } = this.props
    return activeFields.map(field => ({ value: field.name[locale] }))
  }

  rowClick({ row, index }) {
    this.props.selectItem(row, index)
  }

  formatCellValue(row, field) {
    const { locale, codesets, intl: { formatMessage } } = this.props
    return formatCellValue(row, field, { locale, codesets, formatMessage, emptyValue: '' })
  }

  render() {
    const { intl: { formatMessage } } = this.props
    const list = this.props.items.map((item, index) => ({ itemNumber: index + 1, ...item }))

    const activeFields = this.getVisibleFields()

    const fieldCodes = activeFields.map(field => field.code)
    const properties = ['itemNumber', ...fieldCodes]
    const fieldHeadings = this.getFieldHeadings(activeFields)
    const headings = [{ value: formatMessage(messages.rowNumber) }, ...fieldHeadings]

    const errors = list.map(row => this.getRowErrors(row))
    const hasErrors = errors.some(errorList => errorList.length > 0)
    if (hasErrors) {
      properties.push('errors')
      headings.push('')
    }

    const formatters = {
      errors: (_, __, index) =>
        errors[index]
          .map(({ field, error }) => (<div key={field.code} className="text-danger">
            <Icon name="attention" />
            <strong>{this.getFieldName(field)}</strong>: <ErrorMessage error={error} fieldName={this.getFieldName(field)} />
          </div>)
          )
      ,
    }

    filter(activeFields, field => includes(['CODESET', 'BOOLEAN'], field.type))
      .forEach((field) => {
        formatters[field.code] = row => this.formatCellValue(row, field)
      })

    const sortingTypes = {
      itemNumber: SORTING_TYPE_NUMBER,
    }

    return (
      <div style={{ paddingBottom: '10px' }}>
        <ErrorBoundary>
          <Table
            headings={headings}
            properties={properties}
            contentRows={list}
            formatters={formatters}
            sortingTypes={sortingTypes}
            onRowClick={this.rowClick}
            sortable
          />
        </ErrorBoundary>
      </div>
    )
  }
}
