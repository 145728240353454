import React from 'react'
import { Button, Modal as BSModal } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { isObject, has, isFunction } from 'lodash'
import ErrorBoundary from 'src/components/ErrorBoundary'
import Loader from 'src/components/Loader'
import { GLOBAL_STATUS_ID } from 'src/constants'
import SwitchLocale from './SwitchLocale'
import messages from './messages'
import { isSomethingPreventingModalClose } from '../utils/modalHelper'

const addKeyDownHandler = () => {
  const element = document.querySelector('div.modal[role="dialog"]')

  if (element) {
    element.addEventListener('keydown', keyboardNavigationHandler, false)
  }
}

// NAKKI-895 We need this for special cases like the CN8 Search dialog
const removeKeyDownHandler = () => {
  const element = document.querySelector('div.modal[role="dialog"]')

  if (element) {
    element.removeEventListener('keydown', keyboardNavigationHandler, false)
  }
}

const keyboardNavigationHandler = (event) => {
  if (event.keyCode === 9) {
    const modal = document.querySelector('div.modal[role="dialog"]')
    const fieldElements = modal.querySelectorAll('a, input, select, button, textarea')

    // We have to filter out disabled elements otherwise this handler will fail
    const allEnabledFieldElements = []
    for (const element of fieldElements) {
      if (!element.hasAttribute('disabled')) {
        allEnabledFieldElements.push(element)
      }
    }

    if (allEnabledFieldElements.length > 0) {
      const firstFocusableElement = allEnabledFieldElements[0]
      const lastFocusableElement = allEnabledFieldElements[allEnabledFieldElements.length - 1]

      if (event.shiftKey && document.activeElement === firstFocusableElement) {
        event.preventDefault()
        lastFocusableElement.focus()
      } else if (!event.shiftKey && document.activeElement === lastFocusableElement) {
        event.preventDefault()
        firstFocusableElement.focus()
      }
    }
  }
}

const handleOnSwitch = (onSwitch, lang) => {
  const element = document.getElementById('dialog-title')
  if (element) {
    setTimeout(() => {
      element.focus()
    }, 500)
  }
  onSwitch(lang)
}

// NAKKI-905 react-boostrap modal sets aria-hidden="true" to all other top level elements thus the screen reader won't
// read GLOBAL_STATUS_ID element changes. Lets remove the attribute from GLOBAL_STATUS_ID element.
const enableGlobalStatus = () => {
  const globalStatus = document.getElementById(GLOBAL_STATUS_ID)
  if (globalStatus) {
    globalStatus.removeAttribute('aria-hidden')
  }
}

// NAKKI-905 Fixes react-bootstrap modal's incorrect role attributes
const fixDialogRoles = () => {
  const elements = Array.from(document.querySelectorAll('div[role="dialog"],div[role="document"]'))
  elements.forEach((el) => {
    if (el.classList.contains('modal')) {
      el.setAttribute('aria-labelledby', 'dialog-title')
      el.setAttribute('aria-describedby', 'dialog-message')
    } else {
      el.removeAttribute('role')
    }
  })

  const modalBodies = Array.from(document.querySelectorAll('.modal-body'))
  modalBodies.forEach((modalBody) => {
    if (modalBody.firstChild && modalBody.querySelector('p')) {
      const existingDialogMessage = modalBody.querySelector('#dialog-message')

      if (!existingDialogMessage) {
        const firstParagraphElement = modalBody.querySelector('p')

        // remove role if we add dialog-message since it will be readed twice
        firstParagraphElement.removeAttribute('role')
        firstParagraphElement.setAttribute('id', 'dialog-message')
      }
    }
  })
}

const onShowCallback = (disableNavigationHandling, onShowCb) => {
  enableGlobalStatus()
  fixDialogRoles()
  if (!disableNavigationHandling) {
    addKeyDownHandler()
  }
  if (onShowCb) {
    onShowCb()
  }
}

export default function Modal({
  animation,
  show,
  size,
  titleMessage,
  cancelMessage = messages.cancel,
  cancelDisabled,
  onClickCancel,
  showCancel = true,
  continueMessage,
  continueDisabled,
  onClickContinue,
  showContinue = true,
  focusButton,
  continueBtnStyle = 'primary',
  loading = false,
  locale,
  onSwitchLocale,
  children,
  className,
  bodyStyle,
  contentRef,
  customHeaderButtons,
  customFooterButtons,
  backdrop = 'static',
  disableNavigationHandling = false,
  focusDisableButton = false,
  onShowCb,
  onExited,
  onEscKey,
  disableEscKey = false,
  focusLocaleSwitch = false,
}) {
  // NAKKI-895 If disableNavigationHandling is changed after the Modal has been initialized,
  // we need to update the handler
  if (disableNavigationHandling) {
    removeKeyDownHandler()
  } else {
    addKeyDownHandler()
  }

  const enrichedHide = () => {
    // Check if there are tooltips, inputs open when pressing escape key
    if (isSomethingPreventingModalClose()) {
      return
    }

    // In some cases pressing esc and clicking cancel is different behaviour, onEscKey is first class citizen
    if (isFunction(onEscKey)) {
      onEscKey()
    } else if (isFunction(onClickCancel)) {
      onClickCancel()
    }
  }

  return (
    <BSModal
      onExited={onExited}
      show={show}
      bsSize={size}
      dialogClassName={className}
      animation={animation}
      backdrop={backdrop}
      onHide={enrichedHide}
      restoreFocus
      enforceFocus
      autoFocus
      keyboard={!disableEscKey}
      aria-hidden={!show}
      onShow={() => onShowCallback(disableNavigationHandling, onShowCb)}
    >
      <div ref={contentRef}>
        {loading && <Loader blocking />}
        {titleMessage &&
          <BSModal.Header>
            <BSModal.Title componentClass="div">
              <h2 id="dialog-title" tabIndex="-1">
                {isObject(titleMessage) ? [
                  has(titleMessage, 'value')
                    ? <span key="formattedTitleMsg">
                      <FormattedMessage {...titleMessage} values={{ value: titleMessage.value }} />
                    </span>
                    : <span key="formattedTitleMsg"><FormattedMessage {...titleMessage} /></span>]
                  : titleMessage}
              </h2>
              {onSwitchLocale &&
                <SwitchLocale
                  locale={locale}
                  onSwitchLocale={lang => handleOnSwitch(onSwitchLocale, lang)}
                  autoFocus={focusLocaleSwitch}
                />
              }
              {customHeaderButtons}
            </BSModal.Title>
          </BSModal.Header>
        }
        <BSModal.Body style={bodyStyle}>
          <ErrorBoundary>
            {children}
          </ErrorBoundary>
        </BSModal.Body>
        <BSModal.Footer>
          {showCancel &&
            <Button
              disabled={cancelDisabled}
              onClick={onClickCancel}
              id-qa-test="btn-modal-cancel"
              autoFocus={(!showContinue && focusButton) || focusDisableButton}
            >
              {isObject(cancelMessage) ? <FormattedMessage {...cancelMessage} /> : cancelMessage}
            </Button>
          }
          {!showCancel && !customFooterButtons && <div />}
          {showContinue &&
            <Button
              disabled={continueDisabled}
              onClick={onClickContinue}
              bsStyle={continueBtnStyle}
              id-qa-test="btn-modal-continue"
              autoFocus={!focusDisableButton && focusButton}
            >
              {isObject(continueMessage) ? <FormattedMessage {...continueMessage} /> : continueMessage}
            </Button>
          }
          {customFooterButtons &&
          <div className="custom-footer-buttons">
            {customFooterButtons}
          </div>
          }
        </BSModal.Footer>
      </div>
    </BSModal>
  )
}
