import React from 'react'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'
import Modal from 'src/components/Modal'
import showNotification from 'src/utils/notifications'
import permitMessages from '../messages'
import api from '../../api'

const messages = defineMessages({
  deleteModalCancel: {
    id: 'permits.deleteModalCancel',
    description: 'Cancel button text in modal',
    defaultMessage: 'Cancel',
  },
  deleteModalDelete: {
    id: 'permits.deleteModalDelete',
    description: 'Continue button text in modal',
    defaultMessage: 'Delete',
  },
  deleteModalTitle: {
    id: 'permits.deleteModalTitle',
    description: 'Title of modal',
    defaultMessage: 'Delete application draft?',
  },
  deleteModalMessage: {
    id: 'permits.deleteModalMessage',
    description: 'Message of modal',
    defaultMessage: 'Do you really want to delete this draft?',
  },
})

class DeleteApplicationModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
    }
    this.deleteApplication = this.deleteApplication.bind(this)
  }

  deleteApplication(referenceNumber, version) {
    const { onClickContinue } = this.props
    this.setState({ isLoading: true })
    return api.deleteApplication(referenceNumber, version)
      .then((response) => {
        showNotification({
          level: 'success',
          message: permitMessages.applicationDraftDeleted,
          autoDismiss: true,
        })
        onClickContinue(response)
        this.setState({ isLoading: false })
        return null
      })
      .catch((err) => {
        this.setState({ isLoading: false })
        throw err
      })
  }

  render() {
    const {
      onClickCancel,
      referenceNumber,
      version,
    } = this.props

    return (
      <Modal
        show
        showCancel
        cancelMessage={messages.deleteModalCancel}
        showContinue
        titleMessage={messages.deleteModalTitle}
        continueMessage={messages.deleteModalDelete}
        cancelDisabled={this.state.isLoading}
        continueDisabled={this.state.isLoading}
        onClickContinue={() => {
          this.deleteApplication(referenceNumber, version)
        }}
        onClickCancel={onClickCancel}
        loading={this.state.isLoading}
        focusDisableButton
      >
        <p className="lead"><FormattedMessage {...messages.deleteModalMessage} /></p>
      </Modal>
    )
  }
}

export default injectIntl(DeleteApplicationModal)
