import React from 'react'

export default class Snoobi extends React.Component {
  componentDidMount() {
    const cookieModeAll = localStorage.getItem('cookieMode') === 'all'
    if (cookieModeAll && this.props.account) {
      const snbscript = document.createElement('script')
      const account = this.props.account
      const pageName = ''
      const section = ''
      const anchors = '1'
      const cookies = 'true'
      snbscript.type = 'text/javascript'
      snbscript.async = true
      snbscript.src = `${document.location.protocol === 'https:' ? 'https://' : 'http://'}`
        + 'eu2.snoobi.com/'
        + `?account=${account}`
        + `&page_name=${pageName}`
        + `&section=${section}`
        + `&anchors=${anchors}`
        + `&cookies=${cookies}`

      const s = document.getElementsByTagName('script')[0]
      s.parentNode.insertBefore(snbscript, s)
    }
  }

  // eslint-disable-next-line class-methods-use-this
  render() {
    return (null)
  }
}
