import React from 'react'
import { injectIntl, defineMessages } from 'react-intl'
import cn from 'classnames/bind'
import styles from './loader.scss'

import AccessibleMessage from './AccessibleMessage'

const boundCn = cn.bind(styles)

const messages = defineMessages({
  defaultLoadingText: {
    id: 'loading.defaultLoadingText',
    description: 'Default loading text when specific text is not defined',
    defaultMessage: 'Loading',
  },
})

function Loader({
  inline = false,
  input = false,
  small = false,
  tiny = false,
  blocking = false,
  inverse = false,
  center,
  message,
  style,
  containerStyle,
  intl: { formatMessage },
}) {
  const LoaderTag = inline ? 'span' : 'div'
  const classNames = boundCn({
    loader: true,
    loaderTiny: tiny,
    loaderSmall: small,
    loaderInverse: inverse,
    inputLoader: input,
    'center-block': center,
  })

  let formattedMessage
  if (message) {
    const { values, ...intlMessage } = message
    formattedMessage = message && formatMessage(intlMessage, values)
  } else {
    formattedMessage = formatMessage(messages.defaultLoadingText)
  }

  const loader = (
    <LoaderTag
      className={classNames}
      style={style}
    />
  )
  if (blocking) {
    return (
      <div className={styles.loaderContainer} style={containerStyle}>
        {loader}
        {message && (
          <div className={styles.loaderStatusMessage}>
            <AccessibleMessage message={formattedMessage} />
          </div>
        )}
      </div>
    )
  } else if (input) {
    return (
      <div className={styles.inputLoaderContainer}>
        {loader}
      </div>
    )
  }
  return loader
}

export default injectIntl(Loader)
