import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { get, has, find, set } from 'lodash'
import { getLocationFields } from './GoodsLocation/utils'
import styles from './LocationFields.scss'

export default function LocationFields(props) {
  const { infoElement, field, formApi, renderField } = props
  const prefix = get(infoElement, 'code', '')
  let defaultFieldsArr = []
  if (infoElement.type === 'SINGLE') {
    defaultFieldsArr = getLocationFields(`${infoElement.code}.${prefix}`)
  } else {
    defaultFieldsArr = getLocationFields(prefix)
  }

  const [
    nameField,
    addressField,
    postalCodeField,
    cityField,
    countryCodeField,
  ] = defaultFieldsArr.map(fieldData => (
    {
      ...fieldData,
      infoElement,
      visible: true,
    }
  ))

  // Override default country code field with field in infoElement
  const countryCodeInInfoElement = find(infoElement.fields, { code: countryCodeField.code })

  const formState = formApi.getFormState()

  if (infoElement.type === 'SINGLE') {
    // If single type infoElement, then we assume that formApi refers to application form
    const infoElementValues = get(formState, `values.${infoElement.code}`)
    if (!has(infoElementValues, `${prefix}Address`)) {
      const locationValue = get(infoElementValues, field.code)
      const split = locationValue && locationValue.split(/\n/)
      const newFormState = set(formState.values, `${infoElement.code}`, {
        ...infoElementValues,
        [`${prefix}Name`]: get(split, [0], ''),
        [`${prefix}Address`]: get(split, [1], ''),
        [`${prefix}PostalCode`]: get(split, [2], ''),
        [`${prefix}City`]: get(split, [3], ''),
        [`${prefix}CountryCode`]: get(split, [4], ''),
      })

      formApi.setAllValues(newFormState)
    }
  } else if (!has(formState.values, `${prefix}Address`)) {
    // Repetitive type infoElement, then we assume that formApi refers to sub-form inside modal
    const locationValue = get(formState.values, field.code)
    const split = locationValue && locationValue.split(/\n/)
    formApi.setAllValues({
      ...formState.values,
      [`${prefix}Name`]: get(split, [0], ''),
      [`${prefix}Address`]: get(split, [1], ''),
      [`${prefix}PostalCode`]: get(split, [2], ''),
      [`${prefix}City`]: get(split, [3], ''),
      [`${prefix}CountryCode`]: get(split, [4], ''),
    })
  }

  const aggregateValuesToFieldValue = (name, value) => {
    const currentValue = get(formApi.getFormState(),
      `values[${field.code}]`,
      Array(defaultFieldsArr.length - 1).join('\n')
    )
    const currentValueSplitted = currentValue.split(/\n/)
    defaultFieldsArr.forEach((fieldInArr, index) => {
      if (name === fieldInArr.code) {
        currentValueSplitted[index] = value
      }
    })
    formApi.setValue(field.code, currentValueSplitted.join('\n'))
  }

  return (
    <Row>
      <Col xs={12}>
        {nameField && renderField({
          field: nameField,
          input: {
            onChange: value => aggregateValuesToFieldValue(nameField.code, value),
          },
          errors: formApi.errors,
        })}
      </Col>
      <Col xs={12}>
        {addressField && renderField({
          field: addressField,
          input: {
            onChange: value => aggregateValuesToFieldValue(addressField.code, value),
          },
          errors: formApi.errors,
        })}
      </Col>
      <Col xs={12} className={styles.verticalWrapper}>
        <Col xs={5}>
          {postalCodeField && renderField({
            field: postalCodeField,
            input: {
              onChange: value => aggregateValuesToFieldValue(postalCodeField.code, value),
            },
            errors: formApi.errors,
          })}
        </Col>
        <Col xs={7}>
          {cityField && renderField({
            field: cityField,
            input: {
              onChange: value => aggregateValuesToFieldValue(cityField.code, value),
            },
            errors: formApi.errors,
          })}
        </Col>
      </Col>
      <Col xs={12}>
        {countryCodeField && renderField({
          field: {
            ...countryCodeField,
            ...countryCodeInInfoElement,
          },
          input: {
            onChange: value => aggregateValuesToFieldValue(countryCodeField.code, value),
          },
          errors: formApi.errors,
        })}
      </Col>
    </Row>
  )
}
