import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { injectIntl } from 'react-intl'
import { has, pickBy, filter, get, findIndex, set, find, each, indexOf, isObject, omit } from 'lodash'
import repetitiveComponentMessages from 'src/routes/permits/permit/components/RepetitiveComponent/messages'
import InfoElementHeaderArea from 'src/components/form_v2/InfoElementHeaderArea'
import { uniqueId } from 'src/utils'
import ItemForm from './components/ItemForm'
import ItemsList from './components/ItemsList'
import ItemsListReadOnly from './components/ItemsListReadOnly'
import Import from './components/Import'
import {
  USE_EQUIVALENT_GOOD_FIELD_NAME,
} from '../GoodsToBePlacedUnderProcedure/constants'

const getFieldDefaultValue = (fieldCode) => {
  if (fieldCode === 'economicConditionsAdditionalInformation') {
    // eslint-disable-next-line
    return 'Taloudelliset edellytykset täyttyvät. Unionin tuottajien keskeisille eduille ei katsota aiheutuvan haittaa UTK 211 art. 5 kohta.'
  }
  return ''
}

class GoodsCombined extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedItem: null,
      errors: [],
      showValidation: false,
      justCancelled: false,
    }
    this.combinedInfoElements = this.getCombinedInfoElements()
    this.addNew = this.addNew.bind(this)
    this.cancelEditing = this.cancelEditing.bind(this)
    this.updateItem = this.updateItem.bind(this)
    this.deleteItem = this.deleteItem.bind(this)
    this.selectItem = this.selectItem.bind(this)
  }

  componentDidUpdate(prevProps) {
    const submits = get(this.props, 'formApi.submits', 0)
    const prevSubmits = get(prevProps, 'formApi.submits', 0)
    if (submits > prevSubmits) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ showValidation: true })
      const addNewGoodButton = document.getElementById('new-row-combined-good')
      addNewGoodButton.focus()
    }
  }

  getCombinedInfoElements() {
    const { structure } = this.props

    const getInfoElementObject = infoElementCode =>
      find(structure.infoElements, { code: infoElementCode })

    let infoElements = {
      infoElementGoodsToBePlacedUnderProcedure: getInfoElementObject('goodsToBePlacedUnderProcedure'),
      infoElementYieldRate: getInfoElementObject('yieldRate'),
      infoElementEquivalentGood: getInfoElementObject('equivalentGood'),
      infoElementGoodsIdentification: getInfoElementObject('goodsIdentification'),
      infoElementEconomicConditions: getInfoElementObject('economicConditions'),
    }

    const hiddenInfoElements = {}

    if (structure.code === 'OPO') {
      hiddenInfoElements.infoElementEconomicConditions = get(infoElements, 'infoElementEconomicConditions')
      infoElements = omit(infoElements, 'infoElementEconomicConditions')
    }

    const visibleInfoElements = pickBy(infoElements, isObject)
    return { visibleInfoElements, hiddenInfoElements }
  }

  getAllInfoElements = () =>
    Object.assign(
      {},
      this.combinedInfoElements.visibleInfoElements,
      this.combinedInfoElements.hiddenInfoElements
    )

  getFormValues() {
    const { formApi } = this.props
    const formValues = formApi.getFormState().values
    const combinedValues = []
    const rowIDs = []
    each(this.getAllInfoElements(), (infoElement) => {
      const infoElementValues = get(formValues, infoElement.code, {})
      each(infoElementValues, (row) => {
        let idIndex = indexOf(rowIDs, row.id)
        if (idIndex < 0) {
          rowIDs.push(row.id)
          idIndex = rowIDs.length - 1
        }
        set(combinedValues, `[${idIndex}]`, { ...combinedValues[idIndex], ...row })
      })
    })

    return combinedValues
  }

  addNew(data, errors) {
    const { formApi } = this.props
    const rowId = uniqueId()

    each(this.getAllInfoElements(), (infoElement) => {
      if (!has(formApi.values, infoElement.code)) {
        formApi.values[infoElement.code] = []
      }
      const newItems = formApi.values[infoElement.code]
      const infoElementFieldCodes = infoElement.fields.map(field => field.code)
      const fieldValues = pickBy(data, (fieldValue, fieldName) => infoElementFieldCodes.includes(fieldName))
      const defaultValues = {}

      infoElementFieldCodes.forEach((code) => {
        defaultValues[code] = getFieldDefaultValue(code)
      })

      newItems.push({
        ...defaultValues,
        ...fieldValues,
        errors,
        id: rowId,
        [USE_EQUIVALENT_GOOD_FIELD_NAME]: data[USE_EQUIVALENT_GOOD_FIELD_NAME],
      })

      formApi.setValue(infoElement.code, newItems)
    })
  }

  cancelEditing() {
    this.setState({ selectedItem: null, justCancelled: true })
    setTimeout(() => {
      this.setState({ justCancelled: false })
    }, 500)
  }

  updateItem(oldData, newData) {
    const { formApi } = this.props
    each(this.getAllInfoElements(), (infoElement) => {
      let newItems = get(formApi, `values[${infoElement.code}]`, [])
      const rowIndex = findIndex(get(formApi.values, infoElement.code), { id: newData.id })
      const infoElementFieldCodes = infoElement.fields.map(field => field.code)
      const fieldValues = pickBy(newData, (fieldValue, fieldName) => infoElementFieldCodes.includes(fieldName))
      const defaultValues = {}
      infoElementFieldCodes.forEach((code) => {
        defaultValues[code] = getFieldDefaultValue(code)
      })
      newItems = set(newItems, `[${rowIndex}]`, {
        ...defaultValues,
        ...fieldValues,
        id: newData.id,
        [USE_EQUIVALENT_GOOD_FIELD_NAME]: newData[USE_EQUIVALENT_GOOD_FIELD_NAME],
      })
      formApi.setValue(infoElement.code, newItems)
    })
  }

  deleteItem() {
    const { formApi } = this.props
    const idToRemove = this.state.selectedItem.id
    each(this.getAllInfoElements(), (infoElement) => {
      const newItems = filter(formApi.values[infoElement.code], values => values.id !== idToRemove)
      formApi.setValue(infoElement.code, newItems)
      this.setState({ selectedItem: null })
    })
  }

  selectItem(item) {
    if (!get(this.state, 'justCancelled')) {
      this.setState({
        selectedItem: item,
      })
    }
  }

  render() {
    const {
      infoElement,
      locale,
      renderField,
      intl,
      structure,
      codesets,
      type,
      formApi,
    } = this.props

    const formValues = this.getFormValues()
    const hasGoods = formValues.length > 0
    const isReadOnly = type === 'readonly'
    const errors = formApi.getFormState().errors

    const { showValidation } = this.state

    return (
      <Col xs={12} className="noSidePadding">
        <Row>
          <Col xs={12}>
            <InfoElementHeaderArea
              infoElement={infoElement}
              locale={locale}
              showHelp={!isReadOnly}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            {!isReadOnly && <ItemForm
              selectedItem={this.state.selectedItem}
              cancelEditing={this.cancelEditing}
              updateItem={this.updateItem}
              deleteItem={this.deleteItem}
              locale={locale}
              infoElements={this.combinedInfoElements.visibleInfoElements}
              renderField={renderField}
              addNew={this.addNew}
              intl={intl}
              permitTypeCode={get(structure, 'code')}
            />}
            {!hasGoods && !isReadOnly && <Import
              locale={locale}
              addNew={this.addNew}
              formatMessage={intl.formatMessage}
              infoElements={this.combinedInfoElements.visibleInfoElements}
              permitTypeCode={get(structure, 'code')}
              codesets={codesets}
            />}
            {hasGoods && !isReadOnly && <ItemsList
              items={formValues}
              locale={locale}
              selectItem={this.selectItem}
              infoElements={this.combinedInfoElements.visibleInfoElements}
              intl={intl}
              codesets={codesets}
              permitTypeCode={get(structure, 'code')}
            />}
            {hasGoods && isReadOnly && <ItemsListReadOnly
              items={formValues}
              locale={locale}
              selectItem={item => this.setState({
                selectedItem: item,
              })}
              infoElements={this.combinedInfoElements.visibleInfoElements}
              intl={intl}
              codesets={codesets}
              permitTypeCode={get(structure, 'code')}
              errors={errors}
            />}
            {!isReadOnly && !hasGoods &&
              <div className={showValidation ? 'has-error' : ''}>
                <p
                  id={`new-row-description-${infoElement.code}`}
                  className="help-block"
                  aria-label={intl.formatMessage(repetitiveComponentMessages.mandatoryRowDescription)}
                >
                  {intl.formatMessage(repetitiveComponentMessages.mandatoryRowDescription)}
                </p>
              </div>
            }
          </Col>
        </Row>
      </Col>
    )
  }
}

export default injectIntl(GoodsCombined)
