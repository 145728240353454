import React, { useContext } from 'react'
import { connect } from 'react-redux'
import {
  get,
} from 'lodash'
import CommonFormGroup from 'src/components/form_v2/CommonFormGroup'
import { injectIntl } from 'react-intl'
import {
} from '../constants'
import {
  getCustomFieldComponent,
  isFieldMandatory,
  isFieldVisibleConditionally,
  getCodesetObj,
  getFieldProps,
} from '../permitManager'
import PermitPageContext from './PermitPage/context'

export function renderField({ field, ...options } = {}) {
  if (!field) {
    return null
  }
  return <InfoElementFieldContainer
    key={field.code}
    field={field}
    {...options}
  />
}

function InfoElementField({
  formApi: formApiOverride,
  field,
  input,
  errors,
  warning,
  infoElementOverride = {},
  staticValue,
  showLabel = true,
  descriptionText,
  showHelp,
  validate,
  options,
  optionGroups,
  customValue,
  autoFocus,
  intl: { formatMessage },
  locale,
} = {}) {
  const { formApi: contextFormApi, applicationDate } = useContext(PermitPageContext)
  const formApi = formApiOverride || contextFormApi
  const applicationValues = get(contextFormApi.getFormState(), 'values')

  if (!field) {
    return null
  }

  const values = get(formApi.getFormState(), 'values')
  const visible = field.visible &&
    isFieldVisibleConditionally(field, values, applicationValues)

  if (!visible) {
    return null
  }

  const infoElement = {
    ...field.infoElement,
    ...infoElementOverride,
  }
  const { mandatory } = isFieldMandatory(
    { ...field, infoElement },
    values,
    applicationValues,
  )

  const fieldParams = {
    ...field,
    infoElement,
    mandatory,
  }

  const CustomFieldComponent = getCustomFieldComponent(field)

  if (CustomFieldComponent) {
    return (<CustomFieldComponent
      field={fieldParams}
      formApi={formApi}
      renderField={renderField}
      autoFocus={autoFocus}
      infoElement={infoElement}
      applicationDate={applicationDate}
      staticValue={staticValue}
    />)
  }

  return (
    <CommonFormGroup
      id={field.code}
      formApi={formApi}
      errors={errors || formApi.getFormState().errors}
      warning={warning}
      customValue={customValue}
      showLabel={showLabel}
      options={options}
      optionGroups={optionGroups}
      ariaDescribedBy={`${infoElement.code}-custom-validation`}
      codesetObj={getCodesetObj(field)}
      {...getFieldProps({
        field: fieldParams,
        input,
        locale,
        options,
        staticValue,
        showHelp,
        validate,
        formatMessage,
        formApi,
      })}
      descriptionText={descriptionText}
      autoFocus={autoFocus}
      applicationDate={applicationDate}
    />
  )
}

const mapStateToProps = state => ({
  locale: state.locale,
})

const InfoElementFieldContainer = connect(mapStateToProps)(
  injectIntl(InfoElementField)
)
export default InfoElementFieldContainer
