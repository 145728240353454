import React from 'react'
import { injectIntl } from 'react-intl'
import { HelpBlock } from 'react-bootstrap'
import { get } from 'lodash'
import { showValidationState } from '../../utils/validation'
import AccessibleMessage from '../AccessibleMessage'
import messages from './messages'

/**
 * JSX tag for showing field-specific localized validation result
 * @param {Object} field - Redux Form field object
 */

function ValidationMessage({ field, requireTouched = true, intl, intl: { formatMessage } }) {
  const id = `${get(field, 'input.name', '')}-error`
  if (showValidationState(field, requireTouched)) {
    let message = field.meta.error || field.meta.warning
    // Custom server-side warnings are outdated in dirty form fields: only show when pristine.
    if (!message && field.warning && field.meta.pristine) {
      message = field.warning
    }
    if (message && message.id) {
      let accessibleMessage
      const messageString = formatMessage(message, get(message, 'values', undefined))
      const fieldLabel = field.label || field.labelMessage
      if (fieldLabel) {
        const fieldName = fieldLabel.id ? intl.formatMessage(fieldLabel) : fieldLabel
        accessibleMessage = intl.formatMessage(
          messages.fieldError,
          {
            fieldName,
            errorMessage: messageString,
          },
        )
      } else {
        accessibleMessage = messageString
      }
      return (
        <div>
          <HelpBlock id={id} sr-message={accessibleMessage}>
            { messageString }
          </HelpBlock>
          <AccessibleMessage message={accessibleMessage} hideMessage />
        </div>
      )
    }
  }
  return <span />
}

export default injectIntl(ValidationMessage)
