export const INDIVIDUAL_GUARANTEE_ROUTE_PATH = 'guarantee'
export const FORM_STATE = {
  CREATED: 'created',
  PAYABLE: 'payable',
  UNKNOWN: 'unknown',
  INPAYMENT: 'inPayment',
  PAID: 'paid',
  AVAILABLE: 'available',
  RESERVED: 'reserved',
  LIABILITY_RESERVED: 'liabilityReserved',
}

export const INDIVIDUAL_GUARANTEE_GET_GUARANTEE_STATUS_PATH = 'individual/status'
export const INDIVIDUAL_GUARANTEE_SEND_BANKING_DETAILS_PATH = 'individual/bankingDetails'
export const PAYMENT_PENDING_ROUTE = 'payment/pending'
export const PAYMENT_CANCEL_ROUTE = 'payment/cancel'
export const PAYMENT_START_ROUTE = 'individual/payment/start'
export const STOP_PAGE_ROUTE = 'stop'

export const PAYMENT_CANCEL_API_ROUTE = 'individual/payment/cancel'
export const PAYMENT_SUCCESS_API_ROUTE = 'individual/payment/success'

export const INDIVIDUAL_GUARANTEE_DESTROY_STATE = '@@nakki/individual_guarantee/INDIVIDUAL_GUARANTEE_DESTROY_STATE'
export const INDIVIDUAL_GUARANTEE_UPDATE_STATUS = '@@nakki/individual_guarantee/INDIVIDUAL_GUARANTEE_UPDATE_STATUS'
export const INDIVIDUAL_GUARANTEE_CATEGORY_IMPORT = 'import'
export const INDIVIDUAL_GUARANTEE_CATEGORY_TRANSIT = 'transit'
export const INDIVIDUAL_GUARANTEE_CATEGORIES = [INDIVIDUAL_GUARANTEE_CATEGORY_IMPORT, INDIVIDUAL_GUARANTEE_CATEGORY_TRANSIT]
export const INVALID_PARAMETERS_ERROR = 'INVALID_PARAMETERS'

export const PAYMENT_CODE_PAID = 'PAID'
export const PAYMENT_CODE_INTERNAL_ERROR = 'INTERNAL_ERROR'
export const PAYMENT_STARTED = 'STARTED'
export const PAYMENT_ALREADY_STARTED = 'ALREADY_STARTED'

export const PAYBACK_IBAN_ID = 'paybackIban'
export const PAYBACK_BIC_ID = 'paybackBic'
