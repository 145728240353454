import cn from 'classnames/bind'
import classnames from 'classnames'
import React, { useState } from 'react'
import { FormControl, HelpBlock } from 'react-bootstrap'
import { FormattedMessage, defineMessages } from 'react-intl'
import { Field } from 'react-form'
import styles from '../form/checkbox.scss'
import ErrorMessage from './ErrorMessage'
import { required } from '../../utils/validation'

const boundCn = cn.bind(styles)

export const messages = defineMessages({
  textYes: {
    id: 'text.yes',
    description: 'Text of selected checkbox',
    defaultMessage: 'Yes',
  },
  textNo: {
    id: 'text.no',
    description: 'Text of unselected checkbox',
    defaultMessage: 'No',
  },
})

class Checkbox extends React.Component {
  constructor(props) {
    super(props)
    this.validate = this.validate.bind(this)
    this.onChange = this.onChange.bind(this)
  }

  onChange(value, setValue) {
    setValue(value)
    if (this.props.onChange) {
      this.props.onChange(value)
    }
  }

  validate(value) {
    const { validation } = this.props
    const requiredCheck = required(value === true ? value : undefined)
    if (validation && validation.mandatory && requiredCheck && !value) {
      return { error: requiredCheck }
    }
    return null
  }

  render() {
    return (
      <Field
        validate={this.validate}
        field={this.props.name}
        locale={this.props.locale}
      >
        {(fieldApi) => {
          const { value, error, setValue, setTouched } = fieldApi

          return (
            <CheckboxWithoutField
              {...this.props}
              value={value}
              error={error}
              onChange={val => this.onChange(val, setValue)}
              onBlur={() => setTouched()}
            />
          )
        }}
      </Field>
    )
  }
}

export function CheckboxWithoutField({
  id,
  name,
  static: isStatic,
  inline = false,
  disabled = false,
  inputRef,
  className,
  style,
  children,
  placeholder,
  value,
  error,
  onChange,
  onBlur,
  ...props
}) {
  const [hasFocus, setFocused] = useState(false)

  const classes = {
    disabled,
    checkbox: !inline,
    'checkbox-inline': inline,
    customCheckbox: true,
    focused: hasFocus,
  }

  return (
    <div className={boundCn(className, classes)} style={style}>
      <div>
        {isStatic &&
          <FormControl.Static>
            {value ?
              <FormattedMessage {...messages.textYes} /> :
              <FormattedMessage {...messages.textNo} />
            }
          </FormControl.Static>
        }
        {!isStatic &&
          <label title={placeholder}>
            <input
              id={id}
              name={name}
              ref={inputRef}
              type="checkbox"
              disabled={disabled}
              value={value || ''}
              id-qa-test={`input-${id}`}
              onFocus={() => setFocused(true)}
              onChange={() => onChange(!value)}
              onBlur={() => {
                setFocused(false)
                onBlur()
              }}
              aria-hidden="false"
              checked={value}
              {...props.ariaProps}
            />
            <CheckboxVisualElement focused={hasFocus} selected={!!value} />
            {children}
          </label>
        }
        {error &&
          <HelpBlock>
            <ErrorMessage error={error} fieldName={placeholder} />
          </HelpBlock>
        }
      </div>
    </div>
  )
}

/* eslint-disable max-len */
const svgPaths = {
  checkbox: {
    default: 'M0,20 L20,20 L20,0 L0,0 L0,20 Z M2,18 L18,18 L18,2 L2,2 L2,18 Z',
    focus: 'M0,20 L20,20 L20,0 L0,0 L0,20 Z M3,17 L17,17 L17,3 L3,3 L3,17 Z',
    selected: 'M16.4435639,2 L2,2 L2,18 L18,18 L18,4.00356387 L9.084,13.811 L4.158,8.884 L5.925,7.116 L8.999,10.19 L16.4435639,2 Z M0,20 L0,0 L20,0 L20,20 L0,20 Z',
    focusSelected: 'M0,20 L20,20 L20,0 L0,0 L0,20 Z M15.5345817,3 L3,3 L3,17 L17,17 L17,5.10354549 L9.084,13.811 L4.158,8.884 L5.925,7.116 L8.999,10.19 L15.5345817,3 Z',
  },
  pin: 'M27.9,11.3l-4.7-4.7L18.6,2l-5.5,7.5c0,0-4.1-1.2-8.6,3.3l5.2,5.2L2,25.8l0,2.1h2.1l7.7-7.7l5.2,5.2c4.5-4.5,3.3-8.6,3.3-8.6L27.9,11.3z M16.4,20.9L9,13.4c3-3.1,5.8-0.3,5.8-0.3L18.4,7l4.2,4.4l-6,3.7C16.6,15.1,19.4,17.9,16.4,20.9z',
  pinSelected: 'M27.6,11.1l-8.7-8.7L18.5,2l-0.3,0.4c-1.7,2.3-3.5,4.8-5,7c-0.1,0-0.1,0-0.2,0c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4,0-0.6,0c-0.2,0-0.5,0-0.7,0C10.4,9.5,9.7,9.7,9,9.9c-1.1,0.3-2,0.9-2.6,1.4l0,0l0,0c-0.2,0.2-0.4,0.3-0.5,0.5c-0.2,0.2-0.4,0.3-0.6,0.5l-0.3,0.3L4.6,13l0.3,0.3l4.8,4.8l-7.6,7.6L2,25.9v0.2v1.4V28h0.5h1.4h0.2l0.1-0.1c2.2-2.3,4.7-4.7,7.7-7.5l4.8,4.8l0.4,0.4l0.3-0.4c0.2-0.2,0.4-0.4,0.6-0.6c0.2-0.2,0.4-0.4,0.6-0.7c0.7-0.8,1.2-1.7,1.5-2.8c0.4-1.3,0.6-2.6,0.4-3.8l0-0.1l0-0.1l0,0c1.7-1.2,3.3-2.4,4.9-3.6c0.7-0.5,1.5-1.1,2.2-1.6l0.5-0.3L27.6,11.1z',
}
/* eslint-enable max-len */

function CheckboxVisualElement({ selected, focused }) {
  const classNames = classnames({
    [styles.customCheckboxButton]: true,
    [styles.customCheckboxButtonSelected]: selected,
    [styles.customCheckboxButtonFocus]: focused,
  })

  const hiddenElementAriaProps = {
    'aria-hidden': true,
    role: 'presentation',
  }

  return (
    <svg
      {...hiddenElementAriaProps}
      viewBox="0 0 20 20"
      className={classNames}
      style={{ pointerEvents: 'none' }}
    >
      {selected ?
        <path d={svgPaths.checkbox[focused ? 'focusSelected' : 'selected']} />
        :
        <path d={svgPaths.checkbox[focused ? 'focus' : 'default']} />
      }
    </svg>
  )
}

export default Checkbox
