export const minutesToMs = minutes => minutes * 1000 * 60

const msToMinutes = ms => ms / 1000 / 60

export const getMinutesLeft = timestamp =>
  Math.round(msToMinutes((timestamp * 1000) - (Math.floor(new Date().getTime()))))

export const endWithBackSlash = url => (url.endsWith('/') ? url : `${url}/`)

export const timeoutMinutesLeft = timeoutObj =>
  minutesToMs(timeoutObj.minutes) - ((new Date()).getTime() - timeoutObj.timerStart)
