import React from 'react'
import { injectIntl } from 'react-intl'
import { get } from 'lodash'

import {
  APPLICATION_BASIS_ADDITIONAL_INFORMATION_TEMPORARY_CODE,
  EXTENSION_VALUE_NAME_TITLE_TYPE,
  PERMIT_TEXTS_CODESET_NAME,
} from '../../constants'
import CommonFormGroup from '../../../../../components/form_v2/CommonFormGroup'
import { required } from '../../../../../utils/validation'
import { formatOptionalCmsMessage } from '../../../../../utils/index'
import {
  getAdditionalFieldCode,
  getAdditionalInformationValidationErrorMessage,
  getCheckboxValidationErrorMessage,
  getMuuValue,
  getSelectedItemsByTitle,
  getSubCodesetName,
  onlyOneOptionInTitle,
} from './applicationBasisHelpers'
import messages from '../../messages'

function ApplicationBasisCheckboxes(props) {
  const {
    selectedApplicationBasisTitle,
    selectedApplicationBasis = [],
    field,
    formApi,
    infoElement,
    applicationDate,
    intl,
    renderField,
    items,
    additionalInformationText,
  } = props
  const additionalInformationFieldCode = getAdditionalFieldCode(infoElement)
  const muuValue = getMuuValue(infoElement)

  const onlyOneOption = onlyOneOptionInTitle(items, selectedApplicationBasisTitle)
  const additionalInformationField = infoElement.fields.find(infoElementField =>
    infoElementField.code === additionalInformationFieldCode)
  const additionalInformationRequired = selectedApplicationBasis?.find(articles =>
    articles[field.code] === muuValue)
  const customFilterFn = (codesetItems, title) =>
    () => getSelectedItemsByTitle(codesetItems, title)

  const codesetObj = {
    basicAttribute: true,
    codesetName: PERMIT_TEXTS_CODESET_NAME,
    subCodesetName: getSubCodesetName(infoElement),
    extensionValue: EXTENSION_VALUE_NAME_TITLE_TYPE,
    filterByExtensionValue: selectedApplicationBasisTitle,
    customFilterFn: customFilterFn(items, selectedApplicationBasisTitle),
  }

  const validation = (value) => {
    const requireCheck = required(value)

    if (requireCheck) {
      return get(messages, getCheckboxValidationErrorMessage(infoElement))
    }

    return null
  }

  const selectOnChange = (value) => {
    formApi.setValue(infoElement.code, value.map(code =>
      ({ [field.code]: code, [additionalInformationFieldCode]: null })
    ))
  }

  const checkboxInputProps = {
    type: 'codesetCheckboxes',
    multilineLabel: true,
    name: infoElement.code,
    static: onlyOneOption,
    validate: true,
    help: onlyOneOption ? null : {
      content: formatOptionalCmsMessage(intl, '/permits/fields/applicationBasisTitleHelp'),
    },
    validation: {
      mandatory: true,
      returningValidator: validation,
    },
    visible: true,
    value: selectedApplicationBasis?.map(applicationBasis => applicationBasis[field.code]),
    onChange: selectOnChange,
    controlled: true,
  }

  if (onlyOneOption && additionalInformationRequired) {
    const additionalOnChange = (value) => {
      formApi.setValue(infoElement.code,
        [{ [field.code]: onlyOneOption.code, [additionalInformationFieldCode]: value }]
      )
    }

    const additionalInformationValidation = (value) => {
      const requireCheck = required(value)

      if (requireCheck) {
        return get(messages, getAdditionalInformationValidationErrorMessage(infoElement))
      }

      return null
    }

    return renderField(
      {
        field: {
          ...additionalInformationField,
          code: APPLICATION_BASIS_ADDITIONAL_INFORMATION_TEMPORARY_CODE,
          onChange: additionalOnChange,
          mandatory: !!additionalInformationRequired,
          validate: true,
          input: {
            validation: {
              returningValidator: additionalInformationValidation,
            },
          },
        },
        customValue: additionalInformationText,
      }
    )
  }

  return <CommonFormGroup
    id={`${infoElement.code}.${field.code}`}
    formApi={formApi}
    applicationDate={applicationDate}
    ariaDescribedBy={`${infoElement.code}-custom-validation`}
    codesetObj={codesetObj}
    input={checkboxInputProps}
    label={onlyOneOption ?
      formatOptionalCmsMessage(intl, '/permits/fields/applicationBasisSelectedLabel') : formatOptionalCmsMessage(intl, '/permits/fields/applicationBasisSelectLabel')}
    showLabel
  />
}

export default injectIntl(
  ApplicationBasisCheckboxes
)
