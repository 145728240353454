import { handleActions } from 'redux-actions'
import { BOOTSTRAP_CONFIG } from '../constants'

const filterFeatures = bootstrapConfig => Object.assign({}, bootstrapConfig, { features: undefined })

export default handleActions({
  [BOOTSTRAP_CONFIG]: {
    next: (state, action) => ({
      ...state,
      features: action.payload.bootstrapConfig.features,
      bootstrapConfig: filterFeatures(action.payload.bootstrapConfig),
      services: action.payload.services,
      serviceGroups: action.payload.serviceGroups,
    }),
  },
}, {
  bootstrapConfig: null,
  services: null,
  serviceGroups: null,
})
