import { defineMessages } from 'react-intl'

const messages = defineMessages({
  loadingCustomerInformation: {
    id: 'customer.loading',
    description: 'Message shown when loading customer information',
    defaultMessage: 'Loading customer information...',
  },
  loadingRepresentativeInformation: {
    id: 'customer.loadingRepresentative',
    description: 'Message shown when loading representative information',
    defaultMessage: 'Loading representative information...',
  },
  companyInformationTitle: {
    id: 'customer.companyInformationTitle',
    description: 'Title for company information',
    defaultMessage: 'Company information',
  },
  personInformationTitle: {
    id: 'customer.personInformationTitle',
    description: 'Title for person applicant information',
    defaultMessage: 'Applicant information',
  },

  representativeInformationTitle: {
    id: 'customer.representativeInformationTitle',
    description: 'Title for representative information',
    defaultMessage: 'Representative information',
  },
  customerInformationTitle: {
    id: 'customer.customerInformationTitle',
    description: 'Title for customer information',
    defaultMessage: 'Customer information',
  },
  AEOAuthorisationTitle: {
    id: 'customer.AEOAuthorisation',
    description: 'Title for AEO authorisation',
    defaultMessage: 'AEO authorisation',
  },
  contactInformation: {
    id: 'customer.contactInformation',
    description: 'Title for contact information',
    defaultMessage: 'Contact information',
  },
  applicant: {
    id: 'customer.applicant',
    description: 'Title for applicant',
    defaultMessage: 'Applicant',
  },
  representative: {
    id: 'customer.representative',
    description: 'Title for representative',
    defaultMessage: 'Representative',
  },
  missing: {
    id: 'customer.missing',
    description: 'Title for missing text',
    defaultMessage: 'Missing',
  },
  applyForEori: {
    id: 'customer.applyForEori',
    description: 'Text for a link for applying EORI',
    defaultMessage: 'Apply for EORI',
  },
  AEONoCertificate: {
    id: 'customer.AEONoCertificate',
    description: 'Text when customer doesn\'t have AEO certificate',
    defaultMessage: 'No',
  },
  identifier: {
    id: 'customer.identifier',
    description: 'Text for identifier',
    defaultMessage: 'Identifier',
  },
})

export default messages
