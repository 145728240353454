
import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { CustomerInformation } from 'src/components/customer'
import InfoElementHeaderArea from 'src/components/form_v2/InfoElementHeaderArea'
import messages from 'src/components/customer/messages'
// eslint-disable-next-line import/no-named-as-default
import RepresentativeNotification from './RepresentativeNotification'


export default function AuthorizationOrDecisionHolder(props) {
  const { infoElement, locale, isPerson, formApi } = props

  return (
    <React.Fragment>
      <RepresentativeNotification focusAfterClose={infoElement.code} />
      <Col sm={6} xs={12}>
        <Row>
          <Col xs={12}>
            <InfoElementHeaderArea
              showHelp
              infoElement={infoElement}
              locale={locale}
              customTitle={isPerson ? <FormattedMessage {...messages.personInformationTitle} /> : <FormattedMessage {...messages.companyInformationTitle} />}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <CustomerInformation isPerson={isPerson} infoElement={infoElement} formApi={formApi} />
          </Col>
        </Row>
      </Col>
    </React.Fragment>
  )
}
