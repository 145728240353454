import React from 'react'
import { Col } from 'react-bootstrap'

import ErrorMessage from 'src/components/form_v2/ErrorMessage'

import LabelArea from '../../../../../components/form_v2/LabelArea'


export default function GuaranteePerCustomsProcedureRow({ itemIndex, field, fieldError, locale, children, label }) {
  return (
    <Col sm={12} xs={12} className="formElementGutter">
      <LabelArea
        id={`${field.code}Label`}
        input={{}}
        showLabel
        mandatory
      >
        {label}
      </LabelArea>
      {children && <p id-qa-test={`label-${field.code}-${itemIndex}`} className="form-control-static">
        {children}
      </p>}
      {fieldError &&
      <div className={'has-error'}>
        <p className="help-block">
          <ErrorMessage error={fieldError} fieldName={field.name[locale]} />
        </p>
      </div>
      }
    </Col>
  )
}
