import React from 'react'
import { injectIntl, defineMessages } from 'react-intl'
import { FormControl } from 'react-bootstrap'
import { find, isObject, omit, includes } from 'lodash'
import Icon from 'src/components/Icon'
import styles from 'src/styles/_forms.scss'
import CommonFormGroup from './CommonFormGroup'

const messages = defineMessages({
  errorNoOptions: {
    id: 'form.error.noOptionsPlaceholder',
    description: 'Error placeholder for input without necessary data',
    defaultMessage: 'Error in loading options',
  },
  selectNonGroupedTitle: {
    id: 'form.selectNonGroupedTitle',
    description: 'Title for option group',
    defaultMessage: 'Other',
  },
})

const Select = injectIntl((props) => {
  const {
    id,
    input,
    multiple,
    disabled,
    options,
    optionGroups,
    mandatory,
    fetchError,
    ariaLabel,
    labelId,
    intl: { formatMessage },
    placeholderMessage,
    autoFocus,
    onBlur,
    'aria-describedby': ariaDescribedBy,
  } = props

  let placeholder = placeholderMessage

  if (fetchError && !(options && options.length)) {
    placeholder = messages.errorNoOptions
  }

  let combinedOptions = []

  if (placeholder) {
    combinedOptions.push(
      <option
        key="placeholder"
        disabled
        value=""
        id-qa-test={'option-placeholder'}
      >
        {isObject(placeholder) ? formatMessage(placeholder) : placeholder}
      </option>
    )
  }

  const currentValueNotInOptions = Boolean(input.value && !find(options, it => it.value === input.value))

  if (currentValueNotInOptions) {
    combinedOptions.push(
      <option
        key={input.value}
        value={input.value}
        id-qa-test={`option-${input.value}`}
      >
        {input.value}
      </option>
    )
  }

  const groups = {}
  const nonGrouped = []

  if (options && optionGroups) {
    options.forEach((option) => {
      let optGroup = null
      optionGroups.forEach((optionGroup) => {
        if (includes(optionGroup.values, option.value)) {
          optGroup = optionGroup
        }
      })
      if (optGroup) {
        if (!groups[optGroup.title.id]) {
          groups[optGroup.title.id] = []
        }
        groups[optGroup.title.id].push({
          ...option,
          sort: optGroup.sort,
        })
      } else {
        nonGrouped.push({
          ...option,
          sort: 10,
        })
      }
    })
  }

  if (optionGroups) {
    Object
      .keys(groups)
      .sort((a, b) => groups[a][0].sort - groups[b][0].sort)
      .forEach((key) => {
        const groupOptions = groups[key].map(option =>
          <option
            key={option.id}
            value={option.value}
            id-qa-test={`option-${option.value}`}
          >
            {option.message ? formatMessage(option.message) : option.value}
          </option>
        )
        const optGroup = find(optionGroups, group => group.title.id === key)
        combinedOptions.push(
          <optgroup key={optGroup.title.id} label={formatMessage(optGroup.title)}>
            {groupOptions}
          </optgroup>
        )
      })

    combinedOptions = combinedOptions.concat(
      <optgroup key={'nonGrouped'} label={formatMessage(messages.selectNonGroupedTitle)}>
        {nonGrouped.map(option =>
          <option key={option.id} value={option.value} id-qa-test={`option-${option.value}`}>
            {option.message ? formatMessage(option.message) : option.value}
          </option>
        )}
      </optgroup>
    )
  } else {
    options.forEach((option) => {
      combinedOptions.push(
        <option key={`option-${option.value}`} value={option.value}>
          {option.message ? formatMessage(option.message) : option.title}
        </option>
      )
    })
  }

  return (
    <div>
      <FormControl
        {...omit(input, ['static', 'validate', 'visible'])}
        componentClass="select"
        id-qa-test={`select-${props.input.name}`}
        disabled={disabled}
        multiple={multiple}
        aria-label={ariaLabel}
        aria-labelledby={input.ariaLabelledBy ? input.ariaLabelledBy : labelId}
        aria-required={mandatory}
        aria-describedby={ariaDescribedBy}
        onChange={e => input.onChange(e.target.value)}
        autoFocus={autoFocus}
        onBlur={onBlur}
        id={id}
      >
        {combinedOptions}
      </FormControl>
      <span
        className={styles.inputActionIcon}
        style={{ pointerEvents: 'none', cursor: 'pointer' }}
      >
        <Icon name="chevron-tight-down" inline />
      </span>
    </div>
  )
})

export default Select
/**
 * Wraps Select with CommonFormGroup
 */
export function SelectFieldGroup(props) {
  return (
    <CommonFormGroup {...props}>
      <Select {...props} />
    </CommonFormGroup>
  )
}
