import React from 'react'
import { defineMessages, FormattedMessage } from 'react-intl'
import NotificationSystem from 'react-notification-system-redux'
import { isEqual, isFunction } from 'lodash'
import Modal from './Modal'
import Icon from './Icon'
import './notifications.scss'
import logger from '../utils/logger'

import AccessibleMessage from '../components/AccessibleMessage'

const messages = defineMessages({
  closeModalNotification: {
    id: 'notifications.button.closeModalNotification',
    description: 'Button text for closing modal notification',
    defaultMessage: 'OK',
  },
  errorModalTitle: {
    id: 'notifications.errorModalTitle',
    description: 'Default title for error modal notification',
    defaultMessage: 'Error',
  },
})
/**
 * Renders multiple non-blocking stacked notifications.
 */
function NonBlockingNotifications({ notifications, formatMessage }) {
  // Convert notification data from state to react-notification-system
  const translatedNotifications = notifications.map((it) => {
    const notification = {
      position: 'br',
      autoDismiss: 0,
      style: null,
      ...it,
      dismissible: 'click',
    }
    if (notification.autoDismiss === true) {
      if (notification.level === 'success' || notification.level === 'info') {
        notification.autoDismiss = 5
      } else {
        notification.autoDismiss = 15
      }
    }
    if (notification.level === 'error') {
      notification.children = (<Icon name="alert-triangle" />)
    }
    if (notification.title && notification.title.id) {
      const { values, ...intlMessage } = notification.title
      notification.title = formatMessage(intlMessage, values)
    }
    if (notification.message && notification.message.id) {
      const { values, ...intlMessage } = notification.message
      notification.message = formatMessage(intlMessage, values)
    }
    if (typeof notification.message !== 'string') {
      logger.error('Warning message is not accessible to screen readers', notification.message)
    }
    if (notification.additionalInfo && notification.additionalInfo.id) {
      const { values, ...intlMessage } = notification.additionalInfo
      notification.message += ` ${formatMessage(intlMessage, values)}`
    }

    notification.children = (
      <AccessibleMessage message={notification.message} hideMessage />
    )

    if (notification.icon) {
      notification.message = <React.Fragment>
        <Icon name="checkmark" md />
        <span style={{ marginLeft: '2.5rem' }}>
          {notification.message}
        </span>
      </React.Fragment>
    }

    return notification
  })

  // eslint-disable-next-line react/style-prop-object
  return <NotificationSystem notifications={translatedNotifications} style={false} />
}

/* The notifications should only update when either:
 1) Locale changes
 2) The notifications change

 Otherwise internal state of the notification system gets momentarily out of sync when notifications
 are removed. This results in the notification rendering twice which also causes screen readers to
 read them twice.
 */
const MemoizedNonBlockingNotifications = React.memo(NonBlockingNotifications, (prevProps, nextProps) => {
  // formatMessage always changes, so we must compare locale
  if (prevProps.locale !== nextProps.locale) {
    return false
  }

  return isEqual(
    prevProps.notifications.map(n => n.uid),
    nextProps.notifications.map(n => n.uid),
  )
})

/**
 * Renders one modal notification at a time from array of notifications.
 */
function renderModalNotification(notifications, locale, onSwitchLocale, hide) {
  if (!notifications || !notifications.length) {
    return null
  }
  const { uid, title, message, level, additionalInfo, continueMessage, onClickContinue } = notifications[0]
  let className = `notification-modal notification-modal-${level}`
  let icon
  if (level === 'error') {
    icon = (<Icon name="alert-triangle" md className="modal-icon text-danger" />)
    className += ' has-icon'
  }
  return (
    <Modal
      show
      showCancel={false}
      showContinue
      continueBtnStyle="primary"
      continueMessage={continueMessage || messages.closeModalNotification}
      titleMessage={title || (level === 'error' && messages.errorModalTitle)}
      className={className}
      locale={locale}
      focusButton
      onEscKey={() => hide(uid)}
      onClickContinue={() => {
        hide(uid)
        if (isFunction(onClickContinue)) {
          onClickContinue()
        }
      }}
    >
      <p className="lead" id="dialog-message">
        {icon}
        {message && message.id && <FormattedMessage {...message} values={{ br: <br /> }} /> /* check if message is eligible for FormattedMessage */ }
        {typeof message === 'string' && message /* some modal errors gives only string */ }
      </p>

      {additionalInfo && additionalInfo.id &&
        <p><FormattedMessage {...additionalInfo} /></p>
      }

    </Modal>
  )
}

export default class Notifications extends React.Component {
  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.showLoginNotification()
    }
  }

  render() {
    const { notifications, intl: { formatMessage }, hide, locale, onSwitchLocale } = this.props
    const nonBlockingNotifications = []
    const modalNotifications = []
    notifications.forEach((it) => {
      const { modal, ...options } = it
      if (modal === true) {
        modalNotifications.push(options)
      } else {
        nonBlockingNotifications.push(options)
      }
    })
    return (
      <div>
        <MemoizedNonBlockingNotifications notifications={nonBlockingNotifications} formatMessage={formatMessage} locale={locale} />
        {renderModalNotification(modalNotifications, locale, onSwitchLocale, hide)}
      </div>
    )
  }
}
