import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Row, Col, Button } from 'react-bootstrap'
import moment from 'moment'
import { get, find, has } from 'lodash'
import {
  CODESET_STRING_DELIMITER,
} from 'src/components/codeset/constants'
import Icon from 'src/components/Icon'
import Modal from 'src/components/Modal'
import ButtonToolbar from 'src/components/ButtonToolbar'
import noRowsRenderer from 'src/components/lists/noRowsRenderer'
import { StatefulAccordion as Accordion, AccordionContainer } from 'src/components/Accordion'
import { focusElementById } from 'src/utils/index'
import messages from '../messages'

export default class PicsList extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      picCount: props.picCount || 0,
      list: props.pics,
      showDeleteConfirmationModal: null,
      selectedPic: null,
    }
    this.getCountryByCode = this.getCountryByCode.bind(this)
  }

  getCountryByCode(code) {
    const { codesets, countryCodeField, locale } = this.props
    const subCodesetString = has(countryCodeField, 'subCodesetName')
      ? `${CODESET_STRING_DELIMITER}${countryCodeField.subCodesetName}`
      : ''
    const countryCodeCodeset = get(codesets, countryCodeField.codesetName + subCodesetString, [])
    const dateKey = Object.keys(countryCodeCodeset)[0]
    const countries = get(countryCodeCodeset, dateKey, [])
    const country = find(countries, { code })
    return get(country, `name.${locale}`)
  }

  render() {
    const {
      selectPic,
      deletePic,
      pics,
    } = this.props

    const {
      showDeleteConfirmationModal,
      selectedPic,
    } = this.state

    if (!pics || pics.length === 0) {
      return noRowsRenderer(messages.noRows, true)
    }

    return (
      <div style={{ paddingBottom: '10px' }}>
        {showDeleteConfirmationModal &&
          <Modal
            show={showDeleteConfirmationModal}
            showContinue
            showCancel
            titleMessage={messages.deletePersonText}
            continueMessage={messages.continueDeleteConfirmation}
            cancelMessage={messages.ignoreDeleteConfirmation}
            focusDisableButton
            onClickCancel={() => {
              this.setState({ showDeleteConfirmationModal: null })
              focusElementById(`delete-person-${showDeleteConfirmationModal}`)
            }}
            onClickContinue={() => {
              deletePic(selectedPic)
              this.setState({ showDeleteConfirmationModal: null })
            }}
          >
            <Row>
              <Col xs={2}>
                <Icon
                  name={'delete'}
                  style={{ display: 'block', fontSize: '26px' }}
                />
              </Col>
              <Col xs={10}>
                <p className="lead">
                  <FormattedMessage {...messages.deletePicConfirmation} />
                </p>
              </Col>
            </Row>
          </Modal>
        }
        <AccordionContainer>
          {pics.map((row, i) => (
            <PicAccordion
              key={`1tr_${i}`}
              row={row}
              nr={i}
              selectPic={selectPic}
              deletePic={() => this.setState({
                showDeleteConfirmationModal: i + 1,
                selectedPic: row,
              })}
              getCountryByCode={this.getCountryByCode}
            />
          ))}
        </AccordionContainer>
      </div>
    )
  }
}

function PicAccordion({ nr, row, selectPic, deletePic, getCountryByCode }) {
  return (
    <Accordion
      small
      accordionId={`pic_${nr}`}
      title={
        <React.Fragment>
          <span id-qa-test={`label-person-in-charge-name-${nr}`}>
            <b>{row.personInChargeName}</b>
          </span>
          <span>{row.personInChargeCompany}</span>
        </React.Fragment>
      }
    >
      <Row style={{ padding: '0 10px' }}>
        <Col sm={4}>
          <p id-qa-test={`label-person-in-charge-address-${nr}`} className="no-margin">
            {row.personInChargeAddress}
          </p>
          <p className="no-margin">
            <span id-qa-test={`label-person-in-charge-postal-code-${nr}`}>
              {row.personInChargePostalCode}
            </span> <span id-qa-test={`label-person-in-charge-city-${nr}`}>
              {row.personInChargeCity}
            </span> <span id-qa-test={`label-person-in-charge-country-code-${nr}`}>
              {getCountryByCode(row.personInChargeCountryCode)}
            </span>
          </p>
        </Col>
        <Col sm={4}>
          <p id-qa-test={`label-person-in-charge-date-of-birth-${nr}`} className="no-margin">
            {moment(row.personInHargeDateOfBirth, 'YYYYMMDD').format('DD.MM.YYYY')}
          </p>
          <p id-qa-test={`label-person-in-charge-identification-number-${nr}`} className="no-margin">
            {row.personInChargeNationalIdentificationNumber}
          </p>
        </Col>
        <Col sm={4} componentClass={ButtonToolbar} className="text-right">
          <Button
            bsStyle="link"
            onClick={() => selectPic(row)}
            id-qa-test={`button-edit-person-${nr}`}
          >
            <FormattedMessage {...messages.editPic} /><Icon name="edit" />
          </Button>
          <Button
            bsStyle="link"
            onClick={deletePic}
            id={`delete-person-${nr + 1}`}
            id-qa-test={`button-delete-person-${nr}`}
            tabIndex={0}
          >
            <FormattedMessage {...messages.deletePic} /><Icon name="delete" />
          </Button>
        </Col>
      </Row>
    </Accordion>
  )
}
