import React from 'react'
import { sortBy, find, filter, get, indexOf, isArray, first } from 'lodash'
import { Field } from 'react-form'
import { FormControl, HelpBlock } from 'react-bootstrap'
import { defineMessages } from 'react-intl'
import moment from 'moment'
import { updateStatusMessageForInput } from 'src/utils'
import Loader from 'src/components/Loader'
import Autocomplete from 'src/components/form_v2/Autocomplete'
import AutocompleteMultiple from 'src/components/form_v2/AutocompleteMultiple'
import Select from 'src/components/form_v2/Select'
import RadioList from 'src/components/form_v2/RadioList'
import CheckboxList from 'src/components/form_v2/CheckboxList'
import ErrorMessage from 'src/components/form_v2/ErrorMessage'
import { required } from 'src/utils/validation'
import { itemToSelectOption, getFilterredCodeset } from '../utils'
import { CODESET_STRING_DELIMITER } from '../constants'

const messages = defineMessages({
  errorInvalidOption: {
    id: 'form.error.invalidOptionPlaceholder',
    description: 'Error placeholder for input without valid data',
    defaultMessage: 'Invalid option',
  },
})

class CommonCodeset extends React.Component {
  constructor(props) {
    super(props)
    this.validate = this.validate.bind(this)
    this.handleValidation = this.handleValidation.bind(this)
  }

  validate(value) {
    const { codesets, codesetObj } = this.props
    const { validation, multiple } = this.props.input

    const requiredCheck = required(value)
    if (validation.mandatory && requiredCheck) {
      if (get(validation, 'customErrorMessage')) {
        return validation.customErrorMessage
      }
      return requiredCheck
    }

    const date = moment().format('YYYY-MM-DD')
    const codesetName = codesetObj.codesetName + (codesetObj.subCodesetName ?
      `${CODESET_STRING_DELIMITER}${codesetObj.subCodesetName}` :
      '')

    if (multiple) {
      if (codesets && codesetObj && value) {
        value.map((item) => {
          if (indexOf(codesets[codesetName][date], { code: item }) < 0) {
            return messages.errorInvalidOption
          }
          return null
        })
      }
    } else if (codesets && codesetObj && value && !find(codesets[codesetName][date], { code: value })) {
      return messages.errorInvalidOption
    }

    return null
  }

  // eslint-disable-next-line consistent-return
  handleValidation(value) {
    const { input } = this.props
    const validationErrors = this.validate(value)
    if (typeof input.validation.handlerFn === 'function') {
      input.validation.handlerFn(input.name, validationErrors)
      return null
    }

    if (typeof input.validation?.returningValidator === 'function') {
      return input.validation.returningValidator(value)
    }

    if (validationErrors) {
      return validationErrors
    }
    return null
  }

  handleValidationAndSetFieldError(value) {
    const { input, formApi } = this.props
    const { name } = input
    const fieldValidationErrors = this.handleValidation(value)
    formApi.setError(name, fieldValidationErrors)
  }

  renderStatic(selectOptions, value, inputName) {
    const { input } = this.props
    let item = {}
    if (input.multiple) {
      item = filter(selectOptions, option => indexOf(value, option.value) >= 0)
      return (
        <FormControl.Static id-qa-test={`label-${input.name}`}>
          {(item && item.map(selectedItem => selectedItem.title).join(', ')) || '--'}
        </FormControl.Static>
      )
    }

    item = find(selectOptions, { id: input?.controlled ? first(input.value) : input.value || value })
    return (
      <FormControl.Static id-qa-test={`label-${inputName}`}>
        {(item && item.title) || input.defaultValue || '--'}
      </FormControl.Static>
    )
  }

  render() {
    const {
      id,
      label,
      input,
      type,
      codesets,
      codesetObj,
      fetchingCodesets,
      locale,
      optionGroups,
      groupOptionsBy,
      formApi,
      applicationDate,
    } = this.props

    let selectOptions = {}
    if (codesets) {
      const codeset = getFilterredCodeset(codesets, codesetObj, applicationDate)

      selectOptions = sortBy(codeset, item => item.code)
        .map(item => itemToSelectOption(item, locale, codesetObj.codesetExtension, id, codesetObj.onlyName))
    }
    const inputType = type
    let Component = Select
    if (inputType === 'codesetRadio') {
      Component = RadioList
    } else if (inputType === 'codesetAutocomplete') {
      if (input.multiple) {
        Component = AutocompleteMultiple
      } else {
        Component = Autocomplete
      }
    } else if (inputType === 'codesetCheckboxes') {
      Component = CheckboxList
    }

    return (
      <div>
        {fetchingCodesets[codesetObj.codesetName] && <Loader small />}
        {!fetchingCodesets[codesetObj.codesetName] &&
          <Field
            validate={input.validate && this.handleValidation}
            field={input.name}
            locale={locale}
            options={selectOptions} // Just to go around react-forms update, we dont want it to run pure
          >
            {(fieldApi) => {
              const { input: { onChange, onBlur } } = this.props
              const { value, error, setValue, setTouched } = fieldApi

              return (
                <div>
                  {(inputType === 'codesetStatic' || input.static) &&
                    this.renderStatic(selectOptions, value, input.name)
                  }
                  {(inputType !== 'codesetStatic' && !input.static) &&
                    <Component
                      label={label}
                      input={{
                        ...input,
                        value: input.value || value || '',
                        onChange: (newValue) => {
                          setValue(newValue)

                          if (onChange) {
                            onChange(newValue, null)
                          }
                        },
                        onBlur: (event) => {
                          setTouched()
                          if (input.validate) {
                            const validationValue = isArray(event) ? event : value
                            this.handleValidationAndSetFieldError(validationValue)
                          }
                          if (onBlur) {
                            onBlur(event)
                          }
                          setTimeout(() => updateStatusMessageForInput(input.name), 10)
                        },
                      }}
                      aria-describedby={this.props['aria-describedby']}
                      formApi={formApi}
                      options={selectOptions}
                      optionGroups={optionGroups}
                      groupOptionsBy={groupOptionsBy}
                      loading={fetchingCodesets[codesetObj.codesetName]}
                      labelMessage={label}
                      placeholderMessage={input.placeholder}
                      formGroupClassname={'formElementGutter'}
                    />
                  }
                  {error &&
                    <div className="has-error">
                      <HelpBlock id={`${input.name}-error`}>
                        <ErrorMessage error={error} fieldName={label} />
                      </HelpBlock>
                    </div>
                  }
                </div>
              )
            }}
          </Field>
        }
      </div>
    )
  }
}

export default CommonCodeset
