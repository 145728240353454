/**
 * Validator factory and common field validators
 * @module utils/validation
 */

import moment from 'moment'
import { padStart } from 'lodash'

import { defineMessages } from 'react-intl'
import { modulo } from 'src/utils'
import {
  PERMIT_GOODS_TO_ENJOY_RELIEF_FROM_DUTIES_CURRENCY_FIELD_NAME,
  PERMIT_GOODS_TO_ENJOY_RELIEF_FROM_DUTIES_AMOUNT_FIELD_NAME,
} from '../routes/permits/permit/components/PermitPage/constants'

/**
 * Intl message definitions for validation
 */
export const messages = defineMessages({
  required: {
    id: 'form.validation.required',
    description: 'Form validation message for empty required field',
    defaultMessage: 'Required field',
  },
  integer: {
    id: 'form.validation.integer',
    description: 'Form validation message for integer field',
    defaultMessage: 'Must be an integer',
  },
  positiveInteger: {
    id: 'form.validation.positiveInteger',
    description: 'Form validation message for positive integer field',
    defaultMessage: 'Must be a positive integer',
  },
  positiveIntegerOrEmpty: {
    id: 'form.validation.positiveIntegerOrEmpty',
    description: 'Form validation message for positive integer or empty field',
    defaultMessage: 'Must be a positive integer or empty',
  },
  nonNegativeInteger: {
    id: 'form.validation.nonNegativeInteger',
    description: 'Form validation message for non-negative integer field (natural number)',
    defaultMessage: 'Must be a non-negative integer',
  },
  nonNegativeDecimal: {
    id: 'form.validation.nonNegativeDecimal',
    description: 'Form validation message for non-negative decimal field',
    defaultMessage: 'Must be a non-negative decimal',
  },
  mustBeDecimal: {
    id: 'form.validation.mustBeDecimal',
    description: 'Form validation message for decimal field',
    defaultMessage: 'Must be an integer or a decimal number.',
  },
  invalidEmail: {
    id: 'form.validation.invalidEmail',
    description: 'Form validation message for invalid email address',
    defaultMessage: 'Invalid email address',
  },
  invalidPhone: {
    id: 'form.validation.invalidPhone',
    description: 'Form validation message for invalid phone number',
    defaultMessage: 'Invalid phone number',
  },
  maxLength: {
    id: 'form.validation.maxLength',
    description: 'Form validation message for maximun character count',
    defaultMessage: 'Must be no more than {max} characters',
  },
  minLength: {
    id: 'form.validation.minLength',
    description: 'Form validation message for minimum character count',
    defaultMessage: 'Must be at least {min} characters',
  },
  max: {
    id: 'form.validation.maxValue',
    description: 'Form validation message for maximun value',
    defaultMessage: 'Must be no greater than {max}',
  },
  min: {
    id: 'form.validation.minValue',
    description: 'Form validation message for minimum value',
    defaultMessage: 'Must be at least {min}',
  },
  regEx: {
    id: 'form.validation.regEx',
    description: 'Form validation message for failed regular expression test',
    defaultMessage: 'Value is not allowed. Allowed characters are {allowedMarkup}.',
  },
  invalidEori: {
    id: 'form.validation.invalidEori',
    description: 'Form validation message for failed regular expression test',
    defaultMessage: 'EORI number is not valid. An acceptable format is FI1234567.',
  },
  invalidWarehouseIdentifier: {
    id: 'form.validation.invalidWarehouseIdentifier',
    description: 'Form validation message for failed regular expression test',
    defaultMessage: `Warehouse identifier is not valid. An acceptable format is EORI number/
      + 6 characters FI1234567-123456.`,
  },
  unidentifiedGlobalError: {
    id: 'form.validation.unidentifiedGlobalError',
    description: 'Form validation message for unidentified validation error for entire form',
    defaultMessage: 'Unexpected validation error. Check form inputs for more specific errors.',
  },
  requiredIban: {
    id: 'form.validation.required.iban',
    description: 'Form validation message for missing required IBAN number.',
    defaultMessage: 'IBAN is required',
  },
  invalidIban: {
    id: 'form.validation.invalidIban',
    description: 'Form validation message for invalid IBAN number.',
    defaultMessage: 'Invalid IBAN',
  },
  invalidIbanNonSepa: {
    id: 'form.validation.invalidIbanNonSepa',
    description: 'Form validation message for non-SEPA IBAN number.',
    defaultMessage: 'Invalid IBAN -  Non SEPA',
  },
  requiredBic: {
    id: 'form.validation.required.bic',
    description: 'Form validation message for missing required BIC number.',
    defaultMessage: 'BIC is required',
  },
  invalidBic: {
    id: 'form.validation.invalidBic',
    description: 'Form validation message for invalid BIC number.',
    defaultMessage: 'Invalid BIC',
  },
  invalidDate: {
    id: 'form.validation.invalidDate',
    description: 'Form validation message for invalid date',
    defaultMessage: 'The date is invalid. An acceptable format is d.m.yyyy.',
  },
  parterIdMisMatch: {
    id: 'form.validation.partnerIdMisMatch',
    description: 'Form validation message for partner id mis match',
    defaultMessage: 'Parner id should be same as memberState',
  },
  invalidPartnerId: {
    id: 'form.validation.invalidPartnerId',
    description: 'Form validation message for invalid partner id',
    defaultMessage: 'Parner id is not valid',
  },
  invalidValue: {
    id: 'form.validation.invalidValue',
    description: 'Label error',
    defaultMessage: '?? Invalid value',
  },
})

// eslint-disable-next-line max-len, no-control-regex
const emailRegex = /^(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]$/

const finnishVatNumberRegex = /^FI\d{8}$/i
/**
 * Converts server-side field error code to client side i18n message format.
 *
 * Error.field value should be a property in apiMessages.
 */
const convertFieldError = (error, apiMessages) =>
  ({ [error.field || '_error']: apiMessages[error.code] || error.message || messages.unidentifiedGlobalError })

/**
 * Gets all field errors from (server-side) data in i18n format.
 */
export function getFieldErrors(data, apiMessages) {
  let formErrors
  if (data.length) {
    formErrors = data.map((item) => {
      const itemErrors = {}
      if (item && item.errors) {
        item.errors.forEach(error =>
          Object.assign(itemErrors, convertFieldError(error, apiMessages))
        )
      }
      return itemErrors
    })
    // IMPROVE: Non rows-specific handling of array results
    formErrors = { rows: formErrors }
  } else {
    formErrors = {}
    if (data && data.errors) {
      data.errors.forEach(error =>
        Object.assign(formErrors, convertFieldError(error, apiMessages))
      )
    }
    if (data.code) {
      // eslint-disable-next-line no-underscore-dangle
      formErrors._error = apiMessages[data.code] ? apiMessages[data.code] : messages.unidentifiedGlobalError
    }
  }
  return formErrors
}

const isEmpty = value => value === undefined || value === null || value === '' || value.length === 0
const join = rules =>
  (value, data) =>
    rules.map(rule => rule(value, data)).filter(Boolean)[0]

/**
 * Don't show validation field to pristine fields.
 *
 * Show state when any data related to the field has changed:
 *  - Server side warnings
 *  - Touched (blurred) fields
 *  - Dirty (user changed) fields
 *  - Autofilled fields
 */
export function showValidationState(props, requireTouched) {
  if (props.overrideValidation) {
    return false
  }
  if (!requireTouched) {
    return true
  }

  if (!props.meta) {
    return false
  }

  // Show server-side warnings only if field is pristine, server-side validation is outdated if field is changed in UI
  // (note that this is custom warning handling, not meta!)
  if (props.warning && props.meta.pristine) {
    return true
  }

  return props.meta.touched || props.meta.dirty || props.meta.autofilled || false
}

/**
 * Get validation state from Redux Form fields.
 * Always show server-side warnings. Otherwise show validation only for fields that have been touched.
 * @param {Object} props - Redux Form props
 * @return {(string|null)} validation state
 */
export function getValidationState(props, requireTouched = true) {
  if (!props.meta.touched) {
    return null
  }
  if (props.meta && showValidationState(props, requireTouched)) {
    if (props.meta.invalid) {
      // Error from sync validation or submit
      return 'error'
    }
    if (props.meta.warning) {
      // Sync validation warning
      return 'warning'
    }
    if (props.warning && props.meta.pristine) {
      // Warnings from server, not yet implemented in Redux Form meta
      return 'warning'
    }
    if (props.input.value && props.meta.valid && props.meta.touched) {
      // Even if showValidationState is true, never show valid state unless really touched
      return 'success'
    }
  }
  return null
}

export function getValidationClassName(props) {
  const validationState = getValidationState(props, !props.forceValidation)
  return validationState && `has-${validationState}`
}

/**
 * Create validator based on given rules.
 * @param {Object} rules - Validation rules, e.g. { firstName: [required, maxLength(20)] }
 * @return {function} validator for given rules
 */
export function createValidator(rules) {
  return (data = {}) => {
    const errors = {}
    Object.keys(rules).forEach((key) => {
      const rule = join([].concat(rules[key])) // concat enables both functions and arrays of functions
      const error = rule(data[key], data)
      if (error) {
        errors[key] = error
      }
    })
    return errors
  }
}


/**
 * Common validators
 */

export function email(value) {
  if (!isEmpty(value) && !emailRegex.test(value)) {
    return { ...messages.invalidEmail }
  }
  return null
}

export function isFinnishVatNumber(value) {
  return finnishVatNumberRegex.test(value)
}

export function phone(value, minLen, maxLen) {
  const phoneNumberExpression = new RegExp(`^\\+(?:[0-9] ?){${minLen},${maxLen}}[0-9]( ?)$`)
  if (!isEmpty(value) && !phoneNumberExpression.test(value)) {
    return { ...messages.invalidPhone }
  }
  return null
}

export function required(value) {
  if (isEmpty(value)) {
    return { ...messages.required }
  }
  return null
}

export function requiredConditionally(value, code = null) {
  if (isEmpty(value)) {
    if (code === PERMIT_GOODS_TO_ENJOY_RELIEF_FROM_DUTIES_AMOUNT_FIELD_NAME || code === PERMIT_GOODS_TO_ENJOY_RELIEF_FROM_DUTIES_CURRENCY_FIELD_NAME) {
      return { ...messages.required }
    }

    return { ...messages.invalidValue }
  }
  return null
}

export function minLength(minValue) {
  return (value) => {
    if (!isEmpty(value) && value.length < minValue) {
      return { ...messages.minLength, values: { min: minValue } }
    }
    return null
  }
}

export function maxLength(maxValue, options) {
  const opts = Object.assign({}, { ignoreSpaces: false }, options)

  return (value) => {
    if (isEmpty(value)) {
      return null
    }

    let length

    if (opts.ignoreSpaces) {
      length = value.replace(/\s/g, '').length
    } else {
      length = value.length
    }

    if (length > maxValue) {
      return { ...messages.maxLength, values: { max: maxValue } }
    }

    return null
  }
}

export function min(minValue) {
  return (value) => {
    if (!isEmpty(value) && value < minValue) {
      return { ...messages.min, values: { min: minValue } }
    }
    return null
  }
}

export function max(maxValue) {
  return (value) => {
    if (!isEmpty(value) && value > maxValue) {
      return { ...messages.max, values: { max: maxValue } }
    }
    return null
  }
}

export function integer(value) {
  if (!isEmpty(value) && !Number.isInteger(Number(value))) {
    return { ...messages.integer }
  }
  return null
}

export function nonNegativeInteger(value) {
  if (integer(value) !== null) {
    return { ...messages.nonNegativeInteger }
  }
  if (Number(value) < 0) {
    return { ...messages.nonNegativeInteger }
  }
  return null
}

export function positiveIntegerOrEmpty(value) {
  if (isEmpty(value)) {
    return null
  }
  return positiveInteger(value) != null ?
    { ...messages.positiveIntegerOrEmpty } : null
}

export function positiveInteger(value) {
  if (isEmpty(value)) {
    return null
  }
  if (integer(value) !== null) {
    return { ...messages.positiveInteger }
  }
  if (Number(value) < 1) {
    return { ...messages.positiveInteger }
  }
  return null
}

export function nonNegativeDecimal(maxWholeLength, maxDecimalLength) {
  const decimalExpression = new RegExp(`^\\d{1,${maxWholeLength}}(\\,\\d{0,${maxDecimalLength}})?$`)
  return (value) => {
    if (isEmpty(value) || parseFloat(value) < 0) {
      return { ...messages.nonNegativeDecimal }
    } else if (!decimalExpression.test(value)) {
      return {
        ...messages.mustBeDecimal,
        values: {
          maxWholeLength,
          maxDecimalLength,
        },
      }
    }
    return null
  }
}

export function regEx(expression, description) {
  return (value) => {
    if (!isEmpty(value) && !expression.test(value)) {
      return { ...messages.regEx, values: { allowedMarkup: description } }
    }
    return null
  }
}

export function eori(value) {
  if (!isEmpty(value) && !/^[A-Z]{2}[0-9]{7}-[0-9]{1}$/i.test(value)) {
    return { ...messages.invalidEori }
  }
  return null
}

export function warehouseIdentifier(value) {
  if (!isEmpty(value) && !/^[A-Z]{2}[0-9]{7}-[0-9]{1}[R0-9]{1}[0-9]{4}$/i.test(value)) {
    return { ...messages.invalidWarehouseIdentifier }
  }
  return null
}

export function getRequiredMessage() {
  return { ...messages.required }
}

export function iban(value, requiredField = false) {
  if (requiredField && !value) {
    return { ...messages.requiredIban }
  }

  const noSpaces = value ? value.replace(/\s/g, '') : ''
  if (/^[A-Z]{2}[0-9A-Z]{13,29}$/.test(noSpaces)) {
    const modFormat = `${noSpaces.substring(4)}${noSpaces.substring(0, 4)}`
    let integerFormat = ''
    for (const c of modFormat) {
      integerFormat += isNaN(parseInt(c, 10)) ? (c.charCodeAt(0) - 55) : c
    }
    const mod97 = modulo(integerFormat, 97)
    if (mod97 === 1) {
      return null
    }
  }
  return { ...messages.invalidIban }
}

export function sepaCountry(value) {
  // eslint-disable-next-line max-len
  const sepaPattern = /^(AT|BE|BG|CH|CY|CZ|DE|DK|EE|EI|ES|FI|FR|FO|GB|GI|GL|GR|HR|HU|IE|IS|IT|LI|LT|LU|LV|MC|MT|NL|PL|PT|RO|SE|SK|SI|SM)/i

  if (sepaPattern.test(value.toUpperCase())) {
    return null
  }
  return { ...messages.invalidIbanNonSepa }
}

export function bic(value, requiredField = false) {
  if (requiredField && !value) {
    return { ...messages.requiredBic }
  }
  const noSpaces = value ? value.replace(/\s/g, '') : ''
  if (/^[A-Z]{6}([0-9A-Z]{2}|[0-9A-Z]{5})$/.test(noSpaces)) {
    return null
  }
  return { ...messages.invalidBic }
}

export function getValidationErrors(value, input, validate) {
  const validationErrors = validate(value)
  if (typeof input.validation.handlerFn === 'function') {
    input.validation.handlerFn(input.name, validationErrors)
    return null
  }
  if (validationErrors) {
    return validationErrors
  }
  return null
}

export function validateDob(mandatory, year, month, day) {
  const allPresent = year && month && day
  const touched = year || month || day

  if (!mandatory && !touched) {
    return { dob: undefined, valid: true }
  }

  if (!allPresent) {
    return { dob: undefined, valid: false }
  }

  const dob = moment(`${year}-${padStart(month, 2, '0')}-${padStart(day, 2, '0')}`)
  return { dob, valid: dob.isValid() }
}
