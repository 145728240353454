import { handleActions } from 'redux-actions'
import { SET_SHOW_COOKIE_NOTICE } from '../constants'

export default handleActions({
  [SET_SHOW_COOKIE_NOTICE]: {
    next: (state, action) => ({
      ...state,
      showNotice: action.payload,
    }),
  },
}, {
  showNotice: true,
})
