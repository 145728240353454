import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { CustomerInformation } from 'src/components/customer'

export default function AuthorizationOrDecisionHolderReadOnly(props) {
  const {
    infoElement,
    renderErrors,
    isPerson,
    values,
    applicant,
  } = props

  return (
    <Row>
      <Col xs={12} sm={6}>
        <CustomerInformation isPerson={isPerson} infoElement={infoElement} values={values} applicant={applicant} staticValue />
        {renderErrors()}
      </Col>
    </Row>
  )
}
