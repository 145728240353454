import React from 'react'
import { get } from 'lodash'
import Table from 'src/components/Table/Table'
import ErrorBoundary from 'src/components/ErrorBoundary'
import noRowsRenderer from 'src/components/lists/noRowsRenderer'
import { formatCellValue } from '../../../formatValue'
import messages from '../messages'

export default class ItemsTable extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.tableName = `goodLocationTable-${new Date().getTime()}`
    this.state = {
      list: [],
      showDeleteApplicationModal: false,
      selectedDraftReferenceNumber: null,
      selectedDraftVersion: null,
      selectedRowId: this.props.selectedRowId,
    }
    this.onRowClick = this.onRowClick.bind(this)
    this.getCellValue = this.getCellValue.bind(this)
  }

  componentDidMount() {
    this.setRows(this.props.items || [])
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const newRows = get(nextProps, 'items', [])
    this.setRows(newRows)
  }

  onRowClick({ row }) {
    this.props.selectItem(row)
  }

  setRows(rows) {
    const nextState = {
      list: rows,
    }
    this.setState(nextState)
  }

  getCellValue(row, field) {
    const { codesets, locale } = this.props
    return formatCellValue(row, field, { codesets, locale })
  }

  render() {
    const { list } = this.state
    const { locale, fields } = this.props

    const headings = fields.map(field => ({ value: field.name[locale] }))
    const properties = fields.map(field => field.code)

    const formatters = {}

    fields.forEach((field) => {
      formatters[field.code] = row => this.getCellValue(row, field)
    })

    if (list.length === 0) {
      return noRowsRenderer(messages.noRows)
    }

    return (
      <div style={{ paddingBottom: '10px' }}>
        <ErrorBoundary>
          <Table
            headings={headings}
            contentRows={list}
            properties={properties}
            formatters={formatters}
            onRowClick={this.onRowClick}
            sortable
          />
        </ErrorBoundary>
      </div>
    )
  }
}
