import { isEqual, omit } from 'lodash'
import { reducer } from 'react-notification-system-redux'

function isNotificationEqual(a, b) {
  return a.level === b.level &&
    a.modal === b.modal &&
    a.action === b.action &&
    isEqual(a.message, b.message) &&
    isEqual(a.title, b.title)
}

/**
 * Extends default functionality by limiting maximum amount of duplicate notificates and
 * always dismissing non-unique notifications.
 */
export default function Notifications(state = [], action = {}) {
  if (action.type === 'RNS_HIDE_NOTIFICATION' || action.type === 'RNS_SHOW_NOTIFICATION') {
    if (action.type === 'RNS_SHOW_NOTIFICATION') {
      const newNotification = omit(action, 'type')
      const matchingNotifications = state.reduce((count, notification) =>
        (isNotificationEqual(notification, action) ? count + 1 : count), 0)
      if (matchingNotifications) {
        if (newNotification.modal || matchingNotifications >= 3) {
          return state
        }
        if (!newNotification.modal && !newNotification.autoDismiss) {
          newNotification.autoDismiss = 3
        }
      }
      return [
        ...state,
        newNotification,
      ]
    }
    return reducer(state, action)
  }
  return state
}
