import moment from 'moment'
import { isEmpty, has, keys, find, get } from 'lodash'
import { CODESET_STRING_DELIMITER } from 'src/components/codeset/constants'
import messages from './messages'
import { getCompanyNameFieldCode } from './components/BusinessIdInput'

export function getCellValueFormatter(options) {
  return (row, field) => formatCellValue(row, field, options)
}

export function formatCellValue(
  row,
  field,
  { codesets, locale, formatMessage, includeCountryCode = true, emptyValue = '-' }
) {
  if (!row[field.code]) {
    return emptyValue
  }
  if (codesets && locale) {
    if (field.type === 'CODESET') {
      return getCodesetValue(row, field, codesets, locale)
    }
    if (field.type === 'COUNTRY_CODE') {
      return getCodesetValue(row, field, codesets, locale, { includeCodeValue: includeCountryCode })
    }
  }
  const value = row[field.code]
  if (field.type === 'CUSTOMERREGISTRY' || field.type === 'COMPANY') {
    const customerName = row[getCompanyNameFieldCode(field)]
    return customerName || value
  }
  if (field.type === 'DATE') {
    return moment(value).format('DD.MM.YYYY')
  }
  if (formatMessage && field.type === 'BOOLEAN') {
    if (value === 'true') {
      return formatMessage(messages.radioSelectionYes)
    } else if (value === 'false') {
      return formatMessage(messages.radioSelectionNo)
    } else if (isEmpty(value)) {
      return emptyValue
    }
  }

  return value
}

function getCodesetValue(row, field, codesets, locale, { includeCodeValue = true } = {}) {
  const codesetNameCombined = field.codesetName +
    (field.subCodesetName ? `${CODESET_STRING_DELIMITER}${field.subCodesetName}` : '')
  if (has(codesets, codesetNameCombined)) {
    const key = keys(codesets[codesetNameCombined])[0]
    const codesetItem = find(codesets[codesetNameCombined][key], { code: row[field.code] })
    const codesetValue = get(codesetItem, `name.${locale}`)
    return includeCodeValue
      ? `${codesetValue} (${row[field.code]})`
      : codesetValue
  }
  return row[field.code]
}
