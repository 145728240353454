import React, { useContext, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import {
  APPLICATION_BASIS_TITLE_KEY,
  PERMIT_TEXTS_CODESET_NAME,
  RELIEF_TITLE_SUB_CODESET_NAME,
  APPLICATION_BASIS_ADDITIONAL_INFORMATION_TEMPORARY_CODE,
} from '../../constants'
import PermitPageContext from '../PermitPage/context'
import CommonFormGroup from '../../../../../components/form_v2/CommonFormGroup'
import ApplicationBasisCheckboxes from './ApplicationBasisCheckboxes'
import HeaderArea from '../../../../../components/form_v2/HeaderArea'
import { required } from '../../../../../utils/validation'
import {
  getAdditionalFieldCode,
  getApplicationBasisFieldCode,
  getCodesetReliefItems,
  getCodesetReliefItemsByUser,
  getCodesetTitleItemsByUser,
  getCodesetTitles,
  getInitialAdditionalInformation,
  getMuuValue,
  getSelectedTitleCode,
  getSubCodesetName,
  onlyOneOptionInTitle,
} from './applicationBasisHelpers'
import messages from '../../messages'

export default function ApplicationBasis({ codesets, infoElement, formApi, locale, renderField, isPerson }) {
  const fieldCode = getApplicationBasisFieldCode(infoElement)
  const additionalInformationFieldCode = getAdditionalFieldCode(infoElement)
  const subCodesetName = getSubCodesetName(infoElement)

  const { applicationDate } = useContext(PermitPageContext)
  const userTitles = getCodesetTitles(codesets)

  const reliefItems = getCodesetReliefItems(codesets, subCodesetName)
  const userItems = getCodesetReliefItemsByUser(reliefItems, isPerson)
  const selectedApplicationBasis = formApi.values?.[infoElement.code]

  const applicationBasisField = infoElement.fields.find(infoElementField => infoElementField.code === fieldCode)
  const selectedTitle = formApi.values?.[APPLICATION_BASIS_TITLE_KEY]
  const additionalInformationText = formApi.values?.[APPLICATION_BASIS_ADDITIONAL_INFORMATION_TEMPORARY_CODE]

  useEffect(() => {
    const initialTitle = getSelectedTitleCode(userItems, userTitles, selectedApplicationBasis, infoElement)
    if (initialTitle) {
      formApi.setValue(APPLICATION_BASIS_TITLE_KEY, initialTitle)
    }

    const initialAdditionalInformation = getInitialAdditionalInformation(formApi, infoElement)

    if (initialAdditionalInformation) {
      formApi.setValue(APPLICATION_BASIS_ADDITIONAL_INFORMATION_TEMPORARY_CODE, initialAdditionalInformation)
    }
  }, [])

  const onChangeOption = (value) => {
    const onlyOneOption = onlyOneOptionInTitle(userItems, value)
    const muuValue = getMuuValue(infoElement)

    if (onlyOneOption) {
      if (onlyOneOption.code === muuValue) {
        formApi.setValue(
          infoElement.code, [{ [fieldCode]: onlyOneOption.code, [additionalInformationFieldCode]: additionalInformationText }]
        )
      } else {
        formApi.setValue(
          infoElement.code, [{ [fieldCode]: onlyOneOption.code, [additionalInformationFieldCode]: null }]
        )
      }
    } else {
      formApi.setValue(infoElement.code, null)
      formApi.setError(infoElement.code, undefined)
    }
  }

  const returningValidator = (value) => {
    const requireCheck = required(value)

    if (requireCheck) {
      const customErrorMessage = messages.mandatoryApplicationBasis
      return customErrorMessage
    }

    return null
  }

  const customFilterFn = (titles, items) => () =>
    getCodesetTitleItemsByUser(titles, items)

  const titleCodesetObj = {
    basicAttribute: true,
    codesetName: PERMIT_TEXTS_CODESET_NAME,
    subCodesetName: RELIEF_TITLE_SUB_CODESET_NAME,
    onlyName: true,
    customFilterFn: customFilterFn(userTitles, userItems),
  }

  const titleInputProps = {
    type: 'codesetRadio',
    multilineLabel: true,
    name: APPLICATION_BASIS_TITLE_KEY,
    static: false,
    multiple: false,
    validate: true,
    visible: true,
    validation: {
      mandatory: false,
      returningValidator,
    },
    onChange: onChangeOption,
    value: selectedTitle,
  }

  return (
    <Col xs={12}>
      <Row>
        <Col xs={12}>
          <HeaderArea
            title={infoElement.name[locale]}
            help={infoElement.help?.[locale]}
            mandatory
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <CommonFormGroup
            label={infoElement.name[locale]}
            id={applicationBasisField.code}
            formApi={formApi}
            applicationDate={applicationDate}
            ariaDescribedBy={`${infoElement.code}`}
            codesetObj={titleCodesetObj}
            input={titleInputProps}
          />
          {selectedTitle &&
            <ApplicationBasisCheckboxes
              applicationDate={applicationDate}
              field={applicationBasisField}
              formApi={formApi}
              codesets={codesets}
              selectedApplicationBasisTitle={selectedTitle}
              infoElement={infoElement}
              selectedApplicationBasis={selectedApplicationBasis}
              renderField={renderField}
              isPerson={isPerson}
              items={userItems}
              additionalInformationText={additionalInformationText}
            />}
        </Col>
      </Row>
    </Col>
  )
}
