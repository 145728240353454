/**
 * @file React Intl translation messages for Intrastat rows page
 * @see {@link https://github.com/yahoo/react-intl}
 * @author Sami Tiilikainen
 */

import { defineMessages } from 'react-intl'

const messages = defineMessages({
  noRows: {
    id: 'intrastat.rows.noRows',
    description: 'Information message for empty rows list',
    defaultMessage: 'No rows',
  },
  memberStateInputLabel: {
    id: 'intrastat.memberState.label',
    description: 'Label for intrastat sender/destination member state of consignment (MSConsDestCode)',
    defaultMessage: 'Member state',
  },
  memberStateInputPlaceholder: {
    id: 'intrastat.memberState.placeholder',
    description: 'Input placeholder for intrastat sender/destination member state of consignment (MSConsDestCode)',
    defaultMessage: 'Type member state of consignment...',
  },
  arrivalMemberStateInputLabel: {
    id: 'intrastat.memberState.arrival.label',
    description: 'Label for intrastat sender member state in arrival of goods (MSConsDestCode)',
    defaultMessage: 'Country of expedition',
  },
  arrivalMemberStateInputPlaceholder: {
    id: 'intrastat.memberState.arrival.placeholder',
    description: 'Input placeholder for intrastat sender member state in arrival of goods (MSConsDestCode)',
    defaultMessage: 'Select sender country...',
  },
  dispatchMemberStateInputLabel: {
    id: 'intrastat.memberState.dispatch.label',
    description: 'Label for intrastat destination member state in dispatch of goods (MSConsDestCode)',
    defaultMessage: 'Country of destination',
  },
  dispatchMemberStateInputPlaceholder: {
    id: 'intrastat.memberState.dispatch.placeholder',
    description: 'Input placeholder for intrastat destination member state in dispatch of goods (MSConsDestCode)',
    defaultMessage: 'Select destination country...',
  },
  partnerIdInputLabel: {
    id: 'intrastat.partnerId.label',
    description: 'Label for intrastat partner company VAT',
    defaultMessage: 'Partner',
  },
  partnerIdInputPlaceholder: {
    id: 'intrastat.partnerId.placeholder',
    description: 'Input placeholder for intrastat partner company VAT',
    defaultMessage: 'Type VAT for partner company...',
  },
  countryOfOriginInputLabel: {
    id: 'intrastat.originCountry.label',
    description: 'Label for country of origin',
    defaultMessage: 'Country of origin',
  },
  countryOfOriginInputPlaceholder: {
    id: 'intrastat.countryOfOrigin.placeholder',
    description: 'Input placeholder for country of origin field',
    defaultMessage: 'Type country of origin...',
  },
  CN8CodeInputLabel: {
    id: 'intrastat.cn8Code.label',
    description: 'Label for CN8 code',
    defaultMessage: 'CN8 code',
  },
  CN8CodeInputPlaceholder: {
    id: 'intrastat.cn8Code.placeholder',
    description: 'Label for CN8 code placeholder',
    defaultMessage: 'Type CN8 code...',
  },
  CN8BrowseButtonLabel: {
    id: 'intrastat.cn8Browse.label',
    description: 'Label for CN8 browse button',
    defaultMessage: 'Browse...',
  },
  CN8BrowseButtonDescription: {
    id: 'intrastat.cn8Browse.description',
    description: 'Description for CN8 browse button',
    defaultMessage: 'Browse CN8 codes',
  },
  CN8SearchButtonLabel: {
    id: 'intrastat.cn8Search.label',
    description: 'Label for CN8 search button',
    defaultMessage: 'Search...',
  },
  CN8LoadingLabel: {
    id: 'intrastat.rows.cn8Loading',
    description: 'Label for cn8 loading indication',
    defaultMessage: 'Loading...',
  },
  natureOfTransactionCodeInputLabel: {
    id: 'intrastat.natureOfTransaction.label',
    description: 'Label for intrastat nature of transaction',
    defaultMessage: 'Nature of transaction',
  },
  natureOfTransactionCodeInputPlaceholder: {
    id: 'intrastat.natureOfTransaction.placeholder',
    description: 'Input placeholder for empty intrastat nature of transaction field',
    defaultMessage: 'Select nature of transaction...',
  },
  modeOfTransportInputLabel: {
    id: 'intrastat.modeOfTransport.label',
    description: 'Label for means of mode of transport',
    defaultMessage: 'Transport',
  },
  modeOfTransportInputPlaceholder: {
    id: 'intrastat.transport.placeholder',
    description: 'Input placeholder for empty mode of transport field',
    defaultMessage: 'Select mode of transport...',
  },
  netMassInputLabel: {
    id: 'intrastat.netMass.label',
    description: 'Label for net mass',
    defaultMessage: 'Net mass',
  },
  netMassInputPlaceholder: {
    id: 'intrastat.netMass.placeholder',
    description: 'Input placeholder for empty net mass field',
    defaultMessage: 'Type net mass (kg)...',
  },
  next: {
    id: 'intrastat.rowForm.nextRow',
    description: 'Row form next row button text',
    defaultMessage: 'Next',
  },
  previous: {
    id: 'intrastat.rowForm.prevRow',
    description: 'Row form previous row button text',
    defaultMessage: 'Previous',
  },
  quantityInSUInputLabel: {
    id: 'intrastat.quantityInSU.label',
    description: 'Label for Intrastat quantity in supplementary units',
    defaultMessage: 'Quantity in supplementary units',
  },
  quantityInSUInputPlaceholder: {
    id: 'intrastat.quantityInSU.placeholder',
    description: 'Input placeholder for empty Intrastat quantity in supplementary units field',
    defaultMessage: 'Type quantity in supplementary units...',
  },
  statisticalValueInputLabel: {
    id: 'intrastat.statisticalValue.label',
    description: 'Label for statistical value',
    defaultMessage: 'Statistical value',
  },
  statisticalValueCurrencyInputLabel: {
    id: 'intrastat.statisticalValueCurrency.label',
    description: 'Label for statistical value currency',
    defaultMessage: 'Statistical value currency',
  },
  statisticalValueInputPlaceholder: {
    id: 'intrastat.statisticalValue.placeholder',
    description: 'Placeholder for statistical value',
    defaultMessage: 'Type statistical value...',
  },
  statisticalValueCurrencyCodeInputPlaceholder: {
    id: 'intrastat.statisticalValueCurrency.placeholder',
    description: 'Placeholder for statistical value currency',
    defaultMessage: 'Currency...',
  },
  statisticalValueInEurLabel: {
    id: 'intrastat.statisticalValueInEur.label',
    description: 'Label for Intrastat statistical value in eur',
    defaultMessage: 'Statistical value in EUR',
  },
  invoicedAmountInputLabel: {
    id: 'intrastat.invoicedAmount.label',
    description: 'Label for invoiced amount/value',
    defaultMessage: 'Invoiced amount',
  },
  invoicedAmountCurrencyInputLabel: {
    id: 'intrastat.invoicedAmountCurrency.label',
    description: 'Label for invoiced amount/value currency',
    defaultMessage: 'Invoiced amount currency',
  },
  invoicedAmountInputPlaceholder: {
    id: 'intrastat.invoicedAmount.placeholder',
    description: 'Placeholder for invoiced amount/value',
    defaultMessage: 'Type invoiced amount...',
  },
  invoicedValueCurrencyCodeInputPlaceholder: {
    id: 'intrastat.invoicedValueCurrency.placeholder',
    description: 'Placeholder for invoiced value currency',
    defaultMessage: 'Currency...',
  },
  invoicedAmountInEurLabel: {
    id: 'intrastat.invoicedAmountInEur.label',
    description: 'Label for Intrastat invoiced amount in eur',
    defaultMessage: 'Invoiced amount in EUR',
  },
  popularCurrenciesGroupLabel: {
    id: 'intrastat.popularCurrenciesGroupLabel',
    description: 'Option group label for "popular" currencies',
    defaultMessage: 'Popular',
  },
  otherCurrenciesGroupLabel: {
    id: 'intrastat.otherCurrenciesGroupLabel',
    description: 'Option group label for other currencies',
    defaultMessage: 'Other',
  },
  addRow: {
    id: 'intrastat.rows.add',
    description: 'Label for adding row',
    defaultMessage: 'Add row',
  },
  saveRow: {
    id: 'intrastat.rows.save',
    description: 'Label for saving row',
    defaultMessage: 'Save row',
  },
  saveRowAndCreateAnother: {
    id: 'intrastat.rows.saveAndCreateAnother',
    description: 'Label for saving row and creating another row',
    defaultMessage: 'Save and create next row',
  },
  cancelRow: {
    id: 'intrastat.rows.cancel',
    description: 'Label for cancelling row editing',
    defaultMessage: 'Cancel',
  },
  deleteRow: {
    id: 'intrastat.rows.delete',
    description: 'Label for deleting row',
    defaultMessage: 'Delete',
  },
  removeRow: {
    id: 'intrastat.rows.remove',
    description: 'Label for removing row',
    defaultMessage: 'Remove',
  },
  invalidRowsFilterToggle: {
    id: 'intrastat.rows.invalidRowsFilter',
    description: 'Label for filtering only invalid intrastat rows',
    defaultMessage: 'Only rows with errors',
  },
  rowIdLabel: {
    id: 'intrastat.rows.id',
    description: 'Label for row id',
    defaultMessage: 'Row id',
  },
  newRowHeading: {
    id: 'intrastat.rows.newRow.heading',
    description: 'Heading for new row form',
    defaultMessage: 'New row information',
  },
  rowEditHeading: {
    id: 'intrastat.rows.rowEdit.heading',
    description: 'Heading for row edit form',
    defaultMessage: 'Editing row {rowNumber}',
  },
  invoiceNumberInputLabel: {
    id: 'intrastat.rows.invoiceNumber.label',
    description: 'Label for intrastat row invoice number',
    defaultMessage: 'Invoice number',
  },
  invoiceNumberInputPlaceholder: {
    id: 'intrastat.rows.invoiceNumber.placeholder',
    description: 'Placeholder for intrastat row invoice number',
    defaultMessage: 'Type invoice number...',
  },
  selectPlaceholder: {
    id: 'intrastat.select.placeholder',
    description: 'Placeholder for select field empty value',
    defaultMessage: 'Please choose...',
  },
  noDeclarationRowsLabel: {
    id: 'intrastat.rows.noDeclarationRows.label',
    description: 'Label for no declaration rows -checkbox (zero declaration) on rows view',
    defaultMessage: 'No declaration rows',
  },
  errorNilDeclarationHasRows: {
    id: 'intrastat.error.rows.nilDeclarationHasRows',
    description: 'A declaration marked as "no rows" contains rows',
    defaultMessage: 'Declaration marked as "no rows" contains rows.',
  },
  errorsInRows: {
    id: 'intrastat.error.rows',
    description: 'Global error notification when there are errors in declaration rows',
    defaultMessage: 'There are errors on rows',
  },
  referencePeriodBeforeRows: {
    id: 'intrastat.referencePeriodBeforeRows',
    description: 'Warning shown on intrastat rows view, when reference period is not set',
    defaultMessage: 'Reference period must be set before handling rows',
  },
  optional: {
    id: 'intrastat.optionalField',
    description: 'Info text show on optional field',
    defaultMessage: 'Not mandatory',
  },
  invalidCN8Code: {
    id: 'intrastat.invalidCN8Code',
    description: 'Error message shown when provided CN8 code on intrastat row form is invalid',
    defaultMessage: 'Invalid CN8 Code',
  },
  errorDeletingRow: {
    id: 'intrastat.rows.deleteError',
    description: 'Error message for intrastat row deletion failure',
    defaultMessage: 'Could not delete row',
  },
  doneSavingRow: {
    id: 'intrastat.rows.doneSavingRow',
    description: 'Notification message shown after saving row',
    defaultMessage: 'Row was saved successfully',
  },
  missingInformation: {
    id: 'intrastat.rows.missingInformation',
    description: 'Notification message shown when missing information',
    defaultMessage: 'Missing information. Cannot save the row.',
  },
  abbreviationKilograms: {
    id: 'intrastat.rows.abbreviationKilograms',
    description: 'Abbrebviation for intrastat net mass unit kilograms',
    defaultMessage: 'kg',
  },
  rowSelectDiscardChangesConfirmation: {
    id: 'intrastat.rows.rowSelectDiscardChangesConfirmation',
    description: 'Confirmation for discarding unsaved changes when user is switching rows in form',
    defaultMessage: 'Current row has unsaved changes. Do you want to continue without saving changes?',
  },
})

export default messages
