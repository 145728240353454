import { defineMessages } from 'react-intl'

export default defineMessages({
  title: {
    id: 'auth.logoutTitle',
    description: 'Title for logout page',
    defaultMessage: 'Logout',
  },
  loggedOut: {
    id: 'auth.loggedOut',
    description: 'Status text for unauthenticated user in logout',
    defaultMessage: 'You are logged out.',
  },
  logoutRequested: {
    id: 'auth.loggingOut',
    description: 'Status text for ongoing logout',
    defaultMessage: 'Logging out...',
  },
  logoutFailed: {
    id: 'auth.logoutFailure',
    description: 'Status text for failed logout',
    defaultMessage: 'Could not log out.',
  },
})
