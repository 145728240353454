import React from 'react'
import { defineMessages, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { collectCmsMessages } from 'src/utils'
import { get } from 'lodash'
import ReactMarkdown from 'react-markdown'
import IconRenderer from 'src/components/IconRenderer/'
import LinkRenderer from 'src/components/LinkRenderer/'
import { Form } from 'react-form'
import CommonFormGroup from 'src/components/form_v2/CommonFormGroup'

import Modal from './Modal'
import styles from './suomiFiAuthorizationTypeSelection.scss'
import { focusFirstElementByIdOrName, setPageTitle } from '../utils/index'
import { PATH_TO_TITLE_MAP, SUOMI_FI_AUTHORIZATION_QUERY_PARAM } from '../constants'

// Header-related intl message definitions
const messages = defineMessages({
  title: {
    id: 'suomiFiAuthorizationTypeSelection.title',
    description: 'Title for role selection modal',
    defaultMessage: 'Authorization type selection',
  },
  continue: {
    id: 'roleSelector.continue',
    description: 'Button value for role selection operation "continue"',
    defaultMessage: 'Continue',
  },
  cancel: {
    id: 'roleSelector.cancel',
    description: 'Button value for role selector cancel operation (resulting in logout)',
    defaultMessage: 'Logout',
  },
  hpa: {
    id: 'suomiFiAuthorizationTypeSelection.hpa',
    description: 'Value for acting on behalf of some company',
    defaultMessage: 'I act on behalf of some company',
  },
  ipa: {
    id: 'suomiFiAuthorizationTypeSelection.ipa',
    description: 'Value for acting on behalf of some company',
    defaultMessage: 'I act on behalf of some company',
  },
  ypa: {
    id: 'suomiFiAuthorizationTypeSelection.ypa',
    description: 'Value for acting on behalf of some company',
    defaultMessage: 'I act on behalf of some company',
  },
})

class SuomiFiAuthorizationTypeSelector extends React.Component {
  constructor(props) {
    super(props)
    this.formApi = null
  }

  componentDidMount() {
    const { locale } = this.props
    setPageTitle(PATH_TO_TITLE_MAP, SUOMI_FI_AUTHORIZATION_QUERY_PARAM, locale)
  }

  componentDidUpdate(prevProps) {
    const { locale } = this.props
    if (locale !== prevProps.locale) {
      setPageTitle(PATH_TO_TITLE_MAP, SUOMI_FI_AUTHORIZATION_QUERY_PARAM, locale)
    }
  }

  onClick = () => {
    this.formApi.submitForm().then(() => {
      const errors = get(this.formApi.getFormState(), 'errors', {})

      if (errors) {
        focusFirstElementByIdOrName(Object.keys(errors))
      }
    })
  }

  connectFormApi = (formApi) => {
    this.formApi = formApi
  }


  render() {
    const {
      authorizationTypes,
      onSelect,
      onRejected,
      locale,
      onSwitchLocale,
      children,
      content: {
        suomiFiAuthorizationTypeSelectorBodyText,
        suomiFiAuthorizationTypeSelectorBottomText,
        suomiFiAuthorizationTypeSelectorHelpText,
      },
      intl: {
        formatMessage,
      },
    } = this.props
    return (
      <div>
        <Modal
          show
          titleMessage={messages.title}
          cancelMessage={messages.cancel}
          showCancel
          onClickCancel={onRejected}
          continueMessage={messages.continue}
          onClickContinue={this.onClick}
          showContinue
          locale={locale}
          onSwitchLocale={onSwitchLocale}
          bodyStyle={{ paddingTop: '25px' }}
          disableEscKey
          focusLocaleSwitch
        >
          <div className={styles.roleList}>
            <Form
              getApi={this.connectFormApi}
              onSubmit={() => {
                const formState = this.formApi.getFormState()
                const authorizationType = get(formState, 'values.authorizationType')
                onSelect(authorizationType)
              }}
              render={formApi => (
                <form>
                  <CommonFormGroup
                    errors={formApi.errors}
                    formApi={formApi}
                    formGroupClassname="formElementGutter"
                    formName="SuomiFiAuthorizationTypeSelector"
                    input={{
                      help: { content: suomiFiAuthorizationTypeSelectorHelpText },
                      name: 'authorizationType',
                      static: false,
                      type: 'radioListWithField',
                      validate: true,
                      validation: {
                        mandatory: true,
                      },
                      visible: true,
                      multilineLabel: true,
                    }}
                    label={suomiFiAuthorizationTypeSelectorBodyText}
                    options={authorizationTypes.map(authorizationType => ({
                      title: formatMessage(messages[authorizationType]),
                      value: authorizationType,
                    }))}
                    showLabel
                    type="BOOLEAN"
                  />
                </form>
              )}
            />
          </div>
          <ReactMarkdown
            source={suomiFiAuthorizationTypeSelectorBottomText}
            renderers={{ Image: IconRenderer, Link: LinkRenderer }}
          />
        </Modal>
        <div id="dialog-message">
          {children}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const cmsMessages = get(state, 'content.cmsMessages', {})
  const authenticationContent = collectCmsMessages('/authentication', cmsMessages)

  return {
    content: authenticationContent,
    locale: state.locale,
  }
}

export default connect(mapStateToProps)(injectIntl(SuomiFiAuthorizationTypeSelector))
