import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { find, endsWith, xor, get, indexOf, intersection, includes, isArray, isEmpty } from 'lodash'
import InfoElementHeaderArea from 'src/components/form_v2/InfoElementHeaderArea'
import {
  PERMIT_DEBT_GUARANTEE_PROCEDURE_TYPES,
  PERMIT_LIABILITY_GUARANTEE_PROCEDURE_TYPES,
  PERMIT_GUARANTEE_PROCEDURE_TYPE_ALL_VISIBLE,
  PERMIT_DEBT_GUARANTEE_PROCEDURE_TYPE_SELECTION_VISIBLE,
  PERMIT_LIABILITY_GUARANTEE_PROCEDURE_TYPE_SELECTION_VISIBLE,
} from '../constants'
import messages from '../messages'

export default function CustomsProcedureType(props) {
  const { infoElement, locale, formApi, renderField } = props
  const customsProcedureTypeField = find(infoElement.fields, field => endsWith(field.code, 'customsProcedureType'))

  const applicationTypeCode = get(formApi, 'values.applicationTypeCode')

  const fieldProps = {
    input: {
      multiple: true,
      type: 'codesetAutocomplete',
    },
  }

  const otherFields = xor(infoElement.fields, [
    customsProcedureTypeField,
  ])

  const debtGuaranteeValues = get(formApi.values, 'customsProcedureType.debtGuaranteeProcedureType')
  const liabilityGuaranteeValues = get(formApi.values, 'customsProcedureType.liabilityGuaranteeProcedureType')
  const hasDebtGuaranteeValues = isArray(debtGuaranteeValues) && !isEmpty(debtGuaranteeValues)
  const hasLiabilityGuaranteeValues = isArray(liabilityGuaranteeValues) && !isEmpty(liabilityGuaranteeValues)
  const debtGuaranteeFieldMandatory = !hasLiabilityGuaranteeValues || hasDebtGuaranteeValues
  const liabilityGuaranteeFieldMandatory = !hasDebtGuaranteeValues || hasLiabilityGuaranteeValues

  const debtGuaranteeProcedureTypeField = {
    ...customsProcedureTypeField,
    code: 'customsProcedureType.debtGuaranteeProcedureType',
    codesetValidValues: PERMIT_DEBT_GUARANTEE_PROCEDURE_TYPES,
    mandatory: debtGuaranteeFieldMandatory,
    name: {
      en: 'Debt guarantee procedures',
      fi: 'Velkavakuusmenettelyt',
      sv: 'Skuldgarantiförfarande',
    },
  }

  const liabilityGuaranteeProcedureTypeField = {
    ...customsProcedureTypeField,
    code: 'customsProcedureType.liabilityGuaranteeProcedureType',
    codesetValidValues: PERMIT_LIABILITY_GUARANTEE_PROCEDURE_TYPES,
    mandatory: liabilityGuaranteeFieldMandatory,
    name: {
      en: 'Liability guarantee procedures',
      fi: 'Vastuuvakuusmenettelyt',
      sv: 'Ansvarsgarantiförfarande',
    },
  }

  if (!debtGuaranteeFieldMandatory &&
    get(formApi, 'errors.customsProcedureType.debtGuaranteeProcedureType')) {
    formApi.setError('customsProcedureType.debtGuaranteeProcedureType', null)
  }
  if (!liabilityGuaranteeFieldMandatory &&
    get(formApi, 'errors.customsProcedureType.liabilityGuaranteeProcedureType')) {
    formApi.setError('customsProcedureType.liabilityGuaranteeProcedureType', null)
  }

  function isDebtOrLiability(code) {
    return indexOf(
      xor(PERMIT_DEBT_GUARANTEE_PROCEDURE_TYPE_SELECTION_VISIBLE,
        PERMIT_LIABILITY_GUARANTEE_PROCEDURE_TYPE_SELECTION_VISIBLE),
      code) >= 0
  }

  function isDebtAndLiability(code) {
    return indexOf(
      intersection(PERMIT_DEBT_GUARANTEE_PROCEDURE_TYPE_SELECTION_VISIBLE,
        PERMIT_LIABILITY_GUARANTEE_PROCEDURE_TYPE_SELECTION_VISIBLE),
      code) >= 0
  }

  if (includes(PERMIT_GUARANTEE_PROCEDURE_TYPE_ALL_VISIBLE, applicationTypeCode)) {
    return (
      <Col sm={6} xs={12}>
        <Row>
          <Col xs={12}>
            <InfoElementHeaderArea
              infoElement={infoElement}
              locale={locale}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            {renderField({
              field: customsProcedureTypeField,
              ...fieldProps,
            })}
          </Col>
        </Row>
      </Col>
    )
  }

  return (
    <Col sm={6} xs={12}>
      <Row>
        <Col xs={12}>
          <InfoElementHeaderArea
            infoElement={infoElement}
            locale={locale}
          />
          {isDebtOrLiability(applicationTypeCode) &&
            <FormattedMessage {...messages.customsProcedureTypeInfoTextShort} />
          }

          {isDebtAndLiability(applicationTypeCode) &&
            <FormattedMessage {...messages.customsProcedureTypeInfoTextLong} />
          }
          <br /><br />
        </Col>
      </Row>
      <Row>
        {indexOf(PERMIT_DEBT_GUARANTEE_PROCEDURE_TYPE_SELECTION_VISIBLE, applicationTypeCode) >= 0 && <Col
          xs={12}
        >
          {debtGuaranteeProcedureTypeField && renderField({
            field: debtGuaranteeProcedureTypeField,
            ...fieldProps,
          })}
        </Col>}
        {indexOf(PERMIT_LIABILITY_GUARANTEE_PROCEDURE_TYPE_SELECTION_VISIBLE, applicationTypeCode) >= 0 && <Col
          xs={12}
        >
          {liabilityGuaranteeProcedureTypeField && renderField({
            field: liabilityGuaranteeProcedureTypeField,
            ...fieldProps,
          })}
        </Col>}
        <Col xs={12}>
          {otherFields && otherFields.map(field => renderField({
            field,
            ...fieldProps,
          }))}
        </Col>
      </Row>
    </Col>
  )
}
