import React from 'react'
import { Col, Button, Row, ButtonToolbar } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { Form } from 'react-form'
import debounce from 'lodash.debounce'
import { has, find, get, includes, isEmpty, isFinite } from 'lodash'
import Loader from 'src/components/Loader'
import InfoElementHeaderArea from 'src/components/form_v2/InfoElementHeaderArea'
import ErrorBoundary from 'src/components/ErrorBoundary'
import Icon from 'src/components/Icon'
import Modal from 'src/components/Modal'
import showNotification from 'src/utils/notifications'
import { focusFirstInputElementWithError } from 'src/utils'
import { messages as validationMessages } from 'src/utils/validation'
import Currency from '../../Currency'
import messages from '../messages'
import permitMessages from '../../../messages'
import {
  fetchTaricDescription,
  checkFormValues,
  setTaricCodeError,
  setTaricCodeDescription,
  isTaricCodeFieldMandatory,
  isTaricCodeValid,
} from '../utils'
import {
  USE_EQUIVALENT_GOOD_FIELD_NAME,
} from '../constants'
import AccessibleMessage from '../../../../../../components/AccessibleMessage'
import {
  PERMIT_GOODS_TO_ENJOY_RELIEF_FROM_DUTIES_ADDITIONAL_INFORMATION_FIELD_NAME,
  PERMIT_GOODS_TO_ENJOY_RELIEF_FROM_DUTIES_AMOUNT_FIELD_NAME,
  PERMIT_GOODS_TO_ENJOY_RELIEF_FROM_DUTIES_COUNTRY_FIELD_NAME,
  PERMIT_GOODS_TO_ENJOY_RELIEF_FROM_DUTIES_CURRENCY_FIELD_NAME,
  PERMIT_GOODS_TO_ENJOY_RELIEF_FROM_DUTIES_DESCRIPTION_FIELD_NAME,
  PERMIT_GOODS_TO_ENJOY_RELIEF_FROM_DUTIES_FIELD_PREFIX,
  PERMIT_GOODS_TO_ENJOY_RELIEF_FROM_DUTIES_MEASUREMENT_UNIT_FIELD_NAME,
  PERMIT_GOODS_TO_ENJOY_RELIEF_FROM_DUTIES_QUANTITY_FIELD_NAME,
} from '../../PermitPage/constants'
import HeaderArea from '../../../../../../components/form_v2/HeaderArea'

const MODE_SAVE_AND_ADD_NEW = 'saveAndAddNew'

const defaultState = {
  isNew: null,
  isFetchingDescription: false,
  showDeleteConfirmationModal: false,
  useFields: null,
}
export default class ItemForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = defaultState
    this.formApi = null

    this.fieldPrefix = props.infoElement.fieldPrefix || props.infoElement.code

    if (this.fieldPrefix === PERMIT_GOODS_TO_ENJOY_RELIEF_FROM_DUTIES_FIELD_PREFIX) {
      this.taricFieldCode = props.codeFieldName
    } else {
      this.taricFieldCode = `${this.fieldPrefix}${props.codeFieldName}`
    }
    this.save = this.save.bind(this)
    this.cancel = this.cancel.bind(this)
    this.showDeleteModal = this.showDeleteModal.bind(this)
    this.setErrors = this.setErrors.bind(this)
    this.connectFormApi = this.connectFormApi.bind(this)
    this.searchDescriptionByTaricCode = this.searchDescriptionByTaricCode.bind(this)
    this.clearTaricCodeDescription = this.clearTaricCodeDescription.bind(this)
    this.onTaricCodeChange = this.onTaricCodeChange.bind(this)
    this.isReliefFromDuties = this.fieldPrefix === PERMIT_GOODS_TO_ENJOY_RELIEF_FROM_DUTIES_FIELD_PREFIX
  }

  componentDidMount() {
    const { selectedItem } = this.props
    if (selectedItem) {
      this.searchDescriptionByTaricCode(
        selectedItem[this.taricFieldCode]
      )
    }
  }

  componentDidUpdate(prevProps) {
    const { selectedItem, infoElement } = this.props

    if (selectedItem && (selectedItem !== prevProps.selectedItem)) {
      if (!isEmpty(get(selectedItem, 'errors'))) {
        this.setErrors(get(selectedItem, 'errors'))
      }
      if (!has(selectedItem, `errors.${this.taricFieldCode}`)) {
        if (has(selectedItem, this.taricFieldCode) &&
          !isEmpty(selectedItem[this.taricFieldCode])) {
          this.searchDescriptionByTaricCode(
            selectedItem[this.taricFieldCode]
          )
        }
      }
    }

    if (get(this.props, 'combined') && infoElement.code === 'equivalentGood') {
      const submits = get(this, 'formApi.submits', 0)
      const prevSubmits = get(prevProps, 'formApi.submits', 0)
      if (submits > prevSubmits) {
        this.validateValues()
      }

      const useEquivalentGoodValue = get(this.props, `formApi.values.${USE_EQUIVALENT_GOOD_FIELD_NAME}`)
      const prevUseEquivalentGoodValue = get(prevProps, `formApi.values.${USE_EQUIVALENT_GOOD_FIELD_NAME}`)
      if (useEquivalentGoodValue !== prevUseEquivalentGoodValue) {
        this.validateValues()
      }
    }
  }

  onTaricCodeChange(value) {
    this.debounceSearchDescriptionByTaricCode(value)
  }

  setErrors(errors) {
    if (this.formApi) {
      setTimeout(() => {
        const currentFormState = this.formApi.getFormState()
        this.formApi.setFormState({
          ...currentFormState,
          errors: {
            ...get(currentFormState, 'errors'),
            ...errors,
          },
        })
      }, 50)
    } else {
      setTimeout(() => {
        this.setErrors(errors)
      }, 100)
    }
  }

  getFormValues() {
    const formState = this.formApi && this.formApi.getFormState()
    return get(formState, 'values')
  }

  debounceSearchDescriptionByTaricCode = debounce(taricCode => this.searchDescriptionByTaricCode(taricCode), 300)

  validateValues() {
    const { formApi } = this.props
    const formState = formApi.getFormState()
    const hasEquivalentGoodUsageSelected = get(formState.values, USE_EQUIVALENT_GOOD_FIELD_NAME)
    if (!hasEquivalentGoodUsageSelected) {
      formApi.setError(USE_EQUIVALENT_GOOD_FIELD_NAME, validationMessages.required)
    } else {
      formApi.setError(USE_EQUIVALENT_GOOD_FIELD_NAME, null)
    }
  }

  searchDescriptionByTaricCode(taricCode) {
    const { locale, infoElement } = this.props
    if (isFinite(parseInt(taricCode, 10))) {
      if (isTaricCodeValid(taricCode)) {
        setTaricCodeError(this.formApi, this.taricFieldCode, null)
        this.setState({
          isFetchingDescription: true,
        })
        fetchTaricDescription(taricCode, locale)
          .then((response) => {
            if (!response) {
              setTaricCodeError(this.formApi, this.taricFieldCode, messages.goodNotFound)
            } else {
              setTaricCodeError(this.formApi, this.taricFieldCode, null)
            }
            this.setState({
              isFetchingDescription: false,
            })

            setTaricCodeDescription(this.formApi, this.taricFieldCode, response)
            return true
          })
      } else {
        setTaricCodeError(this.formApi, this.taricFieldCode, messages.taricCodeNotValid)
        this.clearTaricCodeDescription()
      }
    } else if (isTaricCodeFieldMandatory(infoElement, this.taricFieldCode)) {
      setTaricCodeError(this.formApi, this.taricFieldCode, messages.taricCodeNotValid)
      this.clearTaricCodeDescription()
    } else if (!taricCode) {
      this.clearTaricCodeDescription()
    }
    return true
  }

  connectFormApi(formApi) {
    this.formApi = formApi
  }

  clearTaricCodeDescription() {
    setTaricCodeDescription(this.formApi, this.taricFieldCode, null)
  }

  clearFormApi() {
    this.formApi.setFormState({})
  }

  cancel(mode) {
    if (mode === MODE_SAVE_AND_ADD_NEW) {
      this.clearFields()
      const taricCodeElem = document.getElementById(`${this.taricFieldCode}`)
      taricCodeElem.focus()
    } else {
      if (this.state.isNew) {
        this.setState({ isNew: false })
      } else {
        this.props.cancelEditing()
      }
      this.clearFormApi()
    }
  }

  clearFields() {
    this.formApi.setAllValues({})
  }

  // Needs refactoring, similar logic in multiple locations
  // eslint-disable-next-line consistent-return
  save(mode) {
    const { addNew, updateItem, selectedItem, infoElement } = this.props
    const { isNew } = this.state

    const formValues = this.getFormValues()
    let hasErrors = false

    if (get(formValues, `${this.taricFieldCode}`, '') !== '') {
      this.formApi.setValue(
        this.taricFieldCode,
        formValues[this.taricFieldCode])
    }
    const taricDescription = get(formValues, `${this.taricFieldCode}Description`, null)

    const formValuesAreValid = checkFormValues(
      this.formApi,
      infoElement,
      taricDescription,
      this.taricFieldCode,
    )

    if (!formValuesAreValid) {
      hasErrors = true
    }

    if (!hasErrors) {
      const newFormData = {
        ...get(this.formApi.getFormState(), 'values'),
        errors: {},
      }
      if (isNew) {
        addNew(newFormData)
        this.afterDoneSaving(mode)
      } else if (selectedItem) {
        updateItem(selectedItem, newFormData)
        this.afterDoneSaving(mode)
      }
    } else {
      setTimeout(focusFirstInputElementWithError, 10)
    }
  }

  afterDoneSaving(mode) {
    this.cancel(mode)
    showNotification({
      level: 'success',
      message: messages.doneSavingItem,
      autoDismiss: true,
    })
  }

  showDeleteModal() {
    this.setState({
      showDeleteConfirmationModal: true,
    })
  }

  shouldHideFields() {
    const { combined, formApi, infoElement } = this.props
    if (combined && infoElement.code === 'equivalentGood') {
      const formState = formApi.getFormState()
      const useEquivalentGoodFields = get(formState.values, USE_EQUIVALENT_GOOD_FIELD_NAME, null) === 'true'
      return !useEquivalentGoodFields
    }
    return false
  }

  renderReliefFromDutiesFields(formApi) {
    if (this.shouldHideFields()) {
      return null
    }

    const {
      infoElement,
      renderField,
      intl: { formatMessage },
      locale,
    } = this.props
    const {
      isFetchingDescription,
    } = this.state

    const visibleFields = infoElement.fields.filter(field => field.visible)
    const formValues = this.getFormValues()

    const taricCodeField = find(visibleFields, { code: this.props.codeFieldName })
    const taricDescriptionField = find(visibleFields, { code: PERMIT_GOODS_TO_ENJOY_RELIEF_FROM_DUTIES_DESCRIPTION_FIELD_NAME })
    const quantityField = find(visibleFields, { code: PERMIT_GOODS_TO_ENJOY_RELIEF_FROM_DUTIES_QUANTITY_FIELD_NAME })
    const currencyField =  find(visibleFields, { code: PERMIT_GOODS_TO_ENJOY_RELIEF_FROM_DUTIES_CURRENCY_FIELD_NAME })
    const amountField = find(visibleFields, { code: PERMIT_GOODS_TO_ENJOY_RELIEF_FROM_DUTIES_AMOUNT_FIELD_NAME })
    const countryField = find(visibleFields, { code: PERMIT_GOODS_TO_ENJOY_RELIEF_FROM_DUTIES_COUNTRY_FIELD_NAME })
    const additionalInformationField = find(visibleFields, { code: PERMIT_GOODS_TO_ENJOY_RELIEF_FROM_DUTIES_ADDITIONAL_INFORMATION_FIELD_NAME })
    const measurementUnitField = find(visibleFields, { code: PERMIT_GOODS_TO_ENJOY_RELIEF_FROM_DUTIES_MEASUREMENT_UNIT_FIELD_NAME })

    const defaultFields = [
      taricCodeField,
      taricDescriptionField,
      countryField,
      quantityField,
      currencyField,
      amountField,
      measurementUnitField,
      additionalInformationField,
    ]
      .map(field => field && field.code)
    const restFields = visibleFields
      .filter(field => !includes(defaultFields, field.code))

    const focusTaricCodeField = true


    const taricCodeFieldMandatory = infoElement.mandatory && taricCodeField && taricCodeField.mandatory

    let showCustomErrorMessage = false
    const taricDescription = get(formValues, `${this.taricFieldCode}Description`, null)
    if (taricCodeField && taricCodeField.code) {
      const taricCodeValue = formValues[taricCodeField.code]
      if (taricCodeValue && !taricDescription && taricCodeFieldMandatory) {
        showCustomErrorMessage = true
      }
    }

    const fieldDesc = formatMessage(messages.fieldDescriptionFintaric)
    const additionalFieldDesc = formatMessage(messages.additionalFieldDesc)
    const fieldHeaderDescriptionRelief = formatMessage(messages.fieldHeaderDescriptionRelief)
    const rowSpacing = { marginBottom: '10px' }

    return ([
      <Row>
        <Col xs={12}>
          <Row style={rowSpacing}>
            <Col xs={12}>
              <HeaderArea
                title={infoElement.name[locale]}
                help={infoElement?.help?.[locale]}
                mandatory
              />
              <p>
                {fieldHeaderDescriptionRelief}<br />
                <AccessibleMessage message={fieldHeaderDescriptionRelief} hideMessage />
              </p>
            </Col>
          </Row>
          {renderField({
            field: taricCodeField,
            formApi,
            descriptionText: fieldDesc,
            input: {
              type: 'NUMBER',
              validation: {
                handlerFn: () => {
                },
                customErrorMessage: showCustomErrorMessage ? messages.taricCodeNotValid : null,
              },
              onChange: this.onTaricCodeChange,
            },
            autoFocus: focusTaricCodeField,
          })}
          {isFetchingDescription && <Loader />}
          {taricDescription && <span>
            <i>{taricDescription}<br /><br /></i>
            <AccessibleMessage message={taricDescription} hideMessage />
          </span>}
        </Col>
      </Row>,
      <Row>
        <Col xs={12}>
          {renderField({
            field: taricDescriptionField,
            formApi,
          })}
        </Col>
      </Row>,
      <Row>
        <Col xs={12}>
          {renderField({
            field: countryField,
            formApi,
          })}
        </Col>
      </Row>,
      <Row>
        {quantityField &&
        <Col xs={12} sm={6}>
          {renderField({
            field: quantityField,
            formApi,
            input: {
              name: quantityField.code,
              validate: true,
              visible: true,
            },
          })}
        </Col>
        }
        {measurementUnitField &&
        <Col xs={12} sm={6}>
          {renderField({
            field: measurementUnitField,
            formApi,
            input: {
              name: measurementUnitField.code,
              validate: true,
              visible: true,
            },
          })}
        </Col>
        }
      </Row>,
      <Row>
        {currencyField && <Currency
          infoElement={infoElement}
          locale={locale}
          formApi={formApi}
          renderField={renderField}
          currency={currencyField}
          amount={amountField}
          basic
          input={{
            validate: true,
            validation: {
              strict: false,
              mandatory: currencyField.mandatory || amountField.mandatory,
            },
          }}
          size={{
            xs: 12,
            sm: 6,
            md: 6,
          }}
        />}
        {additionalInformationField &&
          <Col xs={12}>
            {renderField({
              field: additionalInformationField,
              descriptionText: additionalFieldDesc,
              formApi,
            })}
          </Col>
        }
      </Row>,
      restFields.map((field, i) => (
        <Row key={`row-${i}`}>
          <Col xs={12} key={`col-${i}`}>
            {renderField({
              field,
              formApi,
            })}
          </Col>
        </Row>
      )),
    ])
  }


  renderDefaultTaricFields(formApi) {
    if (this.shouldHideFields()) {
      return null
    }

    const {
      infoElement,
      renderField,
      intl: { formatMessage },
      locale,
      combined,
    } = this.props
    const {
      isFetchingDescription,
    } = this.state

    const visibleFields = infoElement.fields.filter(field => field.visible)
    const formValues = this.getFormValues()

    const taricCodeField = find(visibleFields, { code: `${this.fieldPrefix}${this.props.codeFieldName}` })
    const taricDescriptionField = find(visibleFields, { code: `${this.fieldPrefix}Description` })
    const quantityField = find(visibleFields, { code: `${this.fieldPrefix}Quantity` })
    const currencyField = find(visibleFields, { code: `${this.fieldPrefix}Currency` })
    const amountField = find(visibleFields, { code: `${this.fieldPrefix}Amount` })
    const measurementUnitField = find(visibleFields, { code: `${this.fieldPrefix}MeasurementUnit` })

    const defaultFields = [
      taricCodeField,
      taricDescriptionField,
      quantityField,
      currencyField,
      amountField,
      measurementUnitField,
    ]
      .map(field => field && field.code)
    const restFields = visibleFields
      .filter(field => !includes(defaultFields, field.code))

    let focusTaricCodeField = true
    if (combined && infoElement.code === 'equivalentGood') {
      // In combined infoElements, equivalentGood infoElement is not the first infoElement, so prevent autoFocus
      focusTaricCodeField = false
    }

    const taricCodeFieldMandatory = infoElement.mandatory && taricCodeField && taricCodeField.mandatory

    let showCustomErrorMessage = false
    const taricDescription = get(formValues, `${this.taricFieldCode}Description`, null)
    if (taricCodeField && taricCodeField.code) {
      const taricCodeValue = formValues[taricCodeField.code]
      if (taricCodeValue && !taricDescription && taricCodeFieldMandatory) {
        showCustomErrorMessage = true
      }
    }

    const fieldDesc = formatMessage(messages.fieldDescriptionFintaric)

    return ([
      <Row key="itemRow-1">
        <Col xs={12}>
          {renderField({
            field: taricCodeField,
            formApi,
            descriptionText: fieldDesc,
            input: {
              type: 'NUMBER',
              validation: {
                handlerFn: () => {
                },
                customErrorMessage: showCustomErrorMessage ? messages.taricCodeNotValid : null,
              },
              onChange: this.onTaricCodeChange,
            },
            autoFocus: focusTaricCodeField,
          })}
          {isFetchingDescription && <Loader />}
          {taricDescription && <span>
            <i>{taricDescription}<br /><br /></i>
            <AccessibleMessage message={taricDescription} hideMessage />
          </span>}
        </Col>
      </Row>,
      <Row key="itemRow-2">
        <Col xs={12}>
          {renderField({
            field: taricDescriptionField,
            formApi,
          })}
        </Col>
      </Row>,
      (quantityField || measurementUnitField) && <Row key="itemRow-3">
        {quantityField &&
          <Col xs={12} sm={6}>
            {renderField({
              field: quantityField,
              formApi,
              input: {
                name: quantityField.code,
                validate: true,
                visible: true,
              },
            })}
          </Col>
        }
        {measurementUnitField &&
          <Col xs={12} sm={6}>
            {renderField({
              field: {
                ...measurementUnitField,
                type: 'CODESET_AUTOCOMPLETE',
              },
              formApi,
            })}
          </Col>
        }
      </Row>,
      currencyField && amountField && <Row key="itemRow-4">
        <Currency
          infoElement={infoElement}
          locale={locale}
          formApi={formApi}
          renderField={renderField}
          currency={currencyField}
          amount={amountField}
          basic
          input={{
            validate: true,
            validation: {
              strict: false,
              mandatory: currencyField.mandatory || amountField.mandatory,
            },
          }}
          size={{
            xs: 12,
            sm: 6,
            md: 6,
          }}
        />
      </Row>,
      restFields.map((field, i) => (
        <Row key={`row-${i}`}>
          <Col xs={12} key={`col-${i}`}>
            {renderField({
              field,
              formApi,
            })}
          </Col>
        </Row>
      )),
    ])
  }

  renderSelector() {
    const {
      renderField,
      onFieldsToggle,
      intl: { formatMessage },
    } = this.props

    const inputTypeOptions = [
      {
        title: permitMessages.radioSelectionYes,
        value: 'true',
      },
      {
        title: permitMessages.radioSelectionNo,
        value: 'false',
      },
    ]

    const fieldProps = {
      infoElementOverride: {
        mandatory: true,
      },
    }

    return (
      <Row>
        <Col xs={12}>
          {renderField({
            field: {
              visible: true,
              code: USE_EQUIVALENT_GOOD_FIELD_NAME,
              help: {
                fi: '',
              },
              mandatory: true,
              name: {
                en: '',
                fi: formatMessage(messages.useEquivalentGood),
                sv: formatMessage(messages.useEquivalentGood),
              },
              originalHelp: {},
              readonlyInApplication: false,
              type: 'RADIO',
            },
            options: inputTypeOptions,
            formApi: this.formApi,
            ...fieldProps,
            input: {
              onChange: newValue => onFieldsToggle && onFieldsToggle(newValue),
            },
            staticValue: false,
            validate: false,
          })}
        </Col>
      </Row>
    )
  }

  render() {
    const {
      selectedItem,
      error,
      infoElement,
      deleteItem,
      locale,
      useFieldsToggle,
      intl: { formatMessage },
    } = this.props
    const {
      isNew,
      showDeleteConfirmationModal,
    } = this.state

    const titleMessage = isNew ? messages.newItemHeading : messages.itemEditHeading
    const slidingContentStyle = {
      transition: 'transform 200ms ease-in-out',
    }

    const modalButtons = []
    modalButtons.push(<Button
      key="modalCancel"
      bsStyle="default"
      className="pull-left"
      onClick={this.cancel}
      id-qa-test="button-cancel"
    >
      <FormattedMessage {...messages.cancel} />
    </Button>)
    if (isNew) {
      modalButtons.push(<Button
        key="modalSaveAndAddNew"
        bsStyle="default"
        style={{ marginRight: '20px' }}
        disabled={this.state.isFetchingDescription}
        onClick={() => this.save(MODE_SAVE_AND_ADD_NEW)}
        id-qa-test="button-save-add"
      >
        <Icon name="addnew" /><FormattedMessage {...messages.saveAndAddNew} />
      </Button>)
    }
    modalButtons.push(<Button
      key="modalSaveAndClose"
      bsStyle="primary"
      className="pull-right"
      disabled={this.state.isFetchingDescription}
      onClick={() => {
        this.save()
      }}
      id-qa-test="button-save"
    >
      <Icon name="addnew" /><FormattedMessage {...messages.save} />
    </Button>)

    if (!isNew) {
      modalButtons.push(<Button
        key="modalDelete"
        bsStyle="default"
        className="pull-right"
        style={{ marginRight: '20px' }}
        onClick={this.showDeleteModal}
        id-qa-test="button-delete"
      >
        <Icon name="delete" /><FormattedMessage {...messages.delete} />
      </Button>)
    }

    if (get(this.props, 'combined', false)) {
      const formApi = this.props.formApi
      this.connectFormApi(formApi)
      const headerDesc = infoElement.code === 'goodsToBePlacedUnderProcedure' ?
        formatMessage(messages.goodsHeaderDescription) : undefined
      return <React.Fragment>
        <Row>
          <Col xs={12}>
            <InfoElementHeaderArea
              infoElement={infoElement}
              locale={locale}
              descriptionText={headerDesc}
            />
          </Col>
        </Row>
        {useFieldsToggle && this.renderSelector()}
        {this.renderDefaultTaricFields(formApi)}
      </React.Fragment>
    }

    return (
      <div>
        <ErrorBoundary>
          <ButtonToolbar>
            <Button
              id={`new-row-${this.props.infoElement.code}`}
              aria-describedby={`new-row-description-${infoElement.code}`}
              bsStyle="primary"
              onClick={() => this.setState({ isNew: true })}
              id-qa-test={`button-add-${this.props.infoElement.code}`}
            >
              <Icon name="add" /><FormattedMessage {...messages.add} />
            </Button>
          </ButtonToolbar>
          <Modal
            show={Boolean(isNew || selectedItem)}
            size="lg"
            showCancel={false}
            showContinue={false}
            onEscKey={this.cancel}
            titleMessage={titleMessage}
            animation={false}
            contentRef={(ref) => {
              this.modalContentRef = ref
            }}
            customFooterButtons={modalButtons}
          >
            <Modal
              show={showDeleteConfirmationModal}
              showContinue
              showCancel
              titleMessage={messages.deleteItemText}
              continueMessage={messages.continueDeleteConfirmation}
              cancelMessage={messages.ignoreDeleteConfirmation}
              onClickCancel={() => this.setState({ showDeleteConfirmationModal: false })}
              onClickContinue={() => {
                deleteItem(selectedItem)
                this.setState({ showDeleteConfirmationModal: false })
                this.clearFields()
                this.props.cancelEditing()
              }}
              focusDisableButton
            >
              <Row>
                <Col xs={2}>
                  <Icon
                    name={'delete'}
                    style={{ display: 'block', fontSize: '26px' }}
                  />
                </Col>
                <Col xs={10}>
                  <p className="lead">
                    <FormattedMessage {...messages.deleteItemConfirmation} />
                  </p>
                </Col>
              </Row>
            </Modal>
            <div
              ref={(ref) => {
                this.contentRef = ref
              }}
              style={slidingContentStyle}
            >
              <Form
                defaultValues={selectedItem && selectedItem}
                onSubmit={this.save}
                getApi={this.connectFormApi}
                render={formApi =>
                  <form>
                    {this.isReliefFromDuties ? this.renderReliefFromDutiesFields(formApi) : this.renderDefaultTaricFields(formApi)}
                  </form>
                }
              />
              <Row>
                <Col md={8} sm={12}>
                  {error && error.id &&
                  <div className="text-danger text-right" role="alert" id-qa-test="label-error">
                    <FormattedMessage {...messages.error} />
                  </div>
                  }
                </Col>
              </Row>
            </div>
          </Modal>
        </ErrorBoundary>
      </div>
    )
  }
}
