import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { get } from 'lodash'
import InfoArea from 'src/components/InfoArea'
import ErrorBoundary from 'src/components/ErrorBoundary'
import Icon from 'src/components/Icon'
import styles from './DisruptionNotice.scss'
import { GENERAL_SERVICE_CONFIG } from '../../../constants'

const defaultState = {
  hasDisruptionNotices: false,
  disruptionNotices: [],
  fetchingNotices: false,
}

class DisruptionNotice extends React.Component {
  static parseDisruptionsRssFeed(feedXml) {
    let items = []
    if (window.DOMParser) {
      const domParser = new DOMParser()
      const xmlDoc = domParser.parseFromString(feedXml, 'text/xml')
      const feed = xmlDoc.querySelector('rss[version="2.0"]')
      items = Array.from(feed.querySelectorAll('channel item'))
    }
    return items.map((item) => {
      const title = item.querySelector('title').textContent
      const link = item.querySelector('link').textContent
      const date = item.querySelector('date').textContent
      return {
        title,
        link,
        date,
      }
    })
  }

  constructor(props) {
    super(props)
    this.state = {
      ...defaultState,
    }
  }

  componentDidMount() {
    const { locale } = this.props
    this.getFeed(locale)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.locale !== nextProps.locale) {
      this.getFeed(nextProps.locale)
    }
  }

  getFeed(locale) {
    const { disruptionNoticeUrls, service = GENERAL_SERVICE_CONFIG, useServiceSpecificDisruptionNotices } = this.props
    const serviceSpecificNoticeUrl = get(disruptionNoticeUrls, `${service}.${locale}`, null)
    const feedUrl = useServiceSpecificDisruptionNotices === false || serviceSpecificNoticeUrl === null
      ? get(disruptionNoticeUrls, `${GENERAL_SERVICE_CONFIG}.${locale}`)
      : serviceSpecificNoticeUrl

    if (feedUrl) {
      this.setState({ fetchingNotices: true })
      fetch(feedUrl, {
        mode: 'cors',
      })
        .then(
          response => response.text()
        )
        .then((feedXml) => {
          const disruptionNotices = DisruptionNotice.parseDisruptionsRssFeed(feedXml)
          this.setState({
            disruptionNotices,
            hasDisruptionNotices: disruptionNotices.length > 0,
            fetchingNotices: false,
          })
        })
        .catch(() => {
          this.setState({
            fetchingNotices: false,
          })
        })
    } else {
      this.setState({
        disruptionNotices: null,
        hasDisruptionNotices: false,
      })
    }
  }

  render() {
    const { disruptionNotices, fetchingNotices, hasDisruptionNotices } = this.state
    const { enableDisruptionNotices } = this.props

    if (fetchingNotices || !hasDisruptionNotices || enableDisruptionNotices === false) {
      return null
    }

    const Disruption = props => (
      <div className={styles.disruption}>
        <a
          className={styles.disruptionLink}
          href={props.notice.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          {props.notice.title}
          <Icon name="external" />
        </a>
        ({moment(props.notice.date).format('DD.MM.YYYY, hh:mm')})
      </div>
    )

    return (
      <ErrorBoundary>
        <InfoArea icon="info" style={this.props.noMargin ? { margin: 0 } : {}}>
          {disruptionNotices.map((notice, i) =>
            <Disruption key={i} notice={notice} />
          )}
        </InfoArea>
      </ErrorBoundary>
    )
  }
}

function mapStateToProps(state) {
  return {
    useServiceSpecificDisruptionNotices: get(state, 'config.features.SERVICE_SPECIFIC_DISRUPTION_NOTICES', false),
    enableDisruptionNotices: get(state, 'config.features.DISRUPTION_NOTICES', false),
  }
}

export default connect(mapStateToProps)(DisruptionNotice)
