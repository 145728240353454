import { injectIntl } from 'react-intl'
import React from 'react'
import messages from './messages'

function LinkRenderer({ href, children, intl: { formatMessage } }) {
  const target = href.startsWith('mailto:') ? '_top' : '_blank'
  const { linkOpensInNewWindow } = messages
  return (<a href={href} target={target} rel="noopener noreferrer">
    {children}
    <span className="sr-only">{formatMessage(linkOpensInNewWindow)}</span></a>)
}

export default injectIntl(LinkRenderer)
