export const CUSTOMER_INFORMATION_DESTROY_STATE = '@@nakki/customer/CUSTOMER_INFORMATION_DESTROY_STATE'
export const CUSTOMER_INFORMATION_FETCHED = '@@nakki/customer/CUSTOMER_INFORMATION_FETCHED'
export const CUSTOMER_INFORMATION_REQUEST = '@@nakki/customer/CUSTOMER_INFORMATION_REQUEST'

export const REPRESENTATIVE_INFORMATION_FETCHED = '@@nakki/customer/REPRESENTATIVE_INFORMATION_FETCHED'
export const REPRESENTATIVE_INFORMATION_REQUEST = '@@nakki/customer/REPRESENTATIVE_INFORMATION_REQUEST'

export const PERMIT_REPRESENTATIVE_INFORMATION_FETCHED = '@@nakki/customer/PERMIT_REPRESENTATIVE_INFORMATION_FETCHED'
export const PERMIT_REPRESENTATIVE_INFORMATION_REQUEST = '@@nakki/customer/PERMIT_REPRESENTATIVE_INFORMATION_REQUEST'

export const GUARANTEE_STATUSES_FETCHED = '@@nakki/customer/GUARANTEE_STATUSES_FETCHED'
export const GUARANTEE_STATUSES_REQUEST = '@@nakki/customer/GUARANTEE_STATUSES_REQUEST'
export const SHOW_NO_CUSTOMER_INFORMATION_MODAL = '@@nakki/customer/SHOW_NO_CUSTOMER_INFORMATION_MODAL'
export const HIDE_NO_CUSTOMER_INFORMATION_MODAL = '@@nakki/customer/HIDE_NO_CUSTOMER_INFORMATION_MODAL'

export const CURRENT_REPRESENTATIVE_TO_PERMIT_REPRESENTATIVE = '@@nakki/customer/CURRENT_REPRESENTATIVE_TO_PERMIT_REPRESENTATIVE'
