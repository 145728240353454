// Reporter types
export const REPORTING_UNIT = 'REPORTING_UNIT'
export const PSI = 'PSI'
export const TDP = 'TDP'

// Allowed reporter types for delegate company
export const TDP_REPORTER_TYPES = [
  PSI,
  TDP,
  REPORTING_UNIT,
]

// Allowed reporter types for selected authorization
export const PSI_REPORTER_TYPES = [
  PSI,
  REPORTING_UNIT,
]


// Redux forms
export const INTRASTAT_FORM_NAME = 'intrastat'
export const INTRASTAT_ROW_FORM_NAME = 'intrastatRow'

// Declaration statuses
export const DECLARATION_STATUS_ACCEPTED = 'ACCEPTED'
export const DECLARATION_STATUS_DELETED = 'DELETED'
export const DECLARATION_STATUS_DEFACED = 'DEFACED'
export const DECLARATION_STATUS_INCORRECT = 'INCORRECT'
export const DECLARATION_STATUS_RECEIVED = 'RECEIVED'

export const rowErrorField = '_error'

// Error codes
export const ERROR_CODE_DECLARATION_EXISTS = 'declaration.exists'

// Route paths
export const HEADERS_ROUTE_PATH = 'headers'
export const ROWS_ROUTE_PATH = 'rows'
export const CONFIRMATION_ROUTE_PATH = 'confirmation'
export const SUMMARY_ROUTE_PATH = 'summary'
export const SUMMARY_PRINT_ROUTE_PATH = `${SUMMARY_ROUTE_PATH}/print`
export const NEW_ROUTE_PATH = 'new'
export const NEW_DECLARATION_PATH_ID = 'new'

// Action types
export const FORM_STEP_CHANGE = '@@nakki/intrastat/FORM_STEP_CHANGE'
export const DECLARATION_HEADERS_FETCHED = '@@nakki/intrastat/DECLARATION_HEADERS_FETCHED'
export const DECLARATION_HEADERS_SAVED = '@@nakki/intrastat/DECLARATION_HEADERS_SAVED'
export const SUBMITTING_HEADERS = '@@nakki/intrastat/SUBMITTING_HEADERS'
export const DECLARATION_ROWS_FETCHED = '@@nakki/intrastat/DECLARATION_ROWS_FETCHED'
export const DECLARATION_DEFACED = '@@nakki/intrastat/DECLARATION_DEFACED'
export const DECLARATION_DELETED = '@@nakki/intrastat/DECLARATION_DELETED'
export const DECLARATION_VERSION_CREATED = '@@nakki/intrastat/DECLARATION_VERSION_CREATED'
export const DECLARATION_COPIED = '@@nakki/intrastat/DECLARATION_COPIED'
export const DECLARATION_STATUS_CHANGED = '@@nakki/intrastat/DECLARATION_STATUS_CHANGED'
export const DECLARATION_IS_DUPLICATE = '@@nakki/intrastat/DECLARATION_IS_DUPLICATE'
export const DECLARATION_SET_TO_NEW = '@@nakki/intrastat/DECLARATION_SET_TO_NEW'
export const DECLARATION_ROW_UPDATED = '@@nakki/intrastat/DECLARATION_ROW_UPDATED'
export const DECLARATION_ROW_CREATED = '@@nakki/intrastat/DECLARATION_ROW_CREATED'
export const DECLARATION_ROW_DELETED = '@@nakki/intrastat/DECLARATION_ROW_DELETED'
export const DECLARATION_ROWS_CHUNK_SAVED = '@@nakki/intrastat/DECLARATION_ROWS_CHUNK_SAVED'
export const DECLARATION_ROWS_SAVED = '@@nakki/intrastat/DECLARATION_ROWS_SAVED'
export const DECLARATION_ROWS_SAVED_WITH_INTERRUPTION = '@@nakki/intrastat/DECLARATION_ROWS_SAVED_WITH_INTERRUPTION'
export const SHOW_ONLY_INVALID_ROWS = '@@nakki/intrastat/SHOW_ONLY_INVALID_ROWS'
export const SELECT_DECLARATION_ROW = '@@nakki/intrastat/SELECT_DECLARATION_ROW'
export const CHANGE_ROW_EDITING_MODE = '@@nakki/intrastat/CHANGE_ROW_EDITING_MODE'
export const PRESERVE_ROW_FIELD_VALUE = '@@nakki/intrastat/PRESERVE_ROW_FIELD_VALUE'
export const IGNORE_INVALID_TDP = '@@nakki/intrastat/IGNORE_INVALID_TDP'
export const IMPORT_STARTED = '@@nakki/intrastat/IMPORT_STARTED'
export const IMPORT_DONE = '@@nakki/intrastat/IMPORT_DONE'
export const TOGGLE_DELETE_DECLARATION_MODAL = '@@nakki/intrastat/TOGGLE_DELETE_DECLARATION_MODAL'
export const TOGGLE_DELETE_MODAL = '@@nakki/intrastat/TOGGLE_DELETE_MODAL'
export const TOGGLE_IMPORT_MODAL = '@@nakki/intrastat/TOGGLE_IMPORT_MODAL'
export const TOGGLE_PRESERVE_DRAFT_ON_EXIT_MODAL = '@@nakki/intrastat/TOGGLE_PRESERVE_DRAFT_ON_EXIT_MODAL'
export const TOGGLE_DECLARATION_AMENDMENT_MODAL = '@@nakki/intrastat/TOGGLE_DECLARATION_AMENDMENT_MODAL'
export const TOGGLE_COPY_DECLARATION_MODAL = '@@nakki/intrastat/TOGGLE_COPY_DECLARATION_MODAL'
export const TOGGLE_CONFIRM_CLOSE_MODAL = '@@nakki/intrastat/TOGGLE_CONFIRM_CLOSE_MODAL'
export const TOGGLE_NEW_ROW_EDITING = '@@nakki/intrastat/TOGGLE_NEW_ROW_EDITING'
export const CN8_FETCHED = '@@nakki/intrastat/CN8_FETCHED'
export const CN8_TREE_REQUEST = '@@nakki/intrastat/CN8_TREE_REQUEST'
export const CN8_TREE_BRANCH_REQUEST = '@@nakki/intrastat/CN8_TREE_BRANCH_REQUEST'
export const CN8_TREE_CHUNK = '@@nakki/intrastat/CN8_TREE_CHUNK'
export const CN8_TREE_CHUNKS_FETCHED = '@@nakki/intrastat/CN8_TREE_CHUNKS_FETCHED'
export const CN8_TEXT_SEARCH_REQUEST = '@@nakki/intrastat/CN8_TEXT_SEARCH_REQUEST'
export const CN8_TEXT_SEARCH_RESULT = '@@nakki/intrastat/CN8_TEXT_SEARCH_RESULT'
export const CN8_CLEAR_SEARCH_RESULTS = '@@nakki/intrastat/CN8_CLEAR_SEARCH_RESULTS'
export const DESTROY_DECLARATION_STATE = '@@nakki/intrastat/DESTROY_DECLARATION_STATE'
