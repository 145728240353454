import { get, values, first, isEmpty, filter } from 'lodash'
import moment from 'moment'
import {
  APPLICATION_BASIS_TAP_ADDITIONAL_INFORMATION_CODE,
  APPLICATION_BASIS_TAP_FIELD_CODE,
  APPLICATION_BASIS_TAP_INFO_ELEMENT_CODE,
  APPLICATION_BASIS_TAP_MUU_VALUE,
  APPLICATION_BASIS_TAT_ADDITIONAL_INFORMATION_CODE,
  APPLICATION_BASIS_TAT_FIELD_CODE,
  APPLICATION_BASIS_TAT_INFO_ELEMENT_CODE,
  APPLICATION_BASIS_TAT_MUU_VALUE,
  EXCEPTION_SUB_CODESET_NAME,
  EXTENSION_VALUE_NAME_BUSINESS_TYPE,
  EXTENSION_VALUE_NAME_TITLE_TYPE,
  PERMIT_TEXTS_CODESET_NAME,
  RELIEF_SUB_CODESET_NAME,
  RELIEF_TITLE_SUB_CODESET_NAME,
  SHOW_CODESET_OPTION_ONLY_FOR_BUSINESS,
  SHOW_CODESET_OPTION_ONLY_FOR_PERSON,
} from '../../constants'
import { CODESET_STRING_DELIMITER } from '../../../../../components/codeset/constants'

export function getCodesetReliefItems(codesets, subCodeset) {
  const RELIEF_CODESET = PERMIT_TEXTS_CODESET_NAME + CODESET_STRING_DELIMITER + subCodeset
  const codeset = get(codesets, RELIEF_CODESET)
  const date = moment().format('YYYY-MM-DD')
  const codesetKeys = get(codeset, date)
  const codesetValues = []

  for (const item of values(codesetKeys)) {
    codesetValues.push(item)
  }

  return codesetValues
}

export function getCodesetTitles(codesets) {
  const RELIEF_CODESET_TITLES = PERMIT_TEXTS_CODESET_NAME + CODESET_STRING_DELIMITER + RELIEF_TITLE_SUB_CODESET_NAME

  const codeset = get(codesets, RELIEF_CODESET_TITLES)
  const date = moment().format('YYYY-MM-DD')
  const codesetKeys = get(codeset, date)
  const codesetValues = []

  for (const item of values(codesetKeys)) {
    codesetValues.push(item)
  }

  return codesetValues
}

export const getCodesetReliefItemsByUser = (items, isPerson) => filterItemsByAuthType(items, isPerson)

export const getCodesetTitleItemsByUser = (titles, items) => {
  // get only titles which are visible for user
  const titlesWithItems = []

  for (const title of titles) {
    const existInItems = items.some(item => filterItemByTitle(item, title.code))

    if (existInItems) {
      titlesWithItems.push(title)
    }
  }

  return titlesWithItems
}


const filterItemByAuthType = (item, isPerson) => {
  const visibilityType = get(item, `extensionValues.${EXTENSION_VALUE_NAME_BUSINESS_TYPE}`)

  // Hide business only codeset option for person user
  if (isPerson && visibilityType === SHOW_CODESET_OPTION_ONLY_FOR_BUSINESS) {
    return false
  }

  // Hide person only codeset option for business user
  if (!isPerson && visibilityType === SHOW_CODESET_OPTION_ONLY_FOR_PERSON) {
    return false
  }

  return true
}

const filterItemByTitle = (item, title) => get(item, `extensionValues.${EXTENSION_VALUE_NAME_TITLE_TYPE}`) === title

const filterItemsByAuthType = (codesetData, isPerson) => filter(codesetData, item => filterItemByAuthType(item, isPerson))


export function getSelectedItemsByTitle(items, title) {
  const itemsByTitle = filter(items, item => filterItemByTitle(item, title))

  return itemsByTitle
}

export function onlyOneOptionInTitle(items, title) {
  const itemsByTitle = getSelectedItemsByTitle(items, title)

  return itemsByTitle.length === 1 && first(itemsByTitle)
}

function getSelectedTitle(codesetItems, codesetTitles, selectedApplicationBasis = [], infoElement) {
  if (isEmpty(selectedApplicationBasis)) {
    return null
  }

  const titles = []

  const fieldCode = getApplicationBasisFieldCode(infoElement)

  for (const selected of selectedApplicationBasis) {
    const itemIsInCodeset = codesetItems.find(codesetArticle => selected[fieldCode] === codesetArticle.code)
    const itemTitle = get(itemIsInCodeset, `extensionValues.${EXTENSION_VALUE_NAME_TITLE_TYPE}`)

    if (itemTitle && !titles.includes(itemTitle)) {
      titles.push(itemTitle)
    }
  }

  if (titles.length > 1) {
    throw new Error('Got invalid selected items')
  }

  // In current layout we can select only one title.
  const firstTitle = first(titles)

  if (!firstTitle) {
    return null
  }

  const selectedTitle = codesetTitles.find(codesetTitle => codesetTitle.code === firstTitle)

  if (!selectedTitle) {
    return null
  }

  return selectedTitle
}

export function getSelectedTitleCode(items, titles, selectedApplicationBasis, infoElement) {
  const selectedTitle = getSelectedTitle(items, titles, selectedApplicationBasis, infoElement)
  return selectedTitle?.code
}

export function getInitialAdditionalInformation(formApi, infoElement) {
  const additionalBasis = formApi.values?.[infoElement.code]
  const fieldCode = getApplicationBasisFieldCode(infoElement)
  const additionalFieldCode = getAdditionalFieldCode(infoElement)
  const muuValue = getMuuValue(infoElement)

  if (!additionalBasis) {
    return null
  }

  const containsAdditionalInfoKey = additionalBasis.find(item => item[fieldCode] === muuValue)

  if (containsAdditionalInfoKey) {
    return containsAdditionalInfoKey[additionalFieldCode]
  }

  return null
}

export function getSubCodesetName(infoElement) {
  const tapOrTat = getTatOrTapValue(RELIEF_SUB_CODESET_NAME, EXCEPTION_SUB_CODESET_NAME)

  return tapOrTat(infoElement)
}


export function getApplicationBasisFieldCode(infoElement) {
  const tapOrTat = getTatOrTapValue(APPLICATION_BASIS_TAT_FIELD_CODE, APPLICATION_BASIS_TAP_FIELD_CODE)

  return tapOrTat(infoElement)
}

export function getAdditionalFieldCode(infoElement) {
  const tapOrTat = getTatOrTapValue(APPLICATION_BASIS_TAT_ADDITIONAL_INFORMATION_CODE, APPLICATION_BASIS_TAP_ADDITIONAL_INFORMATION_CODE)

  return tapOrTat(infoElement)
}

export function getMuuValue(infoElement) {
  const tapOrTat = getTatOrTapValue(APPLICATION_BASIS_TAT_MUU_VALUE, APPLICATION_BASIS_TAP_MUU_VALUE)

  return tapOrTat(infoElement)
}

export function getCheckboxValidationErrorMessage(infoElement) {
  const tapOrTat = getTatOrTapValue('mandatoryApplicationBasisCheckbox', 'checkAtLeastOne')

  return tapOrTat(infoElement)
}

export function getAdditionalInformationValidationErrorMessage(infoElement) {
  const tapOrTat = getTatOrTapValue('otherReliefError_TAT', 'otherReliefError_TAP')

  return tapOrTat(infoElement)
}

export const getTatOrTapValue = (tat, tap) => (infoElement) => {
  const { code } = infoElement

  if (code === APPLICATION_BASIS_TAT_INFO_ELEMENT_CODE) {
    return tat
  }

  if (code === APPLICATION_BASIS_TAP_INFO_ELEMENT_CODE) {
    return tap
  }

  return null
}
