import { connect } from 'react-redux'
import { get } from 'lodash'
import { injectIntl } from 'react-intl'
import { setShowNoCustomerInfoModal } from 'src/components/customer/actions'
import Frontpage from './components/Frontpage'

const mapStateToProps = state =>
  ({
    auth: state.auth,
    locale: state.locale,
    helpText: get(state, ['content', 'cmsMessages', '/anonymous']),
    helpTextAll: get(state, ['content', 'cmsMessages']),
    disruptionNoticeUrls: get(state, 'config.bootstrapConfig.nakki_disruption_notices'),
    services: state.config.services,
    serviceGroups: state.config.serviceGroups,
    showNoCustomerInfoModal: state.customer.showNoCustomerInfoModal,
  })

const mapActionCreators = {
  setShowNoCustomerInfoModal,
}

export default injectIntl(
  connect(mapStateToProps, mapActionCreators)(Frontpage)
)
