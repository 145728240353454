import React from 'react'
import { Field } from 'react-form'
import { FormControl, HelpBlock } from 'react-bootstrap'
import { injectIntl, defineMessages } from 'react-intl'
import { has, get } from 'lodash'
import { updateStatusMessageForInput } from 'src/utils'
import {
  required,
  email,
  minLength,
  maxLength,
  nonNegativeInteger,
  nonNegativeDecimal,
  regEx,
  phone,
} from '../../utils/validation'
import ErrorMessage from './ErrorMessage'

const messages = defineMessages({
  emailFormat: {
    id: 'form.validation.emailFormat',
    description: 'Label for acceptable email format',
    defaultMessage: '?? An acceptable email format is firstname.lastname@mail.fi',
  },
  phoneFormat: {
    id: 'form.validation.phoneFormat',
    description: 'Label for acceptable phone number format',
    defaultMessage: '?? An acceptable phone number format is +358 50 123 4567',
  },
})

const VALIDATING_INITIAL = 'VALIDATING_INITIAL'
const NOT_VALIDATING =  'NOT_VALIDATING'
const VALIDATING =  'VALIDATING'

class Input extends React.Component {
  constructor(props) {
    super(props)
    this.validate = this.validate.bind(this)
    this.handleValidation = this.handleValidation.bind(this)
    this.validationMode = { value: VALIDATING_INITIAL }
  }

  componentWillUnmount() {
    const { name, formApi } = this.props
    if (formApi) {
      formApi.setError(name, null)
    }
  }

  validate(value) {
    const { validation, type } = this.props
    if (this.validationMode.value === NOT_VALIDATING) {
      return null
    }
    const validationHelper = (arr, message) => {
      if (message) {
        arr.push(message)
      }
      return arr
    }

    let messageArr = []

    if (get(validation, 'mandatory')) {
      const trimmedValue = value ? value.trim() : ''
      const requiredCheck = required(trimmedValue)
      if (validation.mandatory && requiredCheck) {
        if (get(validation, 'customErrorMessage')) {
          return validation.customErrorMessage
        }
        return requiredCheck
      }
    }

    if (get(validation, 'regex.expression')) {
      const defaultErrorMessage = regEx(validation.regex.expression, '')(value)
      if (defaultErrorMessage && get(validation, 'regex.errorMessage')) {
        messageArr = validationHelper(messageArr, validation.regex.errorMessage)
      } else {
        messageArr = validationHelper(messageArr, defaultErrorMessage)
      }
    }

    if (get(validation, 'validator.fn')) {
      const defaultErrorMessage = validation.validator.fn(value)
      if (defaultErrorMessage) {
        if (get(validation, 'validator.errorMessage')) {
          messageArr = validationHelper(messageArr, validation.validator.errorMessage)
        } else {
          messageArr = validationHelper(messageArr, defaultErrorMessage)
        }
      }
    }

    if (get(validation, 'minLength')) {
      messageArr = validationHelper(messageArr, minLength(parseInt(validation.minLength, 10))(value))
    }
    if (get(validation, 'maxLength')) {
      messageArr = validationHelper(messageArr, maxLength(parseInt(validation.maxLength, 10))(value))
    }

    if (type === 'email') {
      const emailErrorMessage = email(value)
      if (emailErrorMessage) {
        if (get(validation, 'customErrorMessage')) {
          messageArr = validationHelper(messageArr, validation.customErrorMessage)
        } else {
          messageArr = validationHelper(messageArr, emailErrorMessage)
        }
      }
    } else if (type === 'phone') {
      const phoneErrorMessage = phone(value, validation.minLength, validation.maxLength)
      if (phoneErrorMessage) {
        if (get(validation, 'customErrorMessage')) {
          messageArr = validationHelper(messageArr, validation.customErrorMessage)
        } else {
          messageArr = validationHelper(messageArr, phoneErrorMessage)
        }
      }
    } else if (type === 'number') {
      if (has(validation, 'maxDecimalLength') && get(validation, 'maxDecimalLength') > 0 && value) {
        messageArr = validationHelper(messageArr, nonNegativeDecimal(
          parseInt(validation.maxLength, 10),
          parseInt(validation.maxDecimalLength, 10)
        )(value))
      } else if (get(validation, 'customErrorMessage')) {
        messageArr = validationHelper(messageArr, validation.customErrorMessage)
      } else {
        messageArr = validationHelper(messageArr, nonNegativeInteger(value))
      }
    }

    if (messageArr.length > 0) {
      return messageArr[0]
    }

    return null
  }

  // eslint-disable-next-line consistent-return
  handleValidation(value) {
    const { name, validation } = this.props
    const validationErrors = this.validate(value)

    if (typeof validation.handlerFn === 'function') {
      validation.handlerFn(name, validationErrors)
      return null
    }

    if (typeof validation?.returningValidator === 'function') {
      return validation.returningValidator(value)
    }

    if (validationErrors) {
      return validationErrors
    }
    return null
  }

  handleValidationAndSetFieldError(value) {
    const { name, formApi } = this.props
    const fieldValidationErrors = this.handleValidation(value)
    formApi.setError(name, fieldValidationErrors)
  }

  validateOnChange(value, setValueFn) {
    const { validation } = this.props
    if (has(validation, 'maxLength')) {
      if (value.length <= parseInt(get(validation, 'maxLength'), 10)) {
        setValueFn()
      }
    } else {
      setValueFn()
    }
  }

  render() {
    const {
      warning,
      type,
      inputStyle,
      disabled,
      inputRef,
      placeholder,
      validate,
      visible,
      validation,
      locale,
      customValue,
      intl: { formatMessage },
      autoFocus,
      label,
      helpText = '',
      ariaProps,
    } = this.props

    let newType = type
    if (type === 'number') {
      newType = 'text'
    }

    let formattingHelpText = helpText
    if (type === 'phone') {
      formattingHelpText = formatMessage(messages.phoneFormat)
    } else if (type === 'email') {
      formattingHelpText = formatMessage(messages.emailFormat)
    }
    return (
      <Field
        field={this.props.name}
        locale={locale}
        visible={visible}
        disabled={disabled}
        placeholder={placeholder}
        validate={validate && this.handleValidation}
      >
        {(fieldApi) => {
          const { onChange, onBlur } = this.props

          const { value, error, setValue, setTouched } = fieldApi
          this.fieldApi = fieldApi

          const formatValue = () => {
            if (has(validation, 'maxDecimalLength') && get(validation, 'maxDecimalLength') > 0) {
              const regex = new RegExp('^\\d+,$')
              if (regex.test(value)) {
                setValue(`${value}0`)
              }
            }
          }
          const errorFieldId = `${this.props.name}-error`


          if (visible) {
            return (
              <div>
                {this.props.static &&
                  <FormControl.Static
                    id-qa-test={`label-${this.props.name.toLowerCase()}`}
                  >
                    {customValue || value || '--'}
                  </FormControl.Static>
                }
                {!this.props.static &&
                  <FormControl
                    style={inputStyle}
                    inputRef={inputRef}
                    componentClass={type === 'textarea' ? type : 'input'}
                    type={newType}
                    value={customValue || value || ''}
                    id-qa-test={`input-${this.props.name.toLowerCase()}`}
                    onChange={(event) => {
                      if (this.validationMode.value === VALIDATING_INITIAL) {
                        this.validationMode = { value: NOT_VALIDATING }
                      }
                      this.validateOnChange(event.target.value, () => {
                        setValue(event.target.value)
                        if (onChange) {
                          onChange(event.target.value, event)
                        }
                      })
                    }}
                    onBlur={(event) => {
                      formatValue()
                      setTouched()
                      if (this.validationMode.value === NOT_VALIDATING) {
                        this.validationMode = { value: VALIDATING }
                      }
                      if (validate) {
                        this.handleValidationAndSetFieldError(value)
                      }
                      if (onBlur) {
                        onBlur(event.target.value)
                      }
                      updateStatusMessageForInput(this.props.name)
                    }}
                    disabled={Boolean(disabled)}
                    placeholder={placeholder}
                    onKeyPress={type === 'textarea' ? null : (event) => {
                      switch (event.key) {
                      case 'Enter': {
                        event.preventDefault()
                        break
                      }
                      default:
                        break
                      }
                    }}
                    rows={type === 'textarea' ? 10 : 1}
                    autoFocus={autoFocus}
                    {...ariaProps}
                  />
                }
                <HelpBlock id={errorFieldId}>
                  {error &&
                    <div>
                      <ErrorMessage error={error} fieldName={label} />
                    </div>
                  }
                  {(formattingHelpText && (!this.props.static || error)) &&
                    <div>
                      {formattingHelpText}
                    </div>
                  }
                  {warning &&
                    <div>
                      {warning}
                    </div>
                  }
                </HelpBlock>
              </div>
            )
          }

          return null
        }}
      </Field>
    )
  }
}

export default injectIntl(Input)
