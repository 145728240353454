export const PERMITS_ROUTE_PATH = 'permits'
export const PERMIT_ROUTE_PATH = 'permit'
export const PERMIT_APPLICATION_ROUTE_PATH = 'application'
export const LIST_ROUTE_PATH = 'permit'
export const PERMITS_DESTROY_STATE = '@@nakki/permits/DESTROY_STATE'

// Permit application statuses
export const PERMIT_APPLICATION_STATUS_DRAFT = 'DRAFT'
export const PERMIT_APPLICATION_STATUS_REGISTERED = 'REGISTERED'
export const PERMIT_APPLICATION_STATUS_VERIFICATION = 'VERIFICATION'
export const PERMIT_APPLICATION_STATUS_ACCEPTED = 'ACCEPTED'
export const PERMIT_APPLICATION_STATUS_NOT_ACCEPTED = 'NOT_ACCEPTED'
export const PERMIT_APPLICATION_STATUS_FAVOURABLE = 'FAVOURABLE'
export const PERMIT_APPLICATION_STATUS_NON_FAVOURABLE = 'NON_FAVOURABLE'
export const PERMIT_APPLICATION_STATUS_WITHDRAWN = 'WITHDRAWN'

// Permit statuses
export const PERMIT_STATUS_DRAFT = 'DRAFT'
export const PERMIT_STATUS_APPROVED = 'APPROVED'
export const PERMIT_STATUS_ACTIVE = 'ACTIVE'
export const PERMIT_STATUS_SUSPENDED = 'SUSPENDED'
export const PERMIT_STATUS_ENDED = 'ENDED'
export const PERMIT_STATUS_REVOKED = 'REVOKED'
export const PERMIT_STATUS_ANNULLED = 'ANNULLED'
