import { chain, has, every, get } from 'lodash'

export const getLocationFields = (prefix) => {
  const nameField = {
    code: `${prefix}Name`,
    mandatory: 'true',
    maxLength: '70',
    minLength: '0',
    name: {
      fi: 'Sijaintipaikan nimi',
      sv: 'Förvaringsplats namn',
      en: 'Name of location',
    },
    type: 'TEXT',
  }
  const addressField = {
    code: `${prefix}Address`,
    mandatory: 'true',
    maxLength: '70',
    minLength: '0',
    name: {
      fi: 'Katuosoite',
      sv: 'Gatuadress',
      en: 'Street address',
    },
    type: 'TEXT',
  }
  const postalCodeField = {
    code: `${prefix}PostalCode`,
    mandatory: 'true',
    maxLength: '9',
    minLength: '0',
    name: {
      fi: 'Postinumero',
      sv: 'Postnummer',
      en: 'Postcode',
    },
    type: 'TEXT',
  }
  const cityField = {
    code: `${prefix}City`,
    mandatory: 'true',
    maxLength: '35',
    minLength: '0',
    name: {
      fi: 'Postitoimipaikka',
      sv: 'Ort',
      en: 'City',
    },
    type: 'TEXT',
  }
  const countryCodeField = {
    code: `${prefix}CountryCode`,
    codesetName: 'Maa',
    subCodesetName: 'OsoiteMaa',
    mandatory: 'true',
    maxLength: '2',
    minLength: '2',
    name: {
      fi: 'Maa',
      sv: 'Landkod',
      en: 'Country code',
    },
    type: 'COUNTRY_CODE',
  }

  return [
    nameField,
    addressField,
    postalCodeField,
    cityField,
    countryCodeField,
  ]
}

// Check that every mandatory field has a value
// Returns true for valid values, otherwise false
export const validateFormValues = (infoElement, formData) => {
  const fieldValues = chain(infoElement.fields)
    .filter(field => field.mandatory)
    .map(field =>
      has(formData, field.code) && get(formData, field.code) !== ''
    )
    .value()

  return every(fieldValues, value => value)
}
