import React, { useContext } from 'react'
import {
  has,
  get,
} from 'lodash'
import { Row, Col } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import InfoElementHeaderArea from 'src/components/form_v2/InfoElementHeaderArea'
import InfoElementValidationMessages from './InfoElementValidationMessages'
import PermitPageContext from './PermitPage/context'
import {
  getInfoElementComponent,
  getInfoElementTitle,
  isInfoElementVisibleConditionally,
} from '../permitManager'

export default function InfoElement(props) {
  const {
    isPerson,
    infoElement,
    formApi,
    locale,
    codesets,
    helpTexts,
    renderField,
    saveDraft,
  } = props
  const { structure, setPageLoader } = useContext(PermitPageContext)

  const values = get(formApi.getFormState(), 'values')
  const visible = infoElement.visible && isInfoElementVisibleConditionally(infoElement, values)

  if (!visible) {
    return null
  }

  const customTitle = getInfoElementTitle(infoElement.code)
  const CustomTitleElement = () => (
    <Col xs={12}>
      {customTitle && <h4><FormattedMessage {...customTitle} /></h4>}
    </Col>
  )

  const InfoElementComponent = getInfoElementComponent(infoElement, structure)

  if (InfoElementComponent) {
    return (
      <Row className={'rowGutter'}>
        <CustomTitleElement />
        <InfoElementComponent
          isPerson={isPerson}
          key={'info-element-component'}
          infoElement={infoElement}
          formApi={formApi}
          renderField={renderField}
          locale={locale}
          structure={structure}
          codesets={codesets}
          helpTexts={helpTexts}
          saveDraft={saveDraft}
          setPageLoader={setPageLoader}
        />
      </Row>
    )
  }
  const infoElementFieldCount = infoElement.fields.length
  const getHelpCombined = (field) => {
    let obj = {}
    if (infoElementFieldCount === 1 && has(infoElement, 'help')) {
      obj = {
        help: {
          [locale]: `${get(infoElement, `help[${locale}]`, '')} \n\n ${get(field, `help[${locale}]`, '')}`,
        },
      }
    }
    return obj
  }

  const hasHeaderArea = infoElementFieldCount > 1
  const containerProps = hasHeaderArea
    ? { role: 'group', 'aria-labelledby': infoElement.code }
    : {}

  return (
    <Row className="rowGutter" {...containerProps}>
      <Col sm={6} xs={12}>
        <Row>
          <CustomTitleElement />
          {hasHeaderArea && <Col xs={12}>
            <InfoElementHeaderArea
              infoElement={infoElement}
              locale={locale}
            />
          </Col>}
          {infoElement.fields.map(field =>
            <Col xs={12} key={field.code}>
              {renderField({
                field: {
                  ...field,
                  ...getHelpCombined(field),
                  name: infoElementFieldCount === 1 ? infoElement.name : field.name,
                },
              })}
            </Col>
          )}
          <InfoElementValidationMessages infoElement={infoElement} formApi={formApi} />
        </Row>
      </Col>
    </Row>
  )
}
