import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { get, findIndex, set } from 'lodash'
import ItemForm from './ItemForm'

export default class ItemSingleForm extends ItemForm {
  onChangeInputType(newInputType) {
    const { formApi, infoElement } = this.props
    const currentFormState = formApi.getFormState()
    const currentInfoElementValues = get(currentFormState.values, infoElement.code)
    const currentInputType = get(currentInfoElementValues, `${this.getFieldFullCode('location')}FieldValueSource`)
    if (currentInputType !== newInputType) {
      const newFormState = set(currentFormState, `values.${infoElement.code}`, {
        ...currentInfoElementValues,
        [`${this.mappedFields.location}FieldValueSource`]: newInputType,
        [`${this.mappedFields.location}`]: '',
        [`${this.fieldPrefix}Name`]: '',
        [`${this.fieldPrefix}Address`]: '',
        [`${this.fieldPrefix}PostalCode`]: '',
        [`${this.fieldPrefix}City`]: '',
        ...this.getDefaultValues(newInputType),
      })
      formApi.setFormState(newFormState)
    }
  }

  getFieldFullCode(code) {
    const { infoElement } = this.props
    const mappedField = get(this.mappedFields, code)
    // In this case full code of field is infoElement code + field code
    return `${infoElement.code}.${mappedField}`
  }

  getValuesAndFilter(formApi) {
    const { infoElement } = this.props
    const infoElementValues = get(formApi.values, infoElement.code)
    const selectedInputType = get(infoElementValues, `${this.mappedFields.location}FieldValueSource`)
    const filteredFields = this.filterVisibleFields(selectedInputType)
    const locationFieldIndex = findIndex(filteredFields, { code: this.getFieldFullCode('location') })
    return {
      selectedInputType,
      filteredFields,
      locationFieldIndex,
    }
  }

  render() {
    const {
      formApi,
      infoElement,
    } = this.props

    return ([
      <Row>
        <Col sm={6} xs={12}>
          {this.renderInputTypeSelector(
            `${infoElement.code}.${this.mappedFields.location}FieldValueSource`,
            formApi,
          )}
        </Col>
      </Row>,
      <Row>
        <Col sm={6} xs={12}>
          {this.renderVisibleFields(formApi)}
        </Col>
      </Row>,
    ])
  }
}
