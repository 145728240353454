import { createContext, useContext } from 'react'

const Context = createContext({
  fetchingActiveGuarantee: false,
})

export function useFormApi() {
  const { formApi } = useContext(Context)

  if (!formApi) {
    throw Error('Context not found, make sure you are under provider')
  }

  return formApi
}

export default Context
