import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { find, endsWith, xor, get } from 'lodash'
import {
  GUARANTOR_CODE_UNDERTAKING,
} from '../constants'

export default class GuaranteeFormReadOnly extends React.Component {
  constructor(props) {
    super(props)
    this.guarantorCode = GUARANTOR_CODE_UNDERTAKING
    const guaranteeForm = get(this.props.values.guaranteeForm, 'guaranteeForm')
    this.state = {
      showGuarantorFields: guaranteeForm === this.guarantorCode,
      guaranteeForm: guaranteeForm === this.guarantorCode,
    }
  }
  render() {
    const { infoElement, locale, renderField } = this.props
    const codeField = find(infoElement.fields, field => endsWith(field.code, 'guaranteeForm'))
    const nameField = find(infoElement.fields, field => endsWith(field.code, 'guarantorName'))
    const addressField = find(infoElement.fields, field => endsWith(field.code, 'guarantorAddress'))
    const countryCodeField = find(infoElement.fields, field => endsWith(field.code, 'guarantorCountryCode'))
    const postcodeField = find(infoElement.fields, field => endsWith(field.code, 'guarantorPostcode'))
    const cityField = find(infoElement.fields, field => endsWith(field.code, 'guarantorCity'))
    const additionalInfoField = find(infoElement.fields, field =>
      endsWith(field.code, 'guarantorAdditionalInformation')
    )

    const fieldProps = {
      staticValue: true,
      showHelp: false,
    }

    const otherFields = xor(infoElement.fields, [
      codeField,
      nameField,
      addressField,
      countryCodeField,
      postcodeField,
      cityField,
      additionalInfoField,
    ])

    return (
      <div>
        <Row>
          <Col xs={12}>
            <h4>
              {infoElement.name[locale]}
            </h4>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            {codeField && renderField({
              field: codeField,
              ...fieldProps,
            })}
          </Col>
          {this.state.showGuarantorFields && [
            <Col xs={12} key="guarantorName">
              {nameField && renderField({
                field: nameField,
                ...fieldProps,
              })}
            </Col>,
            <Col xs={12} key="guarantorAddress">
              {addressField && renderField({
                field: addressField,
                ...fieldProps,
              })}
            </Col>,
            <Col xs={6} key="guarantorPostcode">
              {postcodeField && renderField({
                field: postcodeField,
                ...fieldProps,
              })}
            </Col>,
            <Col xs={6} key="guarantorCity">
              {cityField && renderField({
                field: cityField,
                ...fieldProps,
              })}
            </Col>,
            <Col xs={12} key="guarantorCountryCode">
              {countryCodeField && renderField({
                field: countryCodeField,
                ...fieldProps,
              })}
            </Col>,
          ]}
          <Col xs={12} key="guarantorAdditionalInformation">
            {additionalInfoField && renderField({
              field: additionalInfoField,
              ...fieldProps,
            })}
          </Col>
          <Col xs={12}>
            {otherFields && otherFields.map(field => renderField({
              field,
              ...fieldProps,
            }))}
          </Col>
        </Row>
      </div>
    )
  }
}
