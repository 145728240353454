import classNames from 'classnames'
import React from 'react'
import styles from './icon.scss'

function Icon({ name, className, xs, sm, md, lg, xlg, huge, style, alt }) {
  let size = null
  if (xs) {
    size = styles['icon-xs']
  } else if (sm) {
    size = styles['icon-sm']
  } else if (md) {
    size = styles['icon-md']
  } else if (lg) {
    size = styles['icon-lg']
  } else if (xlg) {
    size = styles['icon-xlg']
  } else if (huge) {
    size = styles['icon-huge']
  }
  return (
    <span
      aria-hidden="true"
      alt={alt}
      style={style}
      className={classNames(className, styles.icon, styles[`icon-tulli-${name}`], size, 'tulli-icon')}
    />
  )
}

export default Icon
