import React, { useState } from 'react'
import classNames from 'classnames'

import Icon from './Icon'
import styles from './Accordion.scss'

export default function Accordion(props) {
  const {
    title,
    accordionId,
    open,
    clickHandler,
    small,
  } = props

  const LinkComponent = small ? 'h4' : 'h2'

  return (
    <div
      className={classNames(styles.accordion,
        small && styles.small,
        open ? styles.contentShown : styles.contentHidden, props.className)}
    >
      <LinkComponent className={styles.accordionToggle}>
        <button
          id={`${accordionId}Button`}
          className={classNames(styles.accordionButton)}
          onClick={clickHandler}
          aria-expanded={open ? 'true' : 'false'}
          aria-controls={`${accordionId}Content`}
        >
          {title}
          <Icon
            name={'arrow-down'}
            className={styles.accordionIcon}
          />
        </button>
      </LinkComponent>
      <div
        id={`${accordionId}Content`}
        className={classNames(styles.accordionContent)}
      >
        {props.children}
      </div>
    </div>
  )
}

export function StatefulAccordion({ initialOpen, ...props }) {
  const [open, setOpen] = useState(Boolean(initialOpen))
  const toggleAccordion = (e) => {
    e.preventDefault()
    setOpen(!open)
  }

  return <Accordion {...props} open={open} clickHandler={toggleAccordion} />
}

export function AccordionContainer({ children }) {
  return (
    <ul className={styles.accordionContainer}>
      {React.Children.map(children, child =>
        <li>{child}</li>
      )}
    </ul>
  )
}
