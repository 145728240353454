import React from 'react'
import ReactMarkdown from 'react-markdown'
import classNames from 'classnames'
import { injectIntl } from 'react-intl'
import { isBrowserInternetExplorer } from 'src/utils'
import Icon from 'src/components/Icon'
import AccessibleMessage from 'src/components/AccessibleMessage'
import LinkRenderer from './LinkRenderer'
import styles from './BrowserInfo.scss'

function BrowserInfo({ intl: { formatMessage } }) {
  if (!isBrowserInternetExplorer()) {
    return null
  }
  const type = 'error'
  const icon = 'attention'
  const message = formatMessage({ id: 'browser.unsupportedBrowser' })
  const link = formatMessage({ id: 'browser.unsupportedBrowserLink' })
  return (
    <div className={classNames(styles.containerInfo, styles[type])}>
      <div className={styles.containerInfoInner}>
        <Icon name={icon} className={styles.iconInfo} />
        <div className={styles.containerInfoText}>
          <span className={styles.containerInfoTitle}>
            {message}
            {link &&
              <ReactMarkdown
                source={link}
                renderers={{ Link: LinkRenderer }}
                target={'_blank'}
              />
            }
            <AccessibleMessage message={message} hideMessage />
          </span>
        </div>
      </div>
    </div>
  )
}

export default injectIntl(BrowserInfo)
