import React from 'react'
import { Row, Col, Table } from 'react-bootstrap'
import { some, get, isEmpty } from 'lodash'
import { FormattedMessage } from 'react-intl'
import ErrorMessage from 'src/components/form_v2/ErrorMessage'
import styles from 'src/components/Table/Table.scss'
import componentMessages from 'src/components/messages'
import { getCellValueFormatter } from '../../formatValue'
import messages from './messages'

const renderRow = (item, itemIndex, fields, hiddenFields, errors, locale, formatCellValue) => {
  const hiddenErrors = hiddenFields
    .map(field => ({
      field,
      error: get(errors, `${field.infoElement.code}.${field.code}-${itemIndex}`, ''),
    }))
    .filter(o => o.error !== '')

  return (<tr key={itemIndex}>
    {fields.map((field) => {
      const fieldError = get(errors, `${field.code}-${itemIndex}`, '')
      return (
        <td key={field.code}>
          {formatCellValue(item, field)}
          {fieldError &&
            <div className={'has-error'}>
              <p className="help-block">
                <ErrorMessage error={fieldError} fieldName={field.name[locale]} />
              </p>
            </div>
          }
        </td>
      )
    })}
    {hiddenErrors.length > 0 && <td>
      <div className={'has-error'}>
        {hiddenErrors.map(({ field, error }) =>
          <p className="help-block">
            {field.name[locale]}: <ErrorMessage error={error} />
          </p>
        )}
      </div>
    </td>}
  </tr>)
}


export default function (props) {
  const {
    infoElement,
    values,
    locale,
    codesets,
    formatMessage,
  } = props

  const isMandatory = infoElement.mandatory && some(infoElement.fields, { mandatory: true })
  const errors = get(props.formApi.getFormState(), `errors.${infoElement.code}`)
  const formatCellValue = getCellValueFormatter({ codesets, locale, formatMessage, emptyValue: '' })

  const hasValues = values[infoElement.code] && !isEmpty(values[infoElement.code])
  const rows = values[infoElement.code]
  const activeFields = infoElement.fields.filter(field => field.visible)
  const hiddenFields = infoElement.fields.filter(field => !field.visible)
  const hasHiddenErrors = hiddenFields.some(field => get(errors, `${field.infoElement.code}.${field.code}`))
  return (
    <div>
      <Row>
        <Col xs={12}>
          <h4>{infoElement.name[locale]}</h4>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          {!hasValues && !isMandatory &&
            <div>--</div>
          }
          {!hasValues && isMandatory &&
            <div className="has-error">
              <div className="help-block">
                <FormattedMessage {...messages.missingRequiredRows} />
              </div>
            </div>
          }

          {hasValues &&
            <div tabIndex="0" role="region" aria-label={formatMessage(componentMessages.table)} className={styles.scrollWrapper}>
              <Table striped bordered>
                <thead>
                  <tr>
                    {activeFields.map(field => <td key={field.code}><b>{field.name[locale]}</b></td>)}
                    {hasHiddenErrors &&
                      <th><b>
                        <FormattedMessage {...messages.error} />
                      </b></th>
                    }
                  </tr>
                </thead>
                <tbody>
                  {rows.map((row, rowIndex) =>
                    renderRow(row, rowIndex, activeFields, hiddenFields, errors, locale, formatCellValue)
                  )}
                </tbody>
              </Table>
            </div>}
        </Col>
      </Row>
    </div>
  )
}
