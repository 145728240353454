import React from 'react'
import { get } from 'lodash'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { collectCmsMessages, focusElementById, updateStatusMessage } from 'src/utils/index'
import InlineNotification from './InlineNotification'
import {
  getApplicant,
  getRepresentativeNameFromAskare,
  getRepresentative,
  getRepresentativeAddressFromAskare,
  mapRepresentativeAskareAddressToPermitAddress,
  populateEmptyPermitAddress,
  getPermitRepresentativeNameFromAskare,
  getApplicantNameFromAskare,
  getRepresentativeNameFromPermit,
  getApplicantNameFromPermit,
} from '../permitHelper'
import { useFormApi } from './PermitPage/context'
import messages from '../messages'
import { setCurrentRepresentativeToPermitRepresentative as setCurrentRepresentativeToPermitRepresentativeAction } from '../../../../components/customer/actions'
import { isApplicantPerson } from '../../../../utils/auth'

export const REPRESENTATIVE_USER_BUT_APPLICATION_HAS_NOT_REPRESENTATIVE = 'REPRESENTATIVE_USER_BUT_APPLICATION_HAS_NOT_REPRESENTATIVE'
export const REPRESENTATIVE_USER_BUT_APPLICATION_HAS_ANOTHER_REPRESENTATIVE = 'REPRESENTATIVE_USER_BUT_APPLICATION_HAS_ANOTHER_REPRESENTATIVE'
export const APPLICANT_USER_BUT_APPLICATION_HAS_REPRESENTATIVE = 'APPLICANT_USER_BUT_APPLICATION_HAS_REPRESENTATIVE'

const populateName = args => populateVariableInTranslation({ variableToReplace: '{name}', ...args })

const populateVariableInTranslation = ({ translation, variableToReplace, value = '' }) => (translation ? translation.replace(variableToReplace, value) : '')

export function getPermitRepresentativeIdentification(formApi) {
  return formApi.values?.representativeDetails?.representativeIdentification
}

export function getCurrentUserApplicationsRepresentativeStatus(formApi) {
  const representative = getRepresentative()
  const representativeAuthorizationOrDecisionHolderIdentification = get(representative, 'representativeIdentification')
  const representativeIdentification = representativeAuthorizationOrDecisionHolderIdentification
  const permitRepresentativeIdentification = getPermitRepresentativeIdentification(formApi)

  /*
    Case 1: User is representative. There are NO representative information in application
  */
  if (representativeIdentification && !permitRepresentativeIdentification) {
    return REPRESENTATIVE_USER_BUT_APPLICATION_HAS_NOT_REPRESENTATIVE
  }

  /*
    Case 2: User is representative. There are OTHER representative information in application
  */
  if (representativeIdentification && permitRepresentativeIdentification && representativeIdentification !== permitRepresentativeIdentification) {
    return REPRESENTATIVE_USER_BUT_APPLICATION_HAS_ANOTHER_REPRESENTATIVE
  }

  /*
    Case 3: User is applicant. There are representative information in application
  */
  if (!representativeIdentification && permitRepresentativeIdentification) {
    return APPLICANT_USER_BUT_APPLICATION_HAS_REPRESENTATIVE
  }

  /*
    Case 4: Happy path, all good
  */
  return null
}


export function RepresentativeNotificationContainer({
  address,
  customer,
  onClickCallback,
  permitMessages,
  applicant,
  representative,
  focusAfterClose,
  intl,
  setCurrentRepresentativeToPermitRepresentative,
  isPerson,
  ...restProps
}) {
  const formApi = useFormApi()
  const { formatMessage }  = intl

  const currentUserApplicationsRepresentative = getCurrentUserApplicationsRepresentativeStatus(formApi)
  const representativeIdentification = get(representative, 'representativeIdentification')
  const representativeId = get(representative, 'id')

  const addRepresentative = () => {
    // Sync redux with new representitave
    setCurrentRepresentativeToPermitRepresentative(representativeId, representativeIdentification)
    formApi.setValue('representativeDetails', { representativeIdentification, ...address })

    if (onClickCallback) {
      setTimeout(() => onClickCallback(), 1)
    }

    updateStatusMessage(get(permitMessages, 'addedRepresentative'))

    if (focusAfterClose) {
      focusElementById(focusAfterClose)
    }
  }

  const setRepresentative = () => {
    // Sync redux with new representitave
    setCurrentRepresentativeToPermitRepresentative(representativeId, representativeIdentification)
    formApi.setValue('representativeDetails', { representativeIdentification, ...address })

    if (onClickCallback) {
      setTimeout(() => onClickCallback(), 1)
    }

    updateStatusMessage(get(permitMessages, 'changedRepresentative'))

    if (focusAfterClose) {
      focusElementById(focusAfterClose)
    }
  }

  const deleteRepresentative = () => {
    formApi.setValue('representative.representativeIdentification', null)
    formApi.setValue('representativeDetails', populateEmptyPermitAddress())

    if (onClickCallback) {
      setTimeout(() => onClickCallback(), 1)
    }

    updateStatusMessage(get(permitMessages, 'removedRepresentative'))

    if (focusAfterClose) {
      focusElementById(focusAfterClose)
    }
  }

  switch (currentUserApplicationsRepresentative) {
  /*
    Case 1: User is representative. There are NO representative information in application
    Show panel for adding representative information
  */
  case REPRESENTATIVE_USER_BUT_APPLICATION_HAS_NOT_REPRESENTATIVE: {
    const message = populateName({
      translation: get(permitMessages, 'addRepresentativeInfoMessage'),
      value: isPerson ? getApplicantNameFromPermit(formApi) : getApplicantNameFromAskare(applicant.id, customer) })
    const buttonText = onClickCallback ? get(permitMessages, 'addAndSendRepresentativeInfoButton') : get(permitMessages, 'addRepresentativeInfoButton')

    return <InlineNotification
      message={message}
      buttonText={buttonText}
      buttonIcon={'add'}
      onClick={addRepresentative}
      representativeInformationTitle={messages.representativeInformation}
      ariaLabel={formatMessage(messages.attention)}
      {...restProps}
    />
  }

  /*
    Case 2: User is representative. There are OTHER representative information in application
    Show panel for changing representative information
  */
  case REPRESENTATIVE_USER_BUT_APPLICATION_HAS_ANOTHER_REPRESENTATIVE: {
    const permitRepresentativeIdentification = getPermitRepresentativeIdentification(formApi)

    const message = populateName({
      translation: get(permitMessages, 'changeRepresentativeInfoMessage'),
      value: isPerson ? getRepresentativeNameFromPermit(formApi) : getPermitRepresentativeNameFromAskare(permitRepresentativeIdentification, customer),
    })
    const buttonText = onClickCallback ? get(permitMessages, 'changeAndSendRepresentativeInfoButton') : get(permitMessages, 'changeRepresentativeInfoButton')

    return <InlineNotification
      message={message}
      buttonText={buttonText}
      buttonIcon={'edit'}
      onClick={setRepresentative}
      representativeInformationTitle={messages.representativeInformation}
      ariaLabel={formatMessage(messages.attention)}
      {...restProps}
    />
  }


  /*
    Case 3: User is applicant. There are representative information in application
    Show panel for removing representative information
  */
  case APPLICANT_USER_BUT_APPLICATION_HAS_REPRESENTATIVE: {
    const permitRepresentativeIdentification = getPermitRepresentativeIdentification(formApi)
    const message = populateName({
      translation: get(permitMessages, 'removeRepresentativeInfoMessage'),
      value: isPerson ? getRepresentativeNameFromPermit(formApi) : getPermitRepresentativeNameFromAskare(permitRepresentativeIdentification, customer) })
    const buttonText = onClickCallback ? get(permitMessages, 'removeAndSendRepresentativeInfoButton') : get(permitMessages, 'removeRepresentativeInfoButton')

    return <InlineNotification
      message={message}
      buttonText={buttonText}
      buttonIcon={'delete'}
      onClick={deleteRepresentative}
      representativeInformationTitle={messages.representativeInformation}
      ariaLabel={formatMessage(messages.attention)}
      {...restProps}
    />
  }

  /*
    Case 4: Happy path, all good
  */
  default:
    return null
  }
}


export function getPrivateRepresentativeAddress(representative) {
  const representativeName = get(representative, 'name')
  const representativeAddress = get(representative, 'address')
  const representativePostalCode = get(representative, 'zipCode')
  const representativeCity = get(representative, 'city')
  const representativeCountryCode = get(representative, 'countryCode')

  return { representativeName, representativeAddress, representativePostalCode, representativeCity, representativeCountryCode }
}

const mapStateToProps = (state) => {
  const customer = get(state, 'customer')
  const applicant = getApplicant()
  const representative = getRepresentative()
  const isPerson = isApplicantPerson()

  const permitMessages = collectCmsMessages('/permits', get(state, 'content.cmsMessages', {}))
  const representativeInformation = get(state.customer, 'representativeInformation')

  const representativeAskareAddress = getRepresentativeAddressFromAskare(get(representative, 'id'), customer)
  const companyName = getRepresentativeNameFromAskare(get(representative, 'id'), customer)
  const address = isPerson ? getPrivateRepresentativeAddress(representative) : mapRepresentativeAskareAddressToPermitAddress(companyName, representativeAskareAddress)

  return {
    representativeInformation,
    address,
    permitMessages,
    customer,
    applicant,
    representative,
    isPerson,
  }
}

const mapActionCreators = {
  setCurrentRepresentativeToPermitRepresentative: setCurrentRepresentativeToPermitRepresentativeAction,
}

export default injectIntl(connect(mapStateToProps, mapActionCreators)(RepresentativeNotificationContainer))
