import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Row, Col } from 'react-bootstrap'
import { get } from 'lodash'
import Loader from 'src/components/Loader'
import messages from '../../customer/messages'
import { useFormApi } from '../../../routes/permits/permit/components/PermitPage/context'
import { getPermitRepresentativeAddressFromAskare, mapRepresentativeAskareAddressToPermitAddress } from '../../../routes/permits/permit/permitHelper'
import styles from '../../customer/components/customerInformation.scss'

function getRepresentativeDetails(isPerson, formApi, customer) {
  if (isPerson) {
    return get(formApi, 'values.representativeDetails')
  }

  const permitRepresentativeIdentification = get(formApi, 'values.representativeDetails.representativeIdentification')
  const representativeDetails = get(customer.permitRepresentativeInformation, permitRepresentativeIdentification)
  const details = mapRepresentativeAskareAddressToPermitAddress(
    representativeDetails?.fullName,
    getPermitRepresentativeAddressFromAskare(permitRepresentativeIdentification, customer))

  return { representativeIdentification: permitRepresentativeIdentification, ...details }
}

export default function RepresentativeInformation({ customer, isPerson, representative }) {
  const formApi = useFormApi()
  const details = getRepresentativeDetails(isPerson, formApi, customer)
  const currentRepresentativeHasPersonalDataAccessRestriction = get(representative, 'hasPersonalDataAccessRestriction')
  const currentRepresentativeIsApplicationRepresentative = representative.representativeIdentification === details.representativeIdentification
  const hideAddress = currentRepresentativeIsApplicationRepresentative && currentRepresentativeHasPersonalDataAccessRestriction

  return (
    <div>
      {customer.fetchingPermitRepresentative &&
      <Loader
        blocking
        message={{ ...messages.loadingRepresentativeInformation }}
      />
      }
      <Row className={styles.verticalCol}>
        <Col xs={12}>
          <label>
            <FormattedMessage {...messages.representative} />
          </label>
        </Col>
        <Col xs={12}>
          <span id-qa-test={'label-representative-name'}>
            {get(details, 'representativeName') || ''}
          </span>
        </Col>
      </Row>

      <Row className={styles.verticalCol}>
        <Col xs={12}>
          <label>
            <FormattedMessage {...messages.customerInformationTitle} />
          </label>
        </Col>
        <Col sm={6}>
          <FormattedMessage {...messages.identifier} />
        </Col>
        <Col sm={6} id-qa-test={'label-EORI'}>
          {get(details, 'representativeIdentification')}
        </Col>
      </Row>
      {!hideAddress &&
        <Row className={styles.verticalCol}>
          <Col sm={6}>
            <FormattedMessage {...messages.contactInformation} />
          </Col>
          <Col sm={6} id-qa-test={'label-contactInformation'}>
            <div>{get(details, 'representativeAddress')}</div>
            <div>{get(details, 'representativePostalCode')} {get(details, 'representativeCity')} {get(details, 'representativeCountryCode')}</div>
          </Col>
        </Row>
      }
    </div>
  )
}
