import React from 'react'
import { FormControl } from 'react-bootstrap'
import { Field } from 'react-form'

export default function Static(props) {
  return (
    <FormControl.Static
      id={props.input.name}
      id-qa-test={`label-${props.input.name}`}
    >
      {props.input.placeholder || props.value}
    </FormControl.Static>
  )
}

export function StaticWithField(props) {
  return (
    <Field
      field={props.input.name}
      id-qa-test={`label-${props.input.name}`}
    >
      {(fieldApi) => {
        const { value } = fieldApi
        return (
          <Static
            {...props}
            value={value}
            id-qa-test={`value-${props.input.name}`}
          />
        )
      }}
    </Field>
  )
}
