import React from 'react'
import { get } from 'lodash'
import ReactMarkdown from 'react-markdown'
import Modal from 'src/components/Modal'
import LinkRenderer from 'src/components/LinkRenderer'

export default function (props) {
  const { visible, helpTexts } = props
  const previousServicePath = localStorage.getItem('previousServicePath')
  const logoutMessage = get(helpTexts, `${previousServicePath}/logoutModalContent`, '') ||
    get(helpTexts, '/authentication/logoutModalDefaultContent', '')

  return (
    <Modal
      show={visible}
      showContinue
      showCancel={false}
      disableEscKey
      titleMessage={get(helpTexts, '/authentication/logoutModalTitle')}
      continueMessage={get(helpTexts, '/authentication/logoutModalContinueButtonText')}
      onClickContinue={() => {
        localStorage.removeItem('previousServicePath')
        props.router.replace('/')
      }}
      focusButton
    >
      <div id="dialog-message" className="lead">
        <ReactMarkdown
          source={logoutMessage}
          renderers={{ Link: LinkRenderer }}
          target={'_blank'}
        />
      </div>
    </Modal>
  )
}
