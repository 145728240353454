import { defineMessages } from 'react-intl'

const messages = defineMessages({
  newItemHeading: {
    id: 'permits.repetitiveComponents.newItemHeading',
    description: 'Label for modal adding new item',
    defaultMessage: '?? Add new item',
  },
  newGuaranteePerCustomsProcedureHeading: {
    id: 'permits.repetitiveComponents.newGuaranteePerCustomsProcedureHeading',
    description: 'Label for modal adding new item',
    defaultMessage: '?? Add new guarantee per customs procedure item',
  },
  itemEditHeading: {
    id: 'permits.repetitiveComponents.itemEditHeading',
    description: 'Label for modal editing item',
    defaultMessage: '?? Edit item',
  },
  editGuaranteePerCustomsProcedureHeading: {
    id: 'permits.repetitiveComponents.editGuaranteePerCustomsProcedureHeading',
    description: 'Label for modal editing item',
    defaultMessage: '?? Edit  guarantee per customs procedure item',
  },
  noRows: {
    id: 'permits.repetitiveComponents.noRows',
    description: 'Information message for empty repetitiveComponents list',
    defaultMessage: '?? No items',
  },
  deleteItemConfirmation: {
    id: 'permits.repetitiveComponents.deleteItemConfirmation',
    description: 'Label for deleting item',
    defaultMessage: '?? Do you really want to delete selected item? This cannot be undone.',
  },
  continueDeleteConfirmation: {
    id: 'permits.repetitiveComponents.continueDeleteConfirmation',
    description: 'Button label for deleting item',
    defaultMessage: '?? Yes',
  },
  ignoreDeleteConfirmation: {
    id: 'permits.repetitiveComponents.ignoreDeleteConfirmation',
    description: 'Button label for ignoring deleting item',
    defaultMessage: '?? No',
  },
  deleteItemText: {
    id: 'permits.repetitiveComponents.deleteItemText',
    description: 'Label for modal title',
    defaultMessage: '?? Delete item',
  },
  edit: {
    id: 'permits.repetitiveComponents.edit',
    description: 'label for item edit button',
    defaultMessage: '?? Edit',
  },
  add: {
    id: 'permits.repetitiveComponents.add',
    description: 'Label for adding item',
    defaultMessage: '?? Add item',
  },
  addGuaranteePerCustomsProcedure: {
    id: 'permits.repetitiveComponents.addGuaranteePerCustomsProcedure',
    description: 'Add guaranteePerCustomsProcedure button',
    defaultMessage: '?? Add guarantee per customs procedure',
  },
  save: {
    id: 'permits.repetitiveComponents.save',
    description: 'Label for saving item',
    defaultMessage: '?? Save item',
  },
  saveProcedure: {
    id: 'permits.repetitiveComponents.saveProcedure',
    description: 'Label for saving procedure',
    defaultMessage: '?? Save procedure',
  },
  saveMessageDeclarantProviderIdentification: {
    id: 'permits.repetitiveComponents.saveMessageDeclarantProviderIdentification',
    description: 'Label for saving message declarant provider identification',
    defaultMessage: '?? Save the service provider',
  },
  cancelMessageDeclarantProviderIdentification: {
    id: 'permits.repetitiveComponents.cancelMessageDeclarantProviderIdentification',
    description: 'Label for cancelling message declarant provider identification',
    defaultMessage: '?? Cancel',
  },
  messageDeclarantProviderIdentificationTitle: {
    id: 'permits.repetitiveComponents.messageDeclarantProviderIdentificationTitle',
    description: 'Modal alert for message declarant provider identification',
    defaultMessage: '?? Save item',
  },
  cancel: {
    id: 'permits.repetitiveComponents.cancel',
    description: 'Label for cancelling item editing',
    defaultMessage: '?? Cancel',
  },
  delete: {
    id: 'permits.repetitiveComponents.delete',
    description: 'Label for deleting item',
    defaultMessage: '?? Delete',
  },
  deleteGuaranteeCustomsProcedure: {
    id: 'permits.repetitiveComponents.deleteGuaranteeCustomsProcedure',
    description: 'Label for deleting guarantee customs procedure',
    defaultMessage: '?? Delete guarantee customs procedure',
  },
  doneSavingItem: {
    id: 'permits.repetitiveComponents.doneSavingItem',
    description: 'Notification message shown after saving item',
    defaultMessage: '?? Item was saved successfully',
  },
  missingMandatoryFields: {
    id: 'permits.repetitiveComponents.missingMandatoryFields',
    description: 'Notification message for missing fields',
    defaultMessage: '?? Fill all required fields',
  },
  missingRequiredRowsAmountOfDuty: {
    id: 'permits.repetitiveComponents.missingRequiredRows_amountOfDuty',
    description: 'Notification message for missing rows',
    defaultMessage: '?? Missing mandatory rows',
  },
  missingRequiredRows: {
    id: 'permits.repetitiveComponents.missingRequiredRows',
    description: 'Notification message for missing rows',
    defaultMessage: '?? Missing mandatory rows',
  },
  mandatoryRowDescription: {
    id: 'permits.repetitiveComponents.mandatoryRowDescription',
    description: 'Description message for mandatory row',
    defaultMessage: '?? This information is mandatory. At least one row has to be added.',
  },
  radioSelectionYes: {
    id: 'permits.radioSelectionYes',
    description: 'Text for selection in radio',
    defaultMessage: '?? Yes',
  },
  radioSelectionNo: {
    id: 'permits.radioSelectionNo',
    description: 'Text for selection in radio',
    defaultMessage: '?? No',
  },
})

export default messages
