/* eslint-disable max-len */
import { defineMessages } from 'react-intl'

const messages = defineMessages({
  newItemHeading: {
    id: 'permits.goods.newItemHeading',
    description: 'Label for modal adding new item',
    defaultMessage: '?? Add new item',
  },
  itemEditHeading: {
    id: 'permits.goods.itemEditHeading',
    description: 'Label for modal editing item',
    defaultMessage: '?? Edit item',
  },
  deleteItemConfirmation: {
    id: 'permits.goods.deleteItemConfirmation',
    description: 'Label for deleting item',
    defaultMessage: '?? Do you really want to delete selected item? This cannot be undone.',
  },
  continueDeleteConfirmation: {
    id: 'permits.goods.continueDeleteConfirmation',
    description: 'Button label for deleting item',
    defaultMessage: '?? Yes',
  },
  ignoreDeleteConfirmation: {
    id: 'permits.goods.ignoreDeleteConfirmation',
    description: 'Button label for ignoring deleting item',
    defaultMessage: '?? No',
  },
  deleteItemText: {
    id: 'permits.goods.deleteItemText',
    description: 'Label for modal title',
    defaultMessage: '?? Delete item',
  },
  add: {
    id: 'permits.goods.add',
    description: 'Label for adding item',
    defaultMessage: '?? Add item',
  },
  save: {
    id: 'permits.goods.save',
    description: 'Label for saving item',
    defaultMessage: '?? Save item',
  },
  cancel: {
    id: 'permits.goods.cancel',
    description: 'Label for cancelling item editing',
    defaultMessage: '?? Cancel',
  },
  delete: {
    id: 'permits.goods.delete',
    description: 'Label for deleting item',
    defaultMessage: '?? Delete',
  },
  doneSavingItem: {
    id: 'permits.goods.doneSavingItem',
    description: 'Notification message shown after saving item',
    defaultMessage: '?? Item was saved successfully',
  },
  missingMandatoryFields: {
    id: 'permits.goods.missingMandatoryFields',
    description: 'Notification message for missing fields',
    defaultMessage: '?? Fill all required fields',
  },
  missingRequiredRows: {
    id: 'permits.goods.missingRequiredRows',
    description: 'Notification message for missing rows',
    defaultMessage: '?? Missing mandatory rows',
  },
  rowNumber: {
    id: 'permits.goods.rowNumber',
    description: 'Table label for row number',
    defaultMessage: '?? Row',
  },
  goodNotFound: {
    id: 'permits.goods.notFound',
    description: 'Error message for input field',
    defaultMessage: '?? Good not found',
  },
  taricCodeNotValid: {
    id: 'permits.goods.taricCodeNotValid',
    description: 'Error message for input field',
    defaultMessage: '?? Taric code not valid',
  },
  itemNumber: {
    id: 'permits.goods.itemNumber',
    description: 'Table label for item number',
    defaultMessage: '?? Item number',
  },
  addFile: {
    id: 'permits.goods.addFile',
    description: 'Button text',
    defaultMessage: '?? Import from file',
  },
  addFileAdditionalHelp: {
    id: 'permits.goods.addfileAdditionalHelp',
    description: 'Text in file drop area',
    defaultMessage: '?? or attach a file by dropping it here. You can also add goods one by one with "Add good" button.',
  },
  invalidFileType: {
    id: 'permits.goods.invalidFileType',
    description: 'Notification message',
    defaultMessage: '?? File must be CSV or TXT file.',
  },
  fileReaderError: {
    id: 'permits.goods.fileReaderError',
    description: 'Notification message',
    defaultMessage: '?? File reader errors',
  },
  invalidNumberOfDataColumnsInCSV: {
    id: 'permits.goods.invalidNumberOfDataColumnsInCSV',
    description: 'Error message for invalid number of data columns in a CSV file.',
    defaultMessage: '?? Invalid number of data columns on line {lineNumber}.',
  },
  invalidEmptyValueInCSV: {
    id: 'permits.goods.invalidEmptyValueInCSV',
    description: 'Error message for an empty column in a CSV file.',
    defaultMessage: 'Invalid empty value on line {lineNumber} column {columnNumber}.',
  },
  invalidNumberOfRows: {
    id: 'permits.goods.invalidNumberOfRows',
    description: 'Error message for invalid number of rows.',
    defaultMessage: '?? Invalid number of rows.',
  },
  goodsCode: {
    id: 'permits.goods.goodsCode',
    description: 'Table label for goods code',
    defaultMessage: '?? Goods code',
  },
  goodsDescription: {
    id: 'permits.goods.goodsDescription',
    description: 'Table label for goods description',
    defaultMessage: '?? Goods description',
  },
  goodsHeaderDescription: {
    id: 'permits.goods.goodsHeaderDescription',
    desription: 'Description text for goods modal title',
    defaultMessage: '?? Insert goods with (min) 4 digits code.',
  },
  fieldDescriptionFintaric: {
    id: 'permits.goods.fieldDescriptionFintaric',
    desription: 'Field description Fintaric',
    defaultMessage: '?? Insert 4, 6, 8 or 10 digit Fintaric code.',
  },
  fieldHeaderDescriptionRelief: {
    id: 'permits.goods.fieldHeaderDescriptionRelief',
    desription: 'Field header relief',
    defaultMessage: '?? Add at least 4 digits.',
  },
  additionalFieldDesc: {
    id: 'permits.goods.additionalFieldDesc',
    desription: 'Field description additional information',
    defaultMessage: '?? Give precision information about the good',
  },
  saveAndAddNew: {
    id: 'permits.goods.saveAndAddNew',
    description: 'Label for saving and adding new row',
    defaultMessage: '?? Save and add new',
  },
  error: {
    id: 'permits.goods.error',
    description: 'Label error',
    defaultMessage: '?? Error',
  },
  useEquivalentGood: {
    id: 'permits.goods.useEquivalentGood',
    description: 'Text for selector',
    defaultMessage: 'Use equivalent good',
  },
  table: {
    id: 'table.label',
    description: 'Table aria text',
    defaultMessage: 'Table',
  },
})

export default messages
