import React from 'react'
import { Col, Button, Row, ButtonToolbar } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { Form } from 'react-form'
import { get, includes, filter, omit } from 'lodash'
import ErrorBoundary from 'src/components/ErrorBoundary'
import Icon from 'src/components/Icon'
import Modal from 'src/components/Modal'
import showNotification from 'src/utils/notifications'
import { focusFirstInputWithErrorInModal } from 'src/utils'
import messages from '../../GoodsToBePlacedUnderProcedure/messages'
import { checkFormValues as checkGoodsFormValues } from '../../GoodsToBePlacedUnderProcedure/utils'
import { checkFormValues as checkRepetitiveFormValues } from '../../RepetitiveComponent/utils'
import GoodsToBePlacedUnderProcedureItemForm from '../../GoodsToBePlacedUnderProcedure/components/ItemForm'
import RepetitiveComponentItemForm from '../../RepetitiveComponent/components/ItemForm'
import { USE_EQUIVALENT_GOOD_FIELD_NAME } from '../../GoodsToBePlacedUnderProcedure/constants'

const MODE_SAVE_AND_ADD_NEW = 'saveAndAddNew'

const defaultState = {
  isNew: null,
  isFetchingDescription: false,
  showDeleteConfirmationModal: false,
  saveMode: null,
}
export default class ItemForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = defaultState
    this.formApi = null

    this.save = this.save.bind(this)
    this.cancel = this.cancel.bind(this)
    this.showDeleteModal = this.showDeleteModal.bind(this)
    this.connectFormApi = this.connectFormApi.bind(this)
    this.toggleEquivalentGoodsFields = this.toggleEquivalentGoodsFields.bind(this)
  }

  // In equivalent goods user has an option to use or not to use equivalent good, this affects to mandatories of fields
  toggleEquivalentGoodsFields(useEquivalentFields) {
    if (!this.formApi) {
      setTimeout(() => {
        this.toggleEquivalentGoodsFields(useEquivalentFields)
      }, 100)
      return
    }
    const { infoElements } = this.props
    const formState = this.formApi.getFormState()
    const equivalentGoodCodes = infoElements.infoElementEquivalentGood.fields
      .map(field => field.code)
    const goodsIdentificationCodes = get(infoElements.infoElementGoodsIdentification, 'fields', [])
      .map(field => field.code)

    const hiddenFieldCodes = useEquivalentFields === 'true'
      ? goodsIdentificationCodes
      : equivalentGoodCodes

    this.formApi.setFormState({
      ...formState,
      errors: omit(formState.errors, hiddenFieldCodes),
      values: {
        ...omit(formState.values, [...equivalentGoodCodes, ...goodsIdentificationCodes]),
        [USE_EQUIVALENT_GOOD_FIELD_NAME]: useEquivalentFields,
      },
    })
  }


  connectFormApi(formApi) {
    this.formApi = formApi
  }

  clearFormApi() {
    this.formApi.setFormState({})
  }

  cancel(mode) {
    if (mode === MODE_SAVE_AND_ADD_NEW) {
      this.clearFields()
      const taricCodeElem = document.getElementById('goodsToBePlacedUnderProcedureCombinedNomenclatureAndTaricCode')
      taricCodeElem.focus()
    } else {
      if (this.state.isNew) {
        this.setState({ isNew: false })
      } else {
        this.props.cancelEditing()
      }
      this.clearFormApi()
    }
  }

  clearFields() {
    this.formApi.setAllValues({})
  }

  afterDoneSaving(mode) {
    this.cancel(mode)
    showNotification({
      level: 'success',
      message: messages.doneSavingItem,
      autoDismiss: true,
    })
  }

  // eslint-disable-next-line consistent-return
  save() {
    const { addNew, updateItem, selectedItem, infoElements } = this.props
    const { isNew, saveMode } = this.state

    const formState = this.formApi && this.formApi.getFormState()
    const formData = get(formState, 'values')

    const goodsInfoElements = filter(infoElements, infoElement =>
      includes(['goodsToBePlacedUnderProcedure', 'equivalentGood'], infoElement.code)
    )
    const otherInfoElements = filter(infoElements, infoElement =>
      !includes(['goodsToBePlacedUnderProcedure', 'equivalentGood'], infoElement.code)
    )

    let hasErrors = false

    // validate goods infoElements first
    goodsInfoElements.forEach((infoElement) => {
      const { codeFieldName } = infoElement
      if (infoElement.code !== 'equivalentGood' || get(formData, USE_EQUIVALENT_GOOD_FIELD_NAME, '') === 'true') {
        const taricFieldCode = `${infoElement.code}${codeFieldName}`
        if (get(formData, taricFieldCode, '') !== '') {
          this.formApi.setValue(taricFieldCode, formData[taricFieldCode])
        }
        const taricDescription = get(formData, `${taricFieldCode}Description`, null)

        const isValid = checkGoodsFormValues(
          this.formApi,
          infoElement,
          taricDescription,
          taricFieldCode,
        )
        if (!isValid) {
          hasErrors = true
        }
      }
    })
    // validate other infoElements
    otherInfoElements.forEach((infoElement) => {
      const isValid = checkRepetitiveFormValues(
        this.formApi,
        infoElement,
      )
      if (!isValid) {
        hasErrors = true
      }
    })


    if (!hasErrors) {
      const newFormData = get(this.formApi.getFormState(), 'values')
      if (isNew) {
        addNew(newFormData)
        this.afterDoneSaving(saveMode)
      } else if (selectedItem) {
        updateItem(selectedItem, newFormData)
        this.afterDoneSaving(saveMode)
      }
    }
  }

  showDeleteModal() {
    this.setState({
      showDeleteConfirmationModal: true,
    })
  }

  render() {
    const {
      selectedItem,
      infoElements,
      renderField,
      cancelEditing,
      updateItem,
      deleteItem,
      addNew,
      permitTypeCode,
      locale,
    } = this.props
    const {
      isNew,
      showDeleteConfirmationModal,
    } = this.state

    const titleMessage = isNew ? messages.newItemHeading : messages.itemEditHeading
    const slidingContentStyle = {
      transition: 'transform 200ms ease-in-out',
    }

    const modalButtons = []
    modalButtons.push(<Button
      key="modalCancel"
      bsStyle="default"
      className="pull-left"
      onClick={this.cancel}
      id-qa-test="button-cancel"
    >
      <FormattedMessage {...messages.cancel} />
    </Button>)
    if (isNew) {
      modalButtons.push(<Button
        key="modalSaveAndAddNew"
        bsStyle="default"
        style={{ marginRight: '20px' }}
        onClick={(formData) => {
          this.setState({ saveMode: MODE_SAVE_AND_ADD_NEW })
          this.formApi.submitForm(formData)
            .then(focusFirstInputWithErrorInModal)
        }}
        id-qa-test="button-save-add"
      >
        <Icon name="addnew" /><FormattedMessage {...messages.saveAndAddNew} />
      </Button>)
    }
    modalButtons.push(<Button
      key="modalSaveAndClose"
      bsStyle="primary"
      className="pull-right"
      onClick={(formData) => {
        this.setState({ saveMode: null })
        this.formApi.submitForm(formData)
          .then(focusFirstInputWithErrorInModal)
      }}
      id-qa-test="button-save"
    >
      <Icon name="addnew" /><FormattedMessage {...messages.save} />
    </Button>)

    if (!isNew) {
      modalButtons.push(<Button
        key="modalDelete"
        bsStyle="default"
        className="pull-right"
        style={{ marginRight: '20px' }}
        onClick={this.showDeleteModal}
        id-qa-test="button-delete"
      >
        <Icon name="delete" /><FormattedMessage {...messages.delete} />
      </Button>)
    }

    const commonPropsForInfoElements = {
      selectedItem,
      cancelEditing,
      updateItem,
      deleteItem,
      locale,
      renderField,
      addNew,
      intl: this.props.intl,
      permitTypeCode,
      combined: true,
    }

    return (
      <div>
        <ErrorBoundary>
          <ButtonToolbar>
            <Button
              id={'new-row-combined-good'}
              aria-describedby={'new-row-description-goodsToBePlacedUnderProcedure'}
              bsStyle="primary"
              onClick={() => this.setState({ isNew: true })}
              id-qa-test={'button-add-combined-good'}
            >
              <Icon name="add" /><FormattedMessage {...messages.add} />
            </Button>
          </ButtonToolbar>
          <Modal
            show={Boolean(isNew || selectedItem)}
            size="lg"
            showCancel={false}
            showContinue={false}
            onEscKey={this.cancel}
            titleMessage={titleMessage}
            animation={false}
            contentRef={(ref) => {
              this.modalContentRef = ref
            }}
            customFooterButtons={modalButtons}
          >
            <Modal
              show={showDeleteConfirmationModal}
              showContinue
              showCancel
              titleMessage={messages.deleteItemText}
              continueMessage={messages.continueDeleteConfirmation}
              cancelMessage={messages.ignoreDeleteConfirmation}
              onClickCancel={() => this.setState({ showDeleteConfirmationModal: false })}
              onClickContinue={() => {
                deleteItem(selectedItem)
                this.setState({ showDeleteConfirmationModal: false })
                this.clearFields()
                this.props.cancelEditing()
              }}
              focusDisableButton
            >
              <Row>
                <Col xs={2}>
                  <Icon
                    name={'delete'}
                    style={{ display: 'block', fontSize: '26px' }}
                  />
                </Col>
                <Col xs={10}>
                  <p className="lead">
                    <FormattedMessage {...messages.deleteItemConfirmation} />
                  </p>
                </Col>
              </Row>
            </Modal>
            <Row>
              <div
                ref={(ref) => {
                  this.contentRef = ref
                }}
                style={slidingContentStyle}
              >
                <Form
                  defaultValues={selectedItem && selectedItem}
                  onSubmit={this.save}
                  getApi={this.connectFormApi}
                  render={formApi => [
                    infoElements.infoElementGoodsToBePlacedUnderProcedure &&
                    <GoodsToBePlacedUnderProcedureItemForm
                      key="itemForm-1"
                      infoElement={infoElements.infoElementGoodsToBePlacedUnderProcedure}
                      formApi={formApi}
                      codeFieldName={infoElements.infoElementGoodsToBePlacedUnderProcedure.codeFieldName}
                      {...commonPropsForInfoElements}
                    />,
                    infoElements.infoElementYieldRate &&
                    <RepetitiveComponentItemForm
                      key="itemForm-2"
                      infoElement={infoElements.infoElementYieldRate}
                      formApi={formApi}
                      codeFieldName={infoElements.infoElementYieldRate.codeFieldName}
                      {...commonPropsForInfoElements}
                    />,
                    infoElements.infoElementEquivalentGood &&
                    <GoodsToBePlacedUnderProcedureItemForm
                      key="itemForm-3"
                      infoElement={infoElements.infoElementEquivalentGood}
                      formApi={formApi}
                      useFieldsToggle
                      onFieldsToggle={this.toggleEquivalentGoodsFields}
                      codeFieldName={infoElements.infoElementEquivalentGood.codeFieldName}
                      {...commonPropsForInfoElements}
                    />,
                    infoElements.infoElementGoodsIdentification &&
                    formApi.values[USE_EQUIVALENT_GOOD_FIELD_NAME] !== 'true' &&
                    <RepetitiveComponentItemForm
                      key="itemForm-4"
                      infoElement={infoElements.infoElementGoodsIdentification}
                      formApi={formApi}
                      codeFieldName={infoElements.infoElementGoodsIdentification.codeFieldName}
                      {...commonPropsForInfoElements}
                    />,
                    infoElements.infoElementEconomicConditions &&
                    <RepetitiveComponentItemForm
                      key="itemForm-5"
                      infoElement={infoElements.infoElementEconomicConditions}
                      formApi={formApi}
                      codeFieldName={infoElements.infoElementEconomicConditions.codeFieldName}
                      {...commonPropsForInfoElements}
                    />,
                  ]}
                />
              </div>
            </Row>
          </Modal>
        </ErrorBoundary>
      </div>
    )
  }
}
