import React from 'react'
import { injectIntl } from 'react-intl'
import ReactMarkdown from 'react-markdown'
import { ControlLabel, Button } from 'react-bootstrap'
import { isEmpty, omit } from 'lodash'
import Icon from 'src/components/Icon'
import Popper from 'src/components/Popper'
import LinkRenderer from 'src/components/LinkRenderer'
import messages from '../messages'

/**
 * Label area for a form control. Renders responsive grid column instead of simple ControlLabel if horizontal=true
 */
function LabelArea({
  id,
  htmlFor,
  input,
  children,
  help,
  mandatory,
  disabled,
  showLabel,
  label,
  staticValue,
  intl: { formatMessage },
}) {
  const className = disabled && !staticValue ? 'text-muted' : ''
  const allowMultilineLabel = input.multilineLabel
  // stretch label to cross the 'fetch company name'-button
  const widthen = (id === 'messageDeclarantProviderIdentificationLabel')
  let helpButton
  if (help && !isEmpty(help.content) && showLabel) {
    const helpLabel = `${label} ${formatMessage(messages.help)}`
    const helpPopperContent = (
      <ReactMarkdown
        source={help.content}
        renderers={{ Link: LinkRenderer }}
      />
    )
    helpButton = (
      <Popper
        aria-label={helpLabel}
        type="popover"
        id={`help-popover-${input.name}`}
        className="help-toggle"
        trigger="click"
        rootClose
        placement="bottom"
        content={helpPopperContent}
        closeIcon
        large
        usePortal
      >
        <Button
          className="btn-help"
          bsStyle="link"
          bsSize="xs"
          title={formatMessage(messages.help)}
          id-qa-test={`btn-help-${input.name}`}
          style={{ paddingRight: widthen ? '10px' : 'inherit' }}
        >
          <Icon name="help" />
          <span className="sr-only">{helpLabel}</span>
        </Button>
      </Popper>
    )
  }
  let mandatoryText = ''
  if (!input.static && !mandatory && showLabel) {
    mandatoryText = `(${formatMessage(messages.formFieldOptional)})`
  }

  const divStyles = {
    position: widthen ? 'unset' : 'relative',
    height: showLabel ? 'auto' : 0,
  }
  let labelStyles = {
    paddingRight: helpButton ? '35px' : '0px',
    overflow: 'hidden',
    marginBottom: 0,
  }
  if (allowMultilineLabel) {
    labelStyles = omit(labelStyles, 'whiteSpace')
  }
  return (
    <div style={divStyles}>
      <ControlLabel id={id} srOnly={!showLabel} style={labelStyles} className={className} htmlFor={htmlFor}>
        {children} {mandatoryText}
      </ControlLabel>{helpButton}
    </div>
  )
}

export default injectIntl(LabelArea)
