/**
 * Error page shown when this app could not be started properly
 * @module layout/RootError
 */

import React from 'react'
import { Jumbotron, Button } from 'react-bootstrap'
import { FormattedMessage, IntlProvider, defineMessages } from 'react-intl'
import * as i18n from '../i18n'
import Heading from '../layout/Heading'
import '../styles/core.scss'

const messages = defineMessages({
  errorTitle: {
    id: 'rootError.title',
    description: 'Title for unknown error in application startup, most likely because of network errors',
    defaultMessage: 'Error',
  },
  errorMessage: {
    id: 'rootError.message',
    description: 'Message for unknown error in application startup, most likely because of network errors',
    defaultMessage: 'Could not start the app, please try reloading this page.',
  },
  reloadButton: {
    id: 'rootError.reloadButtonLabel',
    description: 'Label for reload button in application startup error',
    defaultMessage: 'Reload',
  },
})


export default function RootError({ locale }) {
  return (
    <IntlProvider key={locale} locale={locale} messages={i18n[locale]}>
      <div className="page-container">
        <div className="view-container" style={{ paddingTop: '40px' }}>
          <Heading message={messages.errorTitle} compact showNotifications={false} />
          <div className="container">
            <Jumbotron style={{ background: 'white' }}>
              <p className="lead">
                <FormattedMessage {...messages.errorMessage} />
              </p>
              <p>
                <Button bsSize="large" onClick={() => location.reload()}>
                  <FormattedMessage {...messages.reloadButton} />
                </Button>
              </p>
            </Jumbotron>
          </div>
        </div>
      </div>
    </IntlProvider>
  )
}
