import React from 'react'
import ReactMarkdown from 'react-markdown'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Button } from 'react-bootstrap'
import Icon from 'src/components/Icon'
import Popper from 'src/components/Popper'
import LinkRenderer from 'src/components/LinkRenderer'
import IconRenderer from 'src/components/IconRenderer'
import messages from '../messages'

function HeaderArea({
  small,
  id,
  title,
  descriptionText,
  intl: { formatMessage },
  help,
  showHelp = true,
  legend = false,
  mandatory = true,
}) {
  let helpButton
  const titleSmall = small || false

  if (help && showHelp) {
    const helpPopperContent = (
      <ReactMarkdown
        source={help}
        renderers={{ Link: LinkRenderer }}
      />
    )
    helpButton = (
      <Popper
        type="popover"
        id={`help-popover-${id}`}
        className="help-toggle"
        trigger="click"
        rootClose
        placement="bottom"
        content={helpPopperContent}
        closeIcon
        large
        usePortal
      >
        <Button
          className="btn-help"
          bsStyle="link"
          bsSize="xs"
          id-qa-test={`btn-help-infoelement-${id}`}
        >
          <Icon name="help" />
          <span className="sr-only">{title} {formatMessage(messages.help)}</span>
        </Button>
      </Popper>
    )
  }
  const labelStyles = {
    paddingRight: helpButton ? '35px' : '0px',
  }

  let optionalText = ''
  if (!mandatory) {
    optionalText = `(${formatMessage(messages.formFieldOptional)})`
  }

  return (
    <div>
      {!titleSmall &&
        <div
          style={{ position: 'relative', height: 'auto', ...labelStyles }}
          id={descriptionText ? 'dialog-message' : undefined}
        >
          {legend &&
            <legend>
              <h3 id={id}>{title}</h3>
            </legend>
          }
          {!legend &&
            <h3 className="h4" id={id} tabIndex={-1}>{title}</h3>
          }
          {helpButton}
          {descriptionText && (typeof descriptionText === 'string' ? <ReactMarkdown
            source={descriptionText}
            renderers={{ link: LinkRenderer, Image: IconRenderer }}
            linkTarget="_blank"
          /> : (
            <div style={{ marginBottom: '20px' }}>
              <FormattedMessage {...descriptionText} />
            </div>
          ))
          }

        </div>
      }
      {titleSmall && <span id={id} className={'customLabel help-block'} style={labelStyles}>
        {title} {optionalText} {helpButton}
      </span>}
    </div>
  )
}

export default injectIntl(HeaderArea)
