import React from 'react'
import { Form } from 'react-form'
import { Row, Col } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { find, get } from 'lodash'
import { focusFirstInputElementInModal } from 'src/utils'
import Modal from 'src/components/Modal'
import messages from '../messages'
import {
  ATTACHED_DOCUMENT_TYPE_CODE,
  ATTACHED_DOCUMENT_ID_CODE,
  MAX_FILE_SIZE,
} from '../constants'
import { bytesToSize } from '../utils'

class AttachmentInformationModal extends React.Component {
  constructor(props) {
    super(props)
    this.formApi = null
    this.connectFormApi = this.connectFormApi.bind(this)
    this.saveInformations = this.saveInformations.bind(this)
  }

  // eslint-disable-next-line class-methods-use-this
  componentDidMount() {
    focusFirstInputElementInModal()
  }

  connectFormApi(formApi) {
    this.formApi = formApi
  }

  saveInformations() {
    const { values } = this.formApi.getFormState()
    const { attachments, saveAttachments } = this.props

    const attachmentsWithInformations = []
    attachments.forEach(attachment =>
      attachmentsWithInformations.push({
        ...attachment,
        [ATTACHED_DOCUMENT_TYPE_CODE]: get(values, `${ATTACHED_DOCUMENT_TYPE_CODE}-${attachment.id}`) || null,
      })
    )

    saveAttachments(attachmentsWithInformations)
  }

  render() {
    const {
      infoElement,
      attachments,
      rejectedFiles,
      renderField,
      cancelAttachmentInformations,
    } = this.props
    const attachedDocumentTypeField = find(infoElement.fields, field => field.code === ATTACHED_DOCUMENT_TYPE_CODE)
    const attachedDocumentIdField = find(infoElement.fields, field => field.code === ATTACHED_DOCUMENT_ID_CODE)

    const defaultValues = {}
    attachments.forEach((file) => {
      defaultValues[`${attachedDocumentIdField.code}-${file.id}`] = file.filename
      defaultValues[`${attachedDocumentTypeField.code}-${file.id}`] = null
    })

    return (
      <Modal
        show
        showCancel
        cancelMessage={messages.attachmentInformationModalCancel}
        showContinue
        continueDisabled={rejectedFiles.length === 1 && attachments.length === 0}
        titleMessage={messages.attachmentInformationModalTitle}
        continueMessage={messages.attachmentInformationModalContinue}
        onClickContinue={event => this.formApi.submitForm(event)}
        onClickCancel={() => cancelAttachmentInformations()}
        focusButton={attachments.length === 0}
      >
        <Form
          onSubmit={this.saveInformations}
          getApi={this.connectFormApi}
          defaultValues={defaultValues}
          render={() =>
            <form>
              {rejectedFiles.length > 0 &&
                <div id="dialog-message" className={'alert alert-danger'}>
                  <h4><FormattedMessage {...messages.declinedAttachmentsTitle} />:</h4>
                  {rejectedFiles.map(file =>
                    <Row>
                      <Col xs={12}>
                        <b>{file.name}</b>
                      </Col>
                      <Col xs={12}>
                        <FormattedMessage
                          {...messages.attachmentGenericUploadError}
                          values={{
                            maxFileSize: bytesToSize(MAX_FILE_SIZE),
                          }}
                        />
                      </Col>
                    </Row>
                  )}
                </div>
              }
              {attachments.map(file =>
                <Row key={file.id}>
                  <Col xs={6}>
                    {attachedDocumentIdField && renderField({
                      field: {
                        ...attachedDocumentIdField,
                        code: `${attachedDocumentIdField.code}-${file.id}`,
                      },
                      input: {
                        type: 'static',
                        placeholder: defaultValues[`${attachedDocumentIdField.code}-${file.id}`],
                      },
                    })}
                  </Col>
                  <Col xs={6}>
                    {attachedDocumentTypeField && renderField({
                      field: {
                        ...attachedDocumentTypeField,
                        code: `${attachedDocumentTypeField.code}-${file.id}`,
                      },
                      input: {
                        // eslint-disable-next-line max-len
                        ariaLabelledBy: `${attachedDocumentIdField.code}-${file.id} ${attachedDocumentTypeField.code}-${file.id}Label`,
                      },
                    })}
                  </Col>
                </Row>
              )}
            </form>
          }
        />
      </Modal>
    )
  }
}

export default AttachmentInformationModal
