import React from 'react'
import { get } from 'lodash'
import { Field } from 'react-form'

import DateOfBirth from '../../DateOfBirth/DateOfBirth'

import { required } from '../../../../../../utils/validation'

const ApplicantCustomsResponsibleDateOfBirth = (props) => {
  const validate = (value) => {
    if (get(props.field, 'mandatory')) {
      const requiredCheck = required(value)
      if (props.field.mandatory && requiredCheck) {
        return requiredCheck
      }
    }

    return null
  }

  const setDobValue = (value) => {
    props.formApi.setValue(props.field.code, value)
  }

  const dobFieldValue = props.formApi.getValue(props.field.code)

  return (
    <Field
      field={props.field.code}
      validate={validate}
    >
      {(fieldApi) => {
        const { value, error, setTouched } = fieldApi

        return <DateOfBirth
          field={props.field}
          setDobValue={setDobValue}
          error={error}
          setTouched={setTouched}
          initialValue={dobFieldValue}
          static={props.staticValue}
          value={value}
        />
      }}
    </Field>
  )
}

export default ApplicantCustomsResponsibleDateOfBirth
