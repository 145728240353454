import { differenceWith, get, isEqual } from 'lodash'
import { AUTH_TYPES } from 'src/constants'
import { hasAuthorization, getAllRoles, containsAnyRole, getSelectedAuthorizationOrDelegateRoles } from 'src/utils/auth'


export function getOrderedAuthenticatedServiceGroups(serviceGroups) {
  return sortServiceGroupsByOrder(getAuthenticatedServiceGroups(serviceGroups))
}

export function getAuthenticatedServicesByGroup(services, group) {
  return services.filter((service) => {
    const groupIdAuthenticated = service.extensionValues.find(
      extValue => extValue.extensionName === 'groupIdAuthenticated'
    ).value
    return groupIdAuthenticated === group.code
  })
}

export function getAllowedServices(services, auth, selectedAuthorizationObject) {
  return services.filter(service => isServiceAllowed(service, auth, selectedAuthorizationObject))
}

export function getDeniedServices(services, allowedServices) {
  return differenceWith(services, allowedServices, isEqual)
}

function getAuthenticatedServiceGroups(serviceGroups) {
  return serviceGroups.filter(serviceGroup =>
    serviceGroup.extensionValues.find(extValue => extValue.extensionName === 'usage').value === 'authenticated'
  )
}

function sortServiceGroupsByOrder(serviceGroups) {
  return serviceGroups.sort((serviceGroupA, serviceGroupB) => {
    const aOrder = parseInt(
      serviceGroupA.extensionValues.find(extValue => extValue.extensionName === 'order').value,
      10
    )
    const bOrder = parseInt(
      serviceGroupB.extensionValues.find(extValue => extValue.extensionName === 'order').value,
      10
    )

    return aOrder - bOrder
  })
}

function isServiceAllowed(service, auth, selectedAuthorizationObject) {
  if (!auth) {
    return false
  }

  const selectedAuthorizationType = get(selectedAuthorizationObject, 'type')

  if (!serviceAllowedForCurrentUserType(service, selectedAuthorizationType)) {
    return false
  }

  if (userIsPrivateUser(selectedAuthorizationType)) {
    return true
  }

  return userIsAuthorizedToUseService(service, auth, selectedAuthorizationObject)
}

function serviceAllowedForCurrentUserType(service, userType) {
  const customerTypes = service.extensionValues.find(extValue => extValue.extensionName === 'customerTypes').value
  if (customerTypes.length === 0) {
    return false
  }
  const customerTypesList = customerTypes.split(',')
  if (!customerTypesList.includes(userType)) {
    return false
  }

  return true
}

function userIsPrivateUser(userType) {
  return userType === AUTH_TYPES.private
}

function userIsAuthorizedToUseService(service, auth, selectedAuthorizationObject) {
  if (!serviceRequiresAuthorization(service)) {
    return true
  }

  return userHasRequiredRolesToUseService(service, auth, selectedAuthorizationObject)
}

function serviceRequiresAuthorization(service) {
  const authorizations = get(
    service.extensionValues.find(extValue => extValue.extensionName === 'enablingRoles'),
    'value'
  )

  if (!authorizations || authorizations.length === 0) {
    return false
  }

  return true
}

function userHasRequiredRolesToUseService(service, auth, selectedAuthorizationObject) {
  const enablingRoles = getEnablingRoles(service)

  if (!serviceAllowsRepresentative(service)) {
    return containsAnyRole(enablingRoles, getSelectedAuthorizationOrDelegateRoles(selectedAuthorizationObject, auth.selectedAuthorization))
  }

  return hasAuthorization(getAllRoles(auth.authorizations), enablingRoles)
}

function getEnablingRoles(service) {
  const enablingRolesRaw = service.extensionValues.find(
    extValue => extValue.extensionName === 'enablingRoles'
  ).value

  return parseEnablingRoles(enablingRolesRaw)
}

function parseEnablingRoles(enablingRolesStr) {
  return convertEnablingRolesToList(eraseWhiteSpaceCharacters(enablingRolesStr))
}

function convertEnablingRolesToList(enablingRolesStr) {
  return enablingRolesStr.split(',')
}

function eraseWhiteSpaceCharacters(str) {
  return str.replace(/\s/g, '')
}

function serviceAllowsRepresentative(service) {
  return get(
    service.extensionValues.find(extValue => extValue.extensionName === 'representativeAllowed'),
    'value'
  ) === 'true'
}
