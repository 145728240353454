export const FORM_TYPE_GROUP = 'CUSTOMS_INFORMATION'
export const CUSTOMS_INFORMATION_FORM_ROUTE_PATH = 'customs-information'
export const SUMMARY_ROUTE_PATH = 'summary'
export const CUSTOMER_TYPE_PRIVATE = 'private'
export const CUSTOMER_TYPE_BUSINESS = 'business'
export const CODE_COMMODITY_CODE = 'CI1'
export const CODE_POSTPACKAGE = 'CI2'
export const CODE_ALAND = 'CI3'
export const CODE_OTHER = 'CI4'
export const DEFAULT_PHONE = '+358'

export const FORMS_DESTROY_STATE = '@@nakki/forms/FORMS_DESTROY_STATE'
export const FORMS_SAVE_DATA = '@@nakki/forms/FORMS_SAVE_DATA'
