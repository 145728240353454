
import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { RepresentativeInformation } from 'src/components/representative'
import InfoElementHeaderArea from 'src/components/form_v2/InfoElementHeaderArea'
import messages from 'src/components/customer/messages'

export default function Representative(props) {
  const { infoElement, locale, isPerson } = props

  return (
    <Col sm={6} xs={12}>
      <Row>
        <Col xs={12}>
          <InfoElementHeaderArea
            infoElement={infoElement}
            locale={locale}
            customTitle={<FormattedMessage {...messages.representativeInformationTitle} />}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <RepresentativeInformation isPerson={isPerson} />
        </Col>
      </Row>
    </Col>
  )
}
