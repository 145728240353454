import { defineMessages } from 'react-intl'

export default defineMessages({
  statusDraft: {
    id: 'permits.listing.statusDraft',
    description: 'Value for permits list status "draft"',
    defaultMessage: '?? Draft',
  },
  statusError: {
    id: 'permits.listing.statusError',
    description: 'Value for permits list status "error"',
    defaultMessage: '?? Error',
  },
  statusReady: {
    id: 'permits.listing.statusReady',
    description: 'Value for permits list status "ready"',
    defaultMessage: '?? Ready',
  },
  statusInvalidated: {
    id: 'permits.listing.statusInvalidated',
    description: 'Value for permits list status "invalidated"',
    defaultMessage: '?? Invalidated',
  },
  statusDeleted: {
    id: 'permits.listing.statusDeleted',
    description: 'Warning message for authorisation status deleted',
    defaultMessage: '?? This declaration has status deleted.',
  },
  errorFetchingCustomers: {
    id: 'permits.headers.errorFetchingCustomers',
    description: 'Error message shown when customers could not be fetched.',
    defaultMessage: '?? Could not fetch customer information.',
  },
  newPermitApplication: {
    id: 'permits.newPermitApplication',
    description: 'Label for create new application button in authorisations listing',
    defaultMessage: '?? Create new authorisation application',
  },
  permitSelectorTitle: {
    id: 'permits.permitSelector.title',
    description: 'Title for authorisation selection modal',
    defaultMessage: '?? Create new authorisation application',
  },
  chooseGuaranteeAuthorisation: {
    id: 'permits.permitSelector.chooseGuaranteeAuthorisation',
    description: 'Label for permit type selection',
    defaultMessage: '?? Choose guarantee authorisation',
  },
  permitSelectorContinue: {
    id: 'permits.permitSelector.continue',
    description: 'Button value for authorisation selection operation "continue"',
    defaultMessage: '?? Continue',
  },
  permitSelectorCancel: {
    id: 'permits.permitSelector.cancel',
    description: 'Button value for authorisation selector cancel operation',
    defaultMessage: '?? Cancel',
  },
  labelChoosePermitType: {
    id: 'permits.permitSelector.labelChoosePermitType',
    description: 'Title for authorisation type select',
    defaultMessage: '?? Select authorisation type',
  },
  labelChoosePermit: {
    id: 'permits.permitSelector.labelChoosePermit',
    description: 'Title for authorisations list',
    defaultMessage: '?? Select authorisation',
  },
  placeholderChoosePermitCategory: {
    id: 'permits.permitSelector.placeholderChoosePermitCategory',
    description: 'Placeholder for permit type select',
    defaultMessage: '?? Select what kind of authorisation you are going to apply',
  },
  loadingPermitTypes: {
    id: 'permits.permitSelector.loading',
    description: 'Message dislayed while loading permit types',
    defaultMessage: '?? Loading permit types',
  },
  usePreviousApplication: {
    id: 'permits.permitSelector.usePreviousApplication',
    description: 'Text for checkbox for copying previous application information',
    defaultMessage: '?? Use previous application informations if applicable',
  },
  missingEori: {
    id: 'permits.eoriMissing',
    description: 'Message shown when customer EORI is missing',
    defaultMessage: '?? Missing EORI number.',
  },
  eoriLinkText: {
    id: 'permits.eoriLink',
    description: 'Link to EORI registration form',
    defaultMessage: '?? Please get EORI number',
  },
  permitsEnglishNotSupported: {
    id: 'permits.englishNotSupported',
    description: 'Text in modal',
    defaultMessage: '?? English is not supported. Switch to Finnish or Swedish to continue.',
  },
  permitsLanguageNotSupportedHeading: {
    id: 'permits.languageNotSupported',
    description: 'Heading in modal',
    defaultMessage: '?? Language not supported',
  },
  changeLanguageFi: {
    id: 'permits.englishNotSupported.changeLanguageFi',
    description: 'Text in modal button',
    defaultMessage: '?? Switch to Finnish and continue',
  },
  changeLanguageSv: {
    id: 'permits.englishNotSupported.changeLanguageSv',
    description: 'Text in modal button',
    defaultMessage: '?? Switch to Swedish and continue',
  },
  cancel: {
    id: 'permits.englishNotSupported.cancel',
    description: 'Text in modal button',
    defaultMessage: '?? Cancel',
  },
  titleGuaranteePermitGroup: {
    id: 'permits.permitSelector.titleGuaranteePermitGroup',
    description: 'Name of guarantee permit group',
    defaultMessage: '?? Guarantee',
  },
  titleSimplificationPermitGroup: {
    id: 'permits.permitSelector.titleSimplificationPermitGroup',
    description: 'Name of simplification permit group',
    defaultMessage: '?? Simplifications',
  },
  titleWarehousingPermitGroup: {
    id: 'permits.permitSelector.titleWarehousingPermitGroup',
    description: 'Name of warehousing permit group',
    defaultMessage: '?? Warehousing',
  },
  titleTransitPermitGroup: {
    id: 'permits.permitSelector.titleTransitPermitGroup',
    description: 'Name of transit permit group',
    defaultMessage: '?? Transit',
  },
  titleSpecialProceduresPermitGroup: {
    id: 'permits.permitSelector.titleSpecialProceduresPermitGroup',
    description: 'Name of special procedures permit group',
    defaultMessage: 'Special procedures',
  },
  titleReliefPermitGroup: {
    id: 'permits.permitSelector.titleReliefPermitGroup',
    description: 'Name of relief permit group',
    defaultMessage: 'Relief',
  },
  titleOtherPermitGroup: {
    id: 'permits.permitSelector.titleOtherPermitGroup',
    description: 'Name of other permit group',
    defaultMessage: 'Other',
  },
  headingGoodCode: {
    id: 'permits.itemsList.headingGoodCode',
    description: 'Heading of goodsToBePlacedUnderProcedure infoelement',
    defaultMessage: '?? Code',
  },
  headingEquivalentGoodCode: {
    id: 'permits.itemsList.headingEquivalentGoodCode',
    description: 'Heading of equivalentGood infoelement',
    defaultMessage: '?? Equivalent good code',
  },
  headingGoodDescription: {
    id: 'permits.itemsList.headingGoodDescription',
    description: 'Heading of good description',
    defaultMessage: '?? Description',
  },
})
