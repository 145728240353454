import { defineMessages } from 'react-intl'

/**
 * Message definitions for all possible api message codes
 */
const messages = defineMessages({
  forbidden: {
    id: 'api.forbidden',
    description: 'Error notification message on 403 forbidden server response',
    defaultMessage: 'You are forbidden to access the requested service',
  },
  logoutFailed: {
    id: 'api.logoutFailed',
    description: 'Unknown error in authentication microservice logout operation',
    defaultMessage: 'Could not log out. Please try again or contact support.',
  },
  networkError: {
    id: 'api.networkError',
    description: 'Error notification message on network error (no server response)',
    defaultMessage: 'Error in network. Please try again later.',
  },
  notFound: {
    id: 'api.notFound',
    description: 'Error notification message on 404 not found server response',
    defaultMessage: 'Requested resource was not found.',
  },
  unexpectedError: {
    id: 'api.unexpectedError',
    description: 'Error notification message on unexpected server error response',
    defaultMessage: 'Unexpected server error. Please try again later.',
  },
  serviceUnavailable: {
    id: 'api.serviceUnavailable',
    description: 'Error notification message on 503 service unavailable error response',
    defaultMessage: 'Service is currently not available. Please try again later.',
  },
  unauthorized: {
    id: 'api.unauthorized',
    description: 'Error notification message on 401 unauthorized error response',
    defaultMessage: 'You are not authorized to requested service.',
  },
  invalidToken: {
    id: 'api.invalidToken',
    description: 'Error notification message for expired session error',
    defaultMessage: 'Your session has expired. Please log in.',
  },
  authenticationFailure: {
    id: 'api.loginFailure',
    description: 'General error message shown in failed login',
    defaultMessage: 'Error in login',
  },
  noCompanyUserRoles: {
    id: 'api.noCompanyUserRoles',
    description: 'Error message shown in failed login when user does not have role companyUser',
    defaultMessage: 'You do not have needed Katso role for employment',
  },
  suomiFiAuthorizationCancelled: {
    id: 'api.suomiFiAuthorizationCancelled',
    description: 'Suomi.fi authorization cancelled',
    defaultMessage: 'Authorization cancelled.',
  },
  suomiFiAuthorizationFailure: {
    id: 'api.suomiFiAuthorizationFailure',
    description: 'Suomi.fi authorization failure',
    defaultMessage: 'Authorization failed.',
  },
  rejectedAuthorizationsError: {
    id: 'api.rejectedAuthorizationsError',
    description: 'Company id not accepted',
    // eslint-disable-next-line max-len
    defaultMessage: 'Authorization failure. Company id must have been issued in the EU country and it has to be in VAT format, and it\'s length cannot exceed 14 characters..',
  },
  authorizationNoteHeader: {
    id: 'api.authorizationNoteHeader',
    description: 'Authorization note',
    defaultMessage: 'Authorization note',
  },
  authorizationErrorHeader: {
    id: 'api.authorizationErrorHeader',
    description: 'Authorization error',
    defaultMessage: 'Authorization error',
  },
  onBehalfInterruptHeader: {
    id: 'api.onBehalfInterruptHeader',
    description: 'interrupt OnBehalf',
    defaultMessage: 'On behalf interrupt header',
  },
})

export default messages
