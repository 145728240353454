import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { find, endsWith, xor, get, indexOf, isEmpty, includes } from 'lodash'
import { messages as validationMessages } from 'src/utils/validation'
import {
  PERMIT_DEBT_GUARANTEE_PROCEDURE_TYPES,
  PERMIT_LIABILITY_GUARANTEE_PROCEDURE_TYPES,
  PERMIT_DEBT_GUARANTEE_PROCEDURE_TYPE_SELECTION_VISIBLE,
  PERMIT_LIABILITY_GUARANTEE_PROCEDURE_TYPE_SELECTION_VISIBLE,
  PERMIT_GUARANTEE_PROCEDURE_TYPE_ALL_VISIBLE,
} from '../constants'

export default class CustomsProcedureTypeReadOnly extends React.Component {
  componentDidMount() {
    const { formApi, structure } = this.props
    const applicationTypeCode = get(structure, 'code')
    const hasNoCustomsProcedureTypeValue = isEmpty(get(formApi.values, 'customsProcedureType.customsProcedureType'))

    if (includes(PERMIT_GUARANTEE_PROCEDURE_TYPE_ALL_VISIBLE, applicationTypeCode)) {
      if (hasNoCustomsProcedureTypeValue) {
        formApi.setError('customsProcedureType.customsProcedureType', validationMessages.required)
      }
    } else {
      const liabilityGuaranteeError = get(formApi.errors, 'customsProcedureType.liabilityGuaranteeProcedureType')
      const debtGuaranteeError = get(formApi.errors, 'customsProcedureType.debtGuaranteeProcedureType')

      const hasMissingDebtGuaranteeProcedureType =
        includes(PERMIT_DEBT_GUARANTEE_PROCEDURE_TYPE_SELECTION_VISIBLE, applicationTypeCode) &&
        !debtGuaranteeError &&
        hasNoCustomsProcedureTypeValue

      const hasMissingLiabilityGuaranteeProcedureType =
        includes(PERMIT_LIABILITY_GUARANTEE_PROCEDURE_TYPE_SELECTION_VISIBLE, applicationTypeCode) &&
        !liabilityGuaranteeError &&
        hasNoCustomsProcedureTypeValue

      if (hasMissingDebtGuaranteeProcedureType) {
        formApi.setError('customsProcedureType.debtGuaranteeProcedureType', validationMessages.required)
      }
      if (hasMissingLiabilityGuaranteeProcedureType) {
        formApi.setError('customsProcedureType.liabilityGuaranteeProcedureType', validationMessages.required)
      }
    }
  }

  render() {
    const { infoElement, locale, renderField, structure } = this.props
    const applicationTypeCode = get(structure, 'code')
    const customsProcedureTypeField = find(infoElement.fields, field => endsWith(field.code, 'customsProcedureType'))

    const fieldProps = {
      infoElementOverride: {
        mandatory: false,
      },
      input: {
        multiple: true,
        type: 'codesetAutocomplete',
      },
      staticValue: true,
    }

    const otherFields = xor(infoElement.fields, [
      customsProcedureTypeField,
    ])

    let debtGuaranteeProcedureTypeField = null
    let liabilityGuaranteeProcedureTypeField = null

    if (!includes(PERMIT_GUARANTEE_PROCEDURE_TYPE_ALL_VISIBLE, applicationTypeCode)) {
      const debtGuaranteeProcedureMandatory = includes(
        PERMIT_DEBT_GUARANTEE_PROCEDURE_TYPE_SELECTION_VISIBLE,
        applicationTypeCode)
      const liabilityGuaranteeProcedureTypeMandatory = includes(
        PERMIT_LIABILITY_GUARANTEE_PROCEDURE_TYPE_SELECTION_VISIBLE,
        applicationTypeCode)
      debtGuaranteeProcedureTypeField = {
        ...customsProcedureTypeField,
        code: 'customsProcedureType.debtGuaranteeProcedureType',
        codesetValidValues: PERMIT_DEBT_GUARANTEE_PROCEDURE_TYPES,
        mandatory: debtGuaranteeProcedureMandatory,
        name: {
          en: 'Debt guarantee procedures',
          fi: 'Velkavakuusmenettelyt',
          sv: 'Skuldgarantiförfarande',
        },
      }
      liabilityGuaranteeProcedureTypeField = {
        ...customsProcedureTypeField,
        code: 'customsProcedureType.liabilityGuaranteeProcedureType',
        codesetValidValues: PERMIT_LIABILITY_GUARANTEE_PROCEDURE_TYPES,
        mandatory: liabilityGuaranteeProcedureTypeMandatory,
        name: {
          en: 'Liability guarantee procedures',
          fi: 'Vastuuvakuusmenettelyt',
          sv: 'Ansvarsgarantiförfarande',
        },
      }
    }

    return (
      <div>
        <Row>
          <Col xs={12}>
            <h4>{infoElement.name[locale]}</h4>
          </Col>
        </Row>
        <Row>
          {includes(PERMIT_GUARANTEE_PROCEDURE_TYPE_ALL_VISIBLE, applicationTypeCode) && <Col
            xs={12}
          >
            {renderField({
              field: customsProcedureTypeField,
              ...fieldProps,
            })}
          </Col>}
          {!includes(PERMIT_GUARANTEE_PROCEDURE_TYPE_ALL_VISIBLE, applicationTypeCode) &&
            indexOf(PERMIT_DEBT_GUARANTEE_PROCEDURE_TYPE_SELECTION_VISIBLE, applicationTypeCode) >= 0 &&
            <Col xs={12}>
              {debtGuaranteeProcedureTypeField && renderField({
                field: debtGuaranteeProcedureTypeField,
                ...fieldProps,
              })}
            </Col>
          }
          {!includes(PERMIT_GUARANTEE_PROCEDURE_TYPE_ALL_VISIBLE, applicationTypeCode) &&
            indexOf(PERMIT_LIABILITY_GUARANTEE_PROCEDURE_TYPE_SELECTION_VISIBLE, applicationTypeCode) >= 0 &&
            <Col xs={12}>
              {liabilityGuaranteeProcedureTypeField && renderField({
                field: liabilityGuaranteeProcedureTypeField,
                ...fieldProps,
              })}
            </Col>
          }
          <Col xs={12}>
            {otherFields && otherFields.map(field => renderField({
              field,
              ...fieldProps,
            }))}
          </Col>
        </Row>
      </div>
    )
  }
}
