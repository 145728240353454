import React from 'react'
import { Col, Button, Row } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import Dropzone from 'react-dropzone'
import { pick, isObject, has, get } from 'lodash'
import Loader from 'src/components/Loader'
import ErrorBoundary from 'src/components/ErrorBoundary'
import Icon from 'src/components/Icon'
import showNotification from 'src/utils/notifications'
import messages from '../messages'
import { validateRowData, readAndParseCSVFile, mapRowData } from '../utils'
import {
  PERMIT_GOODS_UNDER_PROCEDURE_BASIC_IMPORT_FIELD_NUMBER,
  PERMIT_GOODS_UNDER_PROCEDURE_ALL_IMPORT_FIELD_NUMBER,
  PERMIT_GOODS_UNDER_PROCEDURE_COLUMN_MAP,
  PERMIT_EQUIVALENT_GOOD_BASIC_IMPORT_FIELD_NUMBER,
  PERMIT_EQUIVALENT_GOOD_ALL_IMPORT_FIELD_NUMBER,
  PERMIT_EQUIVALENT_GOOD_COLUMN_MAP,
  PERMIT_PROCESSED_PRODUCT_BASIC_IMPORT_FIELD_NUMBER,
  PERMIT_PROCESSED_PRODUCT_ALL_IMPORT_FIELD_NUMBER,
  PERMIT_PROCESSED_PRODUCT_COLUMN_MAP,
  PERMIT_REPLACEMENT_PRODUCT_BASIC_IMPORT_FIELD_NUMBER,
  PERMIT_REPLACEMENT_PRODUCT_ALL_IMPORT_FIELD_NUMBER,
  PERMIT_REPLACEMENT_PRODUCT_COLUMN_MAP,
} from '../constants'
import {
  PERMIT_GOODS_FIELD_PREFIX,
  PERMIT_EQUIVALENT_GOOD_FIELD_PREFIX,
  PERMIT_PROCESSED_PRODUCT_FIELD_PREFIX,
  PERMIT_REPLACEMENT_PRODUCT_FIELD_PREFIX,
} from '../../PermitPage/constants'

export default class Import extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
    }
    if (get(props, 'infoElement.code') === PERMIT_EQUIVALENT_GOOD_FIELD_PREFIX) {
      this.fieldMapInFile = PERMIT_EQUIVALENT_GOOD_COLUMN_MAP
    } else if (get(props, 'infoElement.code') === PERMIT_GOODS_FIELD_PREFIX) {
      this.fieldMapInFile = PERMIT_GOODS_UNDER_PROCEDURE_COLUMN_MAP
    } else if (get(props, 'infoElement.code') === PERMIT_PROCESSED_PRODUCT_FIELD_PREFIX) {
      this.fieldMapInFile = PERMIT_PROCESSED_PRODUCT_COLUMN_MAP
    } else if (get(props, 'infoElement.code') === PERMIT_REPLACEMENT_PRODUCT_FIELD_PREFIX) {
      this.fieldMapInFile = PERMIT_REPLACEMENT_PRODUCT_COLUMN_MAP
    }
    this.rowLimit = parseInt(get(this.props.infoElement, 'repeat'), 10)
  }

  getOptions() {
    const { infoElement } = this.props
    let min = 0
    let max = 0
    switch (infoElement.code) {
    case PERMIT_GOODS_FIELD_PREFIX:
      min = PERMIT_GOODS_UNDER_PROCEDURE_BASIC_IMPORT_FIELD_NUMBER
      max = PERMIT_GOODS_UNDER_PROCEDURE_ALL_IMPORT_FIELD_NUMBER
      break
    case PERMIT_EQUIVALENT_GOOD_FIELD_PREFIX:
      min = PERMIT_EQUIVALENT_GOOD_BASIC_IMPORT_FIELD_NUMBER
      max = PERMIT_EQUIVALENT_GOOD_ALL_IMPORT_FIELD_NUMBER
      break
    case PERMIT_PROCESSED_PRODUCT_FIELD_PREFIX:
      min = PERMIT_PROCESSED_PRODUCT_BASIC_IMPORT_FIELD_NUMBER
      max = PERMIT_PROCESSED_PRODUCT_ALL_IMPORT_FIELD_NUMBER
      break
    case PERMIT_REPLACEMENT_PRODUCT_FIELD_PREFIX:
      min = PERMIT_REPLACEMENT_PRODUCT_BASIC_IMPORT_FIELD_NUMBER
      max = PERMIT_REPLACEMENT_PRODUCT_ALL_IMPORT_FIELD_NUMBER
      break
    default:
      break
    }
    return {
      rowLimit: this.rowLimit,
      featureCountInFile: {
        min,
        max,
      },
    }
  }

  handleResponse(response) {
    const { addNew, locale, infoElement, codesets } = this.props
    const { codeFieldName } = infoElement
    const fields = infoElement.fields.filter(field => field.visible).map(field => field.code)

    response.forEach((item) => {
      if (!isNaN(item[0])) { // filter out header row
        const mappedRow = mapRowData(item, fields, this.fieldMapInFile)
        const modifiedItem = { ...mappedRow }

        validateRowData(modifiedItem, {
          fields: infoElement.fields,
          locale,
          codesets,
          codeFieldNames: [`${infoElement.code}${codeFieldName}`],
        })
          .then((validationErrors) => {
            addNew(pick(modifiedItem, fields), validationErrors)
          })
      }
    })
  }

  importFile(file) {
    const { formatMessage } = this.props
    this.setState({ isLoading: true })
    const options = this.getOptions()

    readAndParseCSVFile(file[0], options)
      .then((response) => {
        this.handleResponse(response)
      })
      .catch((err) => {
        this.setState({ isLoading: false })
        showNotification({
          level: 'error',
          message: isObject(err) && has(err, 'message') ? formatMessage(err.message, err.values || {}) : err,
          modal: true,
          title: messages.error,
        })
      })
  }

  render() {
    const {
      isLoading,
    } = this.state

    return (
      <div>
        <ErrorBoundary>
          {isLoading && <Loader blocking />}
          {!this.isReadOnly &&
            <Row>
              <Col xs={12}>
                <Dropzone
                  ref={(node) => {
                    this.dropzoneRef = node
                  }}
                  onDrop={accepted => this.importFile(accepted)}
                  disableClick
                  multiple={false}
                  className="dropzone"
                  activeClassName="dropzone-active"
                >
                  <div className="dropzone-buttons">
                    <Button
                      onClick={() => {
                        this.dropzoneRef.open()
                      }}
                      bsStyle="primary"
                      id-qa-test="btn-import-file"
                    >
                      <Icon name="addnew" /><FormattedMessage {...messages.addFile} />
                    </Button>
                  </div>
                  <div className="dropzone-help centered">
                    <FormattedMessage {...messages.addFileAdditionalHelp} />
                  </div>
                </Dropzone>
              </Col>
            </Row>
          }
        </ErrorBoundary>
      </div>
    )
  }
}
